import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  sliderWrapper: {
    width: "110px",
    "& .slick-track": {
      display: "flex",
      alignItems: "center",
      marginLeft: "unset",
      "& .slick-slide": {
        marginTop: "5px",
        height: "30px",
        width: "30px!important",
        marginRight: "7px",
      },
    },
    "& .slick-arrow.slick-prev": {
      left: "-10px",
    },
    "& .slick-arrow::before": {
      content: '""',
    },
    "& .slick-disabled": {
      cursor: "default",
    },
  },

  avatar: {
    height: "30px",
    width: "30px!important",
  },

  // thirdPartyLabel: {
  //   position: "absolute",
  //   top: "2px",
  //   marginLeft: "3px",
  //   zIndex: 80,
  //   width: "23px",
  //   height: "10px",
  //   fontSize: "4px",
  //   fontWeight: 500,
  //   textAlign: "center",
  //   alignItems: "center",
  //   lineHeight: 2.2,
  //   color: "#000000",
  //   border: "1px solid #D5D9D9",
  //   borderRadius: "4px",
  //   background: "#F8F8F8",
  // }
}));

export default useStyles;
