import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";
import {
  Typography,
  Stack,
  Box,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import { useAdmin, replaceItemById } from "helpers/helpers";
import { setConfirmIsOpenAction } from "redux/actions/confirmDialogs";
import {
  getRoutesService,
  getRouteSummariesService,
  updateRouteService,
} from "services/routes";
import { error, success } from "utils/notifications";
import AssignedRepsPopper from "../CustomersPage/components/CustomersTab/components/AssignedRepsPopper/AssignedRepsPopper";
import {
  StyledMenu,
  StyledButton,
  InfiniteLoaderWrapper,
  TableHeaderControlPanel,
} from "components";
import {
  RoutesFilter,
  RoutesHeader,
  RoutesSummaries,
  RoutesItem,
} from "./components";

import useStyles from "./styles";
import EmptyScreen from "components/EmptyScreen/EmptyScreen";
import RepsAssignedDrawer from "components/RepsAssignedDrawer/RepsAssignedDrawer";
import { useRepsPermissions } from "helpers/hooks";
import { useRoutesBulk } from "./useRoutesBulk";
import { useAssignedReps } from "./useAssignedReps";
import { handleConfirmAction } from "Pages/DiscountsPage/DiscountsPage.helpers";

const RoutesPage = () => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.routes?.create_edit;
    }
  }, [repPermissions]);

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [assignedPopperState, setAssignedPopperState] = useState({
    anchorEl: null,
    rep: null,
    open: false,
  });

  const [routesState, setRoutesState] = useState({
    list: [],
    count: 0,
    loading: false,
    routesExist: false,
  });
  const [routesSummaries, setRoutesSummaries] = useState({});
  const [sortBy, setSortBy] = useState({
    sort_by_name: "",
    sort_by_last_check_in: "",
    sort_by_total_stops: "",
  });
  const [fetchQuery, setFetchQuery] = useState({
    page: 1,
  });
  const [loading, setLoading] = useState(false);

  const fetchRoutes = useCallback(async (query) => {
    setFetchQuery((prev) => ({ ...prev, ...query }));
    const {
      representative_id,
      priority,
      total_stops,
      type,
      search,
      didCancel,
      page,
      sort_by_name,
      sort_by_last_check_in,
      sort_by_total_stops,
    } = query;
    setLoading(true);
    setRoutesState((prev) => ({
      ...prev,
      loading: page === 1,
    }));

    getRoutesService({
      representative_id: representative_id?.value,
      priority,
      total_stops_greater: total_stops?.total_stops_greater?.value,
      total_stops_lesser: total_stops?.total_stops_lesser?.value,
      type,
      search,
      limit: 15,
      page,
      sort_by_name,
      sort_by_last_check_in,
      sort_by_total_stops,
    })
      .then(({ rows: list, count, existData: routesExist }) => {
        if (didCancel) return;
        setRoutesState((prev) => ({
          ...prev,
          list: page === 1 ? [...list] : [...prev.list, ...list],
          count,
          routesExist,
          loading: false,
        }));
        setLoading(false);
      })
      .catch(() => {
        setRoutesState((prev) => ({
          ...prev,
          loading: false,
        }));
        setLoading(false);
      });
  }, []);

  const [menuState, setMenuState] = useState({
    anchorEl: null,
    open: false,
    route: null,
  });

  const handleSetAssignedPopover = (anchorEl, rep, open) => {
    setAssignedPopperState({
      anchorEl,
      rep,
      open,
    });
  };

  const handleDotsClick = (e, route) => {
    setMenuState({ anchorEl: e.target, open: true, route });
  };

  const handleMenuClick = (callback) => {
    setMenuState((prev) => ({ ...prev, open: false }));
    callback();
  };

  const handleViewRoute = (routeId) => {
    navigate(`view/${routeId}`);
  };

  const fetchSummaries = useCallback(() => {
    getRouteSummariesService().then((res) => {
      setRoutesSummaries(res);
    });
  }, []);

  useEffect(() => {
    fetchSummaries();
  }, [fetchSummaries]);

  const handleAssignReps = (reps) => {
    setRoutesState((prev) => ({
      ...prev,
      loading: true,
    }));

    updateRouteService({ representativesIds: reps }, menuState.route.id)
      .then((res) => {
        success("Reps assigned successfully!");
        const newRoutes = replaceItemById(
          res,
          menuState.route,
          routesState.list
        );
        setRoutesState((prev) => ({
          ...prev,
          list: [...newRoutes],
        }));
        setRoutesState((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        setRoutesState((prev) => ({
          ...prev,
          loading: false,
        }));
        error(err?.response?.data?.message);
      });
  };

  const [selectedRepData, setSelectedRepData] = useState(null);

  const handleUpdateAssignedRepsOrder = useCallback(
    async ({ repId, representativesIds, routeId }) => {
      try {
        const newRepresentativesIdsOrder = [
          ...new Set([repId, ...representativesIds]),
        ];

        const res = await updateRouteService(
          { representativesIds: newRepresentativesIdsOrder },
          routeId
        );

        const tempList = cloneDeep(routesState?.list);
        const index = tempList?.findIndex((r) => r.id === routeId);

        tempList[index] = res;

        setRoutesState((prev) => ({ ...prev, list: tempList }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setSelectedRepData(null);
      }
    },
    [routesState?.list]
  );

  useEffect(() => {
    if (selectedRepData) {
      const { selectedRep, representativesIds, routeId } =
        selectedRepData || {};
      const repId = selectedRep?.id;
      handleUpdateAssignedRepsOrder({ repId, representativesIds, routeId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRepData]);

  const {
    setSelectedRoutes,
    selectedRoutes,
    handleCheckRoute,
    isAlreadySelected,
    handleSetLoading,
    handleGetAllRoutes,
    handleGetQuickActions,
    handleFetchAndSelectAll,
    handleGetDropDownActions,
    handleSingleRoutesActions,
    handleCheckAvailableRoutes,
  } = useRoutesBulk({
    routesState,
    setRoutesState,
    setMenuState,
    routesList: routesState.list,
    menuState,
    handleMenuClick,
    fetchQuery,
    currentUser,
  });

  const {
    assignDialogState,
    handleAssign,
    setAssignDialogState,
    handleOpenTransfer,
    bulkAssignRepresentatives,
  } = useAssignedReps({
    selectedRoutes,
    handleGetAllRoutes,
    fetchQuery,
    handleSetLoading,
  });

  const QUICK_ACTIONS = handleGetQuickActions({
    handleAssign,
    handleOpenTransfer,
  });

  const DROPDOWN_ACTIONS = handleGetDropDownActions({
    handleAssign,
    handleOpenTransfer,
  });

  const SINGLE_ROUTE_ACTIONS = handleSingleRoutesActions({ handleAssign });

  const handleSetSortBy = (field) => {
    const sortParams = {
      sort_by_name: null,
      sort_by_last_check_in: null,
      sort_by_total_stops: null,
    };

    sortParams[field] = sortBy[field] === "asc" ? "desc" : "asc";

    setSortBy(sortParams);
  };

  const handleSave = (data) => {
    if (selectedRoutes.length)
      return bulkAssignRepresentatives(data, selectedRoutes);

    const repIds = data.map((r) => r.id);
    return handleAssignReps(repIds);
  };

  const allAvailableChecked = useMemo(
    () => selectedRoutes.length === routesState.list.length,
    [routesState.list.length, selectedRoutes.length]
  );

  const selectedCount = useMemo(
    () => routesState.count || 0,
    [routesState.count]
  );

  const handleDeleteAllReps = useCallback(() => {
    dispatch(
      handleConfirmAction({
        title: "Remove all reps",
        text: (
          <Typography
            fontWeight="400"
            fontSize="13px"
            color="#363531"
            whiteSpace="pre-line"
            marginTop="4px"
            marginBottom="8px"
          >
            Are you sure you want to remove all assigned reps for selected
            customers?
          </Typography>
        ),
        confirmColor: "confirmDelete",
        onConfirm: () => {
          bulkAssignRepresentatives(
            [],
            assignDialogState?.routes?.length
              ? [...assignDialogState?.routes]
              : [...selectedRoutes]
          );
          dispatch(setConfirmIsOpenAction(false));
          setAssignDialogState((prev) => ({
            ...prev,
            open: false,
            reps: [],
            routes: [],
          }));
        },
      })
    );
  }, [
    dispatch,
    bulkAssignRepresentatives,
    assignDialogState?.routes,
    selectedRoutes,
    setAssignDialogState,
  ]);

  return (
    <Box>
      <RepsAssignedDrawer
        isOpen={assignDialogState.open}
        handleSetData={handleSave}
        handleClose={() =>
          setAssignDialogState((prev) => ({
            ...prev,
            open: false,
            reps: [],
            routes: [],
          }))
        }
        assignedRepresentatives={assignDialogState.reps}
        withoutThirdParty
        skipDisabledBtn
        submitBtnLabel="Update"
        handleDeleteAllReps={handleDeleteAllReps}
      />
      <AssignedRepsPopper
        anchorEl={assignedPopperState.anchorEl}
        rep={assignedPopperState.rep}
        open={assignedPopperState.open}
      />
      <StyledMenu
        anchorEl={menuState.anchorEl}
        isOpen={menuState.open}
        handleClose={() => setMenuState((prev) => ({ ...prev, open: false }))}
      >
        {SINGLE_ROUTE_ACTIONS.map(
          ({ sx, label, disabled, onClick, show }, index) => (
            <Box key={index}>
              {show && (
                <MenuItem
                  sx={sx}
                  disabled={disabled}
                  onClick={(e) => onClick(e)}
                >
                  {label}
                </MenuItem>
              )}
            </Box>
          )
        )}
      </StyledMenu>
      <Box className={classes.headerWrapper}>
        <Typography className={classes.pageTitle}>Routes</Typography>
        <StyledButton
          disabled={disabledCreateEdit || isAdmin}
          label="New Route"
          variant="contained"
          className={classes.headerButton}
          onClick={() => navigate("new")}
        />
      </Box>
      <Box className={classes.bodyWrapper}>
        <RoutesSummaries summaries={routesSummaries} />
        <RoutesFilter {...{ fetchRoutes, sortBy, setSelectedRoutes }} />

        <TableHeaderControlPanel
          actionWrapperProps={{
            sx: { padding: "0 20px 0 20px !important" },
          }}
          checkedCount={selectedRoutes.length}
          actionsList={QUICK_ACTIONS}
          loading={routesState.loading || loading}
          dropDownActions={DROPDOWN_ACTIONS}
          onSelectAll={handleFetchAndSelectAll}
          hasCheckedItems={!!selectedRoutes.length}
          availableSelectCount={selectedCount}
          selectName="route"
          onSelectVisible={handleCheckAvailableRoutes}
          cancelSelection={() => setSelectedRoutes([])}
          headerComponent={
            <RoutesHeader
              isChecked={!!routesState.list.length && allAvailableChecked}
              quickSort={sortBy}
              {...{
                handleCheckAvailableRoutes,
                handleSetSortBy,
              }}
            />
          }
        />
        <Stack
          sx={{
            "& #content-wrapper > div > div": {
              border: "0.5px solid #CCCCCC",
              borderWidth: "0px 0.5px 0.5px 0.5px",
            },
            "& > div > div > div > div:last-of-type": {
              borderRadius: "0 0 4px 4px",
            },
          }}
          id="routes-scroll"
        >
          {routesState.loading ? (
            <Box
              sx={{
                height: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size="24px" />
            </Box>
          ) : routesState.list.length ? (
            <InfiniteLoaderWrapper
              itemsList={routesState.list}
              itemsCount={routesState.count}
              offsetHeight={306}
              offsetWidth={61}
              loading={loading}
              hideScroll
              tableStyle={{ borderWidth: "0px" }}
              handleFetch={() => {
                fetchRoutes({ ...fetchQuery, page: fetchQuery.page + 1 });
              }}
              itemHeight={49}
              renderChildren={(index) => (
                <RoutesItem
                  timeZone={currentUser.timeZone}
                  setAssignedAnchorEl={handleSetAssignedPopover}
                  route={routesState.list[index]}
                  {...{
                    handleDotsClick,
                    handleViewRoute,
                    setSelectedRepData,
                    disabledCreateEdit,
                    handleCheckRoute,
                    isAlreadySelected,
                  }}
                />
              )}
            />
          ) : (
            <EmptyScreen
              type="routes"
              onConfirm={() => navigate("/routes/new")}
              showAction={!routesState?.routesExist}
              loading={routesState.loading}
              disabled={
                !!repPermissions && !repPermissions?.routes?.create_edit
              }
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default RoutesPage;
