import { bool, shape, number, string } from "prop-types";
import { Box, Skeleton, Typography } from "@mui/material";
import {
  normalizeEveryFirstLetterToUpper,
  separateNumWithComma,
} from "../../../helpers/helpers";
import { StyledTooltip } from "components";

export const TotalSummaryCustomersItem = ({ customer, isLoading }) => {
  return isLoading ? (
    <Skeleton width="clamp(120px, 9vw, 140px)" height={60} />
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "clamp(120px, 9vw, 140px)",
        minWidth: "clamp(120px, 9vw, 140px)",
        py: "3px",
        px: "6px",
        border: "0.5px solid rgba(213, 217, 217, 0.3)",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <StyledTooltip isDark title={customer?.name} followCursor>
          <Typography fontSize="clamp(11px, 1vw, 12px)" fontWeight={600} noWrap>
            {normalizeEveryFirstLetterToUpper(customer?.name)}
          </Typography>
        </StyledTooltip>
        <Typography fontSize="clamp(11px, 1vw, 12px)" noWrap>
          $ {separateNumWithComma(customer?.totalAmount)}
        </Typography>
      </Box>
    </Box>
  );
};

TotalSummaryCustomersItem.propTypes = {
  customer: shape({
    name: string,
    totalPayments: number,
  }),
  isLoading: bool,
};
