import { success, error } from "utils/notifications";
import {
  createTagService,
  deleteTagService,
  getTagsService,
  mergeTagsService,
  updateTagService,
} from "services/tags";
import { SCROLL_LIMIT, TAGS_TYPE } from "utils/constants";

export const GET_TAGS = "GET_TAGS";
export const START_LOADING_TAGS = "START_LOADING_TAGS";
export const END_LOADING_TAGS = "END_LOADING_TAGS";
export const CREATE_TAG = "CREATE_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const GET_TAGS_COUNT = "GET_TAGS_COUNT";
export const GET_CUSTOMER_TAGS_COUNT = "GET_CUSTOMER_TAGS_COUNT";
export const GET_ORDER_TAGS_COUNT = "GET_ORDER_TAGS_COUNT";

export const getTags = (payload, tagType) => ({
  type: GET_TAGS,
  payload,
  tagType,
});

const createTag = (payload, tagType) => ({
  type: CREATE_TAG,
  payload,
  tagType,
});

const updateTag = (payload, tagType) => ({
  type: UPDATE_TAG,
  payload,
  tagType,
});

const deleteTag = (payload, tagType) => ({
  type: DELETE_TAG,
  payload,
  tagType,
});

const getTagsCount = (payload) => ({
  type: GET_TAGS_COUNT,
  payload,
});

const getCustomerTagsCount = (payload) => ({
  type: GET_CUSTOMER_TAGS_COUNT,
  payload,
});

const getOrderTagsCount = (payload) => ({
  type: GET_ORDER_TAGS_COUNT,
  payload,
});

const startLoadingTags = () => ({ type: START_LOADING_TAGS });
const endLoadingTags = () => ({ type: END_LOADING_TAGS });

const fetchTags = ({ type, query, onSuccess, permissions }) => {
  if (permissions && !permissions?.hasAnyCheckedTrue) return;

  if (permissions) {
    if (
      type === "order" &&
      !(permissions?.orders?.view || permissions?.settings?.orders)
    )
      return;

    if (type === "product") {
      if (!(permissions?.catalog?.product_tags || permissions?.discounts?.view))
        return;
    }

    if (type === "customer") {
      if (
        !(
          permissions?.customers?.customer_tags ||
          permissions?.routes?.create_edit ||
          permissions?.discounts?.view
        )
      )
        return;
    }
  }

  return getTagsService(type, query)
    .then((res) => onSuccess(res))
    .catch((err) => {
      error(err?.response?.data?.message);
    });
};

export const getTagsAction = (query) => {
  return (dispatch, getState) => {
    const state = getState();
    const page = query?.page || 1;
    const limit = query?.limit ?? SCROLL_LIMIT;
    const updatedQuery = { ...query, page, limit };

    const repPermissions =
      state?.auth?.currentUser?.permissions ||
      state?.auth?.representative?.permissions?.[0];

    dispatch(startLoadingTags());

    Promise.all([
      fetchTags({
        type: TAGS_TYPE.CUSTOMER,
        query: updatedQuery,
        onSuccess: (res) => {
          dispatch(
            getTags(
              {
                [TAGS_TYPE.CUSTOMER]: res?.rows || [],
                isScrolling: false,
              },
              TAGS_TYPE.CUSTOMER
            )
          );
          dispatch(getCustomerTagsCount(res.count || 0));
        },
        permissions: repPermissions,
      }),
      // getTagsService(TAGS_TYPE.CUSTOMER)
      //   .then((res) => {
      //     dispatch(getTags(res.rows || [], TAGS_TYPE.CUSTOMER));
      //     dispatch(getCustomerTagsCount(res.count || 0));
      //   })
      //   .catch((err) => {
      //     error(err?.response?.data?.message);
      //   }),
      fetchTags({
        type: TAGS_TYPE.PRODUCT,
        query: updatedQuery,
        onSuccess: (res) => {
          dispatch(
            getTags(
              {
                [TAGS_TYPE.PRODUCT]: res?.rows,
                isScrolling: page > 1,
              },
              TAGS_TYPE.PRODUCT
            )
          );
          dispatch(getTagsCount(res.count || 0));
        },
        permissions: repPermissions,
      }),
      // getTagsService(TAGS_TYPE.PRODUCT, query)
      //   .then((res) => {
      //     dispatch(getTags(res.rows, TAGS_TYPE.PRODUCT));
      //     dispatch(getTagsCount(res.count || 0));
      //   })
      //   .catch((err) => {
      //     error(err?.response?.data?.message);
      //   }),
      fetchTags({
        type: TAGS_TYPE.ORDERS,
        query: updatedQuery,
        onSuccess: (res) => {
          dispatch(
            getTags(
              {
                [TAGS_TYPE.ORDERS]: res?.rows,
                isScrolling: page > 1,
              },
              TAGS_TYPE.ORDERS
            )
          );
          dispatch(getOrderTagsCount(res.count || 0));
        },
        permissions: repPermissions,
      }),
      // getTagsService(TAGS_TYPE.ORDERS, query)
      //   .then((res) => {
      //     dispatch(getTags(res.rows, TAGS_TYPE.ORDERS));
      //     dispatch(getOrderTagsCount(res.count || 0));
      //   })
      //   .catch((err) => {
      //     error(err?.response?.data?.message);
      //   }),
    ])
      .then(() => dispatch(endLoadingTags()))
      .catch((err) => {
        dispatch(endLoadingTags());
        error(err?.response?.data?.message);
      });
  };
};

export const createTagAction = (data, type) => {
  return (dispatch) => {
    dispatch(startLoadingTags());
    createTagService(data, type)
      .then((res) => {
        dispatch(endLoadingTags());
        dispatch(createTag(res, type));
        success("Tag created successfully.");
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingTags());
      });
  };
};

export const updateTagAction = (id, data, type) => {
  return (dispatch) => {
    dispatch(startLoadingTags());
    updateTagService(id, data, type)
      .then((res) => {
        dispatch(endLoadingTags());
        dispatch(updateTag(res, type));
        success("Tag updated successfully.");
      })
      .catch((err) => {
        error(err.message);
        dispatch(endLoadingTags());
      });
  };
};

export const deleteTagAction = (id, type) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.tags.loading) return;

    dispatch(startLoadingTags());
    deleteTagService(id, type)
      .then((res) => {
        dispatch(endLoadingTags());
        dispatch(deleteTag(res, type));
        success("Tag deleted successfully.");
      })
      .catch((err) => {
        error(err.message);
        dispatch(endLoadingTags());
      });
  };
};

export const mergeTagsAction = (type, tagId, mergeIds, setState) => {
  return (dispatch, getState) => {
    const state = getState();
    const repPermissions =
      state?.auth?.currentUser?.permissions ||
      state?.auth?.representative?.permissions?.[0];
    setState((prev) => ({ ...prev, loading: true }));
    mergeTagsService(type, tagId, mergeIds)
      .then(async () => {
        try {
          await fetchTags({
            type: type,
            query: { page: 1, limit: SCROLL_LIMIT },
            onSuccess: (res) => {
              dispatch(
                getTags(
                  {
                    [type]: res?.rows || [],
                    isScrolling: false,
                  },
                  type
                )
              );

              if (type === TAGS_TYPE.CUSTOMER) {
                dispatch(getCustomerTagsCount(res.count || 0));
              }
              if (type === TAGS_TYPE.ORDERS) {
                dispatch(getOrderTagsCount(res.count || 0));
              }
              if (type === TAGS_TYPE.PRODUCT) {
                dispatch(getTagsCount(res.count || 0));
              }

              setState((prev) => ({
                ...prev,
                loading: false,
                open: false,
              }));
            },
            permissions: repPermissions,
          });
        } catch (err) {
          error(err?.response?.data?.message);
          setState((prev) => ({ ...prev, loading: false }));
        }
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        setState((prev) => ({ ...prev, loading: false }));
      });
  };
};
