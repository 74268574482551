import {
  CircularProgress,
  Typography,
  Box,
  IconButton,
  Link,
  Button,
} from "@mui/material";
import useStyles from "../styles";

import { StyledDataGrid } from "./StyledDataGrid";
import { useContext, useEffect, useMemo } from "react";
import { ImportContext } from "Pages/SettingsPage/SettingsPage";
import pluralize from "pluralize";
import { useImport, useImportValidation } from "../Import.hooks";
import { StyledButton, StyledTooltip } from "components";
import { IMPORT_STATUSES } from "../ImportTab.constants";
import { STATE_NAMES } from "Pages/CustomersPage/pages/NewCustomerPage/NewCustomerPage.constants";
import { setPhoneNumberMask } from "helpers/helpers";
import { CartTrashIcon } from "components/Icons";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";

const ImportStepThree = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  //const boxRef = useRef([]);
  const { importData } = useContext(ImportContext);

  //useEffect(() => {
  //  const handleWindowResize = () => {
  //    const currentWidth = boxRef.current.getBoundingClientRect();
  //    setWidthBox(currentWidth.width);
  //  };

  //  window.addEventListener("resize", handleWindowResize);

  //  return () => {
  //    window.removeEventListener("resize", handleWindowResize);
  //  };
  //}, []);

  //const handleWidth = (id) => {
  //  console.log(boxRef);
  //  if (boxRef.current) {
  //    if (!boxRef.current[id]) return 60;
  //    console.log(boxRef.current[id]);
  //    return boxRef.current[id].getBoundingClientRect().width;
  //  }
  //};

  const {
    tableRows,
    handlePaginate,
    importStatusLoading,
    importStatus,
    getErrorsCount,
    importRowsLoading,
    importPagination,
  } = useImport();

  const {
    validationState,
    getErrorMessage,
    handleDeleteCell,
    handleDeleteAllCell,
    currentColumns,
    headers: headersProps,
    handleSaveLocalChanges,
    handleSaveTable,
  } = useImportValidation({ tableRows });

  const headers = useMemo(() => {
    return {
      DELETE: { title: "", field: "", validators: [] },
      ...headersProps,
    };
  }, [headersProps]);

  const { errorsCount, rowsCount } = useMemo(() => importData, [importData]);

  const loadingProgress = useMemo(
    () =>
      [
        IMPORT_STATUSES.REMAP_PROGRESS.status,
        IMPORT_STATUSES.VALIDATION_PROGRESS.status,
        IMPORT_STATUSES.IMPORT_PROGRESS.status,
      ].some((s) => s === importStatus),
    [importStatus]
  );

  const formattedStates = useMemo(
    () =>
      Object.keys(STATE_NAMES).map((stateKey) => ({
        value: stateKey,
        label: STATE_NAMES[stateKey],
      })),
    []
  );

  const COLUMN_PARAMS = useMemo(
    () => ({
      customerBillingState: {
        type: "singleSelect",
        valueOptions: formattedStates,
      },
      customerShippingState: {
        type: "singleSelect",
        valueOptions: formattedStates,
      },
    }),
    [formattedStates]
  );

  useEffect(() => {
    if (
      importData?.uploadSession?.session &&
      importStatus === IMPORT_STATUSES.ERROR_VALIDATION.status
    )
      getErrorsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importData?.uploadSession?.session, importStatus]);

  const handleConfirmCheckedItemsDialog = ({
    isSingle,
    callback,
    withDeleteSession,
  }) => {
    dispatch(
      openConfirmDialogAction({
        title: isSingle
          ? "Remove item?"
          : `Remove ${pluralize("item", errorsCount)}?`,
        text: isSingle
          ? "Are you sure you want to remove item?"
          : withDeleteSession
          ? `Are you sure you want to remove ${errorsCount} ${pluralize(
              "item",
              errorsCount
            )} and cancel this import session?`
          : `Are you sure you want to remove ${errorsCount} ${pluralize(
              "item",
              errorsCount
            )}?`,
        buttons: (
          <>
            <StyledButton
              variant="outlined"
              color="cancel"
              label="Cancel"
              sx={{ height: "28px" }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
            />
            <Button
              sx={{
                width: "auto",
                height: "28px",
                color: "#FFFFFF",
                fontSize: "13px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
                if (callback) return callback();
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </>
        ),
      })
    );
  };

  const readyToImportCount = useMemo(() => {
    const count = rowsCount - (errorsCount || 0);
    return count >= 0 ? count : 0;
  }, [errorsCount, rowsCount]);

  const rowsWithChanges = useMemo(
    () =>
      tableRows.map((row) => {
        const changesForRow = validationState.changes?.[row.id] || {};
        return { ...row, ...changesForRow };
      }),
    [tableRows, validationState.changes]
  );

  return (
    <Box position="relative">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {importData?.importType?.key !== "orders" && (
          <Box display="flex" alignItems="center">
            <Typography className={classes.bodyText}>
              {readyToImportCount} {pluralize("customer", readyToImportCount)}{" "}
              ready to be imported
            </Typography>

            {errorsCount > 0 && (
              <>
                <Typography className={classes.bodyText} color="#EB4233">
                  {" "}
                  {errorsCount} have issues{" "}
                </Typography>
                <Link
                  sx={{
                    fontSize: "16px",
                    color: "#47A06D",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const withDeleteSession = readyToImportCount === 0;

                    handleConfirmCheckedItemsDialog({
                      callback: () =>
                        handleDeleteAllCell({ withDeleteSession }),
                      withDeleteSession,
                    });
                  }}
                >
                  Remove
                </Link>{" "}
              </>
            )}
          </Box>
        )}
        <StyledButton
          label="Save"
          variant="contained"
          sx={{ height: "28px", marginLeft: "auto" }}
          disabled={!validationState.changes || validationState.loading}
          onClick={handleSaveTable}
        />
      </Box>

      {(validationState.loading || importStatusLoading || loadingProgress) && (
        <Box className={classes.loaderWrapper}>
          <CircularProgress />
        </Box>
      )}
      <Box
        height="486px"
        mt="15px"
        sx={{
          overflowX: "auto",
          overflowY: "hidden",
          border: "1px solid #d5d9d9",
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
          pr: 0,
        }}
      >
        <Box className={classes.selectsRow}>
          <Box
            sx={{
              flex: 1,
              minWidth: "60px !important",
              maxWidth: "60px !important",
            }}
            height="40px"
          />
          {Object.keys(importData.mappedCols)?.map((headerKey) => (
            <Box
              key={headerKey}
              className={classes.selectItem}
              height="40px"
              display="flex"
              alignItems="center"
              paddingLeft="15px"
              sx={{
                boxSizing: "border-box",
                maxWidth: "200px !important",
                minWidth: "200px !important",
                width: "200px !important",
              }}
              //ref={(el) => (boxRef.current[headerKey] = el)}
            >
              <Typography noWrap>
                {currentColumns[importData.mappedCols[headerKey]]?.title}
              </Typography>
            </Box>
          ))}
        </Box>
        <StyledDataGrid
          onSaveLocalChanges={handleSaveLocalChanges}
          rowCount={importData.rowsCount}
          editable
          showErrors
          page={importPagination.page}
          loading={importRowsLoading}
          rows={rowsWithChanges}
          columns={Object.keys(headers)?.map((key) => {
            return {
              ...headers[key],
              ...COLUMN_PARAMS[importData.mappedCols[key]],
              editable: true,
              flex: 1,
              headerName: headers[key].title,
              field: key,
              renderCell: ({ row, field, formattedValue }) => {
                const errors = row.validation?.[field];
                const fieldName = importData.mappedCols[key];
                const isPhoneNumber = fieldName === "phone";
                return (
                  <>
                    {key === "DELETE" ? (
                      <IconButton
                        onClick={() => {
                          handleConfirmCheckedItemsDialog({
                            callback: () => handleDeleteCell([row?.id]),
                            isSingle: true,
                          });
                        }}
                      >
                        <CartTrashIcon />
                      </IconButton>
                    ) : (
                      <StyledTooltip
                        arrow
                        placement="top"
                        title={
                          <Typography
                            color="error"
                            fontSize="12px"
                            sx={{ whiteSpace: "pre-wrap", textAlign: "center" }}
                          >
                            {getErrorMessage(errors || {})}
                          </Typography>
                        }
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: { offset: [0, 0] },
                            },
                          ],
                        }}
                        disableHoverListener={!errors}
                      >
                        <Typography fontSize="10px" style={{ width: "100%" }}>
                          {isPhoneNumber
                            ? setPhoneNumberMask(formattedValue)
                            : formattedValue?.trim?.() || "-"}
                        </Typography>
                      </StyledTooltip>
                    )}
                  </>
                );
              },
            };
          })}
          onPageChange={handlePaginate}
        />
      </Box>
    </Box>
  );
};

export default ImportStepThree;
