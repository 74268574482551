import {
  bulkActivationService,
  bulkAssignCustomersService,
  bulkUpdatePaymentTermsService,
  createCustomerService,
  deleteBulkService,
  deleteCustomersService,
  getCustomersService,
  removeAllRepsService,
  transferCustomersService,
  updateCustomerGroupTerritoryService,
  updateCustomerService,
} from "../../services/customers";
import { getRepsAction } from "./reps";
import { success, error } from "../../utils/notifications";
import { getTagsAction } from "./tags";
import {
  getCustomersByTerritoryAction,
  getTerritoryListAction,
  getTerritoryTreeAction,
} from "./territory";
import {
  CUSTOMER_STATUS_FILTERS,
  SCROLL_LIMIT,
  SCROLL_LIMIT_CUSTOMERS,
  SCROLL_LIMIT_INVITES,
} from "../../utils/constants";
import { getGroupsAction } from "./parentCustomers";
import { setEditItemAction, setFormChangedAction } from "./confirmDialogs";
import { getInvitesListAction, getReceivedListAction } from "./order-directs";
import { getTotalSummariesCustomersDataAction } from "./total-summaries";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const EXIST_CUSTOMERS = "EXIST_CUSTOMERS";
export const GET_CUSTOMERS_INACTIVE = "GET_CUSTOMERS_INACTIVE";
export const SHOW_INACTIVE_CUSTOMERS = "SHOW_INACTIVE_CUSTOMERS";
export const SHOW_INACTIVE_REP_CUSTOMERS = "SHOW_INACTIVE_REP_CUSTOMERS";
export const GET_REP_CUSTOMERS = "GET_REP_CUSTOMERS";
export const START_LOADING_CUSTOMERS = "START_LOADING_CUSTOMERS";
export const END_LOADING_CUSTOMERS = "END_LOADING_CUSTOMERS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const START_LOADING_REP_CUSTOMERS = "START_LOADING_REP_CUSTOMERS";
export const END_LOADING_REP_CUSTOMERS = "END_LOADING_REP_CUSTOMERS";
export const CUSTOMERS_GET_PARAMS = "CUSTOMERS_GET_PARAMS";
export const GET_CUSTOMERS_COUNT = "GET_CUSTOMERS_COUNT";
export const GET_CUSTOMERS_INACTIVE_COUNT = "GET_CUSTOMERS_INACTIVE_COUNT";
export const CUSTOMERS_REP_GET_PARAMS = "CUSTOMERS_REP_GET_PARAMS";
export const CUSTOMERS_GET_FILTER_FIELD = "CUSTOMERS_GET_FILTER_FIELD";
export const CUSTOMER_GET_SWITCH = "CUSTOMER_GET_SWITCH";
export const RESET_ACTION = "RESET_ACTION";
export const RESET_ACTION_SWITCH = "RESET_ACTION_SWITCH";
export const CUSTOMERS_GET_GROUP_FILTER_FIELD =
  "CUSTOMERS_GET_GROUP_FILTER_FIELD";
export const CUSTOMER_GET_SWITCH_GROUP = "CUSTOMER_GET_SWITCH_GROUP";
export const RESET_ACTION_GROUP_SWITCH = "RESET_ACTION_GROUP_SWITCH";
export const RESET_ACTION_GROUP_FILTER = "RESET_ACTION_GROUP_FILTER";
export const GET_CUSTOMERS_DATA = "GET_CUSTOMERS_DATA";
export const GET_INACTIVE_CUSTOMERS_DATA = "GET_INACTIVE_CUSTOMERS_DATA";
export const CUSTOMER_STATUS_FILTER = "CUSTOMER_STATUS_FILTER";
export const CUSTOMER_STATUS_FILTER_BY_REP = "CUSTOMER_STATUS_FILTER_BY_REP";
export const SET_SEARCH_CUSTOMERS = "SET_SEARCH_CUSTOMERS";
export const SET_SEARCH_GROUPS = "SET_SEARCH_GROUPS";

const updateCustomer = (payload) => ({
  type: UPDATE_CUSTOMER,
  payload,
});

const createCustomer = (payload) => ({
  type: CREATE_CUSTOMER,
  payload,
});

export const getCustomers = (payload) => ({
  type: GET_CUSTOMERS,
  payload,
});

export const getCustomersByRep = (payload, scrolling) => ({
  type: GET_REP_CUSTOMERS,
  payload,
  scrolling,
});

const deleteUser = (payload) => ({
  type: DELETE_CUSTOMER,
  payload,
});

export const customersSetParams = (payload) => ({
  type: CUSTOMERS_GET_PARAMS,
  payload,
});

export const customersByRepGetParams = (payload) => ({
  type: CUSTOMERS_REP_GET_PARAMS,
  payload,
});

export const customersGetFilterField = (payload) => ({
  type: CUSTOMERS_GET_FILTER_FIELD,
  payload,
});

export const customerGetSwitchField = (payload) => ({
  type: CUSTOMER_GET_SWITCH,
  payload,
});

export const resetActions = (payload) => ({
  type: RESET_ACTION,
  payload,
});

export const resetActionsSwitch = (payload) => ({
  type: RESET_ACTION_SWITCH,
  payload,
});

const customerGroupFilter = (payload) => ({
  type: CUSTOMERS_GET_GROUP_FILTER_FIELD,
  payload,
});

const customerGroupSwitch = (payload) => ({
  type: CUSTOMER_GET_SWITCH_GROUP,
  payload,
});

export const resetActionsGroupSwitch = (payload) => ({
  type: RESET_ACTION_GROUP_SWITCH,
  payload,
});

export const resetActionsGroupFilter = (payload) => ({
  type: RESET_ACTION_GROUP_FILTER,
  payload,
});

export const startLoadingCustomers = () => ({ type: START_LOADING_CUSTOMERS });
export const endLoadingCustomers = () => ({ type: END_LOADING_CUSTOMERS });
export const startLoadingCustomersByRep = () => ({
  type: START_LOADING_REP_CUSTOMERS,
});
export const endLoadingCustomersByRep = () => ({
  type: END_LOADING_REP_CUSTOMERS,
});

export const setShowInactiveCustomers = (payload) => ({
  type: SHOW_INACTIVE_CUSTOMERS,
  payload,
});

export const setCustomersStatusFilter = (payload) => ({
  type: CUSTOMER_STATUS_FILTER,
  payload,
});

export const setShowInactiveCustomersByRep = (payload) => ({
  type: SHOW_INACTIVE_REP_CUSTOMERS,
  payload,
});

export const setCustomerStatusFilterByRep = (payload) => ({
  type: CUSTOMER_STATUS_FILTER_BY_REP,
  payload,
});

export const getCustomersCount = (payload) => ({
  type: GET_CUSTOMERS_COUNT,
  payload,
});

export const getCustomersData = (payload) => ({
  type: GET_CUSTOMERS_DATA,
  payload,
});

export const getCustomersInactiveCount = (payload) => ({
  type: GET_CUSTOMERS_INACTIVE_COUNT,
  payload,
});

export const getCustomersInactiveInfoCount = (payload) => ({
  type: GET_INACTIVE_CUSTOMERS_DATA,
  payload,
});

const customersExist = (payload) => ({
  type: EXIST_CUSTOMERS,
  payload,
});

export const setSearchCustomersAction = (payload) => ({
  type: SET_SEARCH_CUSTOMERS,
  payload,
});

export const setSearchGroupsAction = (payload) => ({
  type: SET_SEARCH_GROUPS,
  payload,
});

export const updateCustomerAction = ({
  data,
  id,
  onSuccess,
  onError,
  navigate,
  skipSuccessMsg,
  returnCustomer = false,
  stateParams = {},
}) => {
  return (dispatch, getState) => {
    dispatch(startLoadingCustomers());
    const state = getState();
    updateCustomerService(data, id)
      .then((res) => {
        // dispatch(setEditItemAction(null));
        dispatch(setFormChangedAction(false));
        getGroupsAction({
          limit: SCROLL_LIMIT,
          limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
        });
        dispatch(endLoadingCustomers());
        dispatch(updateCustomer(res));
        dispatch(getTagsAction());
        dispatch(getRepsAction({ limit: 15 }, { isScrolling: false }));
        dispatch(getCustomersByTerritoryAction());
        if (!skipSuccessMsg) success("Customer updated");
        if (onSuccess)
          onSuccess(returnCustomer ? res : state.confirmDialogs.path);
        if (navigate)
          navigate(state.confirmDialogs.path || "/customers", stateParams);
      })
      .catch((err) => {
        dispatch(endLoadingCustomers());
        if (onError) return onError(err);
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.error(err?.response?.data);
      });
  };
};

export const createCustomerAction = ({ data, onSuccess, onError }) => {
  return (dispatch) => {
    dispatch(startLoadingCustomers());
    createCustomerService(data)
      .then((res) => {
        dispatch(setEditItemAction(null));
        dispatch(setFormChangedAction(false));
        getGroupsAction({
          limit: SCROLL_LIMIT,
          limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
        });
        dispatch(endLoadingCustomers());
        dispatch(createCustomer(res));
        dispatch(getTagsAction());
        if (onSuccess) onSuccess(res);
        success("Customer created");
      })
      .catch((err) => {
        dispatch(endLoadingCustomers());
        if (onError) return onError(err);
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.error(err?.response?.data);
      });
  };
};

export const resetGetActions = () => {
  return (dispatch) => {
    dispatch(resetActions());
  };
};
export const resetGetActionsSwitch = () => {
  return (dispatch) => {
    dispatch(resetActionsSwitch());
  };
};

export const resetGetActionsGroupSwitch = () => {
  return (dispatch) => {
    dispatch(resetActionsGroupSwitch());
  };
};

export const resetGetActionsGroupFilter = () => {
  return (dispatch) => {
    dispatch(resetActionsGroupFilter());
  };
};

export const resetGlobalActions = () => {
  return (dispatch) => {
    dispatch(resetGetActionsGroupSwitch());
    dispatch(resetGetActionsGroupFilter());
    dispatch(resetGetActionsSwitch());
    dispatch(resetActions());
    dispatch(setSearchCustomersAction(""));
    dispatch(setSearchGroupsAction(""));
  };
};
export const updateCustomerGroupTerritoryAction = (
  data,
  query,
  onSuccess,
  onGroupSuccess,
  successProps,
  successGroupParams
) => {
  return (dispatch, getState) => {
    const {
      customers: { loading },
    } = getState();
    if (loading) return;

    dispatch(startLoadingCustomers());

    updateCustomerGroupTerritoryService(data, query)
      .then(() => {
        dispatch(getCustomersAction(successProps, null, onSuccess));
        dispatch(
          getGroupsAction(
            {
              limit: SCROLL_LIMIT,
              limit_sub_customers: onGroupSuccess
                ? successGroupParams.page * SCROLL_LIMIT_CUSTOMERS
                : SCROLL_LIMIT_CUSTOMERS,
              ...successGroupParams,
              page: null,
            },
            (res) => {
              onGroupSuccess && onGroupSuccess(res);
              dispatch(endLoadingCustomers());
            }
          )
        );
        success("Customer(s) updated");
        if (onSuccess) onSuccess();
        dispatch(getTerritoryTreeAction());
        dispatch(getTerritoryListAction());
      })
      .catch((err) => {
        dispatch(endLoadingCustomers());
        error(err?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const deleteBulkAction = ({ data, query, onSuccess }) => {
  return (dispatch, getState) => {
    const state = getState();

    const repPermissions =
      state?.auth?.currentUser?.permissions ||
      state?.auth?.representative?.permissions?.[0];

    // const repPermissions = permissions && permissions?.[0];

    dispatch(startLoadingCustomers());
    deleteBulkService(data, query)
      .then(() => {
        dispatch(endLoadingCustomers());
        dispatch(deleteUser(data.customerIds));
        dispatch(
          getGroupsAction({
            limit: SCROLL_LIMIT,
            limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
          })
        );
        if (!repPermissions)
          dispatch(getInvitesListAction({ limit: SCROLL_LIMIT_INVITES }));
        if (!repPermissions)
          dispatch(getReceivedListAction({ limit: SCROLL_LIMIT_INVITES }));
        dispatch(getTotalSummariesCustomersDataAction());
        success("Customer(s) deleted");
        if (onSuccess) onSuccess();
        dispatch(getCustomersAction());
      })
      .catch((err) => {
        dispatch(endLoadingCustomers());
        error(err?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};
export const deleteCustomerAction = ({
  ids,
  withRefetch = false,
  onClose,
  onSuccess,
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const repPermissions =
      state?.auth?.currentUser?.permissions ||
      state?.auth?.representative?.permissions?.[0];
    const loading = state?.customers?.loading;

    // const repPermissions = permissions && permissions?.[0];

    if (loading) return;

    dispatch(startLoadingCustomers());
    deleteCustomersService(ids)
      .then(() => {
        dispatch(endLoadingCustomers());
        dispatch(deleteUser(ids));
        dispatch(
          getGroupsAction({
            limit: SCROLL_LIMIT,
            limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
          })
        );
        withRefetch && dispatch(getCustomersAction());
        if (!repPermissions)
          dispatch(getInvitesListAction({ limit: SCROLL_LIMIT_INVITES }));
        if (!repPermissions)
          dispatch(getReceivedListAction({ limit: SCROLL_LIMIT_INVITES }));
        success("Customer(s) deleted");
        dispatch(getTotalSummariesCustomersDataAction());
        if (onClose) onClose();
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        dispatch(endLoadingCustomers());
        error(err?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const getCustomersAction = (props, requestRef, onSuccess) => {
  return (dispatch, getState) => {
    const state = getState();
    const page = props?.page || 1;
    const statusFilter = state.customers.status;
    dispatch(startLoadingCustomers());
    if (requestRef) requestRef.current = statusFilter;
    getCustomersService({
      status: `["${statusFilter ?? CUSTOMER_STATUS_FILTERS.active}"]`,
      ...state.customers.customersGetParams,
      ...props,
      limit: props?.limit ?? SCROLL_LIMIT,
      page,
    })
      .then((res) => {
        onSuccess && onSuccess(res?.rows);
        if (requestRef && requestRef.current !== statusFilter) return;
        dispatch(customersExist(res?.existData));
        dispatch(
          getCustomers({
            customers: res?.rows,
            isScrolling: page > 1,
          })
        );
        dispatch(getCustomersData(res));
        dispatch(getCustomersInactiveInfoCount(res));
        dispatch(getCustomersCount(res?.count));
        dispatch(endLoadingCustomers());
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const getCustomersByRepAction = (representative_id, props) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(startLoadingCustomersByRep());
    getCustomersService({
      ...state.customers.customersByRepGetParams,
      ...props,
      representative_id,
      limit: 10,
    })
      .then((res) => {
        dispatch(
          getCustomersByRep(
            res,
            state.customers.customersByRepGetParams.page > 1
          )
        );
        dispatch(endLoadingCustomersByRep());
      })
      .catch((err) => {
        dispatch(endLoadingCustomersByRep());
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const transferCustomersAction = (data) => {
  return (dispatch) => {
    dispatch(startLoadingCustomersByRep());
    transferCustomersService(data)
      .then((res) => {
        getGroupsAction({
          limit: SCROLL_LIMIT,
          limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
        });
        dispatch(getCustomersAction());
        dispatch(getRepsAction({ limit: 15 }, { isScrolling: false }));
        dispatch(getCustomersByRepAction(data.oldRepresentativeId));
        success(res.message);
      })
      .catch((err) => {
        dispatch(endLoadingCustomersByRep());
        error(err?.message);
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

// export const archiveCustomersAction = (ids, active) => {
//   return (dispatch, getState) => {
//     const state = getState();
//     dispatch(startLoadingCustomers());
//     archiveCustomersService(ids, active)
//       .then(() => {
//         dispatch(getCustomersAction());
//         dispatch(
//           getGroupsAction({
//             limit: SCROLL_LIMIT,
//             limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
//           })
//         );
//         success(
//           `Customers(s) ${
//             state.customers.showInactive ? "restored" : "archived"
//           } successfully.`
//         );
//       })
//       .catch((err) => {
//         dispatch(endLoadingCustomers());
//         error(err?.message || "Something went wrong.");
// eslint-disable-next-line no-console
//         console.log(err?.message);
//       });
//   };
// };

export const changePaymentTermBulkAction = (
  data,
  onSuccess,
  onGroupSuccess,
  successProps,
  successGroupParams
) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.customers.loading) return;

    dispatch(startLoadingCustomers());
    bulkUpdatePaymentTermsService(data)
      .then(() => {
        dispatch(getCustomersAction(successProps, null, onSuccess));
        dispatch(
          getGroupsAction(
            {
              limit: SCROLL_LIMIT,
              limit_sub_customers: successGroupParams?.page
                ? successGroupParams?.page * SCROLL_LIMIT_CUSTOMERS
                : SCROLL_LIMIT_CUSTOMERS,
              ...successGroupParams,
              page: null,
            },
            (res) => {
              onGroupSuccess && onGroupSuccess(res);
              dispatch(endLoadingCustomers());
            }
          )
        );
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        dispatch(endLoadingCustomers());
        error(err?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
    success("Term(s) changed successfully.");
    dispatch(getTotalSummariesCustomersDataAction());
  };
};

export const archiveBulkAction = ({ data, query, onSuccess }) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.customers.loading) return;

    dispatch(startLoadingCustomers());
    bulkActivationService(data, query)
      .then(({ unarchivedCustomers }) => {
        dispatch(getCustomersAction());
        dispatch(
          getGroupsAction({
            limit: SCROLL_LIMIT,
            limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
          })
        );
        success(
          `Customers(s) ${
            data.status === CUSTOMER_STATUS_FILTERS.active
              ? "restored"
              : "archived"
          }`
        );
        if (onSuccess) onSuccess();
        dispatch(getTotalSummariesCustomersDataAction());
        if (unarchivedCustomers?.length)
          error(
            <span style={{ whiteSpace: "pre-wrap" }}>
              {unarchivedCustomers.join("\n")}
            </span>
          );
      })
      .catch((err) => {
        dispatch(endLoadingCustomers());
        error(err?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

// export const bulkTransferCustomersAction = (data) => {
//   return (dispatch) => {
//     dispatch(startLoadingCustomers());
//     bulkTransferCustomersService(data)
//       .then(() => {
//         dispatch(getCustomersAction());
//         dispatch(getRepsAction({ limit: 15 }, { isScrolling: false }));
//         success("Customers transferred successfully.");
//       })
//       .catch((err) => {
//         dispatch(endLoadingCustomers());
//         error(err?.message);
// eslint-disable-next-line no-console
//         console.log(err?.message);
//       });
//   };
// };

export const bulkAssignCustomersAction = (
  data,
  onClose,
  query,
  onSuccess,
  onGroupSuccess,
  successParams,
  successGroupParams
) => {
  return (dispatch) => {
    dispatch(startLoadingCustomers());
    bulkAssignCustomersService(data, query)
      .then(() => {
        onClose();
        dispatch(getCustomersAction(successParams, null, onSuccess));
        dispatch(
          getGroupsAction(
            {
              limit: SCROLL_LIMIT,
              limit_sub_customers: onGroupSuccess
                ? successGroupParams?.page * SCROLL_LIMIT_CUSTOMERS
                : SCROLL_LIMIT_CUSTOMERS,
              ...successGroupParams,
              page: null,
            },
            (res) => {
              onGroupSuccess && onGroupSuccess(res);
              dispatch(endLoadingCustomers());
            }
          )
        );
        dispatch(getRepsAction({ limit: 15 }, { isScrolling: false }));
        success(`Customers ${data.deleteOld ? "transferred" : "assigned"}`);
      })
      .catch((err) => {
        dispatch(endLoadingCustomers());
        error(err?.response?.data?.message || err?.message);
      });
  };
};

export const customerGetGroupFilter = (data) => {
  return (dispatch) => {
    dispatch(customerGroupFilter(data));
  };
};

export const customerGetGroupSwitch = (data) => {
  return (dispatch) => {
    dispatch(customerGroupSwitch(data));
  };
};
export const customerGetFiltersAction = (data) => {
  return (dispatch) => {
    dispatch(customersGetFilterField(data));
  };
};
export const customerGetSwitchFieldActions = (data) => {
  return (dispatch) => {
    dispatch(customerGetSwitchField(data));
  };
};
export const customersGetParamsAction = (data, requestRef) => {
  return (dispatch) => {
    dispatch(customersSetParams(data));
    dispatch(
      getCustomersAction({ limit: data.limit || SCROLL_LIMIT }, requestRef)
    );
    dispatch(
      getGroupsAction({
        limit: SCROLL_LIMIT,
        limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
      })
    );
  };
};

export const resetCustomersGetParamsAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setShowInactiveCustomers(false));
    dispatch(
      customersGetParamsAction({
        ...state.customers.customersGetParams,
        representative_id: null,
        territory_id: null,
        tag_id: [],
        group_id: null,
        last_visit: null,
        search: null,
      })
    );
  };
};

export const customersByRepGetParamsAction = (id, data) => {
  return (dispatch) => {
    dispatch(customersByRepGetParams(data));
    dispatch(getCustomersByRepAction(id));
  };
};

export const removeAllRepsAction = (ids, onRefreshSuccess) => {
  return (dispatch) => {
    dispatch(startLoadingCustomers());
    removeAllRepsService(ids)
      .then(() => {
        dispatch(endLoadingCustomers());
        dispatch(getCustomersAction({}, null, onRefreshSuccess));
        dispatch(
          getGroupsAction({
            limit: SCROLL_LIMIT,
            limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
          })
        );
        success("Reps removed successfully.");
      })
      .catch((err) => {
        dispatch(endLoadingCustomers());
        error(err?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};
