import { useEffect, useRef, useState } from "react";
import { string, object, func, number, bool } from "prop-types";
import { Box } from "@mui/material";
import useStyles from "./styles";
import { useRepsPermissions } from "helpers/hooks";
import {
  CheckInBlock,
  HeaderBlock,
  HoursBlock,
  RatingsBlock,
  RoutesBlock,
} from "./components";
import { StyledTooltip } from "components";

const MapTooltip = ({
  address,
  customer,
  setTooltipHovered,
  handleOpenProductDialog,
  handleOpenNotes,
  top,
  left,
  handleOpenRoutesDrawer,
  handleOpenReceivingHours,
  handleCustomerTaskDrawer,
  currentUser,
  children,
  hideTooltip = false,
  showTooltip,
  tooltipIsOpen,
  setTooltipIsOpen,
  setShowTooltip,
  startLoc,
}) => {
  const { routes, assignedRepresentatives, activities } = customer || {};

  const repPermissions = useRepsPermissions();

  const classes = useStyles({
    moreThanOne: assignedRepresentatives?.length > 1,
    top,
    left,
    handleOpenRoutesDrawer,
  });

  const headerBlockRef = useRef(null);
  const customerNameRef = useRef(null);
  const routeItemsRef = useRef([]);

  const [headerBlockWidth, setHeaderBlockWidth] = useState(0);
  const [customerNameWidth, setCustomerNameWidth] = useState(0);

  const handleCloseTooltip = () => setTooltipIsOpen(false);

  const setWidth = (ref, setWidthFunction) => {
    if (ref.current?.clientWidth > 0) {
      setWidthFunction(ref.current?.clientWidth);
    }
  };

  useEffect(() => {
    if (!tooltipIsOpen) return;
    setTimeout(() => {
      setWidth(headerBlockRef, setHeaderBlockWidth);
      setWidth(customerNameRef, setCustomerNameWidth);
    }, 100);
  }, [headerBlockRef, customerNameRef, tooltipIsOpen]);

  const showCustomerContent = !startLoc && !!customer?.customId?.customId;

  return (
    <StyledTooltip
      arrow
      disableFocusListener
      disableHoverListener={hideTooltip}
      open={tooltipIsOpen && showTooltip}
      onOpen={() => setTooltipIsOpen(true)}
      onClose={() => {
        setShowTooltip(false);
        setTooltipIsOpen(false);
      }}
      sx={{
        "& .MuiTooltip-tooltip": {
          mb: "0 !important",
          p: 0,
          maxWidth: "fit-content",
        },
      }}
      placement="top"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, -2] },
          },
        ],
      }}
      title={
        <Box
          className={classes.bodyWrapper}
          onMouseEnter={() => setTooltipHovered(true)}
          onMouseLeave={() => setTooltipHovered(false)}
          onClick={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
          onMouseMove={(e) => e.stopPropagation()}
        >
          <Box sx={{ minWidth: "240px" }}>
            <HeaderBlock
              {...{
                customer,
                headerBlockRef,
                customerNameRef,
                repPermissions,
                classes,
                address,
                customerNameWidth,
              }}
            />

            {showCustomerContent && <RatingsBlock customer={customer} />}

            {showCustomerContent && (
              <HoursBlock
                {...{
                  classes,
                  repPermissions,
                  customer,
                  handleOpenReceivingHours: (data) => {
                    handleCloseTooltip();
                    !!handleOpenReceivingHours &&
                      handleOpenReceivingHours(data);
                  },
                }}
              />
            )}

            {showCustomerContent && (
              <RoutesBlock
                {...{
                  routes,
                  headerBlockWidth,
                  classes,
                  repPermissions,
                  handleOpenRoutesDrawer: (data) => {
                    handleCloseTooltip();
                    !!handleOpenRoutesDrawer && handleOpenRoutesDrawer(data);
                  },
                  customer,
                  routeItemsRef,
                  tooltipIsOpen,
                  headerBlockRef,
                }}
              />
            )}

            {showCustomerContent && (
              <CheckInBlock
                {...{
                  classes,
                  repPermissions,
                  handleCloseTooltip,
                  handleOpenProductDialog,
                  handleCustomerTaskDrawer,
                  customer,
                  handleOpenNotes,
                  activities,
                  currentUser,
                }}
              />
            )}
          </Box>
        </Box>
      }
    >
      {children}
    </StyledTooltip>
  );
};

MapTooltip.propTypes = {
  address: string,
  customer: object,
  setTooltipHovered: func,
  handleOpenProductDialog: func,
  handleOpenNotes: func,
  handleCustomerTaskDrawer: func,
  setAssignedAnchorEl: func,
  top: number,
  left: number,
  handleOpenRoutesDrawer: func,
  handleOpenReceivingHours: func,
  currentUser: object,
  setShowTooltip: func,
  tooltipIsOpen: bool,
  showTooltip: bool,
  setTooltipIsOpen: func,
  children: object,
  hideTooltip: bool,
  startLoc: bool,
  endLoc: bool,
};

export default MapTooltip;
