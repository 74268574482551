import { bool, string, func } from "prop-types";
import moment from "moment";
import { Box, Button, Typography } from "@mui/material";
import { CheckmarkIcon } from "../../Icons";
import { formatDate } from "helpers/helpers";
import { StyledTooltip } from "components";

export const TaskItem = ({
  status,
  title,
  subTitle,
  date,
  onClickGo,
  name,
  hasError,
  titleTooltip,
  showTooltip,
}) => {
  const formattedDate = formatDate({
    utc_date: date,
    formatThisYear: "MMM, DD",
  });

  const showSkipBtn = !status && title === "Notifications";

  return (
    <Box
      sx={{
        border: `0.5px solid ${hasError ? "#F2545C" : "#D5D9D9"}`,
        borderRadius: "4px",
        maxWidth: "618px",
        height: "76px",
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: "16%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CheckmarkIcon
          width="25.01"
          height="18.01"
          stroke={status ? "#47A06D" : "#EFEFEF"}
          strokeWidth={2.5}
        />
      </Box>

      <Box
        sx={{
          width: showSkipBtn ? "59%" : "64%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 16, fontWeight: 600, color: "#1C1C19" }}>
            {title}
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, color: "#D5D9D9" }}>
            {subTitle}
          </Typography>
        </Box>
        {status ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 500,
                color: "#5F6267",
              }}
            >
              {moment(date).format(formattedDate)}
            </Typography>
          </Box>
        ) : null}
      </Box>

      {showSkipBtn && (
        <Box display="flex" alignItems="center">
          <Button
            sx={{
              px: "4px",
              py: 0,
              minWidth: 0,
            }}
            onClick={() => onClickGo(name, true)}
          >
            Skip
          </Button>
        </Box>
      )}

      <Box
        sx={{
          width: "20%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {status ? (
          <Button
            sx={{
              width: "93px",
              height: "35px",
              fontSize: 16,
              fontWeight: 500,
              whiteSpace: "nowrap",
              borderColor: "#D4D4D4",
            }}
            color="cancel"
            variant="outlined"
            onClick={() => onClickGo("")}
          >
            View
          </Button>
        ) : null}
        {!status ? (
          <StyledTooltip
            arrow
            placement="top"
            title={titleTooltip || ""}
            open={showTooltip}
            disableHoverListener={!showTooltip}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -5] },
                },
              ],
            }}
          >
            <Box component="span">
              <Button
                sx={{
                  width: "93px",
                  height: "35px",
                  fontSize: 16,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                  borderColor: "#D4D4D4",
                }}
                variant="contained"
                onClick={() => onClickGo(name)}
              >
                Go
              </Button>
            </Box>
          </StyledTooltip>
        ) : null}
      </Box>
    </Box>
  );
};

TaskItem.propTypes = {
  status: bool,
  title: string,
  subTitle: string,
  date: string,
  onClickGo: func,
  name: string,
  hasError: bool,
  titleTooltip: string,
  showTooltip: bool,
};
