import { Box, Chip, Typography } from "@mui/material";
import { useMemo, useRef } from "react";
import { func, object } from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import ActiveTabs from "../../../../components/ActiveTabs";
import FilterMenu from "../../../../components/FilterMenu";
import { FilterIcon } from "../../../../components/Icons";
import StyledButton from "../../../../components/StyledButton";
import { FilterSearchTextField } from "../../../../components/TextFields/TextFields";
import { normalizeSnakeCaseString } from "../../../../helpers/helpers";
import {
  routesGetSwitchFieldActions,
  routesGetFiltersAction,
  routesQuickFilterActions,
} from "../../../../redux/actions/reps";
import {
  routesGetSwitchSelector,
  routesGetFilterSelector,
  routesGetQuickFilterSelector,
} from "../../../../redux/selectors/reps";
import { FILTER_TABS } from "./RoutesFilter.constants";
import useStyles from "./styles";
import { useEffect } from "react";
import { useDebounce } from "../../../../helpers/hooks";
import { currentUserSelector } from "redux/selectors/auth";
import { ROLES, TYPES, useRepsActions } from "helpers/useRepsActions";

const selector = createSelector(
  routesGetSwitchSelector,
  routesGetFilterSelector,
  routesGetQuickFilterSelector,
  currentUserSelector,
  (routesGetSwitch, routesGetFilter, routesQuickFilter, currentUser) => ({
    routesGetSwitch,
    routesGetFilter,
    routesQuickFilter,
    currentUser,
  })
);

const RoutesFilter = ({ sortBy, fetchRoutes, setSelectedRoutes }) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 500);
  const filterAnchor = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { routesGetSwitch, routesGetFilter, routesQuickFilter } =
    useSelector(selector);

  const [tabField, setTabField] = useState(routesQuickFilter);
  const [filterFields, setFilterFields] = useState(routesGetFilter);
  const [switches, setSwitches] = useState(routesGetSwitch);
  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  useEffect(() => {
    dispatch(routesQuickFilterActions(tabField));
  }, [dispatch, tabField]);

  useEffect(() => {
    dispatch(routesGetSwitchFieldActions(switches));
  }, [dispatch, switches]);

  useEffect(() => {
    dispatch(routesGetFiltersAction(filterFields));
  }, [dispatch, filterFields]);

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const {
    list: salesList,
    count: countSales,
    handleFetchReps,
    page: salesPage,
    setRepsParams,
    searcheble: repsSearcheble,
  } = useRepsActions({
    type: TYPES.reps,
    params: {
      exclude_roles: JSON.stringify([ROLES.third_party]),
      active: true,
    },
    open: filterMenuOpen,
  });

  const selectMatchData = useMemo(
    () => [
      {
        id: "representative_id",
        childrenList: salesList,
        handleFetch: () => handleFetchReps(salesPage + 1),
        setParams: setRepsParams,
        dataCount: countSales,
        dataLength: salesList.length,
        label: "Select Rep",
        searcheble: repsSearcheble,
      },
    ],
    [
      salesList,
      countSales,
      handleFetchReps,
      salesPage,
      repsSearcheble,
      setRepsParams,
    ]
  );

  const getChipLabel = (key) => {
    const fieldKeys = Object.keys(filterFields[key]);
    const isCombined = key === "total_stops";
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
          <span style={{ color: "#5F6368" }}>
            {isCombined
              ? fieldKeys
                  .filter((fieldKey) => filterFields[key][fieldKey]?.value)
                  .map((fieldKey) => {
                    const { prefix, value } = filterFields[key][fieldKey];
                    return `${prefix} ${value}`;
                  })
                  .join(" and ")
              : normalizeSnakeCaseString(
                  filterFields[key]?.name || filterFields[key]
                )}
          </span>
        </Typography>
      </Box>
    );
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  useEffect(() => {
    let didCancel = false;
    const { sort_by_name, sort_by_last_check_in, sort_by_total_stops } = sortBy;
    fetchRoutes({
      ...filterFields,
      [tabField.name]: tabField.value,
      search: searchDebounced,
      didCancel,
      page: 1,
      sort_by_name: sort_by_name || null,
      sort_by_last_check_in: sort_by_last_check_in || null,
      sort_by_total_stops: sort_by_total_stops || null,
    });

    return () => {
      didCancel = true;
    };
  }, [filterFields, fetchRoutes, tabField, searchDebounced, sortBy]);

  return (
    <Box className={classes.filterWrapper}>
      <ActiveTabs
        value={tabField.value}
        onChange={(e, newVal) => {
          setTabField({ name: e.target.name, value: newVal });
          setSelectedRoutes([]);
        }}
        customTabs={FILTER_TABS}
        tabStyles={{ fontWeight: "400" }}
      />

      <FilterSearchTextField
        adminIsAllowed
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        formSx={{ minWidth: "380px" }}
        placeholderWidth="340px"
        placeholder="Search routes"
        fullWidth
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
        customAdornment={
          <Box
            display="flex"
            gap="5px"
            overflow="auto"
            sx={{
              py: "4px",
              "&::-webkit-scrollbar": {
                height: "2px",
              },
            }}
          >
            {filterChipKeys.map((key) => (
              <Chip
                key={key}
                className={classes.filterChip}
                size="small"
                label={getChipLabel(key)}
                deleteIcon={<CloseIcon size="15" style={{ fill: "#707070" }} />}
                onMouseDown={(e) => e.stopPropagation()}
                onDelete={() => handleDeleteFilter(key)}
              />
            ))}
          </Box>
        }
        // show the clear button only if the input field has a value and does not have a chip
        handleClearValue={filterChipKeys?.length ? null : () => setSearch("")}
      />
      <StyledButton
        label="Filter"
        startIcon={
          filterChipKeys.length ? (
            <Typography
              fontSize="9px!important"
              color="#ffffff"
              backgroundColor="#47A06D"
              borderRadius="50%"
              width="15px"
              height="15px"
            >
              {filterChipKeys.length}
            </Typography>
          ) : (
            <FilterIcon />
          )
        }
        variant="outlined"
        ref={filterAnchor}
        color="edit"
        className={classes.filterButton}
        fontSize="15px"
        onClick={() => setFilterMenuOpen(true)}
        data-testid="filter-button"
      />
      <FilterMenu
        anchorEl={filterAnchor.current}
        open={filterMenuOpen}
        onClose={() => setFilterMenuOpen(false)}
        filterFields={filterFields}
        setFilterFields={setFilterFields}
        switches={switches}
        handleApplyFilter={handleApplyFilter}
        selectMatchData={selectMatchData}
      />
    </Box>
  );
};

RoutesFilter.propTypes = {
  sortBy: object,
  fetchRoutes: func,
  setSelectedRoutes: func,
};

export default RoutesFilter;
