import {
  ImportContext,
  defaultImportState,
} from "Pages/SettingsPage/SettingsPage";
import {
  generateAlphabetMap,
  getEnvironment,
  uploadProgress,
} from "helpers/helpers";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  finishImportService,
  fixImportErrorService,
  getImportSchemasService,
  getImportSessionByIdService,
  getImportSessionsService,
  importDeleteAllErrorsService,
  remapImportDataService,
  uploadImportSheetService,
  validateImportService,
} from "services/import";
import { error, successMiddle } from "utils/notifications";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { deleteImportSessionService } from "services/import";
import { getImportSessionStateByIdService } from "services/import";
import { IMPORT_LIMIT, IMPORT_STATUSES } from "./ImportTab.constants";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  endBefore,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  getCountFromServer,
  where,
} from "firebase/firestore";
import { app } from "firebase/Chat/config";
import { useNavigate } from "react-router-dom";
import ExcelSample from "import_templates/customers_sample.xlsx";
import OrderExcelSample from "import_templates/orders_sample.xlsx";
import {
  KEY_TYPES,
  getImportOptions,
} from "../ImportExportTab/ImportExportTab.constants";
import { APP_ENVS } from "utils/constants";
import { useRepsPermissions } from "helpers/hooks";

const useImportStatus = ({ session }) => {
  const [importSessionDoc, importSessionDocLoading] = useDocument(
    doc(getFirestore(app), `importer/${session}`)
  );

  const { status: importStatus } = useMemo(
    () => importSessionDoc?.data() || {},
    [importSessionDoc]
  );

  return { importStatus, importStatusLoading: importSessionDocLoading };
};

export const useImport = () => {
  const { importData, setImportData } = useContext(ImportContext);

  const { importStatus, importStatusLoading } = useImportStatus({
    session: importData.uploadSession?.session,
  });

  const importRef = collection(
    getFirestore(),
    `importer/${importData.uploadSession?.session}/data`
  );

  const [importPagination, setImportPagination] = useState({
    page: 0,
    cursorStart: 2,
    cursorEnd: -1,
    cursorPoints: [],
  });

  const [importRows, importRowsLoading] = useCollection(
    query(
      importRef,
      orderBy("error", "desc"),
      startAfter(importPagination.cursorStart || 2),
      endBefore(importPagination.cursorEnd || -1),
      limit(IMPORT_LIMIT)
    )
  );

  const tableRows = useMemo(
    () => importRows?.docs?.map((r) => ({ id: r.id, ...r.data() })) || [],
    [importRows?.docs]
  );

  const updateImportData = useCallback(
    (newValues) => {
      setImportData((prev) => ({ ...prev, ...newValues }));
    },
    [setImportData]
  );

  const lastDoc = useMemo(
    () => importRows?.docs[importRows?.docs?.length - 1],
    [importRows?.docs]
  );

  const handlePaginate = (page) => {
    if (
      page > importPagination.page &&
      lastDoc?.id === importPagination.cursorStart?.id
    )
      return;
    if (page > importPagination.page) {
      if (!lastDoc) return;
      setImportPagination((prev) => ({
        ...prev,
        cursorStart: lastDoc,
        cursorEnd: -1,
        page,
        cursorPoints: [...prev.cursorPoints, lastDoc],
      }));
    } else {
      setImportPagination((prev) => {
        const prevCursorStart = prev.cursorPoints[prev.cursorPoints.length - 2];
        const lastCursorStart = prev.cursorPoints[prev.cursorPoints.length - 1];

        return {
          ...prev,
          cursorEnd: -1,
          cursorStart: page === 0 ? 2 : prevCursorStart,
          page,
          cursorPoints:
            page === 0
              ? []
              : prev.cursorPoints.filter(
                  (point) =>
                    point?.id !== lastCursorStart?.id ||
                    point !== lastCursorStart
                ),
        };
      });
    }
  };

  const importNextDisabled = useMemo(() => {
    switch (importData.step) {
      case 0:
        return !importData.fileUploadSuccess;
      case 1:
        return importStatus === IMPORT_STATUSES.ERROR_REMAP.status;
      case 2:
        return importStatus !== IMPORT_STATUSES.READY_FOR_IMPORT.status;
      default:
        return false;
    }
  }, [importData.fileUploadSuccess, importData.step, importStatus]);

  const handleCheckImportState = useCallback(async () => {
    try {
      const res = await getImportSessionStateByIdService(
        importData.uploadSession?.session
      );
      return res;
    } catch (err) {
      error("Import data not ready. Try again.");
    }
  }, [importData.uploadSession?.session]);

  const handleUploadSheet = useCallback(
    async (e) => {
      if (e.target.files.length === 0) return;
      const file = e.target.files[0];
      try {
        updateImportData({
          uploading: true,
          fileUploadSuccess: false,
          file: null,
        });

        const uploadSession = await uploadImportSheetService({
          data: file,
          type: importData.importType.key,
          onUploadProgress: (progressEvent) =>
            uploadProgress(progressEvent, setImportData, "uploadingProgress"),
        });

        updateImportData({
          file,
          uploading: false,
          fileUploadSuccess: true,
          uploadSession,
        });
      } catch (err) {
        updateImportData({ uploading: false, fileUploadSuccess: false });
        error(err?.response?.data?.message);
      }
    },
    [importData.importType.key, setImportData, updateImportData]
  );

  const dispatch = useDispatch();

  const handleDeleteImportSession = async (option, schemasList) => {
    try {
      updateImportData({ confirmLoading: true });
      const currentSession =
        importData?.uploadSession?.session ||
        importData.records[option?.key][0]?.session;

      await deleteImportSessionService(currentSession);
      dispatch(setConfirmIsOpenAction(false));

      updateImportData({
        ...defaultImportState,
        isImport: true,
        confirmLoading: false,
        schemas: schemasList,
        importType: option,
      });
    } catch (err) {
      updateImportData({ confirmLoading: false });
      error(err?.response?.data?.message);
    }
  };

  const handleContinueMapping = async (type) => {
    const record = importData.records[type][0];
    const step = IMPORT_STATUSES[record.state].step || 0;
    updateImportData({ step, isImport: true, uploadSession: record });
    dispatch(setConfirmIsOpenAction(false));
  };

  const handleImport = (option, inProgress) => {
    const currentSchema = importData.allSchemas[option.key];

    updateImportData({
      importType: option,
      currentSchema,
    });

    if (inProgress) {
      dispatch(
        openConfirmDialogAction({
          title: "Import Confirmation",
          text: `You have previously uploaded a file for import. Choose one of the options below:
          
                1. Delete Previously Uploaded File & Upload New
                2. Continue Mapping of Previously Uploaded File

                Select your preferred option:`,
          propBtns: {
            left: {
              onClick: () => handleDeleteImportSession(option, currentSchema),
              color: "confirmDelete",
              variant: "contained",
              label: "Delete & Upload New",
            },

            right: {
              color: "primary2",
              onClick: () => handleContinueMapping(option.key),
              variant: "contained",
              label: "Continue Mapping",
            },
          },
        })
      );
      return;
    }

    updateImportData({
      isImport: true,
    });
  };

  const getImportData = useCallback(async () => {
    if (importData.isImport) return;
    try {
      updateImportData({ sessionsListLoading: true });
      const [{ records }, { schemas }] = await Promise.all([
        getImportSessionsService(),
        getImportSchemasService(),
      ]);

      updateImportData({
        sessionsListLoading: false,
        allSchemas: schemas,
        records,
      });
    } catch (err) {
      updateImportData({ sessionsListLoading: false });
      error(err?.response?.data?.message);
    }
  }, [importData.isImport, updateImportData]);

  const handleImportNext = useCallback(async () => {
    const { state } = (await handleCheckImportState()) || {};
    switch (importData.step) {
      case 0: {
        if (state === IMPORT_STATUSES.READY_FOR_REMAP.status)
          updateImportData({ step: importData.step + 1 });
        break;
      }
      case 1: {
        const data = {
          key: importData.importType?.key,
          cols: importData.mappedCols,
        };
        const { success } = await remapImportDataService(
          importData.uploadSession?.session,
          data
        );
        if (success) updateImportData({ step: importData.step + 1 });

        break;
      }
      case 2: {
        const { state } = (await handleCheckImportState()) || {};
        if (state === IMPORT_STATUSES.READY_FOR_IMPORT.status) {
          await finishImportService(importData.uploadSession?.session);
        }
        break;
      }
      default:
        return;
    }
  }, [
    handleCheckImportState,
    importData.mappedCols,
    importData.step,
    importData.uploadSession?.session,
    updateImportData,
    importData.importType?.key,
  ]);

  const getRowsCount = useCallback(
    async ({ force }) => {
      if (
        (!force && importData.countFetched) ||
        !importData.uploadSession?.session
      )
        return;
      const rowsSnap = await getCountFromServer(importRef);

      updateImportData({
        countFetched: true,
        rowsCount: rowsSnap?.data?.()?.count || 0,
      });
    },
    [
      importData.countFetched,
      importData.uploadSession?.session,
      importRef,
      updateImportData,
    ]
  );

  const getErrorsCount = useCallback(async () => {
    const errorQ = query(
      collection(
        getFirestore(),
        `importer/${importData.uploadSession?.session}/data`
      ),
      where("error", "==", 1)
    );

    const errorsSnap = await getCountFromServer(errorQ);

    updateImportData({
      errorsCount: errorsSnap?.data?.()?.count || 0,
      errorsCountFetched: true,
    });
  }, [importData.uploadSession?.session, updateImportData]);

  const getCurrentTemplate = useCallback(() => {
    switch (importData.importType?.title) {
      case "Customers":
        return ExcelSample;
      case "Orders":
        return OrderExcelSample;
      default:
        return ExcelSample;
    }
  }, [importData.importType]);

  return {
    handleUploadSheet,
    importData,
    updateImportData,
    getImportData,
    handleImport,
    handleImportNext,
    importNextDisabled,
    handleCheckImportState,
    handlePaginate,
    importPagination,
    tableRows,
    importStatusLoading,
    importStatus,
    getRowsCount,
    getErrorsCount,
    importRowsLoading,
    getCurrentTemplate,
    handleDeleteImportSession,
  };
};

export const useMapData = () => {
  const { importData } = useContext(ImportContext);

  const { currentSession } = useMemo(
    () => importData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importData.currentSession]
  );

  const currentColumns = useMemo(
    () => importData.currentSchema?.cols || {},
    [importData.currentSchema?.cols]
  );

  const columnsMap = useMemo(
    () =>
      generateAlphabetMap(
        Object.keys(currentColumns)
          .filter((key) => {
            return key !== "discount" && key !== "paymentTerms";
          })
          .map((key) => ({
            ...currentColumns[key],
            field: key,
          }))
      ),
    [currentColumns]
  );

  const { headers } = useMemo(
    () => currentSession || { headers: {} },
    [currentSession]
  );

  const [session, setSession] = useState({
    // query: { limit: 5, offset: 0, search: "", direction: "desc" },
    loading: false,
    columnsMap,
  });

  const { updateImportData } = useImport();

  const updateSession = useCallback((newValues) => {
    setSession((prev) => ({ ...prev, ...newValues }));
  }, []);

  const updateSessionQuery = useCallback(
    (newValues) => {
      updateSession({ query: { ...session.query, ...newValues } });
    },
    [session.query, updateSession]
  );

  const getSessionById = useCallback(async () => {
    try {
      updateSession({ loading: true });
      const { data } = await getImportSessionByIdService(
        importData.uploadSession?.session,
        { ...session.query }
      );
      updateSession({ loading: false });

      updateImportData({ currentSession: data });
      if (data.map) {
        updateImportData({ mappedCols: data.map });
      }
    } catch (err) {
      updateSession({ loading: false });
      error(err?.response?.data?.message);
    }
  }, [
    importData.uploadSession?.session,
    session.query,
    updateImportData,
    updateSession,
  ]);

  useEffect(getSessionById, [getSessionById]);

  const generateMappedCols = () => {
    const cols = {};

    session.columnsMap.forEach((item) => {
      Object.assign(cols, { [item.column]: item.field });
    });
    return cols;
  };

  const mappedCols = useMemo(generateMappedCols, [session.columnsMap]);

  const updateColumnsMapContext = useCallback(() => {
    updateImportData({ mappedCols });
  }, [mappedCols, updateImportData]);

  useEffect(updateColumnsMapContext, [updateColumnsMapContext]);

  const checkUniqueCols = useCallback((cols) => {
    const colsArray = Object.values(cols);
    const hasDuplicate = new Set(colsArray).size !== colsArray.length;
    return hasDuplicate;
  }, []);

  const onRemapChange = useCallback(
    async ({ value, column, index }) => {
      try {
        const cols = { ...importData.mappedCols, [column]: value };

        const hasDuplicates = checkUniqueCols(cols);
        if (hasDuplicates) {
          const newColumnsMap = [...session.columnsMap];

          if (index > -1) {
            const newObj = { ...newColumnsMap[index], field: value };
            newColumnsMap.splice(index, 1, newObj);
            updateSession({
              columnsMap: newColumnsMap,
            });
          }

          return;
        }

        updateSession({ loading: true });

        const data = {
          key: importData.importType?.key,
          cols,
        };
        const { data: newColumns } = await remapImportDataService(
          importData.uploadSession?.session,
          data
        );
        updateSession({
          loading: false,
          columnsMap: Object.keys(newColumns).map((key) => ({
            ...newColumns[key],
            column: key,
          })),
        });
      } catch (err) {
        updateSession({ loading: false });
        error(err?.response?.data?.message);
      }
    },
    [
      checkUniqueCols,
      importData.mappedCols,
      importData.uploadSession?.session,
      session.columnsMap,
      importData.importType?.key,
      updateSession,
    ]
  );

  return {
    session,
    updateSessionQuery,
    onRemapChange,
    currentColumns,
    currentSession,
    headers,
    getSessionById,
  };
};

export const useImportValidation = ({ tableRows }) => {
  const { headers, currentSession, currentColumns } = useMapData();
  const repPermissions = useRepsPermissions();

  const navigate = useNavigate();

  const { importData } = useContext(ImportContext);
  const [validationState, setValidationState] = useState({
    errors: [],
    loading: false,
    count: 0,
    changes: null,
  });

  const {
    updateImportData,
    importStatus,
    getErrorsCount,
    getRowsCount,
    handleDeleteImportSession,
  } = useImport();

  useEffect(() => {
    if (importStatus === IMPORT_STATUSES.READY_FOR_VALIDATION.status) {
      validateImportService(importData.uploadSession?.session);
    }
    if (importStatus === IMPORT_STATUSES.DONE.status) {
      successMiddle(
        `${importData.rowsCount} ${importData.importType?.key} successfully imported`
      );
      if (repPermissions) {
        if (
          importData.importType?.key === KEY_TYPES.customers &&
          !repPermissions?.customers?.view
        ) {
          return navigate("/");
        }
        if (
          importData.importType?.key === KEY_TYPES.orders &&
          !repPermissions?.orders?.view
        ) {
          return navigate("/");
        }
        if (
          importData.importType?.key === KEY_TYPES.products &&
          !repPermissions?.products?.view
        ) {
          return navigate("/");
        }
      }
      navigate(`/${importData.importType?.key}`);
    }
  }, [
    importData.importType?.key,
    importData.rowsCount,
    importData.status,
    importData.uploadSession?.session,
    importStatus,
    navigate,
    repPermissions,
    updateImportData,
  ]);

  const updateValidationState = (newObj) => {
    setValidationState((prev) => ({ ...prev, ...newObj }));
  };

  const ERROR_MAP = {
    isPhoneNumber: "Invalid phone number",
    unique: "Duplicate value found in file",
    unique_in_db: "Duplicate value found in database",
    uniqueComposite: (value) => {
      if (value && Array.isArray(value)) {
        if (
          value.length === 2 &&
          value.includes("ID") &&
          value.includes("Product Name")
        )
          return "Remove duplicate products from the order";
        return `Duplicate of [${value.join(", ")}] combination found in file`;
      }
      return "Duplicate value found in file";
    },
    required: "Value is required",
    isNumber: "Value should be number format",
    isString: "Value should be text format",
    isURL: "Value should be URL format",
    not_found: "Not found in Database",
    isEmail: "Invalid email format",
    isFloat: "Max 2 decimal places (hundredths) allowed.",
    notContainsInvalidCharacters: "Invalid character used",
  };

  // const REQUIRED_FIELDS = useMemo(
  //   () => ({
  //     customers: [
  //       "name",
  //       "customerBillingAddress",
  //       "customerBillingCity",
  //       "customerBillingState",
  //       "customerBillingZip",
  //       "customerShippingAddress",
  //       "customerShippingCity",
  //       "customerShippingState",
  //       "customerShippingZip",
  //     ],
  //     orders: ["orderId", "customer", "productName", "quantity"],
  //   }),
  //   []
  // );

  const getErrorMessage = (errors) => {
    const messages = Object.entries(errors).map(([key, value]) => {
      return (
        (typeof ERROR_MAP[key] === "function"
          ? ERROR_MAP[key](value)
          : ERROR_MAP[key]) || `Unknown error: ${key}`
      );
    });
    return messages.join("\n");
  };

  const formatCell = (field, value) => {
    const VALIDATION_MAP = {
      federalTaxId: (val) => {
        return Number(val);
      },
      percentDiscount: (val) => {
        return Number(val);
      },
      customerBillingZip: (val) => {
        return Number(val);
      },
      customerShippingZip: (val) => {
        return Number(val);
      },
      quantity: (val) => {
        return Number(val);
      },
    };

    const validate = VALIDATION_MAP[field];

    if (validate) return validate(value);
    return value?.toString() || "";
  };

  const handleDeleteCell = async (ids) => {
    try {
      updateValidationState({ loading: true });
      await fixImportErrorService(importData.uploadSession?.session, {
        rowsToDelete: ids,
      });
      getErrorsCount();
      getRowsCount({ force: true });
      updateValidationState({ loading: false });
      updateImportData({ errorsCountFetched: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      updateValidationState({ loading: false });
      error(err?.response?.data?.message);
    }
  };

  const isProdEnv = getEnvironment(APP_ENVS.prod);

  const IMPORT_OPTIONS_LIST = getImportOptions({ isProdEnv, repPermissions });

  const handleDeleteAllCell = async ({ withDeleteSession }) => {
    try {
      const option = IMPORT_OPTIONS_LIST.find((el) => el?.key === "customers");
      const currentSchema = importData.allSchemas[option.key];

      if (withDeleteSession) {
        await handleDeleteImportSession(option, currentSchema);

        updateImportData((prev) => ({ ...prev, importType: null }));

        navigate("/settings", {
          state: { tab: "Import/Export", goBack: false },
        });

        return;
      }

      updateValidationState({ loading: true });
      await importDeleteAllErrorsService(importData.uploadSession?.session);

      getErrorsCount();
      getRowsCount({ force: true });
      updateValidationState({ loading: false });
      updateImportData({ errorsCountFetched: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      updateValidationState({ loading: false });
      error(err?.response?.data?.message);
    }
  };

  const handleSaveLocalChanges = useCallback(
    ({ newRow, modifiedKey }) => {
      if (!newRow || !modifiedKey) {
        return;
      }

      const newValue = newRow[modifiedKey]?.trim?.() || "";

      const oldRow = tableRows.find(({ id }) => id === newRow.id);

      if (!oldRow) {
        return;
      }

      const oldRowValueIsTheSame = oldRow[modifiedKey] === newValue;
      // const fieldName = importData.mappedCols[modifiedKey];

      // const currentRequiredFields =
      //   REQUIRED_FIELDS[importData.currentSchema.key];

      // const isFieldRequired = currentRequiredFields.includes(fieldName);

      setValidationState((prev) => {
        if (oldRowValueIsTheSame) {
          const changesWithoutModifiedKey = { ...prev.changes?.[newRow.id] };
          delete changesWithoutModifiedKey[modifiedKey];
          if (!Object.keys(changesWithoutModifiedKey).length) {
            const changesWithoutRowId = { ...prev.changes };
            delete changesWithoutRowId[newRow.id];
            if (!Object.keys(changesWithoutRowId).length) {
              return { ...prev, changes: null };
            }
            return { ...prev, changes: changesWithoutRowId };
          }
          return {
            ...prev,
            changes: {
              ...prev.changes,
              [newRow.id]: changesWithoutModifiedKey,
            },
          };
        }

        return {
          ...prev,
          changes: {
            ...prev.changes,
            [newRow.id]: {
              ...(prev?.changes?.[newRow.id] || {}),
              [modifiedKey]: newValue,
            },
          },
        };
      });
    },
    [tableRows]
  );

  const handleSaveTable = useCallback(async () => {
    updateValidationState({ loading: true });
    try {
      const formattedChanges = Object.entries(
        validationState.changes || {}
      ).reduce((acc, [rowId, changes]) => {
        const formattedRowChanges = Object.entries(changes).reduce(
          (rowAcc, [field, value]) => {
            const fieldName = importData.mappedCols[field];
            const sendValue = formatCell(fieldName, value);
            return {
              ...rowAcc,
              [field]: sendValue,
            };
          },
          {}
        );

        return {
          ...acc,
          [rowId]: formattedRowChanges,
        };
      }, {});

      if (!formattedChanges || Object.keys(formattedChanges).length === 0) {
        updateValidationState({ loading: false });
        return;
      }
      await fixImportErrorService(
        importData.uploadSession?.session,
        formattedChanges
      );
      getErrorsCount();
      updateValidationState({ loading: false, changes: null });
      updateImportData({ errorsCountFetched: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      updateValidationState({ loading: false });
      error(err?.response?.data?.message);
    }
  }, [
    getErrorsCount,
    importData.mappedCols,
    importData.uploadSession?.session,
    updateImportData,
    validationState.changes,
  ]);

  return {
    validationState,
    getErrorMessage,
    handleDeleteCell,
    handleDeleteAllCell,
    headers,
    currentSession,
    currentColumns,
    handleSaveLocalChanges,
    handleSaveTable,
  };
};
