import React, { useMemo } from "react";
import { object, bool, func } from "prop-types";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { ArrowForwardIos } from "@mui/icons-material";
import { StyledAvatar } from "components";
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors/auth";
import { formatDate, photoUrl } from "helpers/helpers";

const NoteItem = ({ note, showBorder, onClick }) => {
  const currentUser = useSelector(currentUserSelector);
  const {
    createdAt = "",
    text = "",
    representativeDuplicate,
  } = useMemo(() => note || {}, [note]);

  const name = useMemo(
    () => representativeDuplicate?.name || "Admin",
    [representativeDuplicate?.name]
  );

  const fileName = useMemo(
    () =>
      representativeDuplicate
        ? representativeDuplicate?.profilePhoto?.fileName
        : currentUser?.profilePhoto?.fileName,
    [currentUser?.profilePhoto?.fileName, representativeDuplicate]
  );

  const formattedDate = formatDate({
    utc_date: createdAt,
    formatThisYear: "MMM D",
  });

  return (
    <Grid
      onClick={() => onClick(note)}
      columns={25}
      container
      sx={{
        borderBottom: showBorder && "1px solid #D5D9D9",
        "& .MuiTypography-root": {
          fontSize: "13px",
          color: "#1C1C19",
        },
        "&:hover": {
          backgroundColor: "#F7F7F7",
        },
        cursor: "pointer",
      }}
      height="48px"
      alignItems="center"
    >
      <Grid item xs={4} sx={{ pl: "19px" }}>
        <Typography>{moment(createdAt).format(formattedDate)}</Typography>
      </Grid>
      <Grid item xs={13}>
        <Typography noWrap maxWidth="90%">
          {text}
        </Typography>
      </Grid>
      <Grid item xs={7.1} display="flex" gap="8px" alignItems="center">
        <StyledAvatar
          name={representativeDuplicate?.name || ""}
          sx={{ width: 23.5, height: 23.5 }}
          stringStyle={{ fontSize: 12 }}
          src={photoUrl(fileName)}
        />
        <Typography noWrap>{name}</Typography>
      </Grid>
      <Grid item xs>
        <ArrowForwardIos style={{ fill: "#5F6267", fontSize: "13px" }} />
      </Grid>
    </Grid>
  );
};

NoteItem.propTypes = { note: object, showBorder: bool, onClick: func };

export default NoteItem;
