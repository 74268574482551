/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useMemo, useCallback, useRef } from "react";
import { object, bool, func, string } from "prop-types";
import { useNavigate, Link as NavLink } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Box,
  IconButton,
  Chip,
  Stack,
} from "@mui/material";
import { LargeCheckbox } from "components/Checkboxes";
import {
  InformationIcon,
  PaperIcon,
  SendIcon,
  ShippingCarIcon,
  SimpleArrowDownIcon,
} from "components/Icons";
import {
  getCreatedAt,
  useAdmin,
  normalizeSnakeCaseString,
  getFormattedDate,
  getCreatedOrderBy,
  truncateText,
  separateNumWithComma,
} from "helpers/helpers";
import PaymentStatus from "components/PaymentStatus/PaymentStatus.jsx";
import { cl } from "./styles";
import { useSelector } from "react-redux";
import { getPaymentDue } from "Pages/OrdersPage/Orders.helper";
import { createSelector } from "reselect";
import { columnLayoutsSelector } from "redux/selectors/settings";
import { MenuColumnOrder } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/TabSortedComponent/components/columnsData/MenuColumn/MenuColumnOrder";
import { useTheme } from "@emotion/react";
import { StyledTooltip } from "components";
import {
  CustomerMenu,
  DueTooltip,
  FulfillmentTooltip,
  PrintedBlock,
  TagsMenu,
} from "./components";
import moment from "moment-timezone";
import { useBreakpoint } from "helpers/useBreakpoint";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

const columnWidth = {
  Note: 2.5,
  Date: 11,
  Customer: 24.1,
  "Created by": 9.4,
  Payment: 13,
  Fulfillment: 12,
  Total: 8,
  Tags: 20,
};

const CHECKBOX_WIDTH = 52;
const ORDER_WIDTH = 72;
const ACTIONS_WIDTH = 120;

export const OrdersItem = ({
  order,
  isChecked,
  handleCheckOrder,
  timeZone,
  handleChoseMenuItem,
  handleOpenEmail,
  handleConfirmCheckedItemsDialog,
  short,
  showBorder,
  isQuickBooksConnected,
  currentTab,
  isPopup,
  isCustomerView,
  handleClickOnCustomId,
  setOpenAddContactPopup,
  repPermissions,
}) => {
  const theme = useTheme();
  const isAdmin = useAdmin();

  const isThirdParty = useMemo(
    () => order?.type === "THIRD_PARTY",
    [order?.type]
  );

  const [open, setOpen] = useState(false);
  const [anchorCustomerEl, setAnchorCustomerEl] = useState(null);
  const [anchorTagsEl, setAnchorTagsEl] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [hoverColor, setHoverColor] = useState("");

  const checkboxRef = useRef(null);

  const checkboxWidth = checkboxRef.current?.offsetWidth + 1 || 0;

  const customerNameRef = useRef(null);

  const additionalCountTagsRef = useRef(null);

  const handleClickOnArrow = useCallback(
    (e, order) => {
      e.stopPropagation();
      setSelectedOrder(order);
      if (customerNameRef.current) {
        if (repPermissions && !repPermissions?.customers?.create_edit) return;
        setAnchorCustomerEl(customerNameRef.current);
      }
    },
    [repPermissions]
  );

  const handleCloseArrowBtn = (e) => {
    e.stopPropagation();
    setSelectedOrder(null);
    setSelectedTags(null);
    setAnchorCustomerEl(null);
    setAnchorTagsEl(null);
  };

  const handleClickOnRestTags = (e, tags) => {
    e.stopPropagation();
    setSelectedTags(tags);
    if (additionalCountTagsRef.current) {
      setAnchorTagsEl(additionalCountTagsRef.current);
    }
  };

  const navigate = useNavigate();
  const { columnLayouts } = useSelector(selector);

  const breakpoint = useBreakpoint();

  const hasCustomerEmail = useMemo(() => {
    return (
      !!order?.customer?.email ||
      order?.customer?.contacts?.some((contact) => contact?.email)
    );
  }, [order?.customer?.contacts, order?.customer?.email]);

  const getFulfillmentStatus = useMemo(() => {
    const deliveryStatus = normalizeSnakeCaseString(order?.deliveryStatus);
    const items = order?.totalDelivered
      ? `(${order?.totalDelivered} of ${order?.totalQuantity})`
      : "";
    return breakpoint === "xl" ? `${deliveryStatus} ${items}` : deliveryStatus;
  }, [
    breakpoint,
    order?.deliveryStatus,
    order?.totalDelivered,
    order?.totalQuantity,
  ]);

  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  const handleOpenOrder = useCallback(
    (id) => {
      if (formChanged && editType === "checked_items") {
        handleConfirmCheckedItemsDialog(id);
        return;
      }
      navigate(`/orders/${id}`);
    },
    [formChanged, editType, navigate, handleConfirmCheckedItemsDialog]
  );

  const validateIsoDate = (date) => {
    return moment(date, moment.ISO_8601, true).isValid();
  };

  const overduePayment = useMemo(() => {
    const fulfilledAt = order?.fulfilledAt;

    if (!validateIsoDate(fulfilledAt)) return null;

    const todayAtMidnight = moment.tz(timeZone).startOf("day");
    const fulfilledAtMoment = moment.tz(fulfilledAt, timeZone).startOf("day");

    return todayAtMidnight.diff(fulfilledAtMoment, "days");
  }, [order?.fulfilledAt, timeZone]);

  const setHideDue = useMemo(() => {
    if (order?.deliveryStatus === "FULFILLED") return false;

    return !overduePayment;
  }, [order?.deliveryStatus, overduePayment]);

  const hideDueDate = useMemo(
    () =>
      order?.orderStatus === "CANCELED" ||
      order?.paymentStatus === "PAID" ||
      !order?.paymentTermsDuplicate ||
      order?.deliveryStatus !== "FULFILLED",
    [
      order?.deliveryStatus,
      order?.orderStatus,
      order?.paymentStatus,
      order?.paymentTermsDuplicate,
    ]
  );

  const dueData = useMemo(() => {
    return hideDueDate
      ? false
      : getPaymentDue({
          fulfilledAt: order?.fulfilledAt || order?.shippedAt,
          daysInvoices: order?.paymentTermsDuplicate?.daysInvoices,
          hideDue: setHideDue,
        });
  }, [
    hideDueDate,
    order?.fulfilledAt,
    order?.paymentTermsDuplicate?.daysInvoices,
    order?.shippedAt,
    setHideDue,
  ]);

  const tags = useMemo(() => {
    return order?.tags?.length ? order.tags.map((t) => t?.tag) : [];
  }, [order.tags]);

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    []
  );

  const overdueFulfillment = useMemo(() => {
    if (!order?.fulfillBy) return;
    if (order?.deliveryStatus === "FULFILLED") return;

    const todayAtMidnight = moment.tz(timeZone).startOf("day");

    const fulfilledAtMoment = moment
      .tz(order?.fulfillBy, timeZone)
      .startOf("day");

    return todayAtMidnight.diff(fulfilledAtMoment, "days");
  }, [order?.deliveryStatus, order?.fulfillBy, timeZone]);

  const direct_tab_disable = columnLayouts.direct.disable;
  const direct_tab = columnLayouts.direct.main;
  const third_party_tab_disable = columnLayouts.thirdParty.disable;
  const third_party_tab = columnLayouts.thirdParty.main;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const columns = useMemo(() => {
    if (currentTab === "Direct" && direct_tab_disable)
      return [
        ...direct_tab_disable,
        ...direct_tab.filter((col) => col.selected),
      ];
    if (currentTab === "3rd Party")
      return [
        ...third_party_tab_disable,
        ...third_party_tab.filter((col) => col.selected),
      ];
    return [];
  }, [
    currentTab,
    direct_tab,
    direct_tab_disable,
    third_party_tab,
    third_party_tab_disable,
  ]);

  const createdBy = useMemo(() => getCreatedOrderBy(order), [order]);

  const filteredOrderDeliveriesData = useMemo(() => {
    return order?.orderDeliveries?.length
      ? order.orderDeliveries.filter(
          ({ shippingCarrier, shippingCarrierDuplicate }) =>
            shippingCarrier || shippingCarrierDuplicate !== null
        )
      : [];
  }, [order?.orderDeliveries]);

  const columnData = useMemo(
    () => ({
      Order: (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            cursor: "pointer",
            height: "100%",
            textDecorationLine:
              order?.orderStatus === "CANCELED" && "line-through",
            textDecorationColor: "#1C1C19",
          }}
        >
          <Typography mr={0.5} sx={cl.orderText}>
            {order.customId?.customId}
          </Typography>
          {order?.archived && (
            <StyledTooltip
              arrow
              title="Archived"
              placement="top"
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -6] } }],
              }}
            >
              <Box display="flex" alignItems="center" height="100%">
                <InformationIcon size="16" stroke="#FC7063" />
              </Box>
            </StyledTooltip>
          )}
        </Box>
      ),
      Note: (
        <Box display="flex" alignItems="center" position="relative">
          {order && order.note && order.note?.text && (
            <StyledTooltip
              title={order.note.text}
              placement="top"
              arrow
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -4] } }],
              }}
            >
              <Box
                justifyContent="center"
                sx={{
                  height: "17px",
                  svg: { height: "17px", width: "14px" },
                }}
              >
                <PaperIcon color="#5F6267" />
              </Box>
            </StyledTooltip>
          )}
        </Box>
      ),
      Date: (
        <Grid
          item
          sx={{
            ...cl.grid,
            flexDirection: "column",
            alignItems: "flex-start",
            display: "contents",
            textDecorationColor: "#1C1C19",
          }}
        >
          <Typography
            sx={{
              ...cl.typography,
              textDecorationLine:
                order?.orderStatus === "CANCELED" && "line-through",
            }}
          >
            {getCreatedAt(order.createdAt, timeZone)}
          </Typography>
        </Grid>
      ),
      Customer: (
        <>
          <Box display="flex">
            <Box
              sx={cl.orderName}
              display="flex"
              gap="3px"
              alignItems="center"
              flexWrap="nowrap"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClickOnArrow(e, order);
              }}
            >
              <Typography
                ref={customerNameRef}
                sx={{
                  ...cl.typography,
                  textDecorationLine:
                    order?.orderStatus === "CANCELED" && "line-through",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                noWrap
                color="#1C1C19"
              >
                {order?.customer?.displayName || order?.customer?.name}
              </Typography>
              <Box
                display="flex"
                sx={{
                  mb: "-2px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <SimpleArrowDownIcon width="7px" heigh="3.6px" />
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              ...cl.typography,
              marginTop: "2px",
              fontWeight: 300,
              textDecorationLine:
                order?.orderStatus === "CANCELED" && "line-through",
            }}
            noWrap
          >
            {order.customer?.shippingAddress?.formatted_address}
          </Typography>
        </>
      ),
      "Created by": (
        <Typography sx={cl.typography} noWrap>
          {createdBy}
        </Typography>
      ),
      Payment: (
        <Box display="flex" alignItems="center" position="relative">
          <DueTooltip
            emailCount={order?.remindersSent}
            dueData={dueData}
            paymentTermsName={order.paymentTermsDuplicate?.name}
            disableHoverListener={
              order.orderStatus === "CANCELED" ||
              order.paymentStatus === "PAID" ||
              !order.paymentTermsDuplicate
            }
          >
            <PaymentStatus
              str={
                overduePayment > 0 &&
                order.paymentStatus !== "PAID" &&
                dueData?.overdue
                  ? "OVERDUE"
                  : order.paymentStatus
              }
              overdue={dueData?.text}
            />
          </DueTooltip>
        </Box>
      ),
      Fulfillment: (
        <Box display="flex" alignItems="center" position="relative">
          <Box display="flex" alignItems="center" position="relative">
            <DueTooltip
              dueData={{
                text:
                  overdueFulfillment === 0
                    ? "Due today"
                    : `Overdue ${overdueFulfillment} days`,
                dueDate: order.fulfillBy,
              }}
              disableHoverListener={
                order.deliveryStatus === "FULFILLED" || !order.fulfillBy
              }
            >
              {overdueFulfillment >= 0 ? (
                <Box component="span" display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" width="14px">
                    <InformationIcon
                      width="9px"
                      height="9px"
                      stroke={
                        overdueFulfillment === 0
                          ? theme.palette.warning.main
                          : "#FF6969"
                      }
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 300,
                      color: "#EB4233",
                    }}
                  >
                    {overdueFulfillment === 0
                      ? "Due today"
                      : `Overdue ${overdueFulfillment} days`}
                  </Typography>
                </Box>
              ) : (
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{
                      height: "6px",
                      width: "6px",
                      backgroundColor:
                        order.deliveryStatus === "UNFULFILLED"
                          ? "#F0C401"
                          : "#47A06D",
                      borderRadius: "50%",
                      mr: "8px",
                    }}
                  />
                  {order.deliveryStatus === "PARTIALLY_FULFILLED" && (
                    <>
                      <Box
                        sx={{
                          height: "6px",
                          width: "6px",
                          backgroundColor: "#b98900",
                          borderRadius: "50%",
                          position: "absolute",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          left: "1.5px",
                          height: "3px",
                          width: "3px",
                          backgroundImage:
                            "linear-gradient(#ffd79c 50%, #b98900 50%)",
                          borderRadius: "50%",
                        }}
                      />
                    </>
                  )}
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 300,
                      color: "#1C1C19",
                    }}
                  >
                    {getFulfillmentStatus}
                  </Typography>
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {order.deliveryStatus === "PARTIALLY_FULFILLED" ||
                      (order.deliveryStatus === "FULFILLED" &&
                        !!filteredOrderDeliveriesData.length && (
                          <FulfillmentTooltip
                            titleData={filteredOrderDeliveriesData}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Grid
                              container
                              justifyContent="center"
                              width="22px"
                              height="15px"
                              ml="3px"
                            >
                              <ShippingCarIcon />
                            </Grid>
                          </FulfillmentTooltip>
                        ))}
                  </Box>
                </Stack>
              )}
            </DueTooltip>
          </Box>
        </Box>
      ),
      Total: (
        <Typography
          sx={{
            ...cl.typography,
            fontWeight: 600,
            textDecorationLine:
              order.orderStatus === "CANCELED" && "line-through",
            textDecorationColor: "#1C1C19",
          }}
          noWrap
        >
          $ {order?.totalAmount?.toFixed(2)}
        </Typography>
      ),
      Tags: (
        <Box
          sx={{
            ...cl.orderName,
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <Box
            sx={{ width: "30px", minWidth: "30px" }}
            display="flex"
            gap={0.5}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const tagsWithoutFirstItem = [...tags].slice(1);
              handleClickOnRestTags(e, tagsWithoutFirstItem);
            }}
          >
            {tags?.length > 1 ? (
              <>
                <Typography
                  ref={additionalCountTagsRef}
                  fontSize={12}
                  color="#5F6368"
                >
                  +{tags?.length - 1}
                </Typography>
                <Box display="flex" alignItems="center" cursor="pointer">
                  <SimpleArrowDownIcon width="7px" heigh="3.6px" />
                </Box>
              </>
            ) : null}
          </Box>

          {tags?.length ? (
            <Chip
              sx={{
                fontSize: "14px",
                height: "24px",
                color: "#5F6368",
                maxWidth: "calc(100% - 38px)",
                backgroundColor: "#F8F8F8",
              }}
              label={tags[0]?.tag}
              variant="outlined"
            />
          ) : (
            <Typography color="#5F6368">-</Typography>
          )}
        </Box>
      ),
    }),
    [
      dueData,
      filteredOrderDeliveriesData,
      createdBy,
      getFulfillmentStatus,
      handleClickOnArrow,
      open,
      order,
      overdueFulfillment,
      overduePayment,
      tags,
      theme.palette.warning.main,
      timeZone,
    ]
  );

  const hasOrderColumn = columns?.findIndex((c) => c?.name === "Order") >= 0;

  const orderBoxWidth = hasOrderColumn ? ORDER_WIDTH : 0;

  const setColumnWidth = (col) => {
    if (col?.name === "Order") return `${ORDER_WIDTH}px`;

    if (col?.name === "Tags")
      return `calc(${columnWidth?.[col?.name]}% - ${orderBoxWidth}px)`;

    const columns = columnWidth?.[col?.name]
      ? `${columnWidth?.[col?.name]}%`
      : `${
          (100 - defaultColumnWidth) /
          direct_tab.filter((col) => col.selected).length
        }%`;

    return columns;
  };

  return (
    <Paper
      sx={{
        backgroundColor: isChecked ? "#ECF5F0" : hoverColor,
        borderBottom: showBorder && "1px solid #D5D9D9",
        position: "relative",
        opacity: order?.archived ? 0.5 : 1,
        display: "flex",
        flexDirection: "unset !important",
        flexWrap: "unset",
        alignItems: "center",
      }}
      elevation={0}
      square
      component={Grid}
      columns={isCustomerView ? 25 : 22}
      container
      height="48px"
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
      data-testid="order-row"
    >
      {/* checkbox */}
      {!short && !isCustomerView && (
        <Box
          ref={checkboxRef}
          sx={{
            width: `${CHECKBOX_WIDTH}px`,
            minWidth: `${CHECKBOX_WIDTH}px`,
            maxWidth: `${CHECKBOX_WIDTH}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pl: 0,
            "& .MuiCheckbox-root.Mui-checked": {
              width: 22,
              height: 22,
            },
          }}
        >
          <LargeCheckbox
            checked={isChecked}
            sx={{ padding: 0 }}
            formSx={{ m: 0 }}
            size={22}
            rectSize={22}
            onChange={() => handleCheckOrder(order.id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            checkedViewBox="0 0 24 24"
          />
        </Box>
      )}
      {!short && !!isCustomerView && (
        <Box
          ref={checkboxRef}
          sx={{
            width: "50px",
            minWidth: "50px",
            display: "flex",
            alignItems: "center",
            pl: "19px",
          }}
        >
          <LargeCheckbox
            checked={isChecked}
            sx={{ padding: 0 }}
            formSx={{ m: 0 }}
            size={20}
            onChange={() => handleCheckOrder(order.id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Box>
      )}

      {isPopup || isCustomerView ? (
        <>
          <Box
            sx={{
              ...cl.gridStart,
              pl: short && "15px",
              justifyContent: "space-between",
              width: "100px",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap="5px"
              sx={{
                cursor: handleClickOnCustomId ? "pointer" : "auto",
                width: `${ORDER_WIDTH}px`,
                height: "100%",
                textDecorationLine:
                  order?.orderStatus === "CANCELED" && "line-through",
                textDecorationColor: "#1C1C19",
              }}
              onClick={() => {
                if (handleClickOnCustomId) handleClickOnCustomId(order?.id);
              }}
            >
              <Typography sx={cl.orderText}>
                {order.customId?.customId}
              </Typography>

              {order?.archived && (
                <StyledTooltip
                  arrow
                  title="Archived"
                  placement="top"
                  PopperProps={{
                    modifiers: [
                      { name: "offset", options: { offset: [0, -24] } },
                    ],
                  }}
                >
                  <Box display="flex" alignItems="center" height="100%">
                    <InformationIcon size="16" stroke="#FC7063" />
                  </Box>
                </StyledTooltip>
              )}
            </Box>
            {order.note && order.note?.text && (
              <StyledTooltip
                title={order.note?.text}
                placement="top"
                arrow
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                PopperProps={{
                  modifiers: [{ name: "offset", options: { offset: [0, -4] } }],
                }}
              >
                <Box width="30px" display="flex" justifyContent="flex-start">
                  <PaperIcon color="#5F6267" size={17} />
                </Box>
              </StyledTooltip>
            )}
          </Box>

          <Grid
            xs
            item
            sx={cl.gridContainer}
            component={NavLink}
            to={`/orders/${order.id}`}
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) return;
              e.stopPropagation();
              e.preventDefault();
              handleOpenOrder(order.id);
            }}
          >
            {/* Date */}
            <Grid
              item
              xs={isCustomerView ? 4.16 : short ? 4 : 2.3}
              sx={{
                ...cl.grid,
                flexDirection: "column",
                alignItems: "flex-start",
                textDecorationLine:
                  order.orderStatus === "CANCELED" && "line-through",
                textDecorationColor: "#1C1C19",
              }}
            >
              <Typography sx={cl.typography}>
                {getCreatedAt(order.createdAt, timeZone)}
              </Typography>
            </Grid>
            {/* Customer */}
            {!short && !isCustomerView && (
              <Grid
                item
                xs={6.63}
                sx={{
                  ...cl.grid,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  overflow: "hidden",
                  paddingRight: "10px",
                  textDecorationLine:
                    order.orderStatus === "CANCELED" && "line-through",
                  textDecorationColor: "#1C1C19",
                }}
              >
                <Box display="flex">
                  <Box
                    sx={cl.orderName}
                    display="flex"
                    gap="3px"
                    alignItems="center"
                    flexWrap="nowrap"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleClickOnArrow(e, order);
                    }}
                  >
                    <Typography
                      ref={customerNameRef}
                      sx={{
                        ...cl.typography,
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      noWrap
                      color="#1C1C19"
                    >
                      {order?.customer?.displayName || order?.customer?.name}
                    </Typography>
                    <Box
                      display="flex"
                      sx={{
                        mb: "-2px",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <SimpleArrowDownIcon width="7px" heigh="3.6px" />
                    </Box>
                  </Box>
                </Box>

                <Typography
                  sx={{
                    ...cl.typography,
                    marginTop: "2px",
                    fontWeight: 300,
                  }}
                  noWrap
                >
                  {order.customer?.shippingAddress?.formatted_address}
                </Typography>
              </Grid>
            )}
            {/* Created by */}
            <Grid
              item
              xs={isCustomerView ? 3.85 : short ? 3.85 : 2.25}
              sx={cl.gridStart}
            >
              <Typography sx={cl.typography} noWrap>
                {createdBy}
              </Typography>
            </Grid>
            {/* Payment */}
            <Grid
              item
              xs={isCustomerView ? 5.85 : short ? 4.5 : 3}
              sx={cl.gridStart}
            >
              {isThirdParty ? (
                <StyledTooltip
                  isDark
                  title={
                    <Typography variant="subtitle2">3rd party order</Typography>
                  }
                  arrow
                  placement="top"
                >
                  <Box color="#1C1C19">-</Box>
                </StyledTooltip>
              ) : (
                <Box display="flex" alignItems="center" position="relative">
                  <DueTooltip
                    emailCount={order?.remindersSent}
                    dueData={dueData}
                    paymentTermsName={order.paymentTermsDuplicate?.name}
                    disableHoverListener={
                      order.orderStatus === "CANCELED" ||
                      order.paymentStatus === "PAID" ||
                      !order.paymentTermsDuplicate
                    }
                  >
                    <PaymentStatus
                      str={dueData?.overdue ? "OVERDUE" : order.paymentStatus}
                      overdue={dueData?.text}
                    />
                  </DueTooltip>
                </Box>
              )}
            </Grid>

            {/* Fulfillment */}
            <Grid
              item
              xs={isCustomerView ? 4 : short ? 3.39 : 2.7}
              sx={cl.gridStart}
            >
              {isThirdParty ? (
                <StyledTooltip
                  isDark
                  title={
                    <Typography
                      variant="subtitle2"
                      // color="primary"
                    >
                      3rd party order
                    </Typography>
                  }
                  arrow
                  placement="top"
                >
                  <Box color="#1C1C19">-</Box>
                </StyledTooltip>
              ) : (
                <Box display="flex" alignItems="center" position="relative">
                  <Box display="flex" alignItems="center" position="relative">
                    {overdueFulfillment >= 0 && (
                      <DueTooltip
                        dueData={{
                          titleTop:
                            overdueFulfillment === 0
                              ? "Due today"
                              : `Overdue ${overdueFulfillment} days`,
                          titleBottom: order?.fulfillBy
                            ? `Due: ${moment(
                                getFormattedDate(order.fulfillBy, timeZone)
                              )?.format("MM/DD/YY")}`
                            : "",
                          divider: true,
                        }}
                        disableHoverListener={
                          order?.deliveryStatus === "FULFILLED" ||
                          !order?.fulfillBy
                        }
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ width: "17px" }}
                        >
                          <InformationIcon
                            width="10px"
                            height="10px"
                            stroke={
                              overdueFulfillment === 0
                                ? theme.palette.warning.main
                                : "#FF6969"
                            }
                          />
                        </Box>
                      </DueTooltip>
                    )}
                    {overdueFulfillment >= 0 ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: "#EB4233",
                          }}
                        >
                          {overdueFulfillment === 0
                            ? "Due today"
                            : `Overdue ${overdueFulfillment} days`}
                        </Typography>
                      </>
                    ) : (
                      <Stack direction="row" alignItems="center">
                        <Box
                          sx={{
                            height: "6px",
                            width: "6px",
                            backgroundColor:
                              order.deliveryStatus === "UNFULFILLED"
                                ? "#F0C401"
                                : "#47A06D",
                            borderRadius: "50%",
                            mr: "8px",
                          }}
                        />
                        {order.deliveryStatus === "PARTIALLY_FULFILLED" && (
                          <>
                            <Box
                              sx={{
                                height: "6px",
                                width: "6px",
                                backgroundColor: "#b98900",
                                borderRadius: "50%",
                                position: "absolute",
                              }}
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                left: "1.5px",
                                height: "3px",
                                width: "3px",
                                backgroundImage:
                                  "linear-gradient(#ffd79c 50%, #b98900 50%)",
                                borderRadius: "50%",
                              }}
                            />
                          </>
                        )}
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 300,
                            color: "#1C1C19",
                          }}
                        >
                          {getFulfillmentStatus}
                        </Typography>
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {order.deliveryStatus === "PARTIALLY_FULFILLED" ||
                            (order.deliveryStatus === "FULFILLED" &&
                              !!filteredOrderDeliveriesData.length && (
                                <FulfillmentTooltip
                                  titleData={filteredOrderDeliveriesData}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Grid
                                    container
                                    justifyContent="center"
                                    width="22px"
                                    height="15px"
                                    ml="3px"
                                  >
                                    <ShippingCarIcon />
                                  </Grid>
                                </FulfillmentTooltip>
                              ))}
                        </Box>
                      </Stack>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>

            {/* Total */}
            <Grid item xs sx={cl.gridStart}>
              <Typography
                noWrap
                sx={{
                  ...cl.typography,
                  fontWeight: 600,
                  textDecorationLine:
                    order.orderStatus === "CANCELED" && "line-through",
                  textDecorationColor: "#1C1C19",
                }}
              >
                {truncateText(
                  `$ ${separateNumWithComma(order?.totalAmount?.toFixed(2))}`,
                  12
                )}
              </Typography>
            </Grid>

            {!short && (
              <Grid
                item
                xs
                sx={{ ...cl.gridStart, justifyContent: "flex-end", pr: "22px" }}
              >
                <StyledTooltip
                  isDark
                  title={
                    hasCustomerEmail ? (
                      <Typography>Email order</Typography>
                    ) : (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {!hasCustomerEmail && (
                          <Typography>
                            No email address found
                            <br /> for this customer
                          </Typography>
                        )}
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontWeight: 600,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            setOpenAddContactPopup &&
                              setOpenAddContactPopup(true);
                          }}
                        >
                          Add Customer Contact
                        </Typography>
                      </Box>
                    )
                  }
                  // disableHoverListener={hasCustomerEmail}
                  arrow
                  placement="right"
                >
                  <Box>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleOpenEmail(order);
                      }}
                      sx={{
                        backgroundColor: "#F8F8F8",
                        borderRadius: "4px",
                        border: "0.5px solid #D4D4D4",
                        height: "29px",
                        width: "29px",
                      }}
                      disabled={
                        isAdmin ||
                        !hasCustomerEmail ||
                        (!!repPermissions &&
                          !repPermissions?.orders?.create_edit)
                      }
                    >
                      <SendIcon
                        fill={!hasCustomerEmail ? "#B9B9B9" : "#5f6267"}
                      />
                    </IconButton>
                  </Box>
                </StyledTooltip>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: `calc(100% - ${checkboxWidth + ACTIONS_WIDTH}px)`,
            maxWidth: `calc(100% - ${checkboxWidth + ACTIONS_WIDTH}px)`,
            minWidth: `calc(100% - ${checkboxWidth + ACTIONS_WIDTH}px)`,
          }}
        >
          {columns.map((col) => {
            const valueOfWidth = setColumnWidth(col);
            return (
              <Box
                component={NavLink}
                to={`/orders/${order.id}`}
                onClick={(e) => {
                  if (e.metaKey || e.ctrlKey) return;
                  e.stopPropagation();
                  e.preventDefault();
                  handleOpenOrder(order.id);
                }}
                key={col.id}
                sx={{
                  width: valueOfWidth,
                  maxWidth: valueOfWidth,
                  minWidth: valueOfWidth,
                  textDecoration: "none",
                }}
              >
                {columnData?.[col?.name]}
              </Box>
            );
          })}
        </Box>
      )}

      {!!selectedOrder && (
        <CustomerMenu
          isOpen={!!anchorCustomerEl}
          customer={selectedOrder.customer}
          anchorEl={anchorCustomerEl}
          handleClose={handleCloseArrowBtn}
        />
      )}

      {!!anchorTagsEl && (
        <TagsMenu
          open={!!anchorTagsEl}
          tags={selectedTags}
          anchorEl={anchorTagsEl}
          handleClose={handleCloseArrowBtn}
        />
      )}

      {!isCustomerView && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: `${ACTIONS_WIDTH}px`,
            minWidth: `${ACTIONS_WIDTH}px`,
            maxWidth: `${ACTIONS_WIDTH}px`,
          }}
        >
          {!!order?.lastPdfsPrinted?.length && (
            <PrintedBlock lastPrinted={order?.lastPdfsPrinted} sx={{ mr: 1 }} />
          )}

          <MenuColumnOrder
            {...{
              isQuickBooksConnected,
              order,
              hasCustomerEmail,
              isAdmin,
              handleOpenEmail,
              handleChoseMenuItem,
              handleOpenOrder,
              repPermissions,
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

OrdersItem.propTypes = {
  order: object,
  lastItem: bool,
  isChecked: bool,
  handleCheckOrder: func,
  handleOpenOrder: func,
  timeZone: string,
  handleDownloadInvoice: func,
  handleChoseMenuItem: func,
  handleOpenEmail: func,
  handleConfirmCheckedItemsDialog: func,
  short: bool,
  showBorder: bool,
  isQuickBooksConnected: bool,
  currentTab: string,
  isPopup: bool,
  isCustomerView: bool,
  handleClickOnCustomId: func,
  setOpenAddContactPopup: func,
  repPermissions: object,
};

OrdersItem.defaultProps = {
  isChecked: false,
  order: null,
  short: false,
  showBorder: false,
  isQuickBooksConnected: false,
  isCustomerView: false,
};

export default OrdersItem;
