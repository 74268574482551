import { useCallback, useState, useEffect, useRef } from "react";
import { bool, object, func, string, number } from "prop-types";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField as MyTextField,
  Typography,
  IconButton,
  CircularProgress,
  Divider,
  Box,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import {
  getFormattedDate,
  useAdmin,
  photoUrl,
  stringAvatar,
  truncateText,
} from "../../../../../../helpers/helpers";
import {
  TagIcon,
  SmileIcon,
  CrossIcon,
  TrashIcon,
  PenIcon,
  GalleryLocationIcon,
  GalleryNextIcon,
  ShevronIcon,
} from "../../../../../../components/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  applyPhotoTagsAction,
  deleteGalleryCommentAction,
  editGalleryCommentAction,
  endLoadingGallery,
  groupIdsAction,
  postGalleryCommentAction,
  setPictureItemAction,
  startLoadingGallery,
} from "../../../../../../redux/actions/gallery";
import { createSelector } from "reselect";
import {
  currentGalleryItemSelector,
  pictureItemLoadingSelector,
  pictureItemSelector,
  galleryLoadingSelector,
  groupIdsSelector,
} from "../../../../../../redux/selectors/gallery";
import EmojiPicker from "../../../../../../components/EmojiPicker/EmojiPicker";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "../../../../../../redux/actions/confirmDialogs";
import { currentUserSelector } from "../../../../../../redux/selectors/auth";
import moment from "moment";
import Slider from "react-slick";
import StyledAvatar from "../../../../../../components/StyledAvatar/StyledAvatar";
import {
  AccordionComponent,
  DetailsComponent,
  SummaryComponent,
} from "../../../../../../components/AccordionComponent/AccordionComponent";
import ProductAccordionHeader from "../ProductAccordionHeader/ProductAccordionHeader";
import ProductAccordionItem from "../ProductAccordionItem/ProductAccordionItem";
import { getPhotoGroupByIdService } from "../../../../../../services/gallery";
import { error } from "../../../../../../utils/notifications";
import Loader from "../../../../../../components/Loader";
import ImageBox from "../../../../../../components/ImageComponent/ImageBox";
import StyledLabel from "../../../../../../components/StyledLabel/StyledLabel";
import { tagsLoadingSelector } from "../../../../../../redux/selectors/tags";
import { getProductTagsListAction } from "../../../../../../redux/actions/product-tags";
import ImageFullscreen from "../ImageFullscreen";
import { GalleryPagination, TagsDrawer } from "../../../../../../components";
import { TAG_TYPES } from "helpers/useTagsActions";
import { useRepsPermissions } from "helpers/hooks";

const selector = createSelector(
  currentGalleryItemSelector,
  pictureItemLoadingSelector,
  tagsLoadingSelector,
  currentUserSelector,
  pictureItemSelector,
  galleryLoadingSelector,
  groupIdsSelector,
  (
    pictureItem,
    pictureItemLoading,
    tagsLoading,
    currentUser,
    currentPictureId,
    galleryLoading,
    groupIds
  ) => ({
    pictureItem,
    pictureItemLoading,
    tagsLoading,
    currentUser,
    currentPictureId,
    galleryLoading,
    groupIds,
  })
);

const TextField = styled(MyTextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

const Tag = ({ name }) => {
  return (
    <Box
      sx={{
        border: "1px solid #D5D9D9",
        backgroundColor: "#FAFAFB",
        borderRadius: "12px",
        display: "inline-flex",
        alignItems: "center",
        paddingTop: "4px",
        paddingBottom: "2px",
        px: "10px",
        flex: "0 0 auto",
        maxWidth: "530px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "12px",
        }}
      >
        <TagIcon width={11.75} height={11.75} />
      </Box>
      <Typography
        sx={{
          marginLeft: "6px",
          fontSize: "12px",
          fontWeight: 400,
          color: "#5F6368",
          marginTop: "-2px",
        }}
        noWrap
      >
        {name}
      </Typography>
    </Box>
  );
};
Tag.propTypes = {
  name: string,
};
Tag.defaultProps = {
  name: "",
};

const useStyles = makeStyles(() => ({
  textInput: {
    fontSize: "12px",
    height: "36px",
  },
  inputLabel: {
    fontSize: "12px",
    color: "#B5B5AC",
  },
  postInput: {
    fontSize: "15px",
    fontWeight: 400,
  },
}));

const PhotoGroupPopup = ({
  user,
  isOpenProductDialog,
  handleCloseProductDialog,
  currentPage,
  galleryGroupItem,
  hidePagination,
  createCallback,
}) => {
  const isAdmin = useAdmin();
  const {
    pictureItem,
    pictureItemLoading,
    tagsLoading,
    currentUser,
    currentPictureId,
    galleryLoading,
    groupIds,
  } = useSelector(selector);

  const classes = useStyles();
  const dispatch = useDispatch();

  const repPermissions = useRepsPermissions();

  const sliderRef = useRef(null);
  const wrapperListBlock = useRef(null);

  const [message, setMessage] = useState("");
  const [editComment, setEditComment] = useState(null);
  const [emojiPickerAnchor, setEmojiPickerAnchor] = useState(null);
  const [isOpenEditTagsDialog, setIsOpenEditTagsDialog] = useState(false);
  const [currentPictureItem, setCurrentPictureItem] = useState(null);
  const [photosOpen, setPhotosOpen] = useState(false);
  const [loadingPhoto, setLoadingPhoto] = useState(false);

  useEffect(() => {
    if (galleryGroupItem) setCurrentPictureItem(galleryGroupItem);
  }, [galleryGroupItem]);

  useEffect(() => {
    if (!isOpenProductDialog) {
      dispatch(setPictureItemAction(null));
      dispatch(groupIdsAction([]));
    }
  }, [dispatch, isOpenProductDialog]);

  const fetchPhoto = useCallback(
    (id) => {
      dispatch(startLoadingGallery());
      getPhotoGroupByIdService(id || currentPictureId)
        .then((res) => {
          setCurrentPictureItem(res);
          sliderRef.current.slickGoTo("0");
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          error("Something went wrong!");
        })
        .finally(() => dispatch(endLoadingGallery()));
    },
    [currentPictureId, dispatch]
  );

  useEffect(() => {
    if (!isOpenProductDialog) return;
    setCurrentPictureItem(null);
    dispatch(getProductTagsListAction());

    if (pictureItem) return setCurrentPictureItem(pictureItem);
    if (currentPictureId) return fetchPhoto();
  }, [
    pictureItem,
    currentPictureId,
    dispatch,
    fetchPhoto,
    isOpenProductDialog,
  ]);

  const handleSetTags = useCallback(
    (tags) => {
      const tagIds = [];
      const newTags = [];

      tags.forEach((tag) => {
        return tag?.newTag
          ? newTags.push(tag?.tag)
          : tagIds.push(tag?.id || tag?.tag?.id);
      });

      dispatch(
        applyPhotoTagsAction(
          {
            photoGroupId: currentPictureItem?.id,
            tagIds,
            newTags,
          },
          galleryGroupItem && setCurrentPictureItem
        )
      );
      setIsOpenEditTagsDialog(false);
    },
    [dispatch, currentPictureItem?.id, galleryGroupItem]
  );

  const scrollToDown = () => {
    wrapperListBlock.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  useEffect(() => {
    if (wrapperListBlock.current) scrollToDown();
  }, [currentPictureItem?.comments]);

  const handlePost = useCallback(() => {
    if (!message) return;
    setMessage("");
    setEditComment(null);
    if (editComment) {
      dispatch(
        editGalleryCommentAction(
          editComment?.id,
          { text: message },
          galleryGroupItem && setCurrentPictureItem
        )
      );
      return;
    }

    dispatch(
      postGalleryCommentAction(
        currentPictureItem?.id,
        { text: message },
        (currentPictureId || galleryGroupItem) && setCurrentPictureItem,
        createCallback && createCallback
      )
    );
  }, [
    message,
    editComment,
    dispatch,
    currentPictureItem?.id,
    currentPictureId,
    galleryGroupItem,
    createCallback,
  ]);

  const handleDeleteComment = useCallback(
    (id) => {
      dispatch(
        deleteGalleryCommentAction(
          id,
          (currentPictureId || galleryGroupItem) && setCurrentPictureItem,
          createCallback && createCallback
        )
      );
    },
    [createCallback, currentPictureId, dispatch, galleryGroupItem]
  );

  const handleEditComment = (comment) => {
    setEditComment(comment);
    setMessage(comment?.text);
  };

  const handleCloseEditTagsDialog = () => {
    setIsOpenEditTagsDialog(false);
  };

  const handlePressEnter = (e) => {
    if (isAdmin) return;
    const val = e.target.value;
    if (e.keyCode === 13 && val !== "") {
      handlePost();
    }
  };

  const handleOpenConfirmDialog = useCallback(
    (confirmQuery) => {
      dispatch(openConfirmDialogAction(confirmQuery));
    },
    [dispatch]
  );

  const reorderLength = (item) => {
    return item?._count?.products || 0;
  };

  const handleGalleryPagination = useCallback(
    (index) => {
      const groupId = groupIds?.[index - 1];
      if (groupId) fetchPhoto(groupId);
    },
    [fetchPhoto, groupIds]
  );

  const showActionBtns = (comment) => {
    if (!repPermissions && comment?.distributor?.id === user?.id) return true;

    if (
      repPermissions &&
      comment?.representativeDuplicate?.representativeId ===
        repPermissions?.representativeId
    )
      return true;

    return false;
  };

  return (
    <Dialog
      maxWidth="xl"
      scroll="body"
      open={isOpenProductDialog}
      onClose={handleCloseProductDialog}
    >
      <Loader isLoading={galleryLoading} />
      <DialogContent
        sx={{
          width: {
            xs: "1200px",
            xl: "1330px",
          },
          p: 0,
          borderRadius: "4px",
        }}
      >
        <Grid
          container
          sx={{
            p: 0,
            overflow: "hidden",
            height: "700px",
          }}
        >
          <Grid
            sx={{
              p: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              position: "relative",
              height: "700px",
            }}
            item
            xs={5.5}
          >
            <Box
              width="100%"
              height="100%"
              sx={{
                "& .slick-dots": {
                  position: "absolute",
                  bottom: "0px",
                  "& li": {
                    m: 0,
                  },
                  "& .slick-active .MuiBox-root": {
                    backgroundColor: "#5F6267",
                  },
                },
                "& .slick-slider": {
                  height: "100%",
                  "& .slick-list": {
                    height: "100%",
                    "& div": {
                      height: "100%",
                    },
                  },
                },
                "& .slick-slide img": {
                  display: "unset",
                },
                "& .slick-arrow::before": {
                  content: '""',
                },
              }}
            >
              <ImageFullscreen
                images={currentPictureItem?.photos}
                open={photosOpen}
                onClose={() => setPhotosOpen(false)}
              />
              {!!currentPictureItem?.photoProductInfo?.length && (
                <Typography
                  sx={{
                    position: "absolute",
                    zIndex: "1000",
                    backgroundColor: "rgba(255, 255, 255, 0.88)",
                    p: "2px 10px 3px 7px",
                    borderRadius: "4px",
                    left: "62px",
                    top: "24px",
                    maxWidth: "84%",
                  }}
                  noWrap
                  fontSize="11px"
                >
                  {currentPictureItem?.photoProductInfo
                    ?.map((p) => p?.manufacturer?.name)
                    .join(", ")}
                </Typography>
              )}
              <Slider
                ref={sliderRef}
                infinite={false}
                speed={400}
                slidesToShow={1}
                slidesToScroll={1}
                dots
                customPaging={() => (
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      border: "1px solid #5F6267",
                    }}
                  />
                )}
                nextArrow={
                  <Box top="47%" right="26px" zIndex="10">
                    <GalleryNextIcon />
                  </Box>
                }
                prevArrow={
                  <Box
                    top="47%"
                    left="26px"
                    zIndex="10"
                    sx={{ transform: "scale(-1, -1) translateY(0%)" }}
                  >
                    <GalleryNextIcon />
                  </Box>
                }
              >
                {currentPictureItem?.photos?.map((photo) => (
                  <Box
                    key={photo?.id}
                    sx={{
                      display: "flex!important",
                      position: "relative",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setPhotosOpen(true)}
                  >
                    <ImageBox
                      sx={{
                        maxWidth: "500px",
                        maxHeight: "640px",
                        zIndex: 10,
                      }}
                      placeholderSrc={photoUrl(photo?.compressedFileName)}
                      src={photoUrl(photo?.fileName)}
                      setLoadingPhoto={setLoadingPhoto}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        position: "absolute",
                        filter: "blur(70px) brightness(112%)",
                      }}
                      component="img"
                      src={photoUrl(photo?.compressedFileName)}
                    />
                  </Box>
                ))}
              </Slider>
            </Box>
          </Grid>
          {!!currentPictureItem && (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                borderLeft: "1px solid #D5D9D9",
              }}
              item
              xs={6.5}
            >
              <Box
                sx={{
                  borderBottom: "1px solid #D5D9D9",
                  position: "relative",
                  height: "650px",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingLeft="32px"
                  sx={{
                    height: "69px",
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      color: "#707070",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    gap="26px"
                    alignItems="center"
                    maxWidth="70%"
                  >
                    <Box>
                      <GalleryLocationIcon width={20.22} height={24.94} />
                    </Box>
                    <Box maxWidth="100%">
                      <Typography fontWeight="600" noWrap>
                        {currentPictureItem?.customer?.displayName ||
                          currentPictureItem?.customer?.name}
                      </Typography>
                      <Typography fontWeight="400" noWrap>
                        {
                          currentPictureItem?.customer?.shippingAddress
                            ?.formatted_address
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      pr: 1,
                    }}
                  >
                    {!hidePagination && (
                      <GalleryPagination
                        currentPage={currentPage}
                        loading={loadingPhoto}
                        onGalleryPagination={handleGalleryPagination}
                      />
                    )}
                    <IconButton onClick={handleCloseProductDialog}>
                      <CrossIcon size={30} />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderTop: "1px solid #D5D9D9",
                    overflow: "overlay",
                    height: "89.5%",
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: "1px solid #D5D9D9",
                      backgroundColor: "#F9F9F9",
                    }}
                  >
                    <Box display="flex">
                      <Box
                        sx={{
                          padding: "16px 16px 16px 23px",
                        }}
                      >
                        {currentPictureItem?.representativeDuplicate
                          ?.profilePhoto ? (
                          <Avatar
                            src={photoUrl(
                              currentPictureItem?.representativeDuplicate
                                ?.profilePhoto?.fileName
                            )}
                            sx={{ width: 40, height: 40 }}
                          />
                        ) : (
                          <Avatar
                            {...stringAvatar(
                              currentPictureItem?.representativeDuplicate?.name,
                              {
                                width: 40,
                                height: 40,
                              },
                              currentPictureItem?.representativeDuplicate.role
                            )}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          padding: "16px 20px 16px 0",
                        }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs="auto"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 15,
                                fontWeight: 600,
                                maxWidth: { lg: "360px", xl: "430px" },
                              }}
                              noWrap
                            >
                              {
                                currentPictureItem?.representativeDuplicate
                                  ?.name
                              }
                            </Typography>
                            <Box sx={{ position: "relative" }}>
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                  height: "14px",
                                  mt: "2px",
                                  borderColor: "#5F6267",
                                }}
                              />
                              {!currentPictureItem?.representativeDuplicate
                                ?.representativeId && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "-18px",
                                    left: "-45px",
                                  }}
                                >
                                  <StyledLabel text="deleted" />
                                </Box>
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#A1A1A1",
                                p: "1px 0 0 10px",
                              }}
                            >
                              {currentPictureItem?.createdAt &&
                                moment(
                                  getFormattedDate(
                                    currentPictureItem?.createdAt,
                                    currentUser?.timeZone
                                  )
                                ).format("MMM D, YY [at] h:mm a")}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Box mt={1}>
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#707070",
                            }}
                          >
                            {currentPictureItem?.note?.text}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            marginY: "12px",
                            marginRight: "28px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: "10px",
                            maxHeight: "66px",
                            overflowY: "auto",
                          }}
                        >
                          {currentPictureItem?.tags?.map((item) => (
                            <Tag key={item?.tag?.id} name={item?.tag?.tag} />
                          ))}
                          <Button
                            disabled={isAdmin || tagsLoading}
                            onClick={() => setIsOpenEditTagsDialog(true)}
                            sx={{
                              ml: !currentPictureItem?.tags?.length && "-8px",
                            }}
                          >
                            <Typography sx={{ fontSize: "12px" }}>
                              {currentPictureItem?.tags?.length
                                ? "Edit"
                                : "Add"}{" "}
                              tags
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        pl: "23px",
                        pr: "28px",
                        width: "100%",
                      }}
                    >
                      {!!currentPictureItem?.photoProductInfo?.length &&
                        currentPictureItem?.photoProductInfo.map((p) => {
                          const calcReorderLength = reorderLength(p);

                          return (
                            <AccordionComponent
                              key={p?.id}
                              item={p}
                              expandedDflt={false}
                              sx={{ width: "100%" }}
                            >
                              <SummaryComponent
                                item={p}
                                sx={{
                                  height: "38px!important",
                                  minHeight: "38px",
                                  "& .MuiAccordionSummary-content": {
                                    alignItems: "center",
                                    width: "100%",
                                    maxWidth: "100%",
                                  },
                                }}
                              >
                                <Box
                                  display="inline-flex"
                                  gap="7px"
                                  sx={{
                                    width: "100%",
                                    maxWidth: "calc(100% - 20px)",
                                  }}
                                >
                                  <Typography
                                    color="#000000"
                                    fontSize="12px"
                                    fontWeight="600"
                                    noWrap
                                  >
                                    {p?.manufacturer?.name}
                                  </Typography>
                                  <Divider flexItem orientation="vertical" />
                                  <Typography
                                    color="#707070"
                                    fontSize="12px"
                                    // maxWidth="50%"
                                    // noWrap
                                    sx={{ whiteSpace: "nowrap" }}
                                  >
                                    {truncateText(
                                      p?.products?.[0]?.product?.name ||
                                        p?.products?.[0]?.product?.parentProduct
                                          ?.name
                                    )}{" "}
                                    ({p?.products?.length})
                                  </Typography>

                                  {!!calcReorderLength && (
                                    <>
                                      <Divider
                                        flexItem
                                        orientation="vertical"
                                      />
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          color: "#FF8000",
                                          minWidth: "fit-content",
                                          width: "fit-content",
                                          marginLeft: "auto",
                                        }}
                                        noWrap
                                      >
                                        {calcReorderLength} requires reorder
                                      </Typography>
                                    </>
                                  )}
                                </Box>
                                <Box ml="auto">
                                  <ShevronIcon
                                    className="my-ico"
                                    width={11.75}
                                    height={8.97}
                                    color="#707070"
                                  />
                                </Box>
                              </SummaryComponent>
                              <DetailsComponent>
                                <ProductAccordionHeader total={p?.total} />
                                {p?.products?.map((product) => (
                                  <ProductAccordionItem
                                    key={
                                      product?.productId || product.product?.id
                                    }
                                    product={product}
                                    onPromotion={p?.onPromotion}
                                    marketingMaterials={p?.marketingMaterials}
                                    total={p?.total}
                                  />
                                ))}
                              </DetailsComponent>
                            </AccordionComponent>
                          );
                        })}
                    </Box>
                  </Box>
                  <Box py="10px" ref={wrapperListBlock}>
                    {currentPictureItem?.comments?.map((comment) => (
                      <Box
                        key={comment?.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          p: "18px 24px 2px 23px",
                        }}
                      >
                        <Box>
                          <StyledAvatar
                            src={photoUrl(
                              comment?.distributor?.profilePhoto?.fileName ||
                                comment?.representativeDuplicate?.profilePhoto
                                  ?.fileName
                            )}
                            name={comment?.representativeDuplicate?.name || ""}
                            sx={{ width: 40, height: 40 }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "100%",
                            maxWidth: "calc(100% - 163px)",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <Typography fontSize={14} color="#000000" noWrap>
                              {comment?.distributor?.name ||
                                comment?.representativeDuplicate?.name}
                            </Typography>
                            <span style={{ margin: "0 6px" }}>|</span>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#A1A1A1",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {moment(
                                getFormattedDate(
                                  comment?.createdAt,
                                  currentUser?.timeZone
                                )
                              ).format("MMMM D, YYYY [at] h:mm a")}
                            </span>
                          </Box>

                          <Box>
                            <Typography fontSize={14} color="#707070">
                              {comment?.text}
                            </Typography>
                          </Box>
                        </Box>

                        {showActionBtns(comment) ? (
                          <>
                            <IconButton
                              disabled={isAdmin}
                              onClick={() => handleEditComment(comment)}
                            >
                              <PenIcon />
                            </IconButton>
                            <IconButton
                              disabled={isAdmin}
                              onClick={() =>
                                handleOpenConfirmDialog({
                                  title: "Delete comment?",
                                  text: "Are you sure you want to delete this comment?",
                                  buttons: (
                                    <>
                                      <Button
                                        sx={{
                                          width: "98px",
                                          color: "#6A6A6A",
                                          borderColor: "#D4D4D4",
                                          fontSize: "13px",
                                          height: "28px",
                                        }}
                                        onClick={() => {
                                          dispatch(
                                            setConfirmIsOpenAction(false)
                                          );
                                        }}
                                        variant="outlined"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        sx={{
                                          width: "98px",
                                          color: "#FFFFFF",
                                          fontSize: "13px",
                                          height: "28px",
                                          boxShadow: "none",
                                        }}
                                        color="confirmDelete"
                                        onClick={() => {
                                          dispatch(
                                            setConfirmIsOpenAction(false)
                                          );
                                          handleDeleteComment(comment?.id);
                                        }}
                                        variant="contained"
                                      >
                                        Confirm
                                      </Button>
                                    </>
                                  ),
                                })
                              }
                              sx={{ mr: "12px" }}
                            >
                              <TrashIcon />
                            </IconButton>
                          </>
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  height: "50px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <EmojiPicker
                    anchorEl={emojiPickerAnchor}
                    handleClose={() => setEmojiPickerAnchor(null)}
                    onEmojiClick={(e, em) => {
                      setMessage(`${message}${em.emoji}`);
                    }}
                  />
                  <IconButton
                    sx={{ marginLeft: "6px" }}
                    onClick={(e) => setEmojiPickerAnchor(e.target)}
                  >
                    <SmileIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Add a comment"
                    InputProps={{
                      className: classes.postInput,
                      endAdornment: pictureItemLoading && (
                        <CircularProgress size="25px" />
                      ),
                    }}
                    value={message}
                    onKeyDown={handlePressEnter}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Box>
                <Box>
                  {editComment && (
                    <IconButton
                      sx={{ p: "3px" }}
                      onClick={() => {
                        setMessage("");
                        setEditComment(null);
                      }}
                    >
                      <CrossIcon />
                    </IconButton>
                  )}
                  <Button
                    disabled={isAdmin}
                    sx={{ height: "100%" }}
                    onClick={() => {
                      if (isAdmin) return;
                      handlePost();
                    }}
                  >
                    <Typography sx={{ fontSize: "15px" }}>
                      {editComment ? "Save" : "Post"}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <TagsDrawer
        type={TAG_TYPES.product}
        open={isOpenEditTagsDialog}
        styles={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        handleClose={handleCloseEditTagsDialog}
        productTags={
          currentPictureItem?.tags
            ? currentPictureItem?.tags?.map((item) => item?.tag || item)
            : []
        }
        handleSave={({ tags }) => {
          handleSetTags(tags);
        }}
        setCheckedCustomers={() => {}}
      />
    </Dialog>
  );
};

PhotoGroupPopup.propTypes = {
  user: object,
  isOpenProductDialog: bool,
  handleCloseProductDialog: func,
  createCallback: func,
  currentPage: number,
  galleryGroupItem: object,
  hidePagination: bool,
};

PhotoGroupPopup.defaultProps = {
  user: {
    avatar: "",
    name: "No name",
    id: null,
  },
  hidePagination: false,
};

export default PhotoGroupPopup;
