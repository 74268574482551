/*global google*/
import { useCallback, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import { array, func } from "prop-types";
import {
  AccordionComponent,
  DetailsComponent,
  SummaryComponent,
} from "../../../../components/AccordionComponent/AccordionComponent";
import { ShevronIcon } from "../../../../components/Icons";
import { StopItem } from "../../../CreateRoutePage/components";
import useStyles from "./styles";
import { useEffect } from "react";
import { getRouteProgressByIdService } from "../../../../services/routes";
import { useSelector } from "react-redux";
import { getLegs } from "../../ViewRoutePage.helpers";
import { getFileFromBucket } from "../../../../services/files";

const AssignedRepsAccordions = ({
  reps,
  stops,
  setRouteState,
  handleClickCheckIn,
}) => {
  const classes = useStyles();
  const [expandedRep, setExpandedRep] = useState(null);
  const [progressState, setProgressState] = useState({
    loading: false,
    start: null,
    stops: [],
    end: null,
    completed: false,
  });

  const { timeZone } = useSelector(({ auth }) => auth.currentUser);

  const handleExpand = (rep) => {
    if (rep.id === expandedRep?.id) return setExpandedRep(null);

    setExpandedRep(rep);
  };

  const fetchRoutingPolyline = (routing, customers, stops, status) => {
    const legsObj = getLegs(routing);

    getFileFromBucket(legsObj.legs).then((legs) => {
      const routePolylines = [];
      legs.forEach((leg, index) => {
        const completed = customers[index]?.representativeStop;
        const routePolyline = new google.maps.Polyline({
          map: null,
          path: leg.steps,
          options: {
            clickable: false,
            strokeColor:
              completed || status === "COMPLETED" ? "#47A06D" : "#5F6267",
            strokeWeight: 2,
            strokeOpacity: 1,
          },
        });

        routePolylines.push(routePolyline);
      });

      setRouteState((prev) => ({
        ...prev,
        polylines: routePolylines,
        stops,
        hasRoute: true,
      }));
    });
  };

  const fetchProgress = useCallback(() => {
    if (!expandedRep) {
      return setRouteState((prev) => ({ ...prev, stops: [], hasRoute: false }));
    }
    const lastProgress = expandedRep?.routeProgresses?.[0];
    if (!lastProgress) {
      setRouteState((prev) => ({ ...prev, stops, hasRoute: false }));
      return setProgressState((prev) => ({
        ...prev,
        stops,
        start: null,
        end: null,
      }));
    }
    setProgressState((prev) => ({
      ...prev,
      loading: true,
      stops: [],
      start: null,
      end: null,
    }));
    getRouteProgressByIdService(lastProgress.id)
      .then((res) => {
        const pendingRecalculation = res.customers.length !== stops.length;
        if (pendingRecalculation) {
          setRouteState((prev) => ({ ...prev, pendingRecalculation: true }));
        } else {
          setRouteState((prev) => ({ ...prev, pendingRecalculation: false }));
        }
        const customers = pendingRecalculation
          ? [...stops]
          : res?.customers.map(
              ({
                customer,
                representativeStop,
                representativeStoppedAt,
                visitTimeInSeconds,
                checkedInAt,
                checkedOutAt,
                locationType,
              }) => ({
                ...customer,
                representativeStop,
                representativeStoppedAt,
                started: true,
                visitTimeInSeconds,
                checkedInAt,
                checkedOutAt,
                locationType,
                representativeDuplicate: expandedRep,
                checkIn: res?.checkIns?.length
                  ? res?.checkIns.find((c) => c?.customerId === customer?.id) ||
                    null
                  : null,
              })
            );

        fetchRoutingPolyline(
          res.routing,
          customers,
          [
            {
              ...res.startLocation,
              id: "start-location",
              name: "Start location",
              startLoc: true,
            },
            ...customers,
            {
              ...res.endLocation,
              name: "End location",
              endLoc: true,
              ...(customers?.[customers.length - 1]?.shippingAddress
                ?.formatted_address === res?.endLocation?.formatted_address
                ? { ...customers?.[customers.length - 1] }
                : {}),
            },
          ],
          res.status
        );

        setProgressState((prev) => ({
          ...prev,
          loading: false,
          stops: customers,
          start: res.startLocation,
          end: res.endLocation,
          completed: res.status === "COMPLETED",
        }));
      })
      .catch(() => {
        setProgressState((prev) => ({ ...prev, loading: false }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRep]);

  useEffect(() => {
    fetchProgress();
  }, [fetchProgress]);

  const expandDefaultRep = useCallback(() => {
    if (reps.length === 1) setExpandedRep(reps[0].representative);
  }, [reps]);

  useEffect(() => {
    expandDefaultRep();
  }, [expandDefaultRep]);

  const getRouteProgress = (rep) => {
    const progress = rep?.routeProgresses?.[0];
    if (!progress) return "not started";
    return `${progress?._count?.stops} of ${stops?.length} stops`;
  };

  const setMiles = (routing) => {
    const miles = getLegs(routing)?.distance?.toFixed(2);

    let count = "";

    if (miles) {
      const lastTwo = miles.split(".").pop();
      if (lastTwo === "00") {
        count = parseFloat(miles);
      } else {
        count = miles;
      }
    } else {
      count = 0;
    }

    return `${count} miles`;
  };

  return (
    <Box className={classes.accordionsWrapper}>
      {reps.map(({ representative: rep }) => {
        const { routing } = rep?.routeProgresses?.[0] || {};
        return (
          <AccordionComponent
            item={rep}
            key={rep?.id}
            className={classes.accordionItem}
            expandedDflt={false}
            onChange={() => handleExpand(rep)}
            expanded={rep?.id === expandedRep?.id}
          >
            <SummaryComponent className={classes.accordionSummary}>
              <Box
                display="flex"
                alignItems="center"
                gap="14px"
                sx={{ width: "44%", maxWidth: "44%" }}
              >
                <Box width="12px" display="flex" alignItems="center">
                  <ShevronIcon width={11.5} height={6.4} color="#6A6A6A" />
                </Box>
                <Typography color="#6A6A6A" noWrap>
                  {rep?.name}
                </Typography>
              </Box>

              <Box
                display="flex"
                gap="7px"
                alignItems="center"
                sx={{ width: "56%", maxWidth: "56%" }}
              >
                <LinearProgress
                  variant="determinate"
                  value={
                    (rep?.routeProgresses?.[0]?._count?.stops / stops?.length) *
                      100 || 0
                  }
                  className={classes.routeProgress}
                />
                <Typography color="#9C9C94" noWrap>
                  {getRouteProgress(rep)}
                </Typography>
                <Divider orientation="vertical" sx={{ height: "10px" }} />
                <Typography color="#9C9C94" noWrap>
                  {setMiles(routing)}
                </Typography>
              </Box>
            </SummaryComponent>
            <DetailsComponent>
              {progressState.loading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="40px"
                >
                  <CircularProgress size={20} />
                </Box>
              )}
              {progressState.start && (
                <StopItem index="start" stop={progressState.start} completed />
              )}
              {progressState.stops.map((stop, index) => (
                <StopItem
                  key={stop.id}
                  completed={stop.representativeStop}
                  completedDate={stop.representativeStoppedAt}
                  {...{ stop, index, timeZone, handleClickCheckIn }}
                />
              ))}
              {progressState.end && (
                <StopItem
                  index="end"
                  stop={progressState.end}
                  completed={progressState.completed}
                />
              )}
            </DetailsComponent>
          </AccordionComponent>
        );
      })}
    </Box>
  );
};

AssignedRepsAccordions.propTypes = {
  stops: array,
  reps: array,
  setRouteState: func,
  handleClickCheckIn: func,
};

AssignedRepsAccordions.defaultProps = {
  stops: [],
  reps: [],
};

export default AssignedRepsAccordions;
