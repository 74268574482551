import { bool, shape, number, func } from "prop-types";
import { Box, Grid, Paper } from "@mui/material";
import {
  SelectDatePicker,
  TotalSummaryInfoBlock,
} from "../../../../../../components";
import { separateNumWithComma } from "../../../../../../helpers/helpers";
import { useState } from "react";

export const ThirdPartyOrdersTotalSummaries = ({
  isLoading,
  totalSummaries,
  handleChoosePeriod,
}) => {
  const [date, setDate] = useState("This week");

  const handleDateInput = (newDates) => {
    if (newDates) {
      setDate(newDates);
      handleChoosePeriod(newDates);
    }
  };
  return (
    <Paper
      sx={{
        mt: "4px",
        px: 1.75,
        height: "64px",
        maxHeight: "64px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      component={Grid}
      container
      elevation={0}
      variant="outlined"
    >
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        xs={7}
        item
        data-testid="3rd-summaries-block"
      >
        <Box minWidth="fit-content">
          <SelectDatePicker
            adminIsAllowed
            value={date || "Today"}
            noSquare
            height="35px"
            fontSize="15px"
            fullWidth
            sx={{
              "& > fieldset": {
                borderRadius: "4px",
              },
            }}
            textfieldInputSx={{
              borderRadius: "4px",
              mr: "10px",
            }}
            handleDateInput={handleDateInput}
            label=""
            handleClearValue={() => setDate("")}
            onChange={(e) => handleDateInput(e.target.value)}
            data-testid="3rd-orders-summaries-filter-select"
          />
        </Box>

        <TotalSummaryInfoBlock
          isLoading={isLoading}
          items={[
            {
              name: "Total Sales",
              value: totalSummaries?.totalSales
                ? `$ ${separateNumWithComma(totalSummaries?.totalSales)}`
                : 0,
            },
            { name: "Orders", value: totalSummaries?.totalOrders ?? 0 },
            {
              name: "Ordered Items",
              value: totalSummaries?.totalOrderedItems ?? 0,
            },
          ]}
        />
      </Grid>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TotalSummaryInfoBlock
          isLoading={isLoading}
          items={[
            {
              name: "Total Sales",
              value: totalSummaries?.totalSales
                ? `$ ${separateNumWithComma(totalSummaries?.totalSales)}`
                : 0,
            },
            { name: "Orders", value: totalSummaries?.totalOrders ?? 0 },
            {
              name: "Items Ordered",
              value: totalSummaries?.totalOrderedItems ?? 0,
            },
          ]}
        />
      </Box> */}
      <Box />
    </Paper>
  );
};

ThirdPartyOrdersTotalSummaries.propTypes = {
  isLoading: bool,
  totalSummaries: shape({
    totalSales: number,
    totalOrders: number,
    totalOrderedItems: number,
  }),
  handleChoosePeriod: func,
};
