import { DataGrid, GridCellModes } from "@mui/x-data-grid";
import { array, bool, number, func, object } from "prop-types";
import useStyles from "./styles";
import { IMPORT_LIMIT } from "../../ImportTab.constants";
import { useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";

const RESERVED_ROW_KEYS = ["id", "lower_case", "validation", "error"];

const StyledDataGrid = ({
  rows,
  columns,
  editable,
  rowCount,
  loading,
  onPageChange,
  page,
  showErrors,
  onSaveLocalChanges,
}) => {
  const [cellModesModel, setCellModesModel] = useState({});

  const [paginationPosition, setPaginationPosition] = useState({
    x: 0,
    y: 0,
  });

  const [gridElement, setGridElement] = useState(null);
  const classes = useStyles({
    editable,
    paginationPosition,
    columnsCount: columns.length,
  });

  useEffect(() => {
    if (!gridElement) return;
    const updatePaginationPosition = () => {
      const table = gridElement.getBoundingClientRect();
      const scrollOffset = gridElement.parentNode?.scrollLeft;
      const { x, y, width, height } = table;
      setPaginationPosition({
        x: x + width + scrollOffset,
        y: y + height,
      });
    };

    updatePaginationPosition();

    window.addEventListener("resize", updatePaginationPosition);
    window.addEventListener("scroll", updatePaginationPosition);

    return () => {
      window.removeEventListener("resize", updatePaginationPosition);
      window.removeEventListener("scroll", updatePaginationPosition);
    };
  }, [gridElement, setPaginationPosition]);

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleProcessRowUpdate = useCallback(
    (newRow, oldRow) => {
      if (!isEqual(newRow, oldRow)) {
        const filterReservedKeys = (key) => !RESERVED_ROW_KEYS.includes(key);
        const rowKeys = Object.keys(newRow).filter(filterReservedKeys);
        const modifiedKey = rowKeys.find((key) => newRow[key] !== oldRow[key]);
        if (onSaveLocalChanges) {
          onSaveLocalChanges({ newRow, modifiedKey });
        }
        return {
          ...newRow,
          [modifiedKey]: newRow[modifiedKey]?.trim?.() || "",
        };
      }
      return oldRow;
    },
    [onSaveLocalChanges]
  );

  return (
    <DataGrid
      ref={(node) => setGridElement(node)}
      autoHeight
      rows={rows}
      loading={loading}
      rowCount={rowCount}
      disableVirtualization
      columns={columns}
      pageSize={IMPORT_LIMIT}
      rowsPerPageOptions={[IMPORT_LIMIT]}
      disableSelectionOnClick
      page={page || 0}
      onPageChange={(newPage) => onPageChange(newPage)}
      disableColumnSelector
      disableColumnFilter
      paginationMode="server"
      density="compact"
      onCellClick={handleCellClick}
      onCellModesModelChange={handleCellModesModelChange}
      cellModesModel={cellModesModel}
      processRowUpdate={handleProcessRowUpdate}
      experimentalFeatures={{ newEditingApi: true }}
      getRowClassName={({ row }) => !!row.error && showErrors && "error"}
      getCellClassName={({ row, field }) => {
        const errorCell = Object.keys(row?.validation || {}).some(
          (key) => key === field
        );

        if (field === "DELETE") return "delete";

        return showErrors && errorCell ? "error" : "";
      }}
      classes={{
        columnHeader: classes.columnHeader,
        panelHeader: classes.panelHeader,
        columnHeaders: classes.columnHeaders,
        columnHeaderTitleContainerContent:
          classes.columnHeaderTitleContainerContent,

        columnHeaderTitle: classes.columnHeaderTitle,
        cell: classes.gridCell,
        columnSeparator: classes.columnSeparator,
        virtualScroller: classes.virtualScroller,
        row: classes.gridRow,
        root: classes.gridRoot,
        main: classes.gridMain,
        editInputCell: classes.cellInput,
        "cell--editing": classes.editingCell,
        autoHeight: classes.autoHeight,
        footerContainer: classes.footerContainer,
      }}
      sx={{
        "& .MuiDataGrid-cell": {
          padding: "0 !important",
        },
        "& p": {
          overflow: "hidden !important",
          textOverflow: "ellipsis !important",
          whiteSpace: "nowrap !important",
        },
        "& .MuiDataGrid-columnHeader": {
          maxWidth: "200px !important",
          minWidth: "200px !important",
          width: "200px !important",
          whiteSpace: "nowrap !important",
        },
      }}
    />
  );
};

StyledDataGrid.propTypes = {
  rows: array,
  columns: array,
  editable: bool,
  rowCount: number,
  loading: bool,
  onPageChange: func,
  paginationPosition: object,
  page: number,
  showErrors: bool,
  onSaveLocalChanges: func,
};

StyledDataGrid.defaultProps = {
  rows: array,
  columns: array,
  editable: false,
  rowCount: 0,
  loading: false,
  onPageChange: () => {},
  page: 0,
  showErrors: false,
  onSaveLocalChanges: () => {},
};

export default StyledDataGrid;
