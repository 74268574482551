import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { currentUserSelector } from "redux/selectors/auth";
import {
  getCustomerByIdService,
  sendEmailToCustomerService,
  updateCustomerService,
} from "services/customers";
import {
  bulkAssignOrderTagsService,
  bulkPackingSlipOrderReportService,
  getOrdersBulkPackingSlipPDF,
  getOrdersBulkReportPDF,
  getOrdersService,
  updateOrderService,
} from "services/orders";
import {
  deleteCustomerCardService,
  getCustomerCardsService,
} from "services/stripe";
import { error, success } from "utils/notifications";
import { CustomerProfileContext } from "./CustomerProfilePage";
import { getTagsAction } from "redux/actions/tags";
import { revokeInviteService, sendInviteService } from "services/order-directs";
import {
  orderDirectCheckEmail,
  updateDistributorSettingsService,
} from "services/account";
import { getInvitesListAction } from "redux/actions/order-directs";
import {
  CUSTOMER_STATUS_FILTERS,
  SCROLL_LIMIT,
  SCROLL_LIMIT_INVITES,
} from "utils/constants";
import { openFilePreview } from "Pages/OrdersPage/Orders.helper";
import {
  FILTERS_MAP,
  FILTER_TABS_DATA,
  NOTE_FILTERS,
  NOTE_SWITCHES,
  ORDER_FILTERS,
  ORDER_SWITCHES,
  TASK_FILTERS,
  TASK_SWITCHES,
  defaultTableData,
  defaultTableQuery,
  TABLE_TABS,
  ORDER_ITEM_WIDTH,
} from "./CustomerProfile.constants";
import { FILTER_DATE_VALUES } from "Pages/OrdersPage/Orders.constants";
import { getRepByIdService, getRepsService } from "services/reps";
import { getCustomerNotesService } from "services/notes";
import {
  LargeCheckbox,
  StyledButton,
  TableHeaderControlPanel,
  TagField,
} from "components";
import { ActivityItem, NoteItem, OrdersItem, TaskItem } from "./components";
import { isEqual, snakeCase, sortBy } from "lodash";
import { getActivitiesService } from "services/dashboard";
import { getTasksService } from "services/tasks";
import { groupIdsAction } from "redux/actions/gallery";
import { getPhotoGroupByIdService } from "services/gallery";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { setCardName, useAdmin } from "helpers/helpers";
import {
  formatContactsToCards,
  formatCreditCardsToInfoCards,
  formatRepsToCards,
} from "./CustomerProfile.helpers";
import { Box, Button, Typography } from "@mui/material";
import { LicensesBlock } from "../CustomerPage/components/CustomerMainBody/components";
import CustomerReceivedHoursCard from "./components/CustomerProfile/components/CustomerCardInfo/components/CustomerReceivedHoursCard";
import { setCurrentUser } from "redux/actions/auth";
import { getSubCustomersByParentCustomerIdService } from "services/parentCustomers";
import { reportsList } from "Pages/ReportsPage/ReportsPage.constants";
import { DangerIcon, PenIcon, TrashIcon } from "components/Icons";
import { ArchiveIconCustomer, ReportIcon } from "components/Icons/MenuIcons";
import ConfirmNewInstanceText from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/TabSortedComponent/ConfirmNewInstanceText";
import {
  archiveBulkAction,
  deleteCustomerAction,
} from "redux/actions/customers";
import { useRepsPermissions } from "helpers/hooks";
import { debounce } from "lodash";
import { CustomerProfileActions } from "./CustomerProfileActions.hook";
import { missingInfoRedirect } from "Pages/CustomersPage/components/CustomersTab/helpers";
import { updateDraftOrderService } from "services/draft_orders";

export const useCustomerProfile = ({
  handleOpenEmail,
  handleChoseMenuItem,
  isLoading,
  setConfirmDeliveryState,
  handleCloseConfirmDeliveryDialog,
  setIsConfirmDeliveryPopupOpen,
  setType,
  assignTagsState,
  setAssignTagsState,
  handleCloseAssignTagsDialog,
}) => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const currentUser = useSelector(currentUserSelector);
  const { id: distributorId } = currentUser || {};
  const [contactsOpen, setContactsOpen] = useState(false);
  const [emptyCartPopupOpen, setEmptyCartPopupOpen] = useState(false);

  const drafts = useSelector(({ drafts }) => drafts.cart);

  const initCustomerState = {
    customer: null,
    loading: true,
    tableData: defaultTableData,
    tableTab: { value: 0, label: "Orders" },
  };

  const [customerState, setCustomerState] = useState(initCustomerState);

  const isParent =
    !customerState?.customer?.parentCustomer?.name ||
    customerState?.customer?.parentCustomer?.isUncategorized;

  const [mapData, setMapData] = useState(null);

  const [noteState, setNoteState] = useState({ open: false, note: null });
  const [taskState, setTaskState] = useState({ open: false, task: null });

  const [tableQuery, setTableQuery] = useState(defaultTableQuery);

  const [filterTab, setFilterTab] = useState(FILTER_TABS_DATA[6]);

  const updateCustomerState = useCallback((newValues) => {
    setCustomerState((prev) => ({ ...prev, ...newValues }));
  }, []);

  const resetCustomerState = () => {
    setCustomerState(initCustomerState);
  };

  const handleChangeTab = useCallback((tableTab) => {
    setCustomerState((prev) => ({
      ...prev,
      tableTab,
      tableData: {
        ...defaultTableData,
        loading: { ...prev.tableData.loading, [tableTab.value]: true },
        forceFetch: { ...defaultTableData.forceFetch, [tableTab.value]: true },
      },
    }));

    const isSameInitFilter = tableTab.value === customerState.tableTab.value;

    setTableQuery((prev) => ({
      ...prev,
      page: 1,
      filters: isSameInitFilter
        ? tableQuery.filters
        : FILTERS_MAP[tableTab.value],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNote = useCallback(() => {
    setNoteState((prev) => ({ ...prev, open: false, note: null }));
  }, []);

  const handleCloseTask = useCallback(() => {
    setTaskState((prev) => ({ ...prev, open: false, task: null }));
  }, []);

  const handleOpenNote = useCallback((note = null) => {
    setNoteState((prev) => ({ ...prev, open: true, note }));
  }, []);

  const [globalNoteState, setGlobalNoteState] = useState({
    open: false,
    data: null,
  });

  const handleOpenGlobalNote = useCallback((data) => {
    setGlobalNoteState({
      open: true,
      data,
    });
  }, []);
  const handleCloseCustomerNotes = useCallback(() => {
    setGlobalNoteState({
      open: false,
      data: null,
    });
  }, []);

  const handleOpenTask = useCallback((task = null) => {
    setTaskState((prev) => ({ ...prev, open: true, task }));
  }, []);

  //const hasDraftCustomer = useMemo(
  //  () =>
  //    drafts?.data?.customer ||
  //    (drafts?.data?.productsData !== "" && drafts?.data?.customer) ||
  //    (drafts?.data?.productsData !== null && drafts?.data?.customer) ||
  //    drafts?.data?.productsData !== undefined,
  //  [drafts?.data?.customer, drafts?.data?.productsData]
  //);

  const hasDraftCustomer = useMemo(
    () =>
      !!drafts?.data?.productsData &&
      !!drafts?.data?.productsData?.length &&
      !!drafts?.data?.customer,
    [drafts?.data?.customer, drafts?.data?.productsData]
  );

  const navigateToOrderCreate = useCallback(() => {
    navigate("/cart", { state: { customerState: customerState.customer } });
  }, [customerState.customer, navigate]);

  const handleOpenOrder = useCallback(() => {
    if (repPermissions && !repPermissions?.orders?.create_edit) return;

    if (!hasDraftCustomer) {
      navigateToOrderCreate();
    } else {
      setEmptyCartPopupOpen(true);
    }
  }, [hasDraftCustomer, navigateToOrderCreate, repPermissions]);

  const handleDataSearch = useCallback(({ search, filterFields }) => {
    setTableQuery((prev) => ({
      ...prev,
      filters: filterFields ? { ...filterFields } : prev.filters,
      search,
      page: 1,
    }));
  }, []);

  const handleFetchCustomer = useCallback(async () => {
    updateCustomerState({ loading: true });
    try {
      const customer = await getCustomerByIdService(customerId);
      updateCustomerState({ loading: false, customer });
    } catch (err) {
      error(err?.response?.data?.message);
      updateCustomerState({ loading: false });
    }
  }, [customerId, updateCustomerState]);

  const setPaymentStatus = useCallback((paymentStatus) => {
    if (!paymentStatus) return [];
    return paymentStatus.map((el) => {
      return el === "UNPAID" ? "PENDING" : el;
    });
  }, []);

  const handleFetchItems = useCallback(
    async ({ query, requestService }) => {
      try {
        setCustomerState((prev) => ({
          ...prev,
          tableData: {
            ...prev.tableData,
            loading: {
              ...prev.tableData.loading,
              [customerState.tableTab.value]: true,
            },
          },
        }));

        const res = await requestService({ ...query });
        // had to use tableQuery.page instead of prev.page, because on 90% browser scale page would increment twice
        setTableQuery((prev) => ({
          ...prev,
          page: (query.page || tableQuery.page) + 1,
        }));

        return res;
      } catch (err) {
        error(err?.response?.data?.message);
        setCustomerState((prev) => ({
          ...prev,
          tableData: {
            ...prev.tableData,
            loading: {
              ...prev.tableData.loading,
              [customerState.tableTab.value]: false,
            },
          },
        }));
      }
    },
    [customerState.tableTab.value, tableQuery.page]
  );

  const handleFetchOrders = useCallback(
    async (resetChecked = false, fetchAll = false) => {
      const { filters, page, limit, ...q } = { ...tableQuery };
      const {
        sort_by,
        order_status,
        payment_status,
        delivery_status,
        archived,
        fulfill_by,
        payment_due,
        delivery_method,
        ...query
      } = { ...filters };

      if (query.sort_by_order_date) delete query.sort_by_shipping_date;
      if (query.sort_by_shipping_date) delete query.sort_by_order_date;
      const sortBy = `sort_by_${sort_by}`;
      const paymentStatus = payment_status
        ? {
            payment_status: JSON.stringify(setPaymentStatus(payment_status)),
          }
        : {};

      const getBody = {
        ...q,
        ...(!fetchAll && { page: resetChecked ? 1 : page, limit }),
        order_status: JSON.stringify(order_status),
        payment_status: JSON.stringify(payment_status),
        delivery_status: JSON.stringify(delivery_status),
        delivery_method: delivery_method,
        payment_due: payment_due,
        sort_by_order_date: sort_by ? null : "desc",
        [sortBy]: sort_by ? "desc" : undefined,
        sales_id: ["distributor", "order_direct"].includes(
          filters?.sales_id?.value
        )
          ? undefined
          : filters?.sales_id?.value,
        created_by: ["distributor", "order_direct"].includes(
          filters?.sales_id?.value
        )
          ? filters?.sales_id?.value
          : filters?.sales_id?.value
          ? "sales"
          : undefined,
        archived: !!archived,
        fulfill_by: fulfill_by?.value?.start_date
          ? null
          : fulfill_by?.value?.id,
        fulfill_by_start_date: fulfill_by?.value?.start_date,

        fulfill_by_end_date: fulfill_by?.value?.end_date,
        ...paymentStatus,
      };

      const data = await handleFetchItems({
        query: { ...q, ...getBody, customer_id: customerId },
        requestService: (params) => {
          if (repPermissions && !repPermissions?.orders?.view) return;
          return getOrdersService(params);
        },
      });

      const { count = 0, rows = [], existDataByCustomer = false } = data || {};

      const rowsIds = rows.length ? rows.map(({ id }) => id) : [];

      setCustomerState((prev) => ({
        ...prev,
        tableData: {
          ...prev.tableData,
          loading: { ...prev.tableData.loading, [TABLE_TABS.Orders]: false },
          count,
          existDataByCustomer,
          checkedItems: fetchAll
            ? rowsIds
            : !fetchAll && resetChecked
            ? []
            : prev.tableData.checkedItems,
          items: {
            ...prev.tableData.items,
            [TABLE_TABS.Orders]:
              tableQuery.page === 1 || resetChecked || fetchAll
                ? rows
                : [...prev.tableData.items[TABLE_TABS.Orders], ...rows],
          },
        },
      }));
    },
    [customerId, handleFetchItems, repPermissions, setPaymentStatus, tableQuery]
  );

  const actionsSuccessCallback = useCallback(() => {
    handleFetchOrders(true);
  }, [handleFetchOrders]);

  const handleFetchNotes = useCallback(
    async (forceQuery) => {
      const { page: forcePage } = forceQuery || {};
      const { filters, ...q } = tableQuery;
      const page = forcePage || tableQuery.page;

      const { sales_id, date } = filters || {};
      const created_by_distributor = sales_id?.value === "distributor";
      const { start_date, end_date } = date?.value || {};

      const noteQuery = {
        ...q,
        representative_duplicate_id: created_by_distributor
          ? undefined
          : sales_id?.value,
        created_by_distributor,
        date: start_date ? undefined : snakeCase(date?.value),
        start_date,
        end_date,
        page,
      };

      try {
        const { count, rows, existDataByCustomer } = await handleFetchItems({
          query: { ...tableQuery, customer_id: customerId, page },
          requestService: () =>
            getCustomerNotesService(customerId, { ...noteQuery }),
        });

        setCustomerState((prev) => ({
          ...prev,
          tableData: {
            ...prev.tableData,
            loading: { ...prev.tableData.loading, [TABLE_TABS.Notes]: false },
            count,
            existDataByCustomer,
            items: {
              ...prev.tableData.items,
              [TABLE_TABS.Notes]:
                page === 1
                  ? rows
                  : [...prev.tableData.items[TABLE_TABS.Notes], ...rows],
            },
          },
        }));
      } catch (err) {
        error(err?.response?.data?.message);
      }
    },
    [customerId, handleFetchItems, tableQuery]
  );

  const handleFetchActivities = useCallback(
    async (forceQuery) => {
      const { limit } = tableQuery || {};
      const { page: forcePage } = forceQuery || {};
      const page = forcePage || tableQuery.page;

      try {
        const data = await handleFetchItems({
          query: {
            page,
            limit,
            customer_id: customerId,
            type: filterTab?.type,
          },
          requestService: getActivitiesService,
        });

        const { count, rows, existDataByCustomer } = data || {};

        setCustomerState((prev) => ({
          ...prev,
          tableData: {
            ...prev.tableData,
            loading: {
              ...prev.tableData.loading,
              [TABLE_TABS.Timeline]: false,
            },
            count,
            existDataByCustomer,
            items: {
              ...prev.tableData.items,
              [TABLE_TABS.Timeline]:
                page === 1
                  ? rows
                  : [...prev.tableData.items[TABLE_TABS.Timeline], ...rows],
            },
          },
        }));
      } catch (err) {
        error(err?.response?.data?.message);
      }
    },
    [customerId, filterTab?.type, handleFetchItems, tableQuery]
  );

  const handleFetchTasks = useCallback(
    async (forceQuery) => {
      const { page: forcePage } = forceQuery || {};
      const { filters, ...q } = tableQuery;
      const page = forcePage || tableQuery.page;

      const { date, status, sales_id } = filters || {};

      const assigned_to_distributor = sales_id?.value === "distributor";
      const { start_date, end_date } = date?.value || {};

      const STATUSES = {
        OPEN: "PENDING",
        CLOSED: "COMPLETED",
      };

      const taskQuery = {
        ...q,
        representative_id: assigned_to_distributor
          ? distributorId
          : sales_id?.value,
        due_date: start_date ? undefined : snakeCase(date?.value),
        start_due_date: start_date,
        end_due_date: end_date,
        status: STATUSES[status],
        page,
        customer_id: customerId,
        sort_by_date: "desc",
      };

      try {
        const { count, existDataByCustomer, rows } = await handleFetchItems({
          query: { ...tableQuery, page },
          requestService: () => getTasksService({ ...taskQuery }),
        });

        const tasks = rows ? rows : [];

        setCustomerState((prev) => ({
          ...prev,
          tableData: {
            ...prev.tableData,
            loading: { ...prev.tableData.loading, [TABLE_TABS.Tasks]: false },
            count,
            existDataByCustomer,
            items: {
              ...prev.tableData.items,
              [TABLE_TABS.Tasks]:
                page === 1
                  ? tasks
                  : [...prev.tableData.items[TABLE_TABS.Tasks], ...tasks],
            },
          },
        }));
      } catch (err) {
        error(err?.response?.data?.message);
      }
    },
    [customerId, distributorId, handleFetchItems, tableQuery]
  );

  const handlePrintPackingSlips = useCallback(async () => {
    updateCustomerState({ loading: true });
    try {
      const { data } = await getOrdersBulkPackingSlipPDF({
        ordersIds: customerState.tableData.checkedItems,
      });
      updateCustomerState({ loading: false });
      openFilePreview(data);
      actionsSuccessCallback();
    } catch (err) {
      updateCustomerState({ loading: false });
      error(err?.response?.data?.message || err?.message);
    }
  }, [
    actionsSuccessCallback,
    customerState.tableData.checkedItems,
    updateCustomerState,
  ]);

  const handlePrintOrders = useCallback(async () => {
    updateCustomerState({ loading: true });
    try {
      const { data } = await getOrdersBulkReportPDF({
        ordersIds: customerState.tableData.checkedItems,
      });
      updateCustomerState({ loading: false });
      openFilePreview(data);
      actionsSuccessCallback();
    } catch (err) {
      updateCustomerState({ loading: false });
      error(err?.response?.data?.message || err?.message);
    }
  }, [
    actionsSuccessCallback,
    customerState.tableData.checkedItems,
    updateCustomerState,
  ]);

  useEffect(() => {
    if (customerState?.tableTab?.label === "Timeline")
      handleFetchActivities({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTab]);

  const handleCheckAllTableItems = useCallback(
    (allChecked) => {
      if (allChecked) {
        setCustomerState((prev) => ({
          ...prev,
          tableData: { ...prev.tableData, checkedItems: [] },
        }));
      } else {
        const items = customerState.tableData.items[
          customerState.tableTab.value
        ].map(({ id }) => id);
        setCustomerState((prev) => ({
          ...prev,
          tableData: { ...prev.tableData, checkedItems: items },
        }));
      }
    },
    [customerState.tableData.items, customerState.tableTab.value]
  );

  const handleCheckTableItem = useCallback(
    (itemId) => {
      const found = customerState.tableData.checkedItems.some(
        (id) => id === itemId
      );
      if (found) {
        setCustomerState((prev) => ({
          ...prev,
          tableData: {
            ...prev.tableData,
            checkedItems: prev.tableData.checkedItems.filter(
              (id) => id !== itemId
            ),
          },
        }));
      } else {
        setCustomerState((prev) => ({
          ...prev,
          tableData: {
            ...prev.tableData,
            checkedItems: [...prev.tableData.checkedItems, itemId],
          },
        }));
      }
    },
    [customerState.tableData.checkedItems]
  );

  const { checkedItems, items: tableItems } = useMemo(
    () => customerState.tableData,
    [customerState.tableData]
  );

  const items = useMemo(
    () => tableItems[customerState.tableTab.value],
    [customerState.tableTab.value, tableItems]
  );

  const [printPickListDialog, setPrintPickListDialog] = useState(false);
  const [editDelivery, setEditDelivery] = useState(null);

  const handleCloseAcceptDeliveryPopup = () => {
    setIsConfirmDeliveryPopupOpen(false);
    setEditDelivery(null);
    setType("");
  };

  const fetchBulkPackingSlipOrderReport = (data) => {
    updateCustomerState({ loading: true });
    bulkPackingSlipOrderReportService({
      data,
    })
      .then((res) => {
        setTimeout(() => {
          openFilePreview(res.data);
          actionsSuccessCallback();
        }, 1000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      })
      .finally(() => updateCustomerState({ loading: false }));
  };

  const handlePrintPickList = () => {
    const hasMoreThenOne = checkedItems?.length > 1;
    if (hasMoreThenOne) return setPrintPickListDialog(true);

    fetchPrintPickList({ checkedOrdersIds: checkedItems });
  };

  const fetchPrintPickList = ({
    isCheckedGroupOrders = false,
    checkedOrdersIds,
  }) => {
    if (!checkedOrdersIds || checkedOrdersIds?.length < 1) return;

    fetchBulkPackingSlipOrderReport({
      ordersIds: checkedOrdersIds,
      groupOrders: isCheckedGroupOrders,
    });
  };

  const allChecked = useMemo(
    () => !!checkedItems?.length && checkedItems?.length === items?.length,
    [checkedItems?.length, items?.length]
  );

  const selectAllOrders = useCallback(
    (tableCount) => {
      if (items.length === tableCount) {
        const tableIds = items.map((item) => item.id);
        return setCustomerState((prev) => ({
          ...prev,
          tableData: {
            ...prev.tableData,
            checkedItems: tableIds,
          },
        }));
      }
      return handleFetchOrders(true, true);
    },
    [handleFetchOrders, items]
  );

  const transformTagDataForAssignTags = (tags) => tags.map((tag) => ({ tag }));
  const [loadingAssignTags, setLoadingAssignTags] = useState(false);

  const handleSaveAssignTags = useCallback(
    async ({ tags }) => {
      assignTagsState?.isBulk
        ? setAssignTagsState((prev) => ({
            ...prev,
            orders: prev.orders.map((order) => ({
              ...order,
              tags: transformTagDataForAssignTags(tags),
            })),
          }))
        : setAssignTagsState((prev) => ({
            ...prev,
            order: { ...prev.order, tags: transformTagDataForAssignTags(tags) },
          }));
      if (loadingAssignTags) return;

      try {
        setLoadingAssignTags(true);
        const preparedData = {};
        const tagIds = [];
        const newTags = [];

        tags.forEach((tag) => {
          return tag?.newTag
            ? newTags.push(tag?.tag)
            : tagIds.push(tag?.id || tag?.tag?.id);
        });

        preparedData.tagIds = tagIds;
        if (newTags?.length) preparedData.newTags = newTags;

        if (assignTagsState?.isBulk) {
          const data = {
            orderIds: assignTagsState.orders,
            tagIds,
            newTags,
          };
          await bulkAssignOrderTagsService(data);
        } else {
          if (!assignTagsState?.order?.id) return;

          if (assignTagsState?.isDraft) {
            await updateDraftOrderService({
              id: assignTagsState?.order?.id,
              data: preparedData,
            });
          } else {
            await updateOrderService(assignTagsState?.order?.id, preparedData);
          }
        }
        success("Tags updated successfully");

        actionsSuccessCallback();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        handleCloseAssignTagsDialog();
        setLoadingAssignTags(false);
      }
    },
    [
      actionsSuccessCallback,
      assignTagsState?.isBulk,
      assignTagsState?.isDraft,
      assignTagsState?.order?.id,
      assignTagsState.orders,
      handleCloseAssignTagsDialog,
      loadingAssignTags,
      setAssignTagsState,
    ]
  );

  const { dropDownActions, additionalActions } = CustomerProfileActions({
    handlePrintOrders,
    handlePrintPickList,
    actionsSuccessCallback,
    handlePrintPackingSlips,
    customerState,
    setCustomerState,
    clearCheckedList: () => handleCheckAllTableItems(true),
    currentUser,
    orderList: items,
    handleChoseMenuItem,
    setConfirmDeliveryState,
    checkedOrders: checkedItems,
    handleCloseConfirmDeliveryDialog,
    handleClickOnAssignTags: () => {
      setAssignTagsState((prev) => ({
        ...prev,
        open: true,
        order: null,
        orders: checkedItems,
        isBulk: true,
        // isDraft, //?
      }));
    },
  });

  const isQuickBooksConnected = !!currentUser?.quickBooksTokens;

  const CUSTOMER_TABLE_MAP = useMemo(
    () => ({
      0: {
        TableItem: OrdersItem,
        handleFetch: handleFetchOrders,
        hasControlPanel: true,
        getProps: (order, index, itemsLength) => ({
          showBorder: index !== itemsLength - 1,
          order,
          timeZone: currentUser?.timeZone,
          // isPopup: true,
          // isCustomerView: true,
          handleCheckOrder: handleCheckTableItem,
          handleOpenEmail,
          isChecked: customerState.tableData.checkedItems.some(
            (id) => id === order.id
          ),
          setOpenAddContactPopup: () => setContactsOpen(true),
          handleClickOnCustomId: (orderId) => navigate(`/orders/${orderId}`),
          repPermissions,
          isQuickBooksConnected,
          handleChoseMenuItem: (order, itemName) =>
            handleChoseMenuItem(order, itemName, actionsSuccessCallback),
        }),
        getControlPanelProps: (tableLoading, tableCount) => ({
          cancelSelection: () => handleCheckAllTableItems(true),
          headerComponent: null,
          actionWrapperProps: {
            sx: {
              padding: "0 18px 0 19px !important",
              borderLeft: "none !important",
              borderRight: "none !important",

              "& .MuiCheckbox-root svg": {
                width: "20px !important",
                height: "20px !important",
                marginBottom: "2px !important",
              },
            },
          },
          checkedCount: checkedItems.length,
          actionsList: additionalActions,
          loading: isLoading || tableLoading || customerState.loading,
          dropDownActions: dropDownActions,
          onSelectAll: () => selectAllOrders(tableCount),
          hasCheckedItems: !!checkedItems.length,
          availableSelectCount: tableCount,
          selectName: "order",
          onSelectVisible: () => handleCheckAllTableItems(allChecked),
        }),
        ControlPanel: TableHeaderControlPanel,
        labelsWithFixedWidth: [
          {
            text: (
              <LargeCheckbox
                sx={{ padding: 0 }}
                formSx={{ m: 0 }}
                size={20}
                key="select"
                checked={allChecked}
                onChange={() => handleCheckAllTableItems(allChecked)}
              />
            ),
            sx: {
              width: `${ORDER_ITEM_WIDTH.checkbox}px`,
              justifyContent: "flex-start",
              pl: "19px",
              "& label": { marginLeft: 0, marginRight: 0 },
            },
          },
          {
            text: "Order #",
            sx: { width: `${ORDER_ITEM_WIDTH.order_id}px` },
          },
        ],
        headerLabels: [
          {
            text: "Date",
            xs: ORDER_ITEM_WIDTH.date,
          },
          {
            text: "Created by",
            xs: ORDER_ITEM_WIDTH.created_by,
          },
          {
            text: "Payment",
            xs: ORDER_ITEM_WIDTH.payment,
          },
          {
            text: "Fulfillment",
            xs: ORDER_ITEM_WIDTH.fulfillment,
          },
          {
            text: "Total",
            xs: ORDER_ITEM_WIDTH.total,
          },
        ],
        actionBlock: {
          sx: {
            width: `${ORDER_ITEM_WIDTH.actions}px`,
          },
        },
        filterProps: {
          switches: ORDER_SWITCHES,
          filterFields: ORDER_FILTERS,
        },
        filterTabs: null,
        handleCreate:
          (repPermissions && !repPermissions?.orders?.create_edit) || isAdmin
            ? null
            : handleOpenOrder,
      },
      1: {
        TableItem: NoteItem,
        hasControlPanel: false,
        handleFetch: handleFetchNotes,
        getProps: (note, index, itemsLength) => ({
          note,
          showBorder: index !== itemsLength - 1,
          onClick: (note) => handleOpenNote(note),
        }),
        headerLabels: [
          {
            text: "Date",
            xs: 4,
            sx: { pl: "19px" },
          },
          { text: "Notes", xs: 13 },
          { text: "Created by", xs: 8 },
        ],
        filterProps: {
          switches: NOTE_SWITCHES,
          filterFields: NOTE_FILTERS,
        },
        filterTabs: null,
        handleCreate: handleOpenNote,
      },
      2: {
        TableItem: TaskItem,
        handleFetch: handleFetchTasks,
        hasControlPanel: false,
        getProps: (task, index, itemsLength) => ({
          task,
          showBorder: index !== itemsLength - 1,
          onClick: (task) => handleOpenTask(task),
        }),
        headerLabels: [
          { text: "", xs: 1.6 },
          { text: "Task name", xs: 13.4 },
          { text: "Assignee", xs: 3.8 },
          { text: "Due Date", xs: 3.2 },
          { text: "Priority", xs: true },
        ],
        filterProps: {
          switches: TASK_SWITCHES,
          filterFields: TASK_FILTERS,
        },
        filterTabs: null,
        handleCreate: handleOpenTask,
      },
      3: {
        TableItem: ActivityItem,
        handleFetch: handleFetchActivities,
        hasControlPanel: false,
        getProps: (activity) => {
          return {
            activity,
            timeZone: currentUser?.timeZone,
            height: 144,
          };
        },
        headerLabels: null,
        filterProps: {
          switches: [],
          filterFields: {},
        },
        filterTabsTitle: "Timeline",
        filterTabsData: FILTER_TABS_DATA,
        filterTab,
        setFilterTab,
      },
    }),
    [
      handleFetchOrders,
      allChecked,
      repPermissions,
      isAdmin,
      handleOpenOrder,
      handleFetchNotes,
      handleOpenNote,
      handleFetchTasks,
      handleOpenTask,
      handleFetchActivities,
      filterTab,
      currentUser?.timeZone,
      handleCheckTableItem,
      handleOpenEmail,
      customerState.tableData.checkedItems,
      customerState.loading,
      isQuickBooksConnected,
      navigate,
      handleChoseMenuItem,
      actionsSuccessCallback,
      checkedItems.length,
      additionalActions,
      isLoading,
      dropDownActions,
      handleCheckAllTableItems,
      selectAllOrders,
    ]
  );

  const currentTable = useMemo(
    () => CUSTOMER_TABLE_MAP[customerState.tableTab.value],
    [CUSTOMER_TABLE_MAP, customerState.tableTab.value]
  );

  const handleRefetchNotes = useCallback(() => {
    if (customerState.tableTab.value === 1) {
      handleFetchNotes({ page: 1 });
    }
  }, [customerState.tableTab.value, handleFetchNotes]);

  const handleRefetchTasks = useCallback(() => {
    if (customerState.tableTab.value === 2) {
      handleFetchTasks({ page: 1 });
    }
  }, [customerState.tableTab.value, handleFetchTasks]);

  const findCheckedItems = useCallback(({ ids, customerState }) => {
    if (!ids || typeof customerState?.tableTab?.value !== "number") return [];

    const arrIds = [...ids];
    const list = [
      ...customerState?.tableData?.items?.[customerState?.tableTab?.value],
    ];

    if (!list?.length) return [];

    return list.filter((item) => arrIds.includes(item?.id));
  }, []);

  return {
    handleFetchCustomer,
    updateCustomerState,
    customerState,
    resetCustomerState,
    currentTable,
    handleFetchActivities,
    handleCheckAllTableItems,
    handleDataSearch,
    filterSearch: tableQuery.search,
    filters: tableQuery.filters,
    handleChangeTab,
    handleCloseNote,
    noteState,
    globalNoteState,
    handleOpenGlobalNote,
    handleCloseCustomerNotes,
    taskState,
    handleCloseTask,
    handleRefetchTasks,
    customerId,
    handleRefetchNotes,
    handleOpenNote,
    handleOpenTask,
    mapData,
    setMapData,
    setContactsOpen,
    contactsOpen,
    isParent,
    handleOpenOrder,
    emptyCartPopupOpen,
    setEmptyCartPopupOpen,
    navigateToOrderCreate,
    repPermissions,
    printPickListDialog,
    setPrintPickListDialog,
    fetchPrintPickList,
    handleCloseAcceptDeliveryPopup,
    editDelivery,
    actionsSuccessCallback,
    isAdmin,
    handleSaveAssignTags,
    findCheckedItems,
  };
};

export const useCustomerActions = () => {
  const repPermissions = useRepsPermissions();
  const currentUser = useSelector(currentUserSelector);
  const isQuickBooksConnected = !!currentUser?.quickBooksTokens;

  const [isOpenReportDialog, setOpenReportDialog] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [isCustom, setCustom] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const navigate = useNavigate();
  const { customerId } = useParams();
  const dispatch = useDispatch();

  const handleEditCustomer = (customer) => {
    const navigateParams = {
      id: customerId,
      navigate,
      missingInfo: customer?.requiredFieldsMissing,
    };

    missingInfoRedirect(navigateParams);
  };

  const { updateCustomerState, customerState } = useContext(
    CustomerProfileContext
  );

  const handleUpdateCustomer = async ({ data, msg }) => {
    try {
      updateCustomerState({ loading: true });
      const customer = await updateCustomerService(data, customerId);
      updateCustomerState({ loading: false, customer });
      success(msg ? msg : "Assigned reps updated");
    } catch (err) {
      updateCustomerState({ loading: false });
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message);
      error(err?.response?.data?.message);
    }
  };

  const handleCustomerStatus = async (e) => {
    const newStatus = e.target.value;
    await handleUpdateCustomer({
      data: { status: newStatus },
      msg: "Status updated",
    });
  };

  const handleSaveTags = async (tags) => {
    const customerTags = tags
      ?.filter((tag) => tag?.id || tag?.tag?.id)
      ?.map((tag) => tag?.id || tag?.tag?.id);

    const newCustomerTags = tags?.filter((tag) => !tag?.id && !tag?.tag?.id);

    await handleUpdateCustomer({
      data: { customerTags, newCustomerTags },
      msg: `Tags ${newCustomerTags?.length ? "added" : "updated"}`,
    });
    if (newCustomerTags?.length || customerTags?.length) {
      dispatch(getTagsAction());
    }
  };

  const defaultContact = useMemo(() => {
    const contact = customerState?.customer?.contacts?.find(
      (contact) => !!contact?.defaultContact
    );
    return contact?.id || contact?.fid;
  }, [customerState?.customer?.contacts]);

  const handleSaveContacts = async (oldContacts, newContact) => {
    const isEdit = !!newContact?.id;
    delete newContact.fid;

    const contacts = [...oldContacts, newContact];

    const defaultContactIndex = contacts.findIndex(
      (item) =>
        (item.id && item.id === defaultContact) ||
        (item.fid && item.fid === defaultContact)
    );

    if (defaultContactIndex > -1 && newContact.defaultContact) {
      const changedContact = {
        ...contacts[defaultContactIndex],
        defaultContact: false,
      };
      contacts.splice(defaultContactIndex, 1, changedContact);
    }

    await handleUpdateCustomer({
      data: { contacts },
      msg: `Contact ${isEdit ? "updated" : "added"}`,
    });
  };

  const customer = useMemo(
    () => customerState.customer,
    [customerState.customer]
  );

  const { orderDirectInvite } = useMemo(() => customer || {}, [customer]);

  const handleSendInvite = useCallback(
    async ({ email, contactId }) => {
      try {
        updateCustomerState({ loading: true });
        const { data: customer } = await sendInviteService({
          email: contactId ? undefined : email || orderDirectInvite?.email,
          customerId,
          contactId: contactId || undefined,
        });
        success("Invitation sent successfully");
        updateCustomerState({ loading: false, customer });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        updateCustomerState({ loading: false });
        error(err.response?.data?.message || "Something went wrong.");
      }
    },
    [customerId, orderDirectInvite?.email, updateCustomerState]
  );

  const handleCancelInvite = useCallback(
    async (contactId, isRevoke = false) => {
      try {
        let updatedInvite;

        updateCustomerState({ loading: true });
        await revokeInviteService(contactId);
        if (isRevoke) {
          updatedInvite = {
            ...customerState.customer?.orderDirectInvite,
            status: "REJECTED",
          };
        } else {
          // await cancelInviteService(contactId);
          updatedInvite = null;
        }
        updateCustomerState({
          loading: false,
          customer: {
            ...customerState.customer,
            orderDirectInvite: updatedInvite,
          },
        });
        success(`Invitation ${isRevoke ? "revoked" : "canceled"} successfully`);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        updateCustomerState({ loading: false });
        error(err.response?.data?.message || "Something went wrong.");
      }
    },
    [customerState.customer, updateCustomerState]
  );

  const handleInviteNow = useCallback(
    async ({ id: contactId, email }) => {
      try {
        const { available } = await orderDirectCheckEmail({
          email,
          customer_id: customerId,
        });

        if (!available) error("This email already has been used");
        else {
          await handleSendInvite({ email, contactId });
          if (!repPermissions)
            dispatch(getInvitesListAction({ limit: SCROLL_LIMIT_INVITES }));
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        error("Something went wrong!");
        return false;
      }
    },
    [customerId, dispatch, handleSendInvite, repPermissions]
  );

  const handleSaveAssignReps = async ({ data, msg }) => {
    await handleUpdateCustomer({ data: { representatives: data }, msg });
  };

  const handleSaveLicenses = async ({ data, msg }) => {
    await handleUpdateCustomer({ data: { documents: data }, msg });
  };

  const isInactive = useMemo(
    () => customer?.status === CUSTOMER_STATUS_FILTERS.inactive,
    [customer?.status]
  );

  const disableArchive = useMemo(
    () =>
      ((!!customer?.activeOrderCount ||
        !!customer?.countSubCustomerPendingOrders) &&
        !isInactive) ||
      !!customer?.activeOrderCount ||
      !!customer?.countSubCustomerPendingOrders,
    [
      customer?.activeOrderCount,
      customer?.countSubCustomerPendingOrders,
      isInactive,
    ]
  );

  const handleCloseReportDialog = useCallback(() => {
    setCustom(false);
    setOpenReportDialog(false);
    setCurrentReport(null);
  }, []);

  const handleOpenConfirmDialog = useCallback(
    (confirmQuery) => {
      dispatch(openConfirmDialogAction(confirmQuery));
    },
    [dispatch]
  );
  const handleReport = useCallback(
    (report, isCustom) => {
      setCustom(!!isCustom);
      setCurrentReport(report);
      setOpenReportDialog(true);
    },
    [setOpenReportDialog]
  );

  const reportData = {
    ...reportsList[0].reports[0],
    selectAndReorderColumnsBottom:
      reportsList[0].reports[0].selectAndReorderColumnsBottom.map((field) => ({
        ...field,
        chosen: true,
        selected: true,
      })),
    config: { ...reportsList[0].config },
  };

  const handleDeleteCustomers = useCallback(
    (id, hasDublicated) => {
      if (id) {
        dispatch(
          deleteCustomerAction({
            ids: [id],
            withRefetch: hasDublicated,
            onSuccess: navigate("/customers"),
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const handleArchiveCustomers = useCallback(
    (id, callBack) => {
      if (id)
        return dispatch(
          archiveBulkAction({
            data: {
              customerIds: [id],
              status: isInactive
                ? CUSTOMER_STATUS_FILTERS.active
                : CUSTOMER_STATUS_FILTERS.inactive,
            },
            onSuccess: callBack ? callBack : null,
          })
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const handleOpenConfirm = ({
    title,
    instanceListProp,
    instance,
    handleChangeInstance,
    item,
    itemAllChecked,
    handleConfirm,
    text,
    text2,
    dataCount,
    handleFetch,
    dataLength,
    confirmBtnColor,
    confirmBtnText,
  }) => {
    handleOpenConfirmDialog({
      title,
      text2: text2,
      text: text || (
        <ConfirmNewInstanceText
          {...{
            instanceListProp,
            instance,
            handleChangeInstance,
            item,
            itemAllChecked,
            dataCount,
            handleFetch,
            dataLength,
          }}
        />
      ),
      buttons: (
        <>
          <Button
            sx={{
              width: "98px",
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            }}
            onClick={() => {
              dispatch(setConfirmIsOpenAction(false));
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            sx={{
              width: confirmBtnText ? "112px" : "98px",
              p: confirmBtnText && "6px",
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
            }}
            color={
              confirmBtnColor || confirmBtnText ? "confirmDelete" : "primary"
            }
            onClick={() => {
              dispatch(setConfirmIsOpenAction(false));
              handleConfirm();
            }}
            variant="contained"
          >
            {confirmBtnText || "Confirm"}
          </Button>
        </>
      ),
    });
  };

  const customerActions = [
    {
      label: "Edit",
      onClick:
        repPermissions && !repPermissions?.customers?.create_edit
          ? null
          : handleEditCustomer,
      icon: <PenIcon size={9} color="#000000" />,
    },
    {
      label: "Report",
      onClick:
        repPermissions && !repPermissions?.reports?.view
          ? null
          : () => handleReport({ ...reportData, customer }, true),
      icon: <ReportIcon />,
    },
    {
      label: isInactive ? "Restore" : "Archive",
      onClick:
        repPermissions && !repPermissions?.customers?.create_edit
          ? null
          : () => {
              handleOpenConfirmDialog({
                title: (
                  <Box components="span" fontSize={18}>
                    {isInactive ? "Restore customer?" : "Archive customer?"}
                  </Box>
                ),
                text: (
                  <>
                    Are you sure you want to{" "}
                    {isInactive ? "restore" : "archive"}
                    <Box components="span" sx={{ color: "#47A06D" }}>
                      {customer?.displayName || customer?.name} ?
                    </Box>
                  </>
                ),
                buttons: (
                  <>
                    <Button
                      sx={{
                        width: "98px",
                        color: "#6A6A6A",
                        borderColor: "#D4D4D4",
                        fontSize: "13px",
                        height: "28px",
                      }}
                      onClick={() => {
                        dispatch(setConfirmIsOpenAction(false));
                      }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        width: isInactive ? "98px" : "112px",
                        color: "#FFFFFF",
                        fontSize: "13px",
                        height: "28px",
                        boxShadow: "none",
                        p: "6px",
                      }}
                      color={"primary"}
                      onClick={() => {
                        dispatch(setConfirmIsOpenAction(false));
                        handleArchiveCustomers(customer.id, () => {
                          updateCustomerState({
                            customer: {
                              ...customer,
                              status: isInactive
                                ? CUSTOMER_STATUS_FILTERS.active
                                : CUSTOMER_STATUS_FILTERS.inactive,
                            },
                          });
                        });
                      }}
                      variant="contained"
                    >
                      Confirm
                    </Button>
                  </>
                ),
              });
            },
      disabled: disableArchive,
      icon: <ArchiveIconCustomer fill="black" height="10.5" width="8.58" />,
    },
  ];

  const deleteAction = {
    label: (
      <Typography
        sx={{
          color: "#FF6254",
          fontSize: "12px",
        }}
      >
        Delete
      </Typography>
    ),
    onClick: () =>
      repPermissions && !repPermissions?.customers?.create_edit
        ? null
        : handleOpenConfirm({
            title: (
              <Box display="flex" alignItems="center">
                <DangerIcon />
                <span style={{ marginLeft: "11px" }}>Delete customer(s)?</span>
              </Box>
            ),
            text: `Are you sure you want to delete  ${
              customer?.displayName || customer?.name
            }?`,
            text2: "All data will be erased and this can't be undone.",
            handleConfirm: () => handleDeleteCustomers(customer.id),
          }),
    icon: <TrashIcon fill="#FF6254" height="11.2" width="9.15" />,
  };

  const debouncedHandleCustomerStatus = debounce(handleCustomerStatus, 300);

  return {
    handleEditCustomer,
    handleCustomerStatus,
    handleSaveTags,
    handleSaveContacts,
    handleSendInvite,
    handleCancelInvite,
    handleInviteNow,
    customerId,
    handleSaveAssignReps,
    handleSaveLicenses,
    handleCloseReportDialog,
    isOpenReportDialog,
    currentReport,
    isCustom,
    customerActions: isQuickBooksConnected
      ? [...customerActions]
      : [...customerActions, deleteAction],
    showTooltip,
    setShowTooltip,
    debouncedHandleCustomerStatus,
    currentUser,
  };
};

export const useCustomerCards = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const [editPaymentOpen, setEditPaymentOpen] = useState(false);
  const [editCard, setEditCard] = useState(null);

  const [cardsState, setCardsState] = useState({
    list: [],
    lastCardId: "",
    loading: false,
    cardsPopupOpen: false,
  });
  const handleFetchCards = useCallback(
    async (params) => {
      setCardsState((prev) => ({ ...prev, loading: true }));

      try {
        const { hasMore, rows } = await getCustomerCardsService(
          customerId,
          params
        );
        setCardsState((prev) => ({
          ...prev,
          loading: false,
          hasMore,
          list: rows,
        }));
      } catch (err) {
        error(err?.response?.data?.message);
        setCardsState((prev) => ({ ...prev, loading: false }));
      }
    },
    [customerId]
  );

  const handleOpenCardsPopup = () => {
    setCardsState((prev) => ({ ...prev, cardsPopupOpen: true }));
  };

  const handleCloseCardsPopup = () => {
    setCardsState((prev) => ({ ...prev, cardsPopupOpen: false }));
  };

  const handleEditCard = (card) => {
    const foundCard = cardsState.list.find((c) => c?.id === card?.id);
    setEditPaymentOpen(true);
    setEditCard(foundCard || card);
  };
  const onConfirmDelete = (card) => {
    if (customerId) {
      deleteCustomerCardService(customerId, card?.id)
        .then((res) => {
          setCardsState((prev) => ({
            ...prev,
            lastCardId: res.hasMore ? res?.rows[res?.rows?.length - 1]?.id : "",
            list: res?.rows || [],
          }));
          success("Card deleted");
        })
        .catch((err) => {
          error("Something went wrong!");
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          dispatch(setConfirmIsOpenAction(false));
        });
    }
  };
  const handleConfirmDeleteCard = (card) => {
    dispatch(
      openConfirmDialogAction({
        title: "Delete payment method?",
        text: (
          <span>
            Are you sure you want to delete
            <span style={{ fontWeight: "500", color: "#000000" }}>
              {" "}
              {setCardName(card?.brand)} ending in{" "}
              {card.number?.slice(-4) || card.last4}
            </span>
            ? Removing this payment method will not cancel any of your open
            orders that use this method.
          </span>
        ),
        buttons: (
          <>
            <StyledButton
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                height: "28px",
                ml: "auto",
              }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              fontSize="13px"
              label="Cancel"
              variant="outlined"
            />

            <StyledButton
              sx={{
                width: "98px",
                color: "#FFFFFF",
                height: "28px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              fontSize="13px"
              onClick={() => onConfirmDelete(card)}
              variant="contained"
              label="Confirm"
            />
          </>
        ),
      })
    );
  };

  return {
    handleFetchCards,
    cardsState,
    customerId,
    handleOpenCardsPopup,
    handleCloseCardsPopup,
    handleConfirmDeleteCard,
    handleEditCard,
    setEditPaymentOpen,
    setEditCard,
    editPaymentOpen,
    editCard,
  };
};

export const useTableFilters = () => {
  const [repsState, setRepsState] = useState({ list: [], count: 0 });

  const { customerState } = useContext(CustomerProfileContext);

  const handleFetchReps = useCallback(async () => {
    const { list } = repsState;
    const cursor = list[list.length - 1]?.id;
    const fetchQuery = {
      limit: SCROLL_LIMIT,
      roles: JSON.stringify(["sales", "merchandiser"]),
      cursor,
    };
    const { countSales, countMerchandiser, rows } = await getRepsService({
      ...fetchQuery,
    });
    setRepsState((prev) => ({
      ...prev,
      list: cursor ? [...prev.list, ...rows] : rows,
      count: countSales + countMerchandiser,
    }));
  }, [repsState]);

  useEffect(() => {
    handleFetchReps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerHasOrderDirect = useMemo(
    () => !!customerState.customer?.appCustomerId,
    [customerState.customer?.appCustomerId]
  );

  const preparedSalesMatchData = useCallback(
    ({ checkForOrderDirect, mutSalesIdFromIdToDublicateId }) => {
      const repsList =
        mutSalesIdFromIdToDublicateId === true
          ? repsState.list.map((r) => ({ ...r, id: r?.duplicate?.id || r?.id }))
          : repsState.list;
      return {
        id: "sales_id",
        childrenList: [
          { name: "Admin", id: "distributor" },
          customerHasOrderDirect &&
            checkForOrderDirect && { name: "Order Direct", id: "order_direct" },
          ...repsList,
        ],
        handleFetch: handleFetchReps,
        dataCount: repsState.count,
        dataLength: repsState.list.length,
        label: "Select Rep",
      };
    },
    [customerHasOrderDirect, handleFetchReps, repsState.count, repsState.list]
  );

  const MATCH_FILTER_DATA = useMemo(
    () => ({
      0: [
        preparedSalesMatchData({ checkForOrderDirect: true }),
        {
          id: "payment_status",
          checkboxes: [
            { label: "Overdue", field: "payment_due", value: "overdue" },
            { label: "Not due", field: "payment_due", value: "not_due" },
          ],
          valuesForCheckboxes: ["UNPAID"],
        },
        {
          id: "delivery_status",
          valuesForSelect: ["UNFULFILLED"],
          selectProps: {
            children: FILTER_DATE_VALUES,
            label: "Fulfill By Date",
            value: "fulfill_by",
          },
        },
      ],
      1: [
        preparedSalesMatchData({
          checkForOrderDirect: false,
          mutSalesIdFromIdToDublicateId: true,
        }),
        {
          id: "date",
          label: "Select Date",
        },
      ],
      2: [
        preparedSalesMatchData({ checkForOrderDirect: false }),
        {
          id: "date",
          label: "Select Date",
          datesList: [
            { name: "Today", id: "today" },
            { name: "Tomorrow", id: "tomorrow" },
            { name: "This week", id: "this_week" },
            { name: "Next week", id: "next_week" },
          ],
          hideCustomDate: true,
        },
      ],
    }),
    [preparedSalesMatchData]
  );

  const currentMatchFilterData = useMemo(
    () => MATCH_FILTER_DATA[customerState.tableTab.value],
    [MATCH_FILTER_DATA, customerState.tableTab.value]
  );

  return { currentMatchFilterData, repsState, handleFetchReps };
};

export const useEmailActions = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmail = async ({ params, data, formData }) => {
    try {
      setLoading(true);
      await sendEmailToCustomerService({
        params,
        data,
        formData,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message || err?.message);
      error(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
      handleClose();
      success("Email sent!");
    }
  };

  return {
    open,
    loading,
    handleOpen,
    handleClose,
    handleSendEmail,
  };
};

export const usePhotoActions = () => {
  const currentUser = useSelector(currentUserSelector);
  const dispatch = useDispatch();

  const [photoGroupPopupState, setPhotoGroupPopupState] = useState({
    open: false,
    currentPage: null,
    galleryGroupItem: null,
  });

  const handleSetCurrentPage = (data) => {
    setPhotoGroupPopupState((prev) => ({ ...prev, currentPage: data }));
  };

  const handleSetGalleryGroupItem = (data) => {
    setPhotoGroupPopupState((prev) => ({ ...prev, galleryGroupItem: data }));
  };

  const handleOpenPhotoGroupPopup = useCallback(
    (el) => {
      getActivitiesService({
        start_date: el?.createdAt,
        type: "photo_group_added",
      })
        .then((res) => {
          const groupIds = res?.rows
            ?.map((el) => el?.photoGroups)
            .flat()
            .map((el) => el?.id);
          dispatch(groupIdsAction(groupIds));
          const index = groupIds.findIndex((group) => group === el?.id);
          handleSetCurrentPage(index + 1);
        })
        .then(() => {
          return new Promise(() => {
            getPhotoGroupByIdService(el?.id)
              .then((res) => {
                handleSetGalleryGroupItem(res);
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console?.error(err);
                error("Something went wrong!");
              });
            setPhotoGroupPopupState((prev) => ({ ...prev, open: true }));
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err?.message);
          // eslint-disable-next-line no-console
          console.error(
            err?.response?.data?.message || "Something went wrong."
          );
        });
    },
    [dispatch]
  );

  const handleClosePhotoGroupPopup = () => {
    setPhotoGroupPopupState((prev) => ({
      ...prev,
      open: false,
      currentPage: null,
      galleryGroupItem: null,
    }));
  };

  return {
    ...photoGroupPopupState,
    currentUser,
    handleOpenPhotoGroupPopup,
    handleClosePhotoGroupPopup,
    handleSetCurrentPage,
    handleSetGalleryGroupItem,
  };
};

export const useRepsActions = ({
  customer,
  assignedRepresentatives,
  updateCustomerState,
}) => {
  const [repState, setRepState] = useState({
    repProfile: null,
    loading: false,
  });

  const fetchRep = async (id) => {
    setRepState((prev) => ({ ...prev, loading: true }));
    try {
      const rep = await getRepByIdService(id);
      setRepState((prev) => ({ ...prev, repProfile: rep }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message || err?.message);
      error(err?.response?.data?.message || err?.message);
    } finally {
      setRepState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleOpenRepDialog = (id) => fetchRep(id);

  const handleCloseRepDialog = ({ data }) => {
    if (data) {
      const tempRepsList = [...assignedRepresentatives];

      const index = tempRepsList.findIndex(
        (rep) => rep?.representative?.id === data?.id
      );

      tempRepsList.splice(index, 1, {
        representative: {
          ...data,
          baseUser: { email: data?.email },
        },
      });

      updateCustomerState({
        customer: {
          ...customer,
          assignedRepresentatives: [...tempRepsList],
        },
      });
    }
    setRepState({
      repProfile: null,
      loading: false,
    });
  };

  return {
    ...repState,
    handleOpenRepDialog,
    handleCloseRepDialog,
  };
};

export const useLicenses = ({ handleSaveLicenses }) => {
  const [openLicenses, setOpenLicenses] = useState(false);

  const [editLicense, setEditLicense] = useState(null);

  const handleOpenLicenses = () => {
    setOpenLicenses(true);
  };

  const handleCloseLicenses = () => {
    setOpenLicenses(false);
    setEditLicense(null);
  };

  const onSubmitLicense = ({ licenses, license }) => {
    const index = licenses.findIndex((l) => {
      if (license?.id) {
        return l?.id === license.id;
      }
      if (license?.tempId) {
        return l?.tempId === license.tempId;
      }
    });

    if (index === -1) {
      handleSaveLicenses({
        data: [
          ...licenses.map((l) => ({
            attachmentsIds: l?.attachments?.map((f) => f?.id),
            documentNumber: l?.documentNumber,
            expirationDate: l?.expirationDate,
            id: l?.id,
            name: l?.name,
            type: l?.type,
          })),
          {
            attachmentsIds: license?.attachments?.map((f) => f?.id),
            documentNumber: license?.documentNumber,
            expirationDate: license?.expirationDate,
            name: license?.name,
            type: license?.type,
          },
        ],
        msg: "Licenses added",
      });
    } else {
      const tempLicenses = [...licenses];
      tempLicenses[index] = license;
      handleSaveLicenses({
        data: tempLicenses.map((l) => ({
          attachmentsIds: l?.attachments?.map((f) => f?.id),
          documentNumber: l?.documentNumber,
          expirationDate: l?.expirationDate,
          id: l?.id,
          name: l?.name,
          type: l?.type,
        })),
        msg: "License updated",
      });
    }
  };

  const handleDeleteLicense = ({ licenses, licenseDelete }) => {
    const tempLicenses = [...licenses];

    const index = tempLicenses.findIndex((l) => {
      if (licenseDelete?.id) {
        return l?.id === licenseDelete.id;
      }
      if (licenseDelete?.tempId) {
        return l?.tempId === licenseDelete.tempId;
      }
    });

    tempLicenses.splice(index, 1);

    handleSaveLicenses({
      data: tempLicenses.map((l) => ({
        attachmentsIds: l?.attachments?.map((f) => f?.id),
        documentNumber: l?.documentNumber,
        expirationDate: l?.expirationDate,
        id: l?.id,
        name: l?.name,
        type: l?.type,
      })),
      msg: "License deleted",
    });
  };

  return {
    openLicenses,
    handleOpenLicenses,
    handleCloseLicenses,
    editLicense,
    setEditLicense,
    onSubmitLicense,
    handleDeleteLicense,
  };
};

export const useRightColumnActions = ({
  assignedRepresentatives,
  setAssignedRepsOpen,
  contacts,
  setContactsOpen,
  documents,
  setEditLicense,
  handleOpenLicenses,
  handleDeleteLicense,
  tags,
  setTagsOpen,
  tagsList,
  handleSaveTags,
  weekDays,
  removeAllHours,
  handleOpenReceivingHours,
  showWorkingDays,
  handleAddHours,
  cardsState,
  handleOpenCardsPopup,
  handleConfirmDeleteCard,
  handleEditCard,
}) => {
  const repPermissions = useRepsPermissions();

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  const SHOW_ITEMS = 2;

  const types = {
    reps: "Representatives",
    contacts: "Contacts",
    doc: "Licenses & Certificates",
    cards: "Credit Cards",
  };

  const [options, setOptions] = useState({
    [types.reps]: {
      visibleItems: SHOW_ITEMS,
      show_all_items: false,
    },
    [types.contacts]: {
      visibleItems: SHOW_ITEMS,
      show_all_items: false,
    },
    [types.doc]: {
      visibleItems: SHOW_ITEMS,
      show_all_items: false,
    },
    [types.cards]: {
      visibleItems: SHOW_ITEMS,
      show_all_items: false,
    },
  });

  const initialData = useMemo(() => {
    const formatRepsList = formatRepsToCards(assignedRepresentatives);
    const formatContactsList = formatContactsToCards(contacts);
    const formatCardsList = formatCreditCardsToInfoCards(cardsState.list);

    return [
      {
        id: 1,
        value: "assigned_reps",
        title: "Assigned Reps",
        items: options[types.reps].show_all_items
          ? formatRepsList
          : formatRepsList.slice(0, options[types.reps].visibleItems),
        handleAdd: () => setAssignedRepsOpen(true),
        listOptions: {
          type: types.reps,
          visibleItems: options[types.reps].visibleItems,
          showAllItems: options[types.reps].show_all_items,
          counterHiddenItems:
            formatRepsList?.length > options[types.reps].visibleItems
              ? formatRepsList?.length - options[types.reps].visibleItems
              : 0,
          onToggleShowItems: () => {
            setOptions((prev) => ({
              ...prev,
              [types.reps]: {
                ...prev[types.reps],
                show_all_items: !prev[types.reps].show_all_items,
              },
            }));
          },
        },
      },
      {
        id: 2,
        value: "contacts",
        title: "Contacts",
        items: options[types.contacts].show_all_items
          ? formatContactsList
          : formatContactsList.slice(0, options[types.contacts].visibleItems),
        handleAdd: () => setContactsOpen(true),
        listOptions: {
          type: types.contacts,
          visibleItems: options[types.contacts].visibleItems,
          showAllItems: options[types.contacts].show_all_items,
          counterHiddenItems:
            formatContactsList?.length > options[types.contacts].visibleItems
              ? formatContactsList?.length -
                options[types.contacts].visibleItems
              : 0,
          onToggleShowItems: () => {
            setOptions((prev) => ({
              ...prev,
              [types.contacts]: {
                ...prev[types.contacts],
                show_all_items: !prev[types.contacts].show_all_items,
              },
            }));
          },
        },
      },
      {
        id: 3,
        value: "licenses_certificates",
        title: "Licenses & Certificates",
        child: (
          <Box
            sx={{
              "& .MuiGrid-root.MuiGrid-container.MuiGrid-item div:nth-of-type(2)":
                { pr: 1.5 },
              px: 2,
              mt: documents?.length ? "-14px" : "-26px",
              mb: "-2px",
            }}
          >
            <LicensesBlock
              licenses={
                options[types.doc].show_all_items
                  ? documents
                  : documents.slice(0, options[types.doc].visibleItems)
              }
              onEdit={(license) => {
                setEditLicense(license);
                handleOpenLicenses();
              }}
              onDelete={(license) => {
                handleDeleteLicense({ licenses: documents, license });
              }}
              hideAttachments
              scrollBarStyle={{
                right: "-16px",
                marginTop: "14px",
                marginBottom: "14px",
              }}
            />
          </Box>
        ),
        handleAdd: handleOpenLicenses,
        listOptions: {
          type: types.doc,
          visibleItems: options[types.doc].visibleItems,
          showAllItems: options[types.doc].show_all_items,
          counterHiddenItems:
            documents?.length > options[types.doc].visibleItems
              ? documents?.length - options[types.doc].visibleItems
              : 0,
          onToggleShowItems: () => {
            setOptions((prev) => ({
              ...prev,
              [types.doc]: {
                ...prev[types.doc],
                show_all_items: !prev[types.doc].show_all_items,
              },
            }));
          },
          styles: { pb: "13px" },
        },
      },
      {
        id: 4,
        value: "tags",
        title: "Tags",
        child: (
          <Box
            sx={{
              margin: `${tags?.length ? 16 : 0}px 16px ${
                tags?.length ? 16 : 0
              }px`,
            }}
          >
            {!!tags?.length && (
              <TagField
                disabled={disabledCreateEdit}
                fieldName="tags"
                setValue={(fieldName, tags) => {
                  const preparedTags = tags.map((tag) => {
                    if (tag?.newTag) return tag?.tag;
                    return tag;
                  });
                  handleSaveTags(preparedTags);
                }}
                errors={{}}
                setError={() => {}}
                clearErrors={() => {}}
                tagsList={tagsList}
                chosenTags={tags.map(({ tag }) => tag)}
                handleViewAll={() => setTagsOpen(true)}
                type="customer"
                fontSize={"13px"}
                hideViewAllBtns
              />
            )}
          </Box>
        ),
        // wrapperStyle: { maxHeight: "160px" },
        handleAdd: () => setTagsOpen(true),
      },
      {
        id: 5,
        value: "working_hours",
        title: "Working Hours",
        child: (
          <>
            <CustomerReceivedHoursCard
              title="Working Hours"
              items={weekDays}
              onDelete={removeAllHours}
              handleEdit={handleOpenReceivingHours}
              showWorkingDays={showWorkingDays}
              disabled={disabledCreateEdit}
            />
          </>
        ),
        ...(!showWorkingDays ? { handleAdd: handleAddHours } : null),
      },
      {
        id: 6,
        value: "credit_cards",
        title: "Credit Cards",
        items: options[types.cards].show_all_items
          ? formatCardsList
          : formatCardsList?.slice(0, options[types.cards].visibleItems),
        handleAdd: () => {
          if (repPermissions) return;
          handleOpenCardsPopup();
        },
        onDelete: handleConfirmDeleteCard,
        handleEdit: handleEditCard,
        listOptions: {
          type: types.cards,
          visibleItems: options[types.cards].visibleItems,
          showAllItems: options[types.cards].show_all_items,
          counterHiddenItems:
            formatCardsList?.length > options[types.cards].visibleItems
              ? formatCardsList?.length - options[types.cards].visibleItems
              : 0,
          onToggleShowItems: () => {
            setOptions((prev) => ({
              ...prev,
              [types.cards]: {
                ...prev[types.cards],
                show_all_items: !prev[types.cards].show_all_items,
              },
            }));
          },
        },
      },
    ];
  }, [
    assignedRepresentatives,
    cardsState.list,
    contacts,
    disabledCreateEdit,
    documents,
    handleAddHours,
    handleConfirmDeleteCard,
    handleDeleteLicense,
    handleEditCard,
    handleOpenCardsPopup,
    handleOpenLicenses,
    handleOpenReceivingHours,
    handleSaveTags,
    options,
    removeAllHours,
    repPermissions,
    setAssignedRepsOpen,
    setContactsOpen,
    setEditLicense,
    setTagsOpen,
    showWorkingDays,
    tags,
    tagsList,
    types.cards,
    types.contacts,
    types.doc,
    types.reps,
    weekDays,
  ]);

  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const { customerProfileLayoutSettings } = currentUser || {};

  const [tempLayoutSettings, setTempLayoutSettings] = useState(null);

  const handleSetIndexes = (settingsData, initialData) => {
    const caseInsensitiveSort = (a, b) =>
      a.localeCompare(b, undefined, { sensitivity: "base" });
    const a = settingsData.map((el) => el?.name).sort(caseInsensitiveSort);
    const b = initialData.map((el) => el?.value).sort(caseInsensitiveSort);

    const areArraysEqual = isEqual(a, b);

    if (!areArraysEqual) return initialData;

    const preparedItems = settingsData.map((item) =>
      initialData.find((el) => el?.value === item?.name)
    );

    const sortedItems = sortBy(preparedItems, "index");

    return sortedItems;
  };

  const prepareInfoData = useMemo(
    () =>
      tempLayoutSettings || customerProfileLayoutSettings
        ? handleSetIndexes(
            tempLayoutSettings || customerProfileLayoutSettings,
            initialData
          )
        : initialData,
    [initialData, customerProfileLayoutSettings, tempLayoutSettings]
  );

  const handleSaveSettings = async ({ data, msg }) => {
    try {
      const res = await updateDistributorSettingsService({
        customerProfileLayoutSettings: data,
      });
      if (msg) success(msg);
      dispatch(setCurrentUser(res));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message || err?.message);
      error(err?.response?.data?.message || err?.message);
    }
  };

  return {
    infoCards: prepareInfoData,
    handleSaveSettings,
    setTempLayoutSettings,
  };
};

export const useAddressesSubCustomers = ({ parentCustomerId }) => {
  const initState = {
    loading: false,
    list: [],
    count: 0,
    existData: false,
    page: 0,
    hasFetched: false,
  };
  const [addressesSubCustomersState, setAddressesSubCustomersState] =
    useState(initState);

  const handleFetchAddressesSubCustomers = useCallback(
    async (page) => {
      if (!page) return;
      setAddressesSubCustomersState((prev) => ({ ...prev, loading: true }));

      try {
        const res = await getSubCustomersByParentCustomerIdService({
          params: { page, limit: 5 },
          parentCustomerId,
        });

        const { rows = [], count = 0, existData = false } = res;

        setAddressesSubCustomersState((prev) => ({
          ...prev,
          loading: false,
          count,
          existData,
          hasFetched: true,
          page,
          list: page ? [...prev.list, ...rows] : rows,
        }));
      } catch (err) {
        error(err?.response?.data?.message);
        setAddressesSubCustomersState((prev) => ({ ...prev, loading: false }));
      }
    },
    [parentCustomerId]
  );

  const resetAddressesSubCustomersState = () => {
    setAddressesSubCustomersState(initState);
  };

  return {
    addressesSubCustomersState,
    handleFetchAddressesSubCustomers,
    resetAddressesSubCustomersState,
  };
};
