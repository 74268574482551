import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { doc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { app } from "firebase/Chat/config";

import {
  AppBar,
  Badge,
  Button,
  Tab as MyTab,
  Tabs,
  Toolbar,
  styled,
  Typography,
  useMediaQuery,
  Box,
  Link,
} from "@mui/material";

import { removeAllTokens } from "helpers/auth";
import { deleteCurrentUser } from "redux/actions/auth";
import { makeStyles } from "@mui/styles";
import { CartIcon, PeopleIcon, RouteIcon } from "../Icons";
import { APP_ENVS, NAVIGATION_PATHS } from "utils/constants";
import {
  CatalogIcon,
  ChatIcon,
  CustomersIcon,
  DiscountsIcon,
  OrdersIcon,
  ReportsIcon,
  RepsIcon,
  DashboardIcon,
  DashboardIconFilled,
  OrdersIconFilled,
  CatalogIconFilled,
  DiscountsIconFilled,
  TaskIcon,
} from "../Icons/NavbarIcons/index.js";
import {
  openConfirmDialogAction,
  openDiscardChanges,
  setConfirmIsOpenAction,
  setConfirmPathAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import StyledButton from "../StyledButton";
import { NotificationsMenu } from "../NotificationsMenu/NotificationsMenu";
import {
  getNotificationReceivedCustomersAction,
  getNotificationsCountAction,
} from "redux/actions/order-directs";
import {
  selectedChildrensOfProductsAction,
  selectedProductsAction,
} from "redux/actions/products";
import generatedGitInfo from "gitInfo/generatedGitInfo.json";
import StyledTooltip from "../StyledTooltip";
import { bool, array, string, object, oneOfType } from "prop-types";
import { resetGlobalActions } from "redux/actions/customers";
import {
  resetGlobalOrdersActions,
  resetQuickFilterActions,
} from "redux/actions/orders";
import {
  resetGlobalCatalogActions,
  resetNewProductFilter,
} from "redux/actions/products";
import {
  resetGlobalRoutesActions,
  resetGlobalRepsActions,
} from "../../redux/actions/reps";
import { MainMenuBlock, OnboardingTooltip, TrialLine } from "./components";
import { MainLogoIcon } from "../Icons/MainLogos";
import { getEnvironment, useAdmin, useRepresentative } from "helpers/helpers";
import { usePersonalDetails } from "Pages/SettingsPage/components/SettingsTabs/PaymentsTab/components/PayoutsTab/PayoutsTab.hooks";
import { useNavbarComponentActions } from "./NavbarComponent.hooks";
import { useRepsPermissions } from "helpers/hooks";
import { ChangePasswordDialog } from "components";

const Tab = styled((props) => <MyTab {...props} />)(({ theme }) => ({
  color: "#A5A5A5",
  "&.Mui-selected .MuiBox-root": {
    color: theme.primary,
  },
}));

const setBadgeWidth = (count) => {
  if (!count) return "-14px";

  let countLength;

  if (typeof count === "string") {
    countLength = count.length;
  } else {
    countLength = count.toString().length;
  }

  if (countLength === 2) return "-15px";
  if (countLength === 3) return "-16px";
  if (countLength === 4) return "-18px";

  return "-14px";
};

const useStyles = makeStyles(() => ({
  badge: {
    "& > span": {
      fontSize: "8px",
      height: "11px",
      minWidth: "10px",
      width: "12px",
    },
  },

  simpleBadge: {
    "& .MuiBadge-badge": {
      right: ({ countOrders }) => setBadgeWidth(countOrders) || "-14px",
      border: "1px solid",
      borderRadius: "6px",
      padding: "1px 4px !important",
      height: "auto",
    },
  },

  tabIcon: {
    position: "absolute",
    bottom: 5,
    color: "#9C9C94",
  },

  tabLabelWrapper: { display: "flex", justifyContent: "center" },
}));

const NavbarComponent = () => {
  const isAdmin = useAdmin();
  const isRepresentative = useRepresentative();
  const repPermissions = useRepsPermissions();
  const renderRef = useRef(0);
  const menuBtnRef = useRef(null);
  const blockForNotificationMenuRef = useRef(null);
  const drafts = useSelector(({ drafts }) => drafts.cart);
  const chatState = useSelector(({ chat }) => chat);
  const unreadCount = chatState?.unreadCount ?? 0;
  const unreadGroupChatCount = chatState?.unreadGroupChatCount ?? 0;
  const currentEnv = getEnvironment();
  const isProdEnv = currentEnv === APP_ENVS.prod;

  const { currentUser } = useSelector(({ auth }) => auth || {});

  const {
    countOrders,
    id: currentUserId,
    name: businessName,
    baseUser,
    profilePhoto,
    distributorSubscription: subscription,
    subscriptionUsageStatus,
    backOfficeRepresentative,
    // portalAccess,
  } = currentUser || {};

  const preparedCountOrders = useMemo(() => {
    if (countOrders < 1000) return countOrders;
    if (countOrders >= 1000 && countOrders < 9999)
      return `${countOrders.toString()[0]}K`;

    if (countOrders >= 10000 && countOrders < 99999)
      return `${countOrders.toString()[0]}${countOrders.toString()[1]}K`;

    return ">99K";
  }, [countOrders]);

  const { allowedToAddCard, hasOrderDirectCompleted } = usePersonalDetails({
    isSetup: false,
  });

  const notificationTooltipData = useMemo(() => {
    if (!hasOrderDirectCompleted) {
      return {
        title: (
          <span style={{ whiteSpace: "pre-wrap", color: "#FFF" }}>
            {!allowedToAddCard &&
              "1. No bank account linked to your profile.\nAdd a "}
            {!allowedToAddCard &&
              (repPermissions && !repPermissions?.settings?.payments ? (
                "bank account"
              ) : (
                <Link
                  color="#fff"
                  fontWeight={700}
                  to="/settings"
                  state={{ tab: "Payments", paymentsTab: "Payouts" }}
                  component={NavLink}
                >
                  bank account
                </Link>
              ))}
            {!allowedToAddCard && " under the payment section.\n2."} Complete
            your{" "}
            {repPermissions ? (
              "order direct"
            ) : (
              <Link
                color="#FFF"
                fontWeight={allowedToAddCard ? 700 : 400}
                variant="inherit"
                to="/settings"
                state={{ tab: "Order Direct" }}
                underline={allowedToAddCard ? "always" : "none"}
                component={NavLink}
                onClick={(e) => {
                  if (!allowedToAddCard) e.preventDefault();
                }}
              >
                order direct
              </Link>
            )}{" "}
            store setup
          </span>
        ),
        listener: true,
      };
    }
    return { title: "", listener: false };
  }, [allowedToAddCard, hasOrderDirectCompleted, repPermissions]);

  const subscriptionIsCanceled = useSelector(
    ({ subscriptionDialogs }) => subscriptionDialogs.paymentFailedDialog.open
  );

  const trialEnded = useMemo(
    () => !subscription && subscriptionUsageStatus === "TRIAL_USED",
    [subscription, subscriptionUsageStatus]
  );

  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const notificationsCount = useSelector(
    ({ orderDirects }) => orderDirects.notificationsCount
  );
  const { pathname, state } = useLocation();

  const [activeTab, setActiveTab] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const openHeaderList = Boolean(anchorEl);
  const [nameOfCurrentHoveredMenuItem, setNameOfCurrentHoveredMenuItem] =
    useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firestore = getFirestore(app);
  const classes = useStyles({ countOrders: preparedCountOrders });

  const [anchorNotificationsMenu, setAnchorNotificationsMenu] = useState(null);
  const openNotificationsMenu = Boolean(anchorNotificationsMenu);

  const [notificationReceived, setNotificationReceived] = useState();

  useEffect(() => {
    const count = notificationReceived?.customers?.filter(
      (el) => !el?.read
    ).length;
    dispatch(getNotificationsCountAction(count));
  }, [dispatch, notificationReceived]);

  const fetchReceivedCount = useCallback(async () => {
    await onSnapshot(
      doc(firestore, "distributors-direct-statuses", currentUserId),
      (doc) => {
        setNotificationReceived(doc.data() || []);
        dispatch(
          getNotificationReceivedCustomersAction(doc.data()?.customers || [])
        );
      }
    );
  }, [currentUserId, dispatch, firestore]);

  useEffect(() => {
    if (isRepresentative) return;
    fetchReceivedCount();
  }, [fetchReceivedCount, isRepresentative]);

  const handleClickNotifications = (e) => {
    setAnchorNotificationsMenu(e);
  };

  const handleCloseNotifications = () => setAnchorNotificationsMenu(null);

  const handleUpdateReceivedCount = async (customerId) => {
    if (
      notificationReceived?.customers?.some((c) => c.customerId === customerId)
    ) {
      const uploadData = notificationReceived?.customers
        ?.filter((el) => !el?.read)
        ?.map((customer) => {
          return {
            ...customer,
            read: customer?.customerId === customerId,
          };
        });
      await setDoc(
        doc(firestore, "distributors-direct-statuses", currentUserId),
        { customers: uploadData }
      );
    }
  };

  useEffect(() => {
    if (renderRef.current > 1) {
      dispatch(resetQuickFilterActions());
      if (!state?.skipCustomerReset) dispatch(resetGlobalActions());
      dispatch(resetGlobalOrdersActions());
      if (activeTab !== "catalog") dispatch(resetGlobalCatalogActions());
      if (activeTab !== "catalog") dispatch(resetNewProductFilter());
      dispatch(resetGlobalRoutesActions());
      dispatch(resetGlobalRepsActions());
    } else renderRef.current += 1;
  }, [dispatch, activeTab, state?.withFilter, state?.skipCustomerReset]);

  useEffect(() => {
    setActiveTab(pathname.slice(1).split("/")[0]);
  }, [pathname]);

  const handleChangeActiveTab = (e, newTab) => {
    if (formChanged || e.metaKey || e.ctrlKey) return;
    setActiveTab(newTab.slice(1));
  };
  const handleClickLogoClient = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseLogoClient = () => setAnchorEl(null);

  const handleLogOut = useCallback(() => {
    removeAllTokens();
    localStorage.clear();
    dispatch(deleteCurrentUser());
    window.location.reload();
  }, [dispatch]);

  const handleConfirmChangesDialog = useCallback(
    (nav, state) => {
      const onSuccess =
        nav === "Sign Out" ? () => handleLogOut() : () => navigate(nav, state);
      dispatch(openDiscardChanges(onSuccess));
      dispatch(setConfirmPathAction(nav));
    },
    [dispatch, handleLogOut, navigate]
  );
  const handleConfirmCheckedItemsDialog = useCallback(
    (nav, state) => {
      dispatch(
        openConfirmDialogAction({
          path: nav,
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  if (nav === "Sign Out") return handleLogOut();
                  setActiveTab(nav);
                  dispatch(setConfirmIsOpenAction(false));
                  dispatch(setFormChangedAction(false));
                  dispatch(selectedProductsAction([]));
                  dispatch(selectedChildrensOfProductsAction([]));
                  navigate(nav, state);
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, handleLogOut, navigate, editType]
  );

  const {
    onClickSettingsMenuItem,
    onClickCustomersTab,
    onClickOrdersTab,
    onClickCatalogTab,
    onClickRepresentativesTab,
    onClickDiscountsTab,
    onClickTasksTab,
    disabledSettingsMenuItem,
    tabRestrictions,
  } = useNavbarComponentActions({
    handleCloseLogoClient,
    editType,
    handleConfirmCheckedItemsDialog,
    handleConfirmChangesDialog,
    setActiveTab,
    trialEnded,
    subscriptionIsCanceled,
    notificationTooltipData,
  });

  const matchesMinWidth1281 = useMediaQuery("(min-width:1281px)");

  const styleTab = {
    borderLeft: "1px solid #F4F6F9",
    p: 0,
    minWidth: matchesMinWidth1281 ? "104px" : "80px",
    width: matchesMinWidth1281 ? "104px" : "80px",
    fill: "none",
    stroke: "#a5a5a5",
    fontWeight: 400,
    gap: "3px",

    "&.Mui-selected": {
      fontWeight: 600,
      fill: "#47a06d",
      stroke: "#47a06d",
    },
  };
  const styleTabLabel = { fontSize: "10px" };

  const BoxWrapper = ({
    fullWidth,
    children,
    indicator,
    selectionFollowsFocus,
    textColor,
    ...props
  }) => {
    return <Box {...props}>{children}</Box>;
  };

  BoxWrapper.propTypes = {
    children: oneOfType([array, object]),
    fullWidth: bool,
    indicator: bool,
    selectionFollowsFocus: bool,
    textColor: string,
  };

  const CloneProps = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children, ...other } = props;
    return children(other);
  };

  const setValueTab = useMemo(() => {
    const index = NAVIGATION_PATHS.findIndex((path) => path === activeTab);
    return index > -1 ? index : false;
  }, [activeTab]);

  const calcRightPosition = useMemo(() => {
    const menuBtnWidth = menuBtnRef.current?.clientWidth;
    return menuBtnWidth + 140;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuBtnRef.current?.clientWidth]);

  return (
    <AppBar
      color="inherit"
      sx={{
        filter: "drop-shadow(0px 3px 4px rgba(238, 238, 238, 0.05))",
        position: "static",
        display: "relative",
        width: openNotificationsMenu ? "100vw" : "100%",
      }}
    >
      {subscription?.stripeSubscriptionStatus === "trialing" && <TrialLine />}
      <Box
        ref={blockForNotificationMenuRef}
        sx={{
          position: "absolute",
          bottom: 0,
          right: calcRightPosition,
        }}
      />
      <NotificationsMenu
        anchorEl={anchorNotificationsMenu}
        open={openNotificationsMenu}
        handleClose={handleCloseNotifications}
        handleUpdateReceivedCount={handleUpdateReceivedCount}
        handleOpenMenu={() => {
          handleClickNotifications(blockForNotificationMenuRef.current);
        }}
      />
      {openChangePassword && (
        <ChangePasswordDialog
          open={openChangePassword}
          onClose={() => setOpenChangePassword(false)}
        />
      )}
      <Toolbar
        sx={{
          backgroundColor: "white",
          paddingLeft: matchesMinWidth1281 ? "24px" : "8px !important",
        }}
      >
        <Box
          component={NavLink}
          to="/"
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey) return;
            e.preventDefault();
            if (formChanged) {
              if (editType === "checked_items")
                return handleConfirmCheckedItemsDialog("/");
              if (editType !== "checked_items")
                return handleConfirmChangesDialog("/");
            } else {
              navigate("/");
              setActiveTab("");
            }
          }}
          width="160px"
          sx={{ textDecoration: "none" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: matchesMinWidth1281 ? "160px" : "100px",
              gap: "8px",
              position: "relative",
            }}
          >
            <MainLogoIcon
              type={matchesMinWidth1281 ? "regular" : "rounded"}
              width={matchesMinWidth1281 ? 125 : 50}
              height={matchesMinWidth1281 ? 18 : 50}
            />
            {generatedGitInfo?.gitCommitHash && !isProdEnv && (
              <Typography
                fontSize="10px"
                position="absolute"
                sx={{ bottom: -18, left: 19, opacity: 0.5 }}
                color="black"
              >
                Build hash: {generatedGitInfo.gitCommitHash}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: matchesMinWidth1281 ? 2 : 0,
            display: "flex",
          }}
        >
          <Tabs
            value={
              setValueTab
              // NAVIGATION_PATHS.findIndex((path) => path === activeTab) > -1
              //   ? `/${activeTab}`
              //   : false
            }
            sx={{
              flexGrow: 1,
              "&  .MuiTabs-flexContainer": {
                justifyContent: "space-between",
              },
            }}
            onChange={handleChangeActiveTab}
            aria-label="tabs"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "none" } }}
          >
            {/* Dashboard */}
            <CloneProps>
              {(tabProps) => (
                <Tab
                  {...tabProps}
                  disabled={trialEnded || subscriptionIsCanceled}
                  sx={{
                    ...styleTab,
                    opacity: trialEnded || subscriptionIsCanceled ? 0.38 : 1,
                  }}
                  icon={
                    activeTab === NAVIGATION_PATHS[0] ? (
                      <DashboardIconFilled />
                    ) : (
                      <DashboardIcon />
                    )
                  }
                  label={<Box sx={styleTabLabel}>DASHBOARD</Box>}
                  component={NavLink}
                  to="/"
                  value="/"
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) return;

                    e.preventDefault();
                    if (formChanged) {
                      if (editType === "checked_items")
                        return handleConfirmCheckedItemsDialog("/");
                      if (editType !== "checked_items")
                        return handleConfirmChangesDialog("/");
                    } else {
                      navigate("/");
                    }
                  }}
                />
              )}
            </CloneProps>

            {/* Orders */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Orders"
                  ].disableHoverListener()}
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Orders"].disabledTab()}
                    sx={{
                      ...styleTab,
                      opacity: tabRestrictions["Orders"].disabledTab()
                        ? 0.38
                        : 1,
                    }}
                    icon={
                      <Badge
                        invisible={!countOrders}
                        className={classes.simpleBadge}
                        max={999}
                        badgeContent={
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontWeight: 600,
                              color: "#000000",
                              lineHeight: "1 !important",
                            }}
                          >
                            {preparedCountOrders}
                          </Typography>
                        }
                      >
                        {activeTab === NAVIGATION_PATHS[1] ? (
                          <OrdersIconFilled />
                        ) : (
                          <OrdersIcon />
                        )}
                      </Badge>
                    }
                    label={
                      <Box className={classes.tabLabelWrapper}>
                        <Box sx={styleTabLabel}>ORDERS</Box>
                      </Box>
                    }
                    component={NavLink}
                    to="/orders"
                    value="/orders"
                    onClick={onClickOrdersTab}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>

            {/* Catalog */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Catalog"
                  ].disableHoverListener()}
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Catalog"].disabledTab()}
                    sx={{
                      ...styleTab,
                      opacity: tabRestrictions["Catalog"].disabledTab()
                        ? 0.38
                        : 1,
                    }}
                    icon={
                      activeTab === NAVIGATION_PATHS[2] ? (
                        <CatalogIconFilled />
                      ) : (
                        <CatalogIcon />
                      )
                    }
                    label={
                      <Box className={classes.tabLabelWrapper}>
                        <Box sx={styleTabLabel}>CATALOG</Box>
                      </Box>
                    }
                    component={NavLink}
                    to="/catalog"
                    value="/catalog"
                    onClick={onClickCatalogTab}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>

            {/* Customers */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Customers"
                  ].disableHoverListener()}
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Customers"].disabledTab()}
                    sx={{
                      ...styleTab,
                      opacity: tabRestrictions["Customers"].disabledTab()
                        ? 0.38
                        : 1,
                    }}
                    icon={<CustomersIcon />}
                    label={
                      <Box className={classes.tabLabelWrapper}>
                        <Box sx={styleTabLabel}>CUSTOMERS</Box>
                      </Box>
                    }
                    component={NavLink}
                    to="/customers"
                    value="/customers"
                    onClick={onClickCustomersTab}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>

            {/* Representatives */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Representatives"
                  ].disableHoverListener()}
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Representatives"].disabledTab()}
                    sx={{
                      ...styleTab,
                      px: "8px",
                      opacity: tabRestrictions["Representatives"].disabledTab()
                        ? 0.38
                        : 1,
                    }}
                    icon={<RepsIcon />}
                    label={
                      <Box className={classes.tabLabelWrapper} mt="-2px">
                        <Box sx={styleTabLabel}>
                          {matchesMinWidth1281 ? "REPRESENTATIVES" : "REPS"}
                        </Box>
                      </Box>
                    }
                    component={NavLink}
                    to="/representatives"
                    value="/representatives"
                    onClick={onClickRepresentativesTab}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>

            {/* Discounts */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Discounts"
                  ].disableHoverListener()}
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Discounts"].disabledTab()}
                    sx={{
                      ...styleTab,
                      opacity: tabRestrictions["Discounts"].disabledTab()
                        ? 0.38
                        : 1,
                    }}
                    icon={
                      activeTab === NAVIGATION_PATHS[5] ? (
                        <DiscountsIconFilled />
                      ) : (
                        <DiscountsIcon />
                      )
                    }
                    label={<Box sx={styleTabLabel}>DISCOUNTS</Box>}
                    component={NavLink}
                    to="/discounts"
                    value="/discounts"
                    onClick={onClickDiscountsTab}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>

            {/* Tasks */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Tasks"
                  ].disableHoverListener()}
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Tasks"].disabledTab()}
                    sx={{
                      ...styleTab,
                      opacity: tabRestrictions["Tasks"].disabledTab()
                        ? 0.38
                        : 1,
                    }}
                    icon={
                      <TaskIcon fill="currentColor" width={20} height={20} />
                    }
                    label={<Box sx={styleTabLabel}>TASKS</Box>}
                    component={NavLink}
                    to="/tasks"
                    value="/tasks"
                    onClick={onClickTasksTab}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>

            {/* Routes */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Routes"
                  ].disableHoverListener()}
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Routes"].disabledTab()}
                    sx={{
                      ...styleTab,
                      opacity: tabRestrictions["Routes"].disabledTab()
                        ? 0.38
                        : 1,
                    }}
                    icon={
                      <Box height="17px">
                        <RouteIcon width="18px" height="20px" />
                      </Box>
                    }
                    label={<Box sx={styleTabLabel}>Routes</Box>}
                    component={NavLink}
                    to="/routes"
                    value="/routes"
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) return;
                      e.preventDefault();
                      if (formChanged) {
                        if (editType === "checked_items")
                          return handleConfirmCheckedItemsDialog("/routes");
                        handleConfirmChangesDialog("/routes");
                      } else {
                        navigate("/routes");
                      }
                    }}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>

            {/* Reports */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Reports"
                  ].disableHoverListener()}
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Reports"].disabledTab()}
                    sx={{
                      ...styleTab,
                      borderRight: "1px solid #F4F6F9",
                      opacity: tabRestrictions["Reports"].disabledTab()
                        ? 0.38
                        : 1,
                    }}
                    icon={<ReportsIcon />}
                    label={<Box sx={styleTabLabel}>REPORTS</Box>}
                    component={NavLink}
                    to="/reports"
                    value="/reports"
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) return;
                      e.preventDefault();
                      if (formChanged) {
                        if (editType === "checked_items")
                          return handleConfirmCheckedItemsDialog("/reports");
                        if (editType !== "checked_items")
                          return handleConfirmChangesDialog("/reports");
                      } else {
                        navigate("/reports");
                      }
                    }}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>

            {/* Cart */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Cart"
                  ].disableHoverListener()}
                  ml="auto"
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Cart"].disabledTab()}
                    to="/cart"
                    value="/cart"
                    sx={{
                      ...styleTab,
                      minWidth: 7.5,
                      width: "59px",
                      border: "none",
                      p: "12px 16px",
                      opacity: tabRestrictions["Cart"].disabledTab() ? 0.38 : 1,
                    }}
                    component={NavLink}
                    icon={
                      <CartIcon
                        number={drafts?.data?.productsData?.length || 0}
                        // loading={draftsLoading}
                      />
                    }
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) return;
                      e.preventDefault();
                      if (formChanged) {
                        if (editType === "checked_items")
                          return handleConfirmCheckedItemsDialog("/cart");
                        if (editType !== "checked_items")
                          return handleConfirmChangesDialog("/cart");
                      } else {
                        navigate("/cart");
                      }
                    }}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>

            <BoxWrapper display="flex" alignItems="center">
              <Box
                sx={{
                  border: "1px solid #C9C9C9",
                  height: "23px",
                  width: "1px",
                }}
              />
            </BoxWrapper>

            {/* Notifications */}
            <BoxWrapper display="flex" alignItems="center">
              <StyledTooltip
                placement="bottom"
                isDark={!hasOrderDirectCompleted}
                arrow
                title={notificationTooltipData?.title}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: { offset: [0, -20] },
                    },
                  ],
                }}
                disableHoverListener={tabRestrictions[
                  "Notifications"
                ].disableHoverListener()}
              >
                <Box display="flex">
                  <Tab
                    disabled={tabRestrictions["Notifications"].disabledTab()}
                    icon={
                      <Badge
                        className={classes.badge}
                        badgeContent={notificationsCount}
                        color="primary"
                      >
                        <PeopleIcon />
                      </Badge>
                    }
                    sx={{
                      minWidth: "59px",
                      width: "59px",
                      opacity:
                        isAdmin || trialEnded || subscriptionIsCanceled
                          ? 0.38
                          : 1,
                    }}
                    to="/notifications"
                    value="/notifications"
                    aria-controls={
                      openNotificationsMenu ? "notifications-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openNotificationsMenu ? "true" : undefined}
                    onClick={() => {
                      handleClickNotifications(
                        blockForNotificationMenuRef.current
                      );
                    }}
                  />
                </Box>
              </StyledTooltip>
            </BoxWrapper>

            {/* Chat */}
            <CloneProps>
              {(tabProps) => (
                <OnboardingTooltip
                  disableHoverListener={tabRestrictions[
                    "Chat"
                  ].disableHoverListener()}
                >
                  <Tab
                    {...tabProps}
                    disabled={tabRestrictions["Chat"].disabledTab()}
                    sx={{
                      ...styleTab,
                      minWidth: 7.5,
                      width: "59px",
                      border: "none",
                      p: "12px 16px",
                      opacity: tabRestrictions["Chat"].disabledTab() ? 0.38 : 1,
                    }}
                    icon={
                      <Badge
                        className={classes.badge}
                        badgeContent={unreadCount + unreadGroupChatCount}
                        color="primary"
                      >
                        <ChatIcon />
                      </Badge>
                    }
                    component={NavLink}
                    to="/conversations"
                    value="/conversations"
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) return;
                      e.preventDefault();
                      if (formChanged) {
                        if (editType === "checked_items")
                          return handleConfirmCheckedItemsDialog(
                            "/conversations"
                          );
                        if (editType !== "checked_items")
                          return handleConfirmChangesDialog("/conversations");
                      } else {
                        navigate("/conversations");
                      }
                    }}
                  />
                </OnboardingTooltip>
              )}
            </CloneProps>
          </Tabs>

          <MainMenuBlock
            businessName={
              backOfficeRepresentative
                ? backOfficeRepresentative?.name || ""
                : businessName || ""
            }
            businessEmail={
              backOfficeRepresentative
                ? backOfficeRepresentative?.baseUser?.email || ""
                : baseUser?.email || ""
            }
            profilePhoto={
              backOfficeRepresentative
                ? backOfficeRepresentative?.profilePhoto
                : profilePhoto
            }
            {...{
              menuBtnRef,
              openHeaderList,
              handleClickLogoClient,
              trialEnded,
              subscriptionIsCanceled,
              anchorEl,
              handleCloseLogoClient,
              disabledSettingsMenuItem,
              onClickSettingsMenuItem,
              setNameOfCurrentHoveredMenuItem,
              nameOfCurrentHoveredMenuItem,
              isAdmin,
              setOpenChangePassword,
              formChanged,
              editType,
              handleConfirmCheckedItemsDialog,
              handleConfirmChangesDialog,
              setActiveTab,
              handleLogOut,
            }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarComponent;
