import { useCallback, useState, useMemo, useEffect, useRef } from "react";
import { bool, func, object, string } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import moment from "moment";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { ReactComponent as CrossIcon } from "./icons/cross.svg";
import TabProfileComponent from "./TabsContent/TabProfileComponent.jsx";
import TabCustomersComponent from "./TabsContent/TabCustomersComponent/TabCustomersComponent.jsx";
import TabNotificationsComponent from "./TabsContent/TabNotificationsComponent/TabNotificationsComponent.jsx";
import TabPermissionsComponent from "./TabsContent/TabPermissionsComponent/TabPermissionsComponent";
import ReportComponent from "./TabsContent/ReportComponent";
import { customersByRepGetParams } from "redux/actions/customers";
import {
  createUserAction,
  deleteUserAction,
  endLoadingReps,
  startLoadingReps,
  transferDeleteRepsAction,
  updateUserAction,
} from "../../../../redux/actions/reps";
import ConfirmDialog from "./ConfirmDialog/ConfirmDialog";
import { ProfileButton } from "../../../../components/Buttons";
import {
  getCustomersByRepAction,
  transferCustomersAction,
} from "../../../../redux/actions/customers";
import {
  customersByRepCountSelect,
  customersByRepListSelector,
  customersShowInactiveByRepSelector,
} from "../../../../redux/selectors/customers";
import { getRepNotificationsService } from "../../../../services/notifications";
import { error, success } from "utils/notifications";
import { currentUserSelector } from "../../../../redux/selectors/auth";
import {
  MERCHANDISER_PERMISSIONS,
  SALES_PERMISSIONS,
  defaultValues,
} from "./TabsContent/TabProfile.constants";
import { validationSchema } from "./TabsContent/TabProfileComponent.validation";
import { defaultValuesNotifications } from "./TabsContent/TabNotificationsComponent/TabNotifications.constants";
import TransferPopup from "../../../../components/TransferPopup/TransferPopup";
import { setShowInactiveProducts } from "../../../../redux/actions/products";
import { AtentionIcon } from "../../../../components/Icons";
import { getFormattedDate, useAdmin } from "../../../../helpers/helpers";
import ValidationPopper from "../../../../components/ValidationPopper/ValidationPopper";
import {
  salesCountSelector,
  merchandisersCountSelector,
  thirdPartyRepsCountSelector,
  repsCurrentTabSelector,
} from "../../../../redux/selectors/reps";
import { useReps } from "../../Reps.hooks";
import { Loader } from "components";
import { isEqual } from "lodash";
import { useRepsPermissions } from "helpers/hooks";
import { getCurrentUser } from "helpers/auth";
import { setCurrentUser } from "redux/actions/auth";
import { initialCustomersState } from "redux/reducers/customers";
import { useProfileComponent } from "./useProfileComponent";
import {
  openDiscardChanges,
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import {
  confirmDialogFormChangedSelector,
  editTypeSelector,
} from "redux/selectors/confirmDialogs";

const selector = createSelector(
  customersByRepListSelector,
  customersShowInactiveByRepSelector,
  currentUserSelector,
  customersByRepCountSelect,
  salesCountSelector,
  merchandisersCountSelector,
  thirdPartyRepsCountSelector,
  repsCurrentTabSelector,
  confirmDialogFormChangedSelector,
  editTypeSelector,
  (
    customersList,
    showInactive,
    currentUser,
    customersCount,
    salesCount,
    merchandisersCount,
    thirdPartyCount,
    repsCurrentTab,
    formChanged,
    editType
  ) => ({
    customersList,
    showInactive,
    currentUser,
    customersCount,
    salesCount,
    merchandisersCount,
    thirdPartyCount,
    repsCurrentTab,
    formChanged,
    editType,
  })
);

export const ProfileComponent = ({
  profile,
  isOpenProfileDialog,
  handleCloseProfileDialog,
  currentMainTab,
  handleSuccess,
  handleError,
  skipNavigation = false,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    customersList,
    showInactive,
    currentUser,
    customersCount,
    salesCount,
    merchandisersCount,
    thirdPartyCount,
    repsCurrentTab,
    formChanged,
    editType,
  } = useSelector(selector);

  const [submitting, setSubmitting] = useState(false);

  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const [openReport, setOpenReport] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);
  const [selectedReps, setSelectedReps] = useState([]);
  const [checkedCustomers, setCheckedCustomers] = useState([]);
  const [allCustomersChecked, setAllCustomersChecked] = useState(false);
  const [customersListCounter, setCustomersListCounter] = useState(0);
  const [notifications, setNotifications] = useState(null);
  const [validationOpen, setValidationOpen] = useState(false);

  const [progressPhotoUpload, setProgressPhotoUpload] = useState(false);
  const [preparingPhoto, setPreparingPhoto] = useState(false);

  const saveRef = useRef(null);

  const { handleConfirmDialog } = useProfileComponent();

  const setRole = () => {
    if (profile?.role) return profile?.role ?? "";

    if (repsCurrentTab === "All") return "";
    if (repsCurrentTab === "Sales") return "SALES";
    if (repsCurrentTab === "Merchandisers") return "MERCHANDISER";
    if (repsCurrentTab === "3rd Party") return "THIRD_PARTY";

    return "";
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
    trigger,
    clearErrors,
    setError,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      id: profile?.id || "",
      email: profile?.email || profile?.baseUser?.email || defaultValues.email,
      name: profile?.name || defaultValues.name,
      phone: profile?.phone || defaultValues.phone,
      customId: profile?.customId?.customId || "",
      role: setRole(),
      active:
        profile?.active !== undefined ? profile?.active : defaultValues.active,
      profilePhoto: profile?.profilePhoto || null,
      groupContentId: "",
      notificationSettings: { ...defaultValuesNotifications },
      portalAccess: profile?.portalAccess || false,
      permissions:
        (Array.isArray(profile?.permissions) &&
          profile.permissions.find((p) => p?.role === profile?.role)) ||
        [],
    },
    reValidateMode: "onSubmit",
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const preventCloseProfileDialog = useCallback(
    ({ onClose }) => {
      // eslint-disable-next-line no-console
      if (!onClose) return console.error("onClose is a required parameter");

      if (editType === "reps_profile" && formChanged)
        return dispatch(openDiscardChanges(() => onClose()));

      onClose();
    },
    [dispatch, editType, formChanged]
  );

  const isDisable = useMemo(() => {
    if (submitting) return true;

    if (formField?.role === "THIRD_PARTY") {
      return (
        !formField?.name ||
        !formField?.email ||
        errors?.name ||
        errors?.email ||
        errors?.phone
      );
    }

    return (
      !formField?.name ||
      !formField?.role ||
      !formField?.phone ||
      !formField?.email ||
      !!errors?.name ||
      !!errors?.role ||
      !!errors?.phone ||
      !!errors?.email ||
      !!errors?.notificationSettings
    );
  }, [
    submitting,
    formField,
    errors?.name,
    errors?.role,
    errors?.phone,
    errors?.email,
    errors?.notificationSettings,
  ]);

  useEffect(() => {
    setCustomersListCounter(customersList?.length);
  }, [customersList]);

  useEffect(() => {
    if (formField?.notificationSettings?.type === "INDIVIDUAL") {
      if (
        formField?.notificationSettings?.clockInAt &&
        formField?.notificationSettings?.clockIn
      )
        clearErrors("notificationSettings.clockInAt");
      if (
        formField?.notificationSettings?.clockOutAt &&
        formField?.notificationSettings?.clockOut
      )
        clearErrors("notificationSettings.clockOutAt");
      if (formField?.notificationSettings?.days?.length)
        clearErrors("notificationSettings.days");
    }
    if (formField?.name) clearErrors("name");
    if (formField?.customId) clearErrors("customId");
    if (formField?.role) clearErrors("role");
    if (formField?.phone) clearErrors("phone");
    if (formField?.email) clearErrors("email");
  }, [
    clearErrors,
    formField?.customId,
    formField?.email,
    formField?.name,
    formField?.notificationSettings?.clockIn,
    formField?.notificationSettings?.clockInAt,
    formField?.notificationSettings?.clockOut,
    formField?.notificationSettings?.clockOutAt,
    formField?.notificationSettings?.days?.length,
    formField?.notificationSettings?.type,
    formField?.phone,
    formField?.role,
  ]);

  useEffect(() => {
    if (isOpenProfileDialog) dispatch(setEditTypeAction("reps_profile"));

    setCustomersListCounter(0);

    reset({
      ...formField,
      id: profile?.id || "",
      email: profile?.email || profile?.baseUser?.email || defaultValues.email,
      name: profile?.name || defaultValues.name,
      phone: profile?.phone || defaultValues.phone,
      customId: profile?.customId?.customId || "",
      role: setRole(),
      active:
        profile?.active !== undefined ? profile?.active : defaultValues.active,
      profilePhoto: profile?.profilePhoto || null,
      groupContentId: "",
      notificationSettings: { ...defaultValuesNotifications },
      portalAccess: profile?.portalAccess || false,
      permissions:
        (Array.isArray(profile?.permissions) &&
          profile.permissions.find((p) => p?.role === profile?.role)) ||
        [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenProfileDialog]);

  const tabNames = useMemo(() => {
    if ([formField?.role, profile?.role].includes("THIRD_PARTY")) {
      return ["Profile", "Customers", "Permissions"];
    }

    // if (formField?.portalAccess) {
    //   return ["Profile", "Customers", "Notifications", "Permissions"];
    // }

    return ["Profile", "Customers", "Notifications", "Permissions"];
  }, [formField?.role, profile?.role]);

  const [currentTab, setCurrentTab] = useState(tabNames[0]);

  const hasProfileTabError = useMemo(() => {
    return !!(
      errors?.email ||
      errors?.name ||
      errors?.phone ||
      errors?.role ||
      errors?.customId
    );
  }, [
    errors?.customId,
    errors?.email,
    errors?.name,
    errors?.phone,
    errors?.role,
  ]);

  const hasNotificationsTabError = useMemo(() => {
    const { notificationSettings } = errors;
    return !!notificationSettings;
  }, [errors]);

  const setAttentionIcon = useCallback(
    (tab) => {
      if (
        (hasProfileTabError && tab === tabNames[0]) ||
        (hasNotificationsTabError && tab === tabNames[2])
      )
        return <AtentionIcon />;
      return null;
    },
    [hasNotificationsTabError, hasProfileTabError, tabNames]
  );

  useEffect(() => {
    setSelectedReps([]);
    setAllCustomersChecked(false);
    setCheckedCustomers([]);
  }, [showInactive]);

  const checkAllCustomers = () => {
    setAllCustomersChecked(!allCustomersChecked);
    if (checkedCustomers.length === customersList.length) {
      setAllCustomersChecked(false);
      return setCheckedCustomers([]);
    }
    setAllCustomersChecked(true);
    setCheckedCustomers([...customersList]);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
  };

  const handleCheckCustomer = (customer) => {
    const repIndex = checkedCustomers.findIndex(
      (item) => item.id === customer.id
    );
    if (repIndex > -1) {
      const newCustomers = [...checkedCustomers];
      newCustomers.splice(repIndex, 1);
      return setCheckedCustomers([...newCustomers]);
    }
    setCheckedCustomers([...checkedCustomers, customer]);
  };

  useEffect(() => {
    if (
      customersList.length &&
      checkedCustomers.length === customersList.length
    ) {
      return setAllCustomersChecked(true);
    }
    setAllCustomersChecked(false);
  }, [checkedCustomers, customersList]);

  useEffect(() => {
    if (!isOpenProfileDialog) {
      dispatch(setEditTypeAction(""));
      setSelectedReps([]);
      setCheckedCustomers([]);
      setIsTransfer(false);
      setCurrentTab(tabNames[0]);
    }
  }, [dispatch, isOpenProfileDialog, tabNames]);

  // must wrap in useCallback to prevent rerender in Filter Search useEffect
  const handleSelectRep = useCallback((representatives) => {
    setSelectedReps(representatives);
  }, []);

  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));
  }, [isDirty, dispatch]);

  useEffect(() => {
    if (profile?.id) {
      dispatch(getCustomersByRepAction(profile.id));
      if (profile?.role !== "THIRD_PARTY")
        getRepNotificationsService(profile.id)
          .then((res) => {
            const { id, distributorId, role, ...notificationSettings } = res;

            notificationSettings.clockInAt = notificationSettings.clockInAt
              ? moment(notificationSettings.clockInAt).toISOString()
              : "";
            notificationSettings.clockOutAt = notificationSettings.clockOutAt
              ? moment(notificationSettings.clockOutAt).toISOString()
              : "";
            reset({
              id: profile?.id || "",
              email: profile?.baseUser?.email || defaultValues.email,
              name: profile?.name || defaultValues.name,
              phone: profile?.phone || defaultValues.phone,
              customId: profile?.customId?.customId || "",
              role: profile?.role || defaultValues.role,
              active:
                profile?.active !== undefined
                  ? profile?.active
                  : defaultValues.active,
              profilePhoto: profile?.profilePhoto || null,
              notificationSettings,
              notificationSettingsBeforeChange: notificationSettings,
              portalAccess: profile?.portalAccess || false,
              permissions:
                (Array.isArray(profile?.permissions) &&
                  profile.permissions.find((p) => p?.role === profile?.role)) ||
                [],
            });
            setNotifications(res);
          })
          .catch(() => error("Something went wrong."));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id, dispatch, reset]);

  const handleNavigateAfterSave = useCallback(() => {
    if (profile?.showCustomersTub || skipNavigation) return;
    navigate(state?.type === "onboarding" ? "/" : "/representatives");
  }, [navigate, profile?.showCustomersTub, state?.type, skipNavigation]);

  const onSubmit = useCallback(
    (data) => {
      setSubmitting(true);

      let {
        profilePhoto,
        id,
        notificationsType,
        phone,
        groupContentId,
        name,
        permissions,
        // portalAccess,
        notificationSettingsBeforeChange,
        ...uploadData
      } = data;

      const containsSpecialChars = (str) => {
        const regex = /[:\t\n]/;

        return regex.test(str);
      };

      const trimmedName = name.trim();

      uploadData.name = trimmedName;

      const containsSpecial = containsSpecialChars(trimmedName);

      if (containsSpecial) {
        setError("name", {
          type: "character",
          message: "Invalid character used",
        });
        return;
      }

      const notificationSettings =
        data.notificationSettings.type === "COMPANY"
          ? { type: "COMPANY" }
          : data.notificationSettings;

      if (
        data.role === "THIRD_PARTY" &&
        (phone === "+1" || phone === "+1 (___) ___ ____")
      )
        phone = "";

      uploadData = {
        ...uploadData,
        phone: phone.replace(/\s|\(|\)/g, ""),
        notificationSettings:
          data.role !== "THIRD_PARTY" ? notificationSettings : undefined,
      };

      if (groupContentId) uploadData.groupContentId = groupContentId;

      const refreshDate = (date) => {
        const originalDate = new Date(date);
        const currentDate = new Date();

        currentDate.setUTCHours(
          originalDate.getUTCHours(),
          originalDate.getUTCMinutes(),
          originalDate.getUTCSeconds(),
          originalDate.getUTCMilliseconds()
        );

        return currentDate.toISOString();
      };

      if (id) {
        const currentClockInAt = uploadData?.notificationSettings?.clockInAt;
        const isSameCurrentClockInAt = isEqual(
          currentClockInAt,
          notificationSettingsBeforeChange?.clockInAt
        );

        const currentClockOutAt = uploadData?.notificationSettings?.clockOutAt;
        const isSameCurrentClockOutAt = isEqual(
          currentClockOutAt,
          notificationSettingsBeforeChange?.clockOutAt
        );

        if (
          currentClockInAt &&
          (!isSameCurrentClockInAt || !isSameCurrentClockOutAt)
        ) {
          const clockInAt = refreshDate(currentClockInAt);
          uploadData.notificationSettings.clockInAt = clockInAt;
        }

        if (
          currentClockOutAt &&
          (!isSameCurrentClockInAt || !isSameCurrentClockOutAt)
        ) {
          const clockOutAt = refreshDate(currentClockOutAt);
          uploadData.notificationSettings.clockOutAt = clockOutAt;
        }
      }

      delete permissions.id;
      delete permissions.representativeId;
      delete permissions.hasAnyCheckedTrue;

      // const checkHasAnyCheckedTrue = (permissions) => {
      //   const temp = { ...permissions };
      //   delete temp.role;

      //   return Object.keys(temp).some((key) => {
      //     const values = Object.values(temp[key]);
      //     return values.some(Boolean);
      //   });
      // };

      // const hasAnyCheckedTrue = checkHasAnyCheckedTrue(permissions);

      // if (portalAccess && hasAnyCheckedTrue) {
      //   uploadData.portalAccess = true;
      // } else {
      //   uploadData.portalAccess = false;
      // }

      if (uploadData.role !== "THIRD_PARTY")
        uploadData.permissions = permissions;

      if (profile?.id && !profile?.showCustomersTub) {
        dispatch(
          updateUserAction({
            data: uploadData,
            id: profile?.id,
            onSuccess: (res) => {
              setSubmitting(false);

              handleSuccess
                ? handleSuccess(res)
                : success("Representative updated");
            },
            onError: (err) => {
              setSubmitting(false);
              return handleError ? handleError(err) : null;
            },
          })
        );
      } else {
        // ! this "dispatch" disables the button while creation is in progress
        dispatch(startLoadingReps());

        dispatch(
          createUserAction(
            uploadData,
            handleNavigateAfterSave,
            () => {
              if (profile?.onSuccess) {
                setSubmitting(false);
                profile?.onSuccess();
              }
              if (state?.type === "onboarding") {
                getCurrentUser({
                  setCurrentUser: (user) => {
                    dispatch(setCurrentUser(user));
                  },
                  onSuccess: () => {
                    setSubmitting(false);
                    dispatch(endLoadingReps());
                  },
                });
              }
            },
            () => {
              setSubmitting(false);
              dispatch(endLoadingReps());
            }
          )
        );
      }

      dispatch(setShowInactiveProducts(false));

      dispatch(setFormChangedAction(false));

      handleCloseProfileDialog
        ? handleCloseProfileDialog({ data: { ...uploadData, id: profile.id } })
        : () => navigate(-1);
    },
    [
      profile,
      dispatch,
      handleCloseProfileDialog,
      setError,
      handleError,
      handleSuccess,
      handleNavigateAfterSave,
      state?.type,
      navigate,
    ]
  );

  const handleRemovePhoto = () => {
    setValue("groupContentId", "");
    setValue("profilePhoto", null);
    setValue("profilePhotoId", null);
  };

  const renderContent = (tab, names) => {
    switch (tab) {
      case names[0]:
        return (
          <TabProfileComponent
            profile={profile}
            timeZone={currentUser?.timeZone}
            control={control}
            setValue={setValue}
            currentTab={currentMainTab}
            handleRemovePhoto={handleRemovePhoto}
            {...{
              setProgressPhotoUpload,
              preparingPhoto,
              setPreparingPhoto,
              repPermissions,
              handleConfirmDialog,
            }}
          />
        );
      case names[1]:
        return (
          <TabCustomersComponent
            profile={profile}
            isTransfer={isTransfer}
            setSelectedReps={setSelectedReps}
            selectedReps={selectedReps}
            selectedMerch={[]}
            selectedSales={[]}
            handleSelectRep={handleSelectRep}
            handleCheckCustomer={handleCheckCustomer}
            checkedCustomers={checkedCustomers}
            checkAllCustomers={checkAllCustomers}
            setCheckedCustomers={setCheckedCustomers}
            allCustomersChecked={allCustomersChecked}
            profileRole={profile?.role}
            customersCount={customersCount}
          />
        );
      case names[2]:
        return (
          <TabNotificationsComponent
            notifications={notifications}
            setNotifications={setNotifications}
            repId={profile?.id}
            control={control}
            setValue={setValue}
            timeZone={currentUser?.timeZone}
            // handleSubmit={handleSubmit}
          />
        );
      case names[3]:
        return (
          <TabPermissionsComponent
            control={control}
            setValue={setValue}
            formField={formField}
          />
        );
    }
  };

  const { deleteRepsWithStatus } = useReps();

  const handleDeleteUser = useCallback(async () => {
    await deleteRepsWithStatus({
      reps: [profile],
      deleteAction: (ids) => dispatch(deleteUserAction(ids)),
    });
    setConfirmIsOpen(false);
    preventCloseProfileDialog({ onClose: () => handleCloseProfileDialog() });
  }, [
    deleteRepsWithStatus,
    profile,
    preventCloseProfileDialog,
    dispatch,
    handleCloseProfileDialog,
  ]);

  const handleTransferCustomers = useCallback(() => {
    const customerIds = checkedCustomers.map((customer) => customer.id);
    dispatch(
      transferCustomersAction({
        oldRepresentativeId: profile?.id,
        newRepresentativesIds:
          selectedReps.length > 1
            ? selectedReps.map((r) => r.id)
            : [selectedReps[0]?.id],
        customerIds,
      })
    );
    setIsTransfer(false);
    setSelectedReps([]);
    setCheckedCustomers([]);
  }, [profile?.id, selectedReps, checkedCustomers, dispatch]);

  const handleDeleteClick = useCallback(() => {
    const sales = profile?.role === "SALES" && salesCount === 1;
    const merch = profile?.role === "MERCHANDISER" && merchandisersCount === 1;
    const thirdParty = profile?.role === "THIRD_PARTY" && thirdPartyCount === 1;

    if (
      !sales &&
      !merch &&
      !thirdParty &&
      profile?._count?.assignedCustomers > 0
    )
      return setTransferOpen(true);
    setConfirmIsOpen(true);
  }, [merchandisersCount, profile, salesCount, thirdPartyCount]);

  const profileButtons = useMemo(() => {
    if (currentTab === tabNames[2])
      return (
        <>
          <ProfileButton
            sx={{
              ml: "20px",
              height: "37px",
              width: "98px",
              color: "#6A6A6A",
              borderColor: "#D5D9D9",
            }}
            variant="outlined"
            onClick={() =>
              preventCloseProfileDialog({
                onClose: () => handleCloseProfileDialog(),
              })
            }
            label={<Typography fontSize="15px">Cancel</Typography>}
          />
          <Box
            onMouseEnter={() => {
              setValidationOpen(true);
              if (trigger) trigger();
            }}
            component="span"
          >
            <ProfileButton
              disabled={isAdmin || isDisable}
              sx={{ ml: "20px", height: "36px", width: "105px" }}
              variant="contained"
              label={<Typography fontSize="15px">Save</Typography>}
              type="submit"
              form="rep-profile-form"
            />
          </Box>
        </>
      );
    if (isTransfer)
      return (
        <>
          <ProfileButton
            disabled={isAdmin}
            sx={{
              ml: "20px",
              height: "37px",
              color: "#6A6A6A",
              borderColor: "#D5D9D9",
            }}
            variant="outlined"
            onClick={() => {
              if (isAdmin) return;
              setIsTransfer(false);
            }}
            label={<Typography fontSize="15px">Cancel</Typography>}
          />
          <ProfileButton
            sx={{ ml: "20px", height: "37px" }}
            variant="contained"
            disabled={isAdmin || !selectedReps.length}
            onClick={() => {
              if (isAdmin) return;
              handleTransferCustomers();
            }}
            label={<Typography fontSize="15px">Confirm transfer</Typography>}
          />
        </>
      );
    if (currentTab === tabNames[1])
      return (
        <>
          <div />
          <ProfileButton
            sx={{ ml: "20px", height: "36px", width: "105px" }}
            variant="contained"
            disabled={isAdmin || !checkedCustomers.length}
            onClick={() => setIsTransfer(true)}
            label={<Typography fontSize="15px">Transfer</Typography>}
          />
        </>
      );
    return (
      <>
        {currentTab === tabNames[0] ? (
          <ProfileButton
            sx={{ width: "105px", height: "36px" }}
            disabled={isAdmin || !profile}
            variant="outlined"
            color="delete"
            label="Delete"
            onClick={handleDeleteClick}
          />
        ) : (
          <Box />
        )}

        <Box
          onMouseEnter={() => {
            setValidationOpen(true);
            if (trigger) trigger();
          }}
          component="span"
        >
          <ProfileButton
            disabled={isAdmin || isDisable}
            sx={{ width: "105px", height: "36px" }}
            variant="contained"
            label="Save"
            type="submit"
            form="rep-profile-form"
            onClick={() => setValidationOpen(true)}
          />
        </Box>
      </>
    );
  }, [
    currentTab,
    tabNames,
    preventCloseProfileDialog,
    isAdmin,
    isDisable,
    isTransfer,
    selectedReps.length,
    checkedCustomers.length,
    profile,
    handleDeleteClick,
    handleCloseProfileDialog,
    trigger,
    handleTransferCustomers,
  ]);

  const handleConfirmDeleteTransfer = async (sales, merch, thirdParty) => {
    const salesIds = sales.map((s) => s.id);
    const merchIds = merch.map((m) => m.id);
    const thirdPartyIds = thirdParty.map((m) => m.id);

    await handleDeleteUser();

    deleteRepsWithStatus({
      reps: [profile],
      deleteAction: (oldRepresentativeIds) =>
        dispatch(
          transferDeleteRepsAction(
            {
              oldRepresentativeIds,
              newRepresentativeIds: [
                ...salesIds,
                ...merchIds,
                ...thirdPartyIds,
              ],
            },
            !showInactive,
            () => {
              setTransferOpen(false);
              preventCloseProfileDialog({
                onClose: () => handleCloseProfileDialog(),
              });
            }
          )
        ),
    });
  };

  useEffect(() => {
    if (formField?.role === "THIRD_PARTY" || !formField?.role) {
      setValue("permissions", []);
      setValue("portalAccess", false);
      return;
    }

    if (formField?.role === "SALES") {
      setValue("portalAccess", false);
      setValue("permissions", SALES_PERMISSIONS);
      return;
    }

    if (formField?.role === "MERCHANDISER") {
      setValue("portalAccess", false);
      setValue("permissions", MERCHANDISER_PERMISSIONS);
      return;
    }

    setValue("permissions", []);
  }, [formField?.role, setValue]);

  const onTabChange = (event, newValue) => {
    if (currentTab === "Customers")
      dispatch(
        customersByRepGetParams(initialCustomersState.customersByRepGetParams)
      );

    setCurrentTab(newValue);
  };

  return (
    <>
      <Loader isLoading={progressPhotoUpload || preparingPhoto} />
      <Dialog
        maxWidth="lg"
        open={isOpenProfileDialog}
        onClose={() => {
          return preventCloseProfileDialog({
            onClose: handleCloseProfileDialog
              ? () => handleCloseProfileDialog()
              : () => navigate(-1),
          });
        }}
        scroll="body"
      >
        {!isAdmin && (
          <ValidationPopper
            disablePortal
            isOpen={validationOpen}
            anchorEl={saveRef.current}
            errors={errors}
            setIsOpen={setValidationOpen}
            placement="top-start"
            offset={[0, -10]}
            tailProps={{ rotate: -360, top: "auto", bottom: -6, right: 15 }}
            modifiers={[
              {
                name: "flip",
                options: {
                  enabled: false,
                },
              },
              {
                name: "offset",
                options: {
                  offset: [-66, -10],
                },
              },
              {
                name: "preventOverflow",
                options: {
                  enabled: false,
                },
              },
            ]}
            style={{ zIndex: 1 }}
          />
        )}
        <DialogContent
          sx={{
            borderBottom: "1px solid #D4D4D4",
            minWidth: "980px",
            p: 0,
          }}
        >
          <ReportComponent
            isOpenReport={openReport}
            handleCloseReport={handleCloseReport}
          />
          <ConfirmDialog
            isOpen={confirmIsOpen}
            confirmColor="confirmDelete"
            title="Delete employee?"
            text="Are you sure you want to delete this employee? Once you delete it cannot be undone."
            onCancel={() => setConfirmIsOpen(false)}
            onAccept={handleDeleteUser}
          />
          <TransferPopup
            checkedCustomersArr={[]}
            isOpen={transferOpen}
            handleClose={() => setTransferOpen(false)}
            checkedReps={profile ? [profile] : []}
            handleConfirmTransfer={handleConfirmDeleteTransfer}
            deletingReps
          />
          <Grid sx={{ p: "26px" }} container justifyContent="space-between">
            <Grid item display="flex" alignItems="center">
              {isTransfer ? (
                <>
                  <IconButton
                    onClick={() => setIsTransfer(false)}
                    sx={{ pl: 0 }}
                  >
                    <ArrowBackIosRoundedIcon sx={{ fill: "#B5B5AC" }} />
                  </IconButton>
                  <Typography
                    variant="h6"
                    fontWeight="400"
                    fontSize="30px"
                    color="#707070"
                  >
                    Transfer
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    fontWeight="400"
                    fontSize="30px"
                    color="#707070"
                  >
                    Representative Profile
                  </Typography>

                  {/* <ReportButton
                  sx={{ ml: "20px" }}
                  variant="outlined"
                  color="groundLighter"
                  label={"Report"}
                  onClick={() => setOpenReport(true)}
                /> */}
                </>
              )}
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  if (handleCloseProfileDialog)
                    return preventCloseProfileDialog({
                      onClose: () => handleCloseProfileDialog(),
                    });

                  return preventCloseProfileDialog({
                    onClose: () => navigate("/representatives"),
                  });
                }}
              >
                <CrossIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {!isTransfer && (
              <Tabs value={currentTab} onChange={onTabChange}>
                {tabNames.map((tab) => {
                  const setDisabledTab = (tab) => {
                    if (profile?.showCustomersTub && tab === "Customers")
                      return false;

                    if (
                      (!profile && tab === "Customers") ||
                      (!customersListCounter &&
                        tab === "Customers" &&
                        currentTab !== "Customers")
                    )
                      return true;

                    if (
                      tab === "Permissions" &&
                      (formField?.role === "" ||
                        formField?.role === "THIRD_PARTY")
                    )
                      return true;

                    return false;
                  };

                  const isDisabledTab = setDisabledTab(tab);

                  return (
                    <Tab
                      className="profile-tabs"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "20px",
                        fontWeight: "400",
                        px: "26px",
                        color: "#707070",
                      }}
                      disabled={isDisabledTab}
                      key={tab}
                      label={
                        <Box>
                          {setAttentionIcon(tab)} {tab}
                        </Box>
                      }
                      value={tab}
                    />
                  );
                })}
              </Tabs>
            )}
            {currentMainTab === "3rd Party" ? (
              <Box px="34px" pb="5px">
                {profile?.updatedAt && (
                  <Typography fontWeight="400" fontSize="14px" color="#B7B7B7">
                    Updated{" "}
                    {moment(
                      getFormattedDate(
                        profile?.updatedAt,
                        currentUser?.timeZone
                      )
                    ).format("MMM DD YYYY")}
                  </Typography>
                )}
                {profile?.createdAt && (
                  <Typography fontWeight="400" fontSize="14px" color="#B7B7B7">
                    Created{" "}
                    {moment(
                      getFormattedDate(
                        profile?.createdAt,
                        currentUser?.timeZone
                      )
                    ).format("MMM DD YYYY")}
                  </Typography>
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </DialogContent>

        <DialogContent
          sx={{
            p: 0,
            maxWidth: "980px",
            minHeight: "465px",
            overflow: "hidden",
          }}
        >
          <form id="rep-profile-form" onSubmit={handleSubmit(onSubmit)}>
            {renderContent(currentTab, tabNames)}
          </form>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent:
              isTransfer || currentTab === tabNames[2]
                ? "flex-end"
                : "space-between",
            p: "15px 36px 26px 60px",
            height: "102px",
          }}
          ref={saveRef}
        >
          {profileButtons}
        </DialogActions>
      </Dialog>
    </>
  );
};

ProfileComponent.propTypes = {
  profile: object,
  isOpenProfileDialog: bool,
  handleCloseProfileDialog: func,
  currentMainTab: string,
  handleSuccess: func,
  handleError: func,
  skipNavigation: bool,
};

ProfileComponent.defaultProps = {
  profile: null,
  isOpenProfileDialog: false,
};

export default ProfileComponent;
