import { array, func, bool, object } from "prop-types";
import { Box } from "@mui/material";
import { RepBlock, RepsBlock } from "./components";

export const RepsAssignedDrawerBody = ({
  list,
  selectedReps,
  availableReps,
  handleCheckboxChange,
  hasCustomerPendingOrder,
  singleAddRep,
  checkedRep,
  handleCheckRep,
  loading,
}) => {
  return (
    <Box overflow="auto" flexGrow={1} width="544px" maxWidth="544px">
      {singleAddRep && (
        <RepBlock
          list={list}
          checkedRep={checkedRep}
          handleCheckRep={handleCheckRep}
          loading={loading}
        />
      )}

      {!singleAddRep && (
        <RepsBlock
          title="Selected"
          list={selectedReps}
          isSeparateDot
          loading={loading}
          handleCheckboxChange={handleCheckboxChange}
          hasCustomerPendingOrder={hasCustomerPendingOrder}
        />
      )}

      {!singleAddRep && !!availableReps?.length && (
        <Box borderTop="0.5px solid #D5D9D9">
          <RepsBlock
            title="Available"
            list={availableReps}
            handleCheckboxChange={handleCheckboxChange}
          />
        </Box>
      )}
    </Box>
  );
};

RepsAssignedDrawerBody.propTypes = {
  list: array,
  selectedReps: array,
  availableReps: array,
  handleCheckboxChange: func,
  hasCustomerPendingOrder: bool,
  singleAddRep: bool,
  checkedRep: object,
  handleCheckRep: func,
  loading: bool,
};
RepsAssignedDrawerBody.defaultProps = {
  list: [],
  selectedReps: [],
  availableReps: [],
  handleCheckboxChange: () => {},
  hasCustomerPendingOrder: false,
  singleAddRep: false,
  checkedRep: {},
  handleCheckRep: () => {},
  loading: false,
};
