import {
  CREATE_USER,
  GET_USERS,
  START_LOADING_REPS,
  END_LOADING_REPS,
  UPDATE_USER,
  DELETE_USER,
  GET_USERS_INACTIVE,
  SHOW_INACTIVE_REPS,
  GET_REPS_COUNT,
  GET_SALES_COUNT,
  GET_REP_PARAMS,
  GET_REPS_COUNT_INACTIVE,
  GET_SALES_REPS,
  RESET_SALES_REPS,
  GET_MERCHANDISERS_COUNT,
  EXIST_REPS_ACTIVE,
  EXIST_REPS_INACTIVE,
  GET_THIRD_PARTY_REPS,
  GET_THIRD_REPS_COUNT,
  GET_MERCHANDISERS_REPS,
  SET_CURRENT_TAB,
  GET_SUMMARIES,
  START_LOADING_REPS_TOTAL_SUMMERIES,
  END_LOADING_REPS_TOTAL_SUMMERIES,
  REPS_GET_FILTER_FIELD,
  REPS_GET_SWITCH,
  RESET_REPS_ACTION_FILTER,
  RESET_REPS_ACTION_SWITCH,
  ROUTES_GET_FILTER_FIELD,
  ROUTES_GET_SWITCH,
  RESET_ROUTES_ACTION_FILTER,
  RESET_ROUTES_ACTION_SWITCH,
  ROUTES_QUICK_FILTER,
  RESET_ROUTES_QUICK_FILTER,
} from "../actions/reps";

export const DEFAULT_REPS_SWITCHES = [
  {
    value: "activity",
    label: "Latest Activity",
    type: "select",
    checked: false,
    selectLabel: "Select Latest Activity",
  },
  {
    value: "territoryId",
    label: "Territory",
    type: "select",
    checked: false,
    selectLabel: "Select Territory",
  },
  {
    value: "work_status",
    label: "Work status",
    type: "switch",
    checked: false,
    values: ["working", "not_working"],
  },
];

export const DEFAULT_REPS_FILTER = {
  activity: "",
  territoryId: "",
  work_status: "",
};

const initialState = {
  reps: [],
  inactive: [],
  salesReps: [],
  thirdPartyReps: [],
  thirdPartyCount: 0,
  repsFilter: DEFAULT_REPS_FILTER,
  repsFilterSwitches: DEFAULT_REPS_SWITCHES,
  routesQuickFilter: {
    name: "",
    value: "",
  },
  routesFilter: {
    representative_id: "",
    priority: "",
  },
  routesSwitch: [
    {
      value: "total_stops",
      label: "Total Stops",
      type: "textfields",
      checked: false,
      options: [
        {
          id: "total_stops_greater",
          label: "More than",
          prefix: ">",
          placeholder: "0",
          type: "number",
        },
        {
          id: "total_stops_lesser",
          label: "Less than",
          prefix: "<",
          placeholder: "0",
          type: "number",
        },
      ],
    },
    {
      value: "priority",
      label: "Priority",
      type: "switch",
      checked: false,
      values: ["HIGH", "MEDIUM", "LOW"],
    },
    {
      value: "representative_id",
      label: "Representative",
      type: "select",
      checked: false,
      selectLabel: "Select Rep",
    },
  ],
  showInactive: false,
  loading: true,
  count: 0,
  salesCount: 0,
  merchandisersReps: [],
  merchandisersCount: 0,
  inactiveCount: 0,
  params: {},
  existDataActive: null,
  existDataInactive: null,
  currentTab: "",
  topSummaries: {},
  topSummariesLoading: false,
};

const repsReducer = (
  state = initialState,
  { type, active, payload, isScrolling }
) => {
  switch (type) {
    case GET_USERS:
      return {
        ...state,
        reps: isScrolling ? state.reps.concat(payload) : payload,
      };

    case EXIST_REPS_ACTIVE:
      return {
        ...state,
        existDataActive: payload,
      };
    case EXIST_REPS_INACTIVE:
      return {
        ...state,
        existDataInactive: payload,
      };

    case GET_SALES_REPS:
      return {
        ...state,
        salesReps: isScrolling ? state.salesReps.concat(payload) : payload,
      };

    case RESET_SALES_REPS:
      return { ...state, salesReps: [] };

    case GET_THIRD_PARTY_REPS:
      return {
        ...state,
        thirdPartyReps: isScrolling
          ? state.thirdPartyReps.concat(payload)
          : payload,
      };
    case REPS_GET_FILTER_FIELD:
      return { ...state, repsFilter: payload };

    case REPS_GET_SWITCH:
      return { ...state, repsFilterSwitches: payload };

    case RESET_REPS_ACTION_FILTER:
      return { ...state, repsFilter: initialState.repsFilter };
    case RESET_REPS_ACTION_SWITCH:
      return { ...state, repsFilterSwitches: initialState.repsFilterSwitches };
    case ROUTES_GET_FILTER_FIELD:
      return { ...state, routesFilter: payload };
    case ROUTES_GET_SWITCH:
      return { ...state, routesSwitch: payload };
    case RESET_ROUTES_ACTION_FILTER:
      return { ...state, routesFilter: initialState.routesFilter };
    case RESET_ROUTES_ACTION_SWITCH:
      return { ...state, routesSwitch: initialState.routesSwitch };
    case ROUTES_QUICK_FILTER:
      return { ...state, routesQuickFilter: payload };
    case RESET_ROUTES_QUICK_FILTER:
      return { ...state, routesQuickFilter: initialState.routesQuickFilter };
    case GET_REP_PARAMS:
      return { ...state, params: payload };

    case GET_REPS_COUNT:
      return { ...state, count: payload };

    case GET_SALES_COUNT:
      return { ...state, salesCount: payload };

    case GET_MERCHANDISERS_REPS:
      return {
        ...state,
        merchandisersReps: isScrolling
          ? state.merchandisersReps.concat(payload)
          : payload,
      };

    case GET_MERCHANDISERS_COUNT:
      return { ...state, merchandisersCount: payload };

    case GET_THIRD_REPS_COUNT:
      return { ...state, thirdPartyCount: payload };

    case GET_REPS_COUNT_INACTIVE:
      return { ...state, inactiveCount: payload };

    case GET_USERS_INACTIVE:
      return {
        ...state,
        inactive: isScrolling ? state.inactive.concat(payload) : payload,
      };

    case SHOW_INACTIVE_REPS:
      return { ...state, showInactive: payload };

    case SET_CURRENT_TAB:
      return { ...state, currentTab: payload };

    case CREATE_USER: {
      if (!payload.active) {
        return {
          ...state,
          inactive: [payload, ...state.inactive],
          existData: true,
        };
      }
      if (payload?.role === "SALES") {
        return {
          ...state,
          salesReps: [payload, ...state.salesReps],
          salesCount: state.salesCount + 1,
          reps: [payload, ...state.reps],
          count: state.count + 1,
          existData: true,
        };
      }
      if (payload?.role === "MERCHANDISER") {
        return {
          ...state,
          merchandisersReps: [payload, ...state.merchandisersReps],
          merchandisersCount: state.merchandisersCount + 1,
          reps: [payload, ...state.reps],
          count: state.count + 1,
          existData: true,
        };
      }
      if (payload?.role === "THIRD_PARTY") {
        return {
          ...state,
          thirdPartyReps: [payload, ...state.thirdPartyReps],
          thirdPartyCount: state.thirdPartyCount + 1,
          reps: [payload, ...state.reps],
          count: state.count + 1,
          existData: true,
        };
      }
      return state;
    }

    case UPDATE_USER: {
      let newState = { ...state };
      let repsList = payload.active ? [...state.reps] : [...state.inactive];
      let updateIndex = repsList.findIndex((rep) => rep.id === payload.id);
      if (updateIndex >= 0) {
        repsList.splice(updateIndex, 1, payload);
        if (payload.active) newState = { ...state, reps: repsList };
        else newState = { ...state, inactive: repsList };
        return { ...newState, loading: false };
      }

      repsList = !payload.active ? [...state.reps] : [...state.inactive];
      updateIndex = repsList.findIndex((rep) => rep.id === payload.id);
      repsList.splice(updateIndex, 1);
      if (payload.active)
        newState = {
          ...state,
          reps: [...state.reps, payload],
          inactive: repsList,
        };
      else
        newState = {
          ...state,
          inactive: [...state.inactive, payload],
          reps: repsList,
        };
      return { ...newState, loading: false };
    }

    case DELETE_USER: {
      let newState = { ...state };
      const repsList = active[0] ? newState.reps : newState.inactive;

      payload.forEach((id) => {
        const index = repsList.findIndex((rep) => rep.id === id);
        if (index >= 0) {
          repsList.splice(index, 1);
        }
      });

      if (active[0]) newState = { ...state, reps: repsList };
      else newState = { ...state, inactive: repsList };
      return { ...newState, loading: false };
    }

    case START_LOADING_REPS:
      return { ...state, loading: true };

    case END_LOADING_REPS:
      return { ...state, loading: false };

    case START_LOADING_REPS_TOTAL_SUMMERIES:
      return { ...state, topSummariesLoading: true };

    case END_LOADING_REPS_TOTAL_SUMMERIES:
      return { ...state, topSummariesLoading: false };

    case GET_SUMMARIES:
      return { ...state, topSummaries: payload };

    default:
      return state;
  }
};

export default repsReducer;
