import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useCallback, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftIcon, IosArrowForward } from "../../components/Icons";
import Loader from "../../components/Loader";
import StyledButton from "../../components/StyledButton";
import { getRouteByIdService } from "../../services/routes";
import { error } from "../../utils/notifications";
import TabMapComponent from "../CustomersPage/components/CustomersTab/components/TabsContent/TabMapComponent";
import { AssignedRepsAccordions } from "./components";
import useStyles from "./styles";
import { string } from "prop-types";
import { useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";
import MapPopup from "Pages/CustomersPage/pages/NewCustomerPage/MapPopup/MapPopup";

const ViewRoutePage = ({ navigatePath, navigateState }) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const classes = useStyles();
  const navigate = useNavigate();
  const { routeId } = useParams();
  const [routeState, setRouteState] = useState({
    route: {},
    loading: false,
    stops: [],
    routing: null,
    polylines: [],
    hasRoute: false,
    pendingRecalculation: false,
  });

  const [mapData, setMapData] = useState(null);

  const handleClickCheckIn = useCallback((data) => {
    setMapData(data);
  }, []);

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.routes?.create_edit;
    }
  }, [repPermissions]);

  const fetchRoute = useCallback(() => {
    setRouteState((prev) => ({ ...prev, loading: true }));

    getRouteByIdService(routeId)
      .then((res) => {
        setRouteState((prev) => ({ ...prev, loading: false, route: res }));
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        setRouteState((prev) => ({ ...prev, loading: false }));
      });
  }, [routeId]);

  useEffect(() => {
    fetchRoute();
  }, [fetchRoute]);

  return (
    <>
      <MapPopup
        withHeader
        isOpen={!!mapData}
        handleClose={() => setMapData(null)}
        address={mapData?.shippingAddress}
        customer={{
          ...mapData,
        }}
        closeBtnStyle={{
          top: 10,
          right: 10,
        }}
      />

      <Box>
        <Loader isLoading={routeState.loading} />
        <Box className={classes.headerWrapper}>
          <Stack
            direction="row"
            alignItems="center"
            width="calc(100% - 113px)"
            gap="15px"
          >
            <Typography
              onClick={() => {
                navigate(navigatePath || -1, { state: navigateState });
              }}
              sx={{
                fontSize: "20px",
                cursor: "pointer",
                mr: "5px",
                color: "#9C9C94",
              }}
            >
              Routes
            </Typography>
            <IosArrowForward />
            <Typography className={classes.pageTitle} noWrap>
              <span style={{ color: "#5F6267" }}>{routeState.route.name}</span>
            </Typography>
          </Stack>
          <Box>
            <StyledButton
              label="Back"
              startIcon={<ArrowLeftIcon />}
              onClick={() => navigate(-1)}
              color="greySecondary"
            />
            <StyledButton
              disabled={disabledCreateEdit || isAdmin}
              label="Edit"
              onClick={() => navigate(`/routes/edit/${routeId}`)}
              variant="outlined"
              className={classes.smallerButton}
              sx={{ paddingLeft: "22px" }}
              color="primary"
            />
          </Box>
        </Box>
        <Box className={classes.pageWrapper}>
          <AssignedRepsAccordions
            setRouteState={setRouteState}
            reps={routeState.route.assignedRepresentatives}
            stops={
              routeState.route.customers?.map((c) => ({
                ...c.customer,
                started: false,
              })) || []
            }
            {...{ handleClickCheckIn }}
          />

          <TabMapComponent
            pendingRouteRecalculation={routeState.pendingRecalculation}
            routePolylines={routeState.polylines}
            stops={routeState.stops.filter(
              ({ shippingAddress, lat, lng }) =>
                (!!lat && !!lng) ||
                (!!shippingAddress?.lat && !!shippingAddress?.lng)
            )}
            styles={{ height: "100%" }}
            note={routeState.route.note}
            hasRoute={routeState.hasRoute}
            customMapOptions={{
              fullscreenControlOptions: {
                position: 7,
              },
            }}
            handleFetchList={fetchRoute}
          />
        </Box>
      </Box>
    </>
  );
};

ViewRoutePage.propTypes = {
  navigatePath: string,
  navigateState: string,
};
export default ViewRoutePage;
