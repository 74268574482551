/* eslint-disable react/prop-types */
import * as React from "react";

export const ThirdPartyCircleIcon = ({ width = 30, height = 30, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 30"
    {...props}
  >
    <g data-name="Group 12541">
      <g data-name="Group 11937">
        <g data-name="Group 11936">
          <path
            data-name="NoPath \u2013 kopija"
            d="M15 0a15.15 15.15 0 0 1 10.607 4.393A14.76 14.76 0 0 1 30 15 15 15 0 1 1 15 0"
            fill="#47a06d"
          />
          <text
            data-name="3rd Party"
            transform="translate(15 13)"
            fill="#fff"
            fontSize={6}
            fontFamily="OpenSans-Regular, Open Sans"
          >
            <tspan x={-4.777} y={0}>
              {"3rd"}
            </tspan>
            <tspan x={-7.27} y={8}>
              {"Party"}
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);
