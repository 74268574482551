import { useCallback, useEffect, useState } from "react";
import { getCountriesService } from "services/territory";
import { error } from "utils/notifications";
import { useDebounce } from "./hooks";

const init = {
  loading: true,
  list: [],
};

export const useCountryOfOriginActions = ({
  params = {},
  open = true,
} = {}) => {
  const [state, setState] = useState(init);
  const [search, setSearch] = useState("");
  const searchInputDebounced = useDebounce(search, 500);

  const handleFetch = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const res = await getCountriesService({
        ...params,
        search,
      });

      setState((prev) => ({
        ...prev,
        list: Array.isArray(res) ? res.filter(Boolean) : [],
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [params, search]);

  useEffect(() => {
    if (open) {
      handleFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, searchInputDebounced]);

  return { ...state, search, setSearch, handleFetch };
};
