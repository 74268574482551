import { useMemo, useState } from "react";
import { object, array, func, bool } from "prop-types";
//import { useSelector } from "react-redux";

import { Box, Checkbox, Grid, Paper, Typography } from "@mui/material";

import { StyledTooltip } from "components";
import {
  CheckboxIcon,
  CheckmarkIcon,
  CrossIcon,
  GroupIcon,
  ProspectStatusIcon,
} from "components/Icons";
import { useNavigate } from "react-router-dom";
import { MenuColumn } from "../TabSortedComponent/components";
//import { createSelector } from "reselect";
//import { territoryListSelector } from "redux/selectors/territory";
//import { currentUserSelector } from "redux/selectors/auth";
//import { paytermsListSelector } from "redux/selectors/payterms";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";

//const selector = createSelector(
//  territoryListSelector,
//  currentUserSelector,
//  paytermsListSelector,
//  (territoryList, currentUser, paytermsList) => ({
//    territoryList,
//    currentUser,
//    paytermsList,
//  })
//);

const ParentItem = ({
  parent,
  checkedParents,
  handleCheckParent,
  //handleOpenConfirmDialog,
  //onReport,
  //reportData,
  handleOpenCustomerNotes,
  //handleAssignReps,
  //groupList,
  //handleChangePaymentTerm,
  //handleChangeTerritory,
  //handleArchiveCustomers,
  //handleDeleteCustomers,
  //handleOrders,
  //handleFetchParents,
  //handleAssignTags,
  repPermissions,
  handleGetOrderActions,
  isAdmin,
}) => {
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const navigate = useNavigate();

  //const { territoryList, currentUser, paytermsList } = useSelector(selector);

  const [hoverColor, setHoverColor] = useState("");
  const slicedCustomId = parent?.customId?.customId?.slice(0, 6);

  const isChecked = useMemo(
    () =>
      !!checkedParents?.find(
        (checkedParent) => checkedParent?.id === parent?.id
      ),
    [checkedParents, parent?.id]
  );

  const isUncategorizedItem = useMemo(
    () => !!parent?.isUncategorized,
    [parent]
  );

  const statusIcons = {
    [CUSTOMER_STATUS_FILTERS.active]: <CheckmarkIcon />,
    [CUSTOMER_STATUS_FILTERS.inactive]: <CrossIcon fill="#FF0000" />,
    [CUSTOMER_STATUS_FILTERS.prospect]: <ProspectStatusIcon />,
  };

  return (
    <Paper
      sx={{
        height: "51px",
        display: "flex",
        cursor: isUncategorizedItem ? "auto" : "pointer",
        backgroundColor: isChecked ? "#ECF5F0" : hoverColor,
        "&:not(:last-child)": { borderBottom: "1px solid #D5D9D9" },
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={24}
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
    >
      <Box
        width="52px"
        minWidth="52px"
        maxWidth="52px"
        sx={{ ...classes.grid, mr: "4px" }}
      >
        {parent?.name !== "Uncategorized" && (
          <Checkbox
            disabled={isAdmin}
            style={{ width: 22, height: 22 }}
            icon={<CheckboxIcon size={22} />}
            sx={{
              padding: 0,
              "& .MuiSvgIcon-root": { fontSize: 30 },
            }}
            checked={
              checkedParents.findIndex(
                (checkedParent) => checkedParent?.id === parent?.id
              ) > -1
            }
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={() => handleCheckParent(parent)}
          />
        )}
      </Box>
      <Grid
        item
        xs={1}
        sx={{
          ...classes.grid,
          textAlign: "left",
        }}
      >
        {statusIcons[parent?.status]}
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          ...classes.grid,
          pl: 4,
          justifyContent: "flex-start",
        }}
      >
        <StyledTooltip
          title={parent?.customId?.customId}
          disableHoverListener={parent?.customId?.customId?.length <= 6 && true}
        >
          <Typography
            variant="body2"
            color="groundLight.contrastText"
            sx={{
              display: "flex",
              fontSize: "12px",
            }}
          >
            {slicedCustomId?.length < parent?.customId?.customId?.length
              ? slicedCustomId + "..."
              : parent?.customId?.customId || ""}
          </Typography>
        </StyledTooltip>
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          ...classes.grid,
          pl: "40px",
          textAlign: "left",
          justifyContent: "none",
        }}
      >
        <GroupIcon />
        <Typography
          variant="body2"
          color="groundLight.contrastText"
          sx={{
            display: "flex",
            alignItems: "center",
            ml: "22px",
            fontSize: "12px",
            textDecoration: "none",
            "&:hover": {
              textDecoration:
                parent?.name === "Uncategorized" ? "auto" : "underline",
            },
          }}
          onClick={() => {
            if (isUncategorizedItem) return;
            navigate(`/customers/${parent?.id}`);
          }}
        >
          {parent?.displayName || parent?.name}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={classes.grid}>
        <Typography
          variant="body2"
          color="groundLight.contrastText"
          sx={{ display: "inline-block", fontSize: "12px" }}
        >
          {/* {group._count?.customers || 0} */}
          {parent?._count?.subCustomers || 0}
        </Typography>
      </Grid>
      <Grid item xs={5.55} sx={classes.grid}>
        {/* <Typography
          variant="body2"
          color="groundLight.contrastText"
          sx={{ display: "inline-block", fontSize: "12px" }}
        >
          {separateNumWithComma(group.orderDirect)}
        </Typography> */}
      </Grid>
      <Grid
        item
        xs={0.9}
        sx={{
          ...classes.grid,
          justifyContent: "flex-end",
          ml: "auto",
        }}
      >
        {/* <ThreeDotButton onClick={handleClickLogoClient} paddingRight="4px" /> */}
        {/* <StyledMenu
          anchorEl={anchorEl}
          isOpen={openHeaderList}
          handleClose={handleCloseLogoClient}
        >
          <MenuItem
            onClick={(e) => {
              handleCloseLogoClient(e);
              onReport({ ...reportData, parent }, true);
            }}
          >
            <ReportIcon />
            <ListItemText>Report</ListItemText>
          </MenuItem>
          {parent?.name !== "Uncategorized" && (
            <MenuItem disabled={isAdmin} onClick={handleConfirmDelete}>
              <TrashIcon fill="#FF6254" height="10.5" width="8.58" />
              <ListItemText
                sx={{ "& > .MuiTypography-root": { color: "#FF6254" } }}
                classes={{ primary: "deleteItem" }}
              >
                Delete
              </ListItemText>
            </MenuItem>
          )}
        </StyledMenu> */}
        <MenuColumn
          handleGetOrderActions={handleGetOrderActions}
          isGroups={true}
          //hasQuickBooks={!!currentUser.quickBooksTokens}
          hideNotes={true}
          //handleAssignReps={isUncategorizedItem ? null : handleAssignReps}
          //handleOrders={isUncategorizedItem ? null : handleOrders}
          //handleChangeTerritory={
          //  isUncategorizedItem
          //    ? null
          //    : (customerIdArr, territoryId) =>
          //        handleChangeTerritory(
          //          customerIdArr,
          //          territoryId,
          //          handleFetchParents
          //        )
          //}
          //handleChangePaymentTerm={
          //  isUncategorizedItem
          //    ? null
          //    : (customer, paymentTermsId) =>
          //        handleChangePaymentTerm(
          //          customer,
          //          paymentTermsId,
          //          handleFetchParents
          //        )
          //}
          //handleArchiveCustomers={
          //  isUncategorizedItem
          //    ? null
          //    : (customerIdArr) =>
          //        handleArchiveCustomers(customerIdArr, handleFetchParents)
          //}
          //handleDeleteCustomers={
          //  isUncategorizedItem
          //    ? null
          //    : (customerIdArr) =>
          //        handleDeleteCustomers(
          //          customerIdArr,
          //          false,
          //          handleFetchParents
          //        )
          //}
          //handleAssignTags={
          //  isUncategorizedItem
          //    ? null
          //    : (customer) => handleAssignTags(customer)
          //}
          customer={parent}
          {...{
            handleOpenCustomerNotes,
            //handleOpenConfirmDialog,
            //groupList,
            //territoryList,
            //paytermsList,
            //onReport,
            //reportData,
            //handleFetchParents,
            repPermissions,
          }}
        />
      </Grid>
    </Paper>
  );
};

ParentItem.propTypes = {
  parent: object,
  checkedParents: array,
  handleCheckParent: func,
  handleOpenConfirmDialog: func,
  onReport: func,
  reportData: object,
  handleOpenCustomerNotes: func,
  handleAssignReps: func,
  groupList: array,
  handleChangePaymentTerm: func,
  handleChangeTerritory: func,
  handleDeleteCustomers: func,
  handleArchiveCustomers: func,
  handleOrders: func,
  handleFetchParents: func,
  handleAssignTags: func,
  handleGetOrderActions: func,
  repPermissions: object,
  isAdmin: bool,
};
ParentItem.defaultTypes = {
  parent: {},
  checkedParents: [],
};

export default ParentItem;
