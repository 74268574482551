import { object, func, bool, string } from "prop-types";
import { Link as NavLink } from "react-router-dom";
import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import { ActiveIcon, CheckboxIcon, CrossIcon } from "components/Icons";
import { StyledButton, StyledTooltip, ThreeDotButton } from "components";
import { daysAgo, useAdmin } from "helpers/helpers";
import RouteProgressDropDown from "components/RouteProgressDropDown";
import { useCallback, useMemo } from "react";

const RoutesItem = ({
  route,
  timeZone,
  handleDotsClick,
  handleViewRoute,
  handleCheckRoute,
  isAlreadySelected,
  disabledCreateEdit,
  setSelectedRepData,
  setAssignedAnchorEl,
}) => {
  const isAdmin = useAdmin();

  const isSelected = useMemo(
    () => isAlreadySelected(route.id),
    [isAlreadySelected, route.id]
  );

  const priority = useCallback(() => {
    const colors = {
      HIGH: "#FF6969",
      MEDIUM: "#F0C401",
      LOW: "#47A06D",
    };

    const bgColors = {
      HIGH: "rgba(255, 105, 105, 0.2)",
      MEDIUM: "rgba(240, 196, 1, 0.2)",
      LOW: "rgba(71, 160, 109, 0.2)",
    };
    const { priority } = route;
    return (
      <Box
        sx={{
          width: "52px",
          height: "18px",
          background: bgColors[priority],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "3px",
        }}
      >
        <Typography
          sx={{ fontSize: "10px!important" }}
          color={colors[priority]}
        >
          {route.priority}
        </Typography>
      </Box>
    );
  }, [route]);

  const ROUTE_DATA = useMemo(() => {
    return [
      {
        xs: 1.4,
        wrapperProps: {
          sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
        element: route.active ? <ActiveIcon /> : <CrossIcon fill="#FF0000" />,
      },
      {
        xs: 3.5,
        element: (
          <Box sx={{ display: "flex" }}>
            <StyledTooltip
              arrow
              title="No reps assigned."
              disableHoverListener={!!route.assignedRepresentatives.length}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#1C1C19",
                  fontSize: "12px",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                //to={`/routes/view/${route.id}`}
                component={Typography}
                noWrap
                onClick={(e) => {
                  if (
                    e.metaKey ||
                    e.ctrlKey ||
                    !route.assignedRepresentatives.length
                  )
                    return;
                  handleViewRoute(route.id);
                }}
              >
                {route.name}
              </Box>
            </StyledTooltip>
          </Box>
        ),
      },
      {
        xs: 2.8,
        element: (
          <Box sx={{ color: "#1C1C19", fontSize: "12px" }}>
            {route.lastCheckIn ? daysAgo(route.lastCheckIn, timeZone) : "-"}
          </Box>
        ),
      },
      {
        xs: 2.4,
        wrapperProps: {},
        element: <Typography>{route._count.customers}</Typography>,
      },
      {
        xs: 2.1,
        sx: {},
        wrapperProps: {},
        element: priority(),
      },
      {
        xs: 6,
        wrapperProps: { maxHeight: "48px" },
        element: (
          <RouteProgressDropDown
            dropDownItems={route.assignedRepresentatives}
            setAssignedAnchorEl={setAssignedAnchorEl}
            stops={route._count.customers}
            routeId={route?.id}
            setSelectedRepData={setSelectedRepData}
          />
        ),
      },
      {
        xs: true,
        wrapperProps: {
          sx: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "5px",
          },
        },
        element: (
          <Stack direction="row" gap="14px" alignItems="center">
            <StyledTooltip
              arrow
              title="No reps assigned."
              disableHoverListener={!!route.assignedRepresentatives.length}
            >
              <Box>
                <StyledButton
                  label="View Route"
                  variant="outlined"
                  color="groundLighter"
                  disabled={!route.assignedRepresentatives.length}
                  component={NavLink}
                  to={`/routes/view/${route.id}`}
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) return;
                    e.preventDefault();
                    handleViewRoute(route.id);
                  }}
                  fontSize="14px!important"
                  sx={{
                    minWidth: "100px",
                    borderColor: "#CCCCCC",
                    borderRadius: "8px",
                    height: "26px",
                    whiteSpace: "nowrap",
                    p: 0,
                    "& span": { mt: "-1px" },
                  }}
                />
              </Box>
            </StyledTooltip>
            <ThreeDotButton
              paddingRight="7px"
              disabled={disabledCreateEdit || isAdmin}
              onClick={(e) => handleDotsClick(e, route)}
            />
          </Stack>
        ),
      },
    ];
  }, [
    disabledCreateEdit,
    handleDotsClick,
    handleViewRoute,
    isAdmin,
    priority,
    route,
    setAssignedAnchorEl,
    setSelectedRepData,
    timeZone,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        background: "#fff",
        "&:hover": {
          background: "#F7F7F7",
        },
      }}
      data-testid="route-row"
    >
      <Box
        width="62px"
        height="48px"
        maxHeight="48px"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Checkbox
          style={{ width: 22, height: 22 }}
          icon={<CheckboxIcon size={22} />}
          sx={{
            padding: 0,
            // m: "0 0 7px 0px",
            "& .MuiSvgIcon-root": { fontSize: 30 },
          }}
          checked={isSelected}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={() => handleCheckRoute(route)}
        />
      </Box>
      <Grid
        container
        columns={21}
        sx={{
          width: "calc(100% - 62px)",
          display: "flex",
          alignItems: "center",
          height: "48px",
          overflow: "hidden",
          "& .MuiTypography-root": {
            fontSize: "12px",
          },
        }}
      >
        {ROUTE_DATA.map(({ xs, element, wrapperProps }, index) => (
          <Grid key={index} item xs={xs} {...wrapperProps}>
            {element}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

RoutesItem.propTypes = {
  route: object,
  timeZone: string,
  handleViewRoute: func,
  handleDotsClick: func,
  handleCheckRoute: func,
  isAlreadySelected: func,
  setSelectedRepData: func,
  disabledCreateEdit: bool,
  setAssignedAnchorEl: func,
};

export default RoutesItem;
