import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  stopItem: {
    position: "relative",
    width: "100%",
    maxWidth: "456px",
    height: "57px",
    minHeight: "57px",
    padding: "0 23px 0 15px",
    "&:not(:last-of-type)": {
      borderBottom: "0.5px solid #CCCCCC",
    },
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: ({ hasMissingInfo, shouldRemove }) =>
      hasMissingInfo || shouldRemove ? "#EB423320" : "#FFFFFF",
    alignItems: "center",
    "& .title": {
      fontSize: "12px",
      textDecoration: "none",
    },
    "& .titleLink": {
      "& a": {
        textDecoration: "none",
        color: "rgba(0, 0, 0, 0.87)",

        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    "& .index": {
      fontSize: "12px",
      color: ({ completed }) => (completed ? "#47A06D" : "#B5B5AC"),
      "& path": {
        fill: ({ completed }) => (completed ? "#47A06D" : "#B5B5AC"),
        "&:not(.skipstroke)": {
          stroke: ({ completed }) => (completed ? "#47A06D" : "#B5B5AC"),
        },
      },
      fontWeight: "700",
      lineHeight: 1.8,
    },
    "& .address": {
      fontSize: "12px",
      fontWeight: 300,
      marginTop: "2px",
    },

    "& .date": {
      color: "#47A06D",
      fontSize: "12px",
    },
    "& .time": {
      color: "#000000",
      fontSize: "12px",
    },
  },

  indexWrapper: {
    border: ({ completed }) => `2px solid ${completed ? "#47A06E" : "#F0F0F0"}`,
    borderRadius: "50%",
    height: "25px",
    width: "25px",
    minWidth: "25px",
    textAlign: "center",
    paddingBottom: "5px",
  },

  deleteIcon: {
    border: "0.5px solid #B2B2B2",
    padding: "5px",
  },
  editButton: {
    backgroundColor: "#FFF",
    "&:hover": { backgroundColor: "#FFF" },
    borderColor: "#CCC",
    color: "#1C1C19",
    maxWidth: "47px",
    minWidth: "47px",
    height: "26px",
    paddingLeft: "0px",
    paddingRight: "0px",
    borderRadius: "8px",
  },
}));

export default useStyles;
