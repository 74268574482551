import { useMemo } from "react";
import { string, array, bool, func } from "prop-types";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { LargeCheckbox, StyledAvatar, StyledTooltip } from "components";
import { photoUrl } from "helpers/helpers";
import { ThirdPartyCircleIcon } from "components/Icons";

export const RepsBlock = ({
  title,
  list,
  loading,
  isSeparateDot,
  handleCheckboxChange,
  hasCustomerPendingOrder,
}) => {
  const setDisabled = useMemo(() => {
    if (!hasCustomerPendingOrder) return false;

    const selectedSalesCount = list?.filter((r) => r?.role === "SALES")?.length;

    if (selectedSalesCount === 1) return true;

    return false;
  }, [hasCustomerPendingOrder, list]);

  const prepareRole = (role) => {
    switch (role) {
      case "MERCHANDISER":
        return "Merchandiser";

      case "SALES":
        return "Sales";

      case "THIRD_PARTY":
        return "3rd Party";

      default:
        return role;
    }
  };

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress color="primary" size={32} />
    </Box>
  ) : (
    <Box py="18px" px="20px">
      <Box width="100%">
        <Typography pl="12px" fontSize={18} fontWeight={500} color="#3F3F3F">
          {title}
        </Typography>
      </Box>

      {list
        ?.sort((a, b) => {
          const aName = a.name.trim().toLowerCase();
          const bName = b.name.trim().toLowerCase();
          if (aName < bName) return -1;
          if (aName > bName) return 1;
          return 0;
        })
        .map((item) => {
          return (
            <StyledTooltip
              key={item?.id}
              placement="top"
              arrow
              title="At least one sales reps should be assigned"
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -10] } }],
              }}
              disableHoverListener={!(setDisabled && item?.role === "SALES")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  borderRadius: "8px",
                  height: "39px",
                  px: "12px",
                  opacity: setDisabled && item?.role === "SALES" ? 0.5 : 1,
                  "&:hover": { bgcolor: "#F8F8F8" },
                }}
              >
                <FormControlLabel
                  control={
                    <LargeCheckbox
                      size={16}
                      adminIsAllowed
                      disabled={setDisabled && item?.role === "SALES"}
                      checked={!!item?.checked}
                      formSx={{ marginLeft: 0, marginRight: 0 }}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center" width="460px">
                      {item?.role === "THIRD_PARTY" ? (
                        <ThirdPartyCircleIcon width={21} height={21} />
                      ) : (
                        <StyledAvatar
                          sx={{
                            height: "21px",
                            width: "21px",
                            border: "1px solid #CBCBCB",
                          }}
                          src={photoUrl(item.profilePhoto?.fileName)}
                          stringStyle={{ fontSize: "25px" }}
                        />
                      )}
                      <Typography
                        fontSize={17}
                        fontWeight={300}
                        color="#707070"
                        ml="7px"
                        noWrap
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        fontSize={17}
                        fontWeight={300}
                        color="#B2B2B2"
                        ml="4px"
                        noWrap
                        sx={{
                          width: "fit-content",
                          maxWidth: "100%",
                          minWidth: "fit-content",
                        }}
                      >
                        {isSeparateDot ? "•" : "|"} {prepareRole(item?.role)}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </StyledTooltip>
          );
        })}
    </Box>
  );
};

RepsBlock.propTypes = {
  title: string,
  list: array,
  loading: bool,
  isSeparateDot: bool,
  handleCheckboxChange: func,
  hasCustomerPendingOrder: bool,
};
RepsBlock.defaultProps = {
  title: "",
  list: [],
  loading: false,
  isSeparateDot: false,
  handleCheckboxChange: () => {},
  hasCustomerPendingOrder: false,
};
