import { useState } from "react";
import { object, bool, func } from "prop-types";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import { EyeIcon } from "../../../../../../../components/Icons";
import {
  largestTerritory,
  photoUrl,
  stringAvatar,
  normalizeEveryFirstLetterToUpper,
} from "../../../../../../../helpers/helpers";
import { LargeCheckbox } from "../../../../../../../components/Checkboxes";

const ReprItem = ({
  representative,
  isSelected,
  handleSelectRep,
  setAnchorEl,
  setTerritoriesList,
  disabled,
}) => {
  const [hoverColor, setHoverColor] = useState("");

  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    typography: {
      fontSize: "12px",
      fontWeight: "400",
      color: "#1C1C19",
    },
  };

  return (
    <Grid
      sx={{
        cursor: "pointer",
        backgroundColor: isSelected ? "#ECF5F0" : hoverColor,
        opacity: disabled && 0.5,
        pointerEvents: disabled && "none",
        position: "relative",
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #D5D9D9",
        },
      }}
      container
      minHeight="56px"
      columns={16}
      onClick={() => {
        if (!disabled) handleSelectRep(representative);
      }}
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
    >
      {/* {isSelected && (
        <CheckmarkIcon
          style={{ position: "absolute", left: "13px", top: "20px" }}
        />
      )} */}
      <Grid item xs={1} sx={classes.grid}>
        <LargeCheckbox
          sx={{ padding: 0 }}
          formSx={{ m: 0 }}
          checked={isSelected}
        />
      </Grid>
      <Grid
        item
        xs={5}
        sx={{
          pl: "15px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-center",
            width: "100%",
          }}
        >
          {photoUrl(representative.profilePhoto?.fileName) ? (
            <Avatar sx={{ width: "36px", height: "36px" }}>
              <Box
                component="img"
                sx={{ objectFit: "cover", width: "100%", height: "100%" }}
                src={photoUrl(representative.profilePhoto.fileName)}
              />
            </Avatar>
          ) : (
            <Avatar
              {...stringAvatar(representative.name, {
                width: "36px",
                height: "36px",
              })}
            />
          )}
          <Box
            sx={{
              ml: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              py: "2px",
              maxWidth: "calc(100% - 54px)",
            }}
          >
            <Typography
              fontSize="12px"
              color="#1C1C19"
              sx={{ lineHeight: 1, fontWeight: 500 }}
              noWrap
            >
              {representative?.name}
            </Typography>
            <Typography
              // color="groundLight.contrastText"
              color="#5F6267"
              sx={{
                fontSize: "12px",
                fontWeight: 400,
              }}
              noWrap
            >
              {representative?.baseUser?.email || representative?.email || "-"}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={3.5}
        sx={{
          ...classes.grid,
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          fontSize="12px"
          color="groundLight.contrastText"
          fontWeight="400"
        >
          {representative?.role !== "THIRD_PARTY"
            ? normalizeEveryFirstLetterToUpper(representative?.role)
            : "3rd Party"}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          ...classes.grid,
          justifyContent: "flax-start",
        }}
      >
        {largestTerritory(representative.territories) ? (
          <>
            {representative.territories.length > 1 && (
              <IconButton
                sx={{ marginLeft: -0.8 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setTerritoriesList(representative.territories);
                  setAnchorEl(e.currentTarget);
                }}
              >
                <EyeIcon />
              </IconButton>
            )}
            <Typography
              variant="caption"
              color="groundLight"
              noWrap
              sx={{
                color: "#1C1C19",
                whiteSpace: "nowrap",
              }}
            >
              {largestTerritory(representative.territories)}
            </Typography>
          </>
        ) : (
          <Typography sx={{ textAlign: "center" }}>——</Typography>
        )}
      </Grid>
      <Grid item xs={2.5} sx={classes.grid}>
        <Typography
          fontSize="12px"
          color="black"
          fontWeight="500"
          sx={classes.typography}
        >
          {representative._count.assignedCustomers || 0}
        </Typography>
      </Grid>
    </Grid>
  );
};

ReprItem.propTypes = {
  representative: object,
  isSelected: bool,
  handleSelectRep: func,
  setAnchorEl: func,
  setTerritoriesList: func,
  disabled: bool,
};

ReprItem.defaultProps = {
  representative: null,
  isSelected: false,
  disabled: false,
};

export default ReprItem;
