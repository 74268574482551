import { array } from "prop-types";
import { Divider, Grid, Typography } from "@mui/material";
import { ExpandCircleDown } from "@mui/icons-material";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  formatDate,
  getFormattedDate,
  photoUrl,
  setNameAvatar,
} from "helpers/helpers";
import { StyledAvatar } from "components";

export const ActivityBlock = ({ activities }) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const daysAgo = (date) => {
    if (!date) return "-";
    const { timeZone } = currentUser || {};

    const hours = moment().diff(date, "hours");

    if (hours >= -24 && hours <= -1)
      return `Tomorrow at ${moment(getFormattedDate(date, timeZone)).format(
        "h:mm a"
      )}`;
    if (hours >= 0 && hours <= 24)
      return `Today at ${moment(getFormattedDate(date, timeZone)).format(
        "h:mm a"
      )}`;
    if (hours >= 25 && hours <= 48)
      return `Yesterday at ${moment(getFormattedDate(date, timeZone)).format(
        "h:mm a"
      )}`;

    const formattedDate = formatDate({
      utc_date: date,
      formatThisYear: "MMM D",
    });

    return moment(getFormattedDate(date, timeZone)).format(formattedDate);
  };

  const TASK_TYPES = {
    TASK_CREATED: "Created this task",
    TASK_UPDATED: "Updated this task",
    TASK_COMPLETED: "Completed this task",
    TASK_MARKED_INCOMPLETE: "Marked incomplete",
  };

  const createdBy = (item) => {
    const { createdByType, representativeDuplicate } = item || {};
    if (createdByType === "DISTRIBUTOR") {
      return { name: "You", fileName: currentUser?.profilePhoto?.fileName };
    }
    if (createdByType === "REPRESENTATIVE") {
      return {
        name: representativeDuplicate?.name,
        fileName: representativeDuplicate?.profilePhoto?.fileName,
      };
    }

    return { name: "", fileName: "" };
  };

  const prepareType = (type) => {
    return TASK_TYPES[type] || type;
  };

  return (
    <Grid container>
      <Grid
        sx={{
          height: "38px",
          fontSize: 13,
          color: "#5F6267",
        }}
        xs={12}
        item
      >
        Timeline
      </Grid>

      {activities?.map((a) => {
        const created = createdBy(a);
        return (
          <Grid key={a?.id} height="90px" xs={12} item container>
            <Grid
              position="relative"
              justifyContent="center"
              xs={1.3}
              item
              container
            >
              <Divider sx={{ borderStyle: "dashed" }} orientation="vertical" />
              <ExpandCircleDown
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "50%",
                  marginTop: "-12px",
                  marginRight: "-12px",
                  color: "#47A06D",
                  fontSize: 24,
                }}
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              xs
              item
              container
            >
              <Grid
                sx={{
                  border: "1px solid #47A06D50",
                  borderRadius: "4px",
                  alignItems: "center",
                  height: "69px",
                }}
                xs
                item
                container
              >
                <Grid xs={6} item>
                  <Typography ml={1.95} fontSize={13} color="#5F6267" noWrap>
                    {prepareType(a?.type)}
                  </Typography>
                </Grid>

                <Grid height="100%" xs={6} item container>
                  <Grid
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={1}
                    xs={12}
                    item
                  >
                    <StyledAvatar
                      name={setNameAvatar(created?.name)}
                      sx={{ width: 23.5, height: 23.5 }}
                      stringStyle={{ fontSize: 12 }}
                      src={photoUrl(created?.fileName)}
                    />
                    <Typography mr={2.25} fontSize={13} color="#1C1C19" noWrap>
                      {created?.name}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    mr={2.25}
                    xs={12}
                    item
                  >
                    <Typography fontSize={13} color="#00000050" noWrap>
                      {daysAgo(a?.createdAt)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

ActivityBlock.propTypes = { activities: array };
