import { array, func, object } from "prop-types";
import { Box } from "@mui/material";
import Nestable from "react-nestable";
import CustomerCardInfo from "../CustomerCardInfo";
import { useContext, useMemo } from "react";
import { CustomerProfileContext } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfilePage";

export const RightColumnBlock = ({
  infoCards,
  setDeleteContact,
  setDeleteContactOpen,
  handleSaveAssignReps,
  assignedRepresentatives,
  handleEditContact,
  handleOpenRepDialog,
  handleSaveSettings,
  classes,
  setTempLayoutSettings,
}) => {
  const { repPermissions } = useContext(CustomerProfileContext);

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  return (
    <Box
      sx={{
        "& ol": {
          display: "flex",
          flexDirection: "column",
          gap: "11px",
        },
      }}
      flex="1 1 19%"
      width="362px"
      overflow="hidden"
    >
      <Nestable
        items={infoCards}
        renderItem={({ item }) => {
          const {
            title,
            items,
            handleAdd,
            child,
            wrapperStyle,
            onSubmit,
            handleEdit,
            onDelete,
            listOptions = {},
          } = item;
          const repsList = (title) => title === "Assigned Reps" && items;

          return (
            <CustomerCardInfo
              title={title}
              isReps={title === "Assigned Reps"}
              items={items}
              handleAdd={handleAdd}
              child={child}
              wrapperStyle={wrapperStyle}
              onSubmit={onSubmit}
              repsList={repsList(title)}
              onDelete={(item) => {
                if (disabledCreateEdit) return;

                if (title === "Contacts" && item) {
                  setDeleteContact(item);
                  setDeleteContactOpen(true);
                  return;
                }
                if (title === "Assigned Reps" && item?.id) {
                  return handleSaveAssignReps({
                    data: assignedRepresentatives
                      .filter((rep) => rep?.representative?.id !== item?.id)
                      .map((rep) => rep?.representative?.id),
                    msg: "Assigned rep deleted",
                  });
                }
                onDelete(item);
              }}
              handleEdit={(item) => {
                if (title === "Contacts" && item) {
                  return handleEditContact(item);
                }
                if (title === "Assigned Reps" && item?.id) {
                  return handleOpenRepDialog(item?.id);
                }

                handleEdit(item);
              }}
              titleStyle={classes.titleRightBlockStyle}
              disabledCreateEdit={disabledCreateEdit}
              listOptions={listOptions}
            />
          );
        }}
        maxDepth={0}
        onChange={({ items }) => {
          if (repPermissions) return;

          const preparedData = items.map((c, i) => ({
            name: c.value,
            index: i,
          }));
          setTempLayoutSettings(preparedData);
          handleSaveSettings({
            data: preparedData,
            msg: "Layout updated",
          });
        }}
      />
    </Box>
  );
};

RightColumnBlock.propTypes = {
  infoCards: array,
  setDeleteContact: func,
  setDeleteContactOpen: func,
  handleSaveAssignReps: func,
  handleEditContact: func,
  handleOpenRepDialog: func,
  assignedRepresentatives: array,
  handleSaveSettings: func,
  classes: object,
  setTempLayoutSettings: func,
};

export default RightColumnBlock;
