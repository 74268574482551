import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  columnHeaders: {
    minHeight: "33px!important",
    width: "100%",
    overflow: "visible",
    borderRadius: 0,
    border: "none",
  },
  columnHeader: {
    backgroundColor: "#EDEFF2",
    borderBottom: "1px solid #d5d9d9",
    overflow: "hidden",
    minWidth: "60px",
    height: "33px!important",
    paddingLeft: "15px!important",
    "&:not(:last-of-type)": {
      borderRight: "1px solid #d5d9d9",
    },

    '&[data-field="DELETE"]': {
      pointerEvents: "none",
      borderRight: "none",
      minWidth: "60px !important",
      width: "60px !important",
    },
  },

  columnSeparator: { display: "none!important" },

  columnHeaderTitleContainerContent: {
    // height: "33px",
    lineHeight: "33px",
    textTransform: "uppercase",
  },

  columnHeaderTitle: {
    height: "100%",
  },

  virtualScroller: {
    marginTop: "33px!important",
    overflow: "visible!important",
    // width: "100%!important",
  },

  gridMain: {
    overflow: "visible!important",
    width: "100%!important",
  },

  selectsRow: {
    border: "1px solid #d5d9d9",
    borderRadius: "4px 4px 0 0",
  },

  gridCell: {
    minHeight: "33px!important",
    // borderBottom: "none!important",
    paddingLeft: "15px!important",
    paddingRight: "15px!important",
    cursor: "pointer",
    "&:focus-within": {
      outline: "none !important",
    },
    minWidth: "60px !important",
    "&:not(:last-of-type)": {
      borderRight: ({ editable }) => !editable && "1px solid #d5d9d9",
    },

    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap !important",
    },

    "&.MuiDataGrid-cell": {
      border: "1px solid #D5D9D9 !important",
      borderRadius: "4px",
      marginTop: "4px",
      marginLeft: "3px",
      marginRight: "3px",
      maxWidth: "194px !important",
      minWidth: "194px !important",
      width: "194px !important",
      maxHeight: "25px !important",
      minHeight: "25px !important",
      overflow: "hidden",
      padding: "10px !important",
    },

    "&.delete": {
      minWidth: "55px !important",
      maxWidth: "55px !important",
      maxHeight: "33px !important",
      minHeight: "33px !important",
      marginTop: 0,
      paddingLeft: "0px!important",
      paddingRight: "0px!important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderWidth: "0 !important",
    },

    "&.error": {
      border: "1px solid #FF373750 !important",
      borderRadius: "4px",
      maxHeight: "25px !important",
      minHeight: "25px !important",
      marginTop: "4px",
      marginLeft: "3px",
      marginRight: "3px",
      maxWidth: "194px !important",
      minWidth: "194px !important",
      width: "194px !important",
      backgroundColor: "rgba(255, 55, 55, 0.20)",
    },
  },

  gridRow: {
    minHeight: "33px!important",
    maxHeight: "33px!important",

    "&.error": {
      backgroundColor: "rgba(255, 55, 55, 0.10)",
      "&:hover": { backgroundColor: "rgba(255, 55, 55, 0.3) !important" },
      "& .MuiInputBase-root": {
        borderColor: "#FF373700",
        height: "25px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#FF3737!important",
        borderRadius: "20px",
      },
    },
  },

  gridRoot: {
    fontSize: "10px",
    color: "#1C1C19",
    borderRadius: "0 0 4px 4px",
    border: "none",
    width: "100%",
  },

  autoHeight: {
    height: "100%!important",
  },

  cellInput: {
    borderRadius: "4px",
    "& input": {
      paddingLeft: 0,
      fontSize: "10px",
      height: "24px",
    },
  },

  editingCell: {
    boxShadow: "none!important",
    backgroundColor: "transparent!important",
    paddingLeft: "0px!important",
  },

  footerContainer: {
    border: "none",
    position: "fixed",
    top: ({ paginationPosition }) => paginationPosition.y,
    left: ({ paginationPosition }) => paginationPosition.x,
    transform: "translate(calc(-100% - 10px), calc(-100% - 10px))",
    "& .MuiTablePagination-root": {
      overflow: "hidden",
    },
    width: "max-content",
  },
}));

export default useStyles;
