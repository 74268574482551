import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Box } from "@mui/material";
import ProductsList from "./ProductsList";
import CatalogFilter from "../components/CatalogFilter";
import { ProductsTotalSummaries } from "./components";
import {
  totalSummariesCatalogDataSelector,
  totalSummariesCatalogLoadingSelector,
} from "../../../redux/selectors/total-summaries";
import { getTotalSummariesCatalogAction } from "../../../redux/actions/total-summaries";
import { string, object } from "prop-types";
import { useCountryOfOriginActions } from "helpers/useCountryOfOriginActions";

const selector = createSelector(
  totalSummariesCatalogDataSelector,
  totalSummariesCatalogLoadingSelector,
  (totalSummariesCatalogData, totalSummariesCatalogLoading) => ({
    totalSummariesCatalogData,
    totalSummariesCatalogLoading,
  })
);

const ProductsTab = ({ currentTab, repPermissions }) => {
  const { totalSummariesCatalogData, totalSummariesCatalogLoading } =
    useSelector(selector);

  const dispatch = useDispatch();

  const {
    handleFetch: fetchCountryOfOrigin,
    list: countryOfOriginList,
    setSearch: setCountryOfOriginSearch,
    loading: loadingCountryOfOrigin,
  } = useCountryOfOriginActions({
    params: {
      sort_clients_countries_first: true,
      sort_by_name: "asc",
      used_on_products: true,
    },
    open: false,
  });

  useEffect(() => {
    dispatch(getTotalSummariesCatalogAction());
  }, [dispatch]);

  return (
    <Box px="32px" height="100%">
      <ProductsTotalSummaries
        isLoading={totalSummariesCatalogLoading}
        totalSummaries={totalSummariesCatalogData}
      />
      <CatalogFilter
        {...{
          currentTab,
          fetchCountryOfOrigin,
          countryOfOriginList,
          setCountryOfOriginSearch,
          loadingCountryOfOrigin,
        }}
      />
      <ProductsList {...{ currentTab, repPermissions, fetchCountryOfOrigin }} />
    </Box>
  );
};
ProductsTab.propTypes = {
  currentTab: string,
  repPermissions: object,
};
export default ProductsTab;
