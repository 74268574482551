import { useState } from "react";
import {
  arrayOf,
  string,
  shape,
  number,
  func,
  object,
  array,
  bool,
} from "prop-types";
import { Collapse, Grid, Paper, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ReportItem from "./ReportItem";
import { reportsList } from "../ReportsPage.constants";

const ReportComponent = ({
  title,
  reportsCount,
  reports,
  config,
  onExport,
  handleFavorite,
  favorites,
  isFavoriteTab,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Paper
      elevation={0}
      variant="outlined"
      component={Grid}
      container
      data-testid="reports-group"
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        xs={0.8}
        item
        onClick={() => setOpen((prev) => !prev)}
      >
        {isOpen ? (
          <KeyboardArrowUp sx={{ fontSize: 40, color: "#47A06D" }} />
        ) : (
          <KeyboardArrowDown sx={{ fontSize: 40 }} />
        )}
      </Grid>
      <Grid
        xs={9.5}
        item
        sx={{
          py: "15px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        component={Typography}
        fontSize={26}
        fontWeight={600}
        color="#000"
        onClick={() => setOpen((prev) => !prev)}
      >
        {title}
      </Grid>
      <Grid
        xs={1.7}
        item
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          cursor: "pointer",
          pr: "21px",
        }}
        component={Typography}
        fontSize={22}
        fontWeight="300"
        color="#000"
        onClick={() => setOpen((prev) => !prev)}
      >
        {!!reportsCount &&
          `${reportsCount} report${reportsCount > 1 ? "s" : ""}`}
      </Grid>
      <Collapse in={isOpen} component={Grid} xs={12} item container>
        {reports.map((report) => {
          const isFavorite = favorites.find(
            ({ id, body }) =>
              (isFavoriteTab
                ? !body?.isCustom || body?.isCustom
                : !body?.isCustom) &&
              (body?.reportCategory === report.reportCategory ||
                id === report.id)
          );

          const reportItemList = reportsList?.find(({ reports }) =>
            reports?.find(
              ({ reportCategory }) =>
                reportCategory === isFavorite?.body?.reportCategory
            )
          );

          const reportItem = isFavorite
            ? {
                ...reportItemList?.reports?.find(
                  ({ reportCategory }) =>
                    reportCategory === isFavorite?.body?.reportCategory
                ),
                ...isFavorite,
              }
            : report;

          return (
            <ReportItem
              key={`${report?.title}-${report.id}`}
              title={report?.title}
              report={reportItem}
              config={config}
              onExport={onExport}
              handleFavorite={handleFavorite}
              isFavorite={!!isFavorite}
            />
          );
        })}
      </Collapse>
    </Paper>
  );
};
ReportComponent.propTypes = {
  title: string,
  reportsCount: number,
  reports: arrayOf(
    shape({
      id: string,
      title: string,
    })
  ),
  config: object,
  onExport: func,
  handleFavorite: func,
  favorites: array,
  isFavoriteTab: bool,
};
ReportComponent.defaultProps = {
  title: "Default title",
  reportsCount: 0,
  reports: [],
  handleFavorite: () => {},
  isFavorite: false,
  favorites: [],
  isFavoriteTab: false,
};

export default ReportComponent;
