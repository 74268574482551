import { addOnlySharedTags } from "helpers/helpers";
import { useRepsPermissions, useWindowSize } from "helpers/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  applyMultiplePhotoTagsAction,
  applyPhotoTagsAction,
  deletePhotosAction,
  getGalleryAction,
  groupIdsAction,
  setPictureItemAction,
} from "redux/actions/gallery";
import { currentUserSelector } from "redux/selectors/auth";
import {
  galleryCountSelector,
  galleryGetParamsSelector,
  galleryListSelector,
  galleryLoadingSelector,
} from "redux/selectors/gallery";
import { createSelector } from "reselect";
import { getGalleryService } from "services/gallery";
import {
  handleError,
  photoUrl,
  downloadExcelFile as downloadFile,
} from "helpers/helpers";
import JSZipUtils from "jszip-utils";
import { success } from "utils/notifications";
import { IMAGE_EXTENSION, SCROLL_LIMIT_GALLERY } from "utils/constants";
import JSZip from "jszip";
import FileSaver from "file-saver";
import moment from "moment-timezone";
import { getCustomOrdersService } from "services/reports";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { Box, Button, ListItemText } from "@mui/material";
import { DangerIcon } from "components/Icons";
import { debounce } from "lodash";

const selector = createSelector(
  galleryListSelector,
  currentUserSelector,
  galleryCountSelector,
  galleryGetParamsSelector,
  galleryLoadingSelector,
  (
    galleryList,
    currentUser,
    galleryCount,
    galleryGetParams,
    galleryLoading
  ) => ({
    galleryList,
    currentUser,
    galleryCount,
    galleryGetParams,
    galleryLoading,
  })
);

const COLLAPSE_MS = 700;
export const useGalleryTab = ({ setDownloadProgress }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const repPermissions = useRepsPermissions();

  const {
    galleryList,
    currentUser,
    galleryCount,
    galleryGetParams,
    galleryLoading,
  } = useSelector(selector);

  const [currentPage, setCurrentPage] = useState(null);

  const [page, setPage] = useState(1);
  const [checkedPhotos, setCheckedPhotos] = useState([]);
  const [openTagsDrawer, setOpenTagsDrawer] = useState(false);
  const [loadingTagsDrawer, setLoadingTagsDrawer] = useState(false);
  const [isOpenProductDialog, setIsOpenProductDialog] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(null);

  const handleOpenTags = useCallback(() => setOpenTagsDrawer(true), []);

  const handleCloseTagsDrawer = () => setOpenTagsDrawer(false);

  const handleFindAndReplaceByIdGalleryTags = (galleryId, updatedItem) => {
    const currentGalleryIndex = checkedPhotos.findIndex(
      (galleryItem) => galleryItem.id === galleryId
    );

    const updatedGallery = [...checkedPhotos];
    updatedGallery[currentGalleryIndex] = updatedItem;

    setCheckedPhotos(updatedGallery);
  };

  const handleSaveMultipleTags = async (tagIds, newTags) => {
    const photoGroupIds = checkedPhotos.map((photo) => photo.id);
    dispatch(
      applyMultiplePhotoTagsAction(
        {
          photoGroupIds: photoGroupIds,
          tagIds,
          newTags,
          returnUpdated: true,
        },
        (res) => setCheckedPhotos(res)
      )
    );

    setOpenTagsDrawer(false);
  };

  const handleSaveTags = async (tags) => {
    const tagIds = [];
    const newTags = [];

    tags.forEach((tag) => {
      return tag?.newTag
        ? newTags.push(tag?.tag)
        : tagIds.push(tag?.id || tag?.tag?.id);
    });

    if (checkedPhotos.length > 1)
      return handleSaveMultipleTags(tagIds, newTags);

    dispatch(
      applyPhotoTagsAction(
        {
          photoGroupId: checkedPhotos[0]?.id,
          tagIds,
          newTags,
        },
        (res) => handleFindAndReplaceByIdGalleryTags(checkedPhotos[0]?.id, res)
      )
    );

    return setOpenTagsDrawer(false);
  };

  const handleGetProductTags = useCallback(() => {
    if (!checkedPhotos?.length) return;

    if (checkedPhotos.length === 1)
      return checkedPhotos[0].tags.map((item) => item.tag || item);

    return addOnlySharedTags(checkedPhotos);
  }, [checkedPhotos]);

  const productTags = useMemo(
    () => handleGetProductTags(),
    [handleGetProductTags]
  );

  const handleCheckPhoto = (photo) => {
    const customerIndex = checkedPhotos.findIndex(
      (checkedPhoto) => checkedPhoto.id === photo.id
    );
    if (customerIndex > -1) {
      return setCheckedPhotos(
        checkedPhotos.filter((item) => item.id !== photo.id)
      );
    }

    setCheckedPhotos([...checkedPhotos, photo]);
  };

  const handleOpenProductDialog = useCallback(
    (product) => {
      getGalleryService({ ...galleryGetParams })
        .then((res) => {
          const groupIds = res?.rows?.map((el) => el?.id);
          dispatch(groupIdsAction(groupIds));
          const index = groupIds.findIndex((group) => group === product?.id);
          setCurrentPage(index + 1);
          dispatch(setPictureItemAction(product.id));
          setIsOpenProductDialog(true);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err?.message);
        });
    },
    [dispatch, galleryGetParams, setIsOpenProductDialog]
  );

  const handleCloseProductDialog = () => {
    setIsOpenProductDialog(false);
    setCurrentPage(null);
  };

  const handleSetProduct = (product) => {
    handleOpenProductDialog(product);
  };

  const handleDeletePhotos = useCallback(() => {
    const ids = checkedPhotos.map((photo) => photo.id);
    dispatch(deletePhotosAction(ids));
    setCheckedPhotos([]);
  }, [checkedPhotos, dispatch, setCheckedPhotos]);

  const urlToPromise = (url) => {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  const handleDownloadPhotos = useCallback(() => {
    setDownloadProgress({ download: true, progress: 0 });
    success("Downloading in progress");
    const zip = new JSZip();
    checkedPhotos.forEach(async (group) => {
      group.photos.forEach(async (photo) => {
        const downloadedFileName = `${photo.id}${IMAGE_EXTENSION}`;
        zip
          .folder(group.customer.name)
          .file(downloadedFileName, urlToPromise(photoUrl(photo.fileName)), {
            binary: true,
          });
      });
    });
    let beforeUpdate = 0;
    zip
      .generateAsync({ type: "blob" }, (metadata) => {
        if (metadata.percent - beforeUpdate > 5) {
          setDownloadProgress({
            download: true,
            progress: parseFloat(metadata.percent.toFixed(0)),
          });
          beforeUpdate = metadata.percent;
        }
      })
      .then((blob) => {
        FileSaver.saveAs(
          blob,
          `Gallery photos ${moment().format("DD-MM-YYYY")}`
        );
        setDownloadProgress({ download: false, progress: 0 });
      });
    setCheckedPhotos([]);
  }, [checkedPhotos, setCheckedPhotos, setDownloadProgress]);

  const handleDownloadsMerchandisingReport = useCallback(async () => {
    success("Downloading in progress");
    try {
      const body = {
        photoGroupIds: checkedPhotos.map((photo) => photo.id),
      };

      const res = await getCustomOrdersService(
        "photo-groups-merchandising",
        body,
        {}
      );
      downloadFile(
        res,
        "report",
        `Merchandising report ${moment().format("DD MMM YYYY")}`
      );
    } catch (error) {
      handleError(error);
    } finally {
      setCheckedPhotos([]);
    }
  }, [checkedPhotos, setCheckedPhotos]);

  const [windowWidth, windowHeight] = useWindowSize();

  const [height, setHeight] = useState(0);

  const COLUMN_WIDTH = 300;
  const PAGE_PADDING = 64;
  const PHOTO_GAP = 16;
  const MAX_WIDTH = useMemo(() => windowWidth - PAGE_PADDING, [windowWidth]);
  const columnCount = useMemo(
    () => Math.floor(MAX_WIDTH / COLUMN_WIDTH),
    [MAX_WIDTH]
  );

  const PHOTO_GAP_SUM = useMemo(
    () => (columnCount - 1) * PHOTO_GAP,
    [columnCount]
  );

  const ADDITIONAL_OFFSET = useMemo(
    () =>
      Math.floor(
        (MAX_WIDTH - PHOTO_GAP_SUM - columnCount * COLUMN_WIDTH) / columnCount
      ),
    [MAX_WIDTH, PHOTO_GAP_SUM, columnCount]
  );

  const rowCount = useMemo(
    () => Math.ceil(galleryCount / columnCount),
    [galleryCount, columnCount]
  );

  const handleFetch = useCallback(() => {
    dispatch(
      getGalleryAction({
        ...galleryGetParams,
        page: page + 1,
        limit: SCROLL_LIMIT_GALLERY,
      })
    );
    setPage((prev) => prev + 1);
  }, [dispatch, galleryGetParams, page, setPage]);

  const calculateWidth = () => {
    const additionalWidth = ADDITIONAL_OFFSET;
    const shrinkWidth = COLUMN_WIDTH - additionalWidth;
    const grownWidth = COLUMN_WIDTH + additionalWidth;
    if (grownWidth * columnCount > MAX_WIDTH) return shrinkWidth;
    return grownWidth;
  };

  const handleCreateRoute = useCallback(() => {
    if (!checkedPhotos?.length) return;
    const selectedCustomersCollection = new Map();

    checkedPhotos?.forEach((photo) => {
      selectedCustomersCollection.set(photo?.customer?.id, photo?.customer);
    });

    const checkedCustomers = Array.from(selectedCustomersCollection.values());

    navigate("/routes/new", { state: { checkedCustomers } });
  }, [checkedPhotos, navigate]);

  useEffect(() => {
    const element = document.getElementById("gallery-table");
    element && element.scroll({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryGetParams]);

  const [loadingPhotos, setLoadingPhotos] = useState(false);
  const handleSetCheckedPhotosHeader = () => {
    if (galleryList?.length === checkedPhotos?.length)
      return setCheckedPhotos([]);

    setCheckedPhotos([...galleryList]);
  };

  const handleSetCheckAllCustomers = (value) => {
    if (!value) return setCheckedPhotos([]);

    if (galleryList?.length === galleryCount)
      return setCheckedPhotos([...galleryList]);

    if (galleryList?.length < galleryCount) {
      setLoadingPhotos(true);
      getGalleryService()
        .then(({ rows }) => setCheckedPhotos([...rows]))
        .catch((error) => {
          handleError(error);
        })
        .finally(() => setLoadingPhotos(false));
    }
  };

  const disabledCreateRoute = useMemo(() => {
    if (repPermissions && !repPermissions?.routes?.create_edit) return true;

    return !checkedPhotos?.length;
  }, [checkedPhotos?.length, repPermissions]);

  const handleOpenConfirmDialog = useCallback(
    (confirmQuery) => {
      dispatch(openConfirmDialogAction(confirmQuery));
    },
    [dispatch]
  );

  const actionList = useMemo(() => {
    return [
      {
        label: "Create Route",
        disabled: disabledCreateRoute,
        element: null,
        onClick: handleCreateRoute,
        show: true,
      },
      {
        label: "Download Photos",
        element: null,
        disabled: false,
        onClick: handleDownloadPhotos,
        show: true,
      },
      {
        label: "Run merchandising report",
        element: null,
        disabled: false,
        onClick: handleDownloadsMerchandisingReport,
        show: true,
      },
      {
        label: "Edit Photo Tags",
        element: null,
        disabled: false,
        onClick: handleOpenTags,
        show: true,
      },
    ];
  }, [
    disabledCreateRoute,
    handleCreateRoute,
    handleDownloadPhotos,
    handleDownloadsMerchandisingReport,
    handleOpenTags,
  ]);

  const dropDownActionsList = useMemo(() => {
    return [
      {
        label: "Delete",
        element: (
          <ListItemText
            classes={{ primary: "deleteItem" }}
            sx={{
              "& > .MuiTypography-root": { color: "#FF6254", ml: "1px" },
            }}
          >
            Delete
          </ListItemText>
        ),
        disabled: false,
        onClick: () =>
          handleOpenConfirmDialog({
            title: (
              <Box display="flex" alignItems="center">
                <DangerIcon />
                <span style={{ marginLeft: "11px" }}>Delete Photo(s)?</span>
              </Box>
            ),
            text: "Please confirm that you would like to delete selected photo(s). All data will be erased and this can't be undone.",
            buttons: (
              <>
                <Button
                  sx={{
                    width: "98px",
                    color: "#6A6A6A",
                    borderColor: "#D4D4D4",
                    fontSize: "13px",
                    height: "28px",
                  }}
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: "98px",
                    color: "#FFFFFF",
                    fontSize: "13px",
                    height: "28px",
                    boxShadow: "none",
                  }}
                  color="confirmDelete"
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                    handleDeletePhotos();
                  }}
                  variant="contained"
                >
                  Confirm
                </Button>
              </>
            ),
          }),
        show: true,
      },
    ];
  }, [dispatch, handleDeletePhotos, handleOpenConfirmDialog]);

  const handleAddEndListener = useCallback(() => {
    const debounced = debounce(() => setShowControlPanel(true), COLLAPSE_MS);
    checkedPhotos?.length > 0 ? debounced() : setShowControlPanel(false);
  }, [checkedPhotos?.length]);

  useEffect(() => {
    if (!windowHeight) return;

    if (checkedPhotos?.length > 0 && !showControlPanel) {
      setHeight(windowHeight - 256 - 29);
      return;
    }

    if (checkedPhotos?.length === 0 && showControlPanel === null) {
      setHeight(windowHeight - 256);
      return;
    }

    if (checkedPhotos?.length === 0 && showControlPanel === false) {
      const debounced = debounce(() => {
        setHeight(windowHeight - 256);
      }, COLLAPSE_MS);

      debounced();
    }
  }, [checkedPhotos?.length, showControlPanel, windowHeight]);

  return {
    openTagsDrawer,
    setOpenTagsDrawer,
    handleCloseTagsDrawer,
    handleSaveTags,
    loadingTagsDrawer,
    setLoadingTagsDrawer,
    checkedPhotos,
    setCheckedPhotos,
    isOpenProductDialog,
    setPage,
    productTags,
    currentPage,
    setCurrentPage,
    handleCheckPhoto,
    handleOpenProductDialog,
    currentUser,
    actionList,
    dropDownActionsList,
    galleryLoading,
    loadingPhotos,
    handleSetCheckAllCustomers,
    galleryCount,
    handleSetCheckedPhotosHeader,
    galleryList,
    columnCount,
    windowWidth,
    handleFetch,
    rowCount,
    COLUMN_WIDTH,
    calculateWidth,
    PHOTO_GAP,
    handleSetProduct,
    handleCloseProductDialog,
    showControlPanel,
    COLLAPSE_MS,
    handleAddEndListener,
    height,
  };
};
