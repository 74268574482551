import { useCallback, useMemo, useRef } from "react";

import moment from "moment";
import { uniqBy } from "lodash";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { object, string, func, bool } from "prop-types";

import {
  photoUrl,
  getFormattedDate,
  separateNumWithComma,
  formatDate,
  getDiffTime,
} from "helpers/helpers";
import {
  PicIcon,
  FlagIcon,
  NoteIcon,
  PhotoCommentIcon,
  PurchaseOrderIcon,
} from "components/Icons/Activities";
import { TaskItem } from "./TaskItem";
import { NoteItem } from "./NoteItem";
import { PhotoItem } from "./PhotoItem";
import { OrderItem } from "./OrderItem";
import { NextArrow, PrevArrow } from "./Arrows";
import { TaskSmallIcon } from "components/Icons";
import { RemovedPhotoItem } from "./RemovedPhotoItem";
import qbLogo from "assets/logos/quickbookLogo.png";
import {
  PulseBlob,
  CheckInStatusBlock,
  // StyledLabel,
  StyledAvatar,
  StyledTooltip,
} from "components";

import { useDaysAgo, useRepsPermissions } from "helpers/hooks";

import { cl } from "../styles";

import { Box, Paper, Stack, Typography } from "@mui/material";

export const ItemList = ({
  item,
  timeZone,
  distributor,
  handleSetAssignedPopover,
  onOpenProductDialog,
  onClickCheckin,
  checkInCustomerRadiusEnabled,
  handleOpenCustomerNotes,
  handleCustomerTaskDrawer,
}) => {
  const navigate = useNavigate();
  const {
    ItemList: { paper },
  } = cl;

  const repPermissions = useRepsPermissions();

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <NextArrow top="10%" />,
    prevArrow: <PrevArrow top="10%" />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const disabledOrdersCreateEdit = useMemo(() => {
  //   if (repPermissions) {
  //     return !repPermissions?.orders?.create_edit;
  //   }
  // }, [repPermissions]);

  const disabledOrdersView = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.orders?.view;
    }
  }, [repPermissions]);

  const disabledCustomersGallery = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.gallery;
    }
  }, [repPermissions]);

  const disabledCustomersCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  const disabledCustomersView = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.view;
    }
  }, [repPermissions]);

  const isCheckIn = useMemo(
    () => item?.checkIn?.status === "CHECK_IN",
    [item?.checkIn?.status]
  );

  const profilePhoto = useCallback(
    (user) => user?.avatar || photoUrl(user?.profilePhoto?.fileName),
    []
  );

  const checkInTime = useMemo(() => {
    return item?.checkIn?.checkedInAt
      ? item?.checkIn?.checkedInAt
      : item?.createdAt;
  }, [item?.checkIn?.checkedInAt, item?.createdAt]);

  const checkOutTime = useMemo(() => {
    return item?.checkIn?.checkedOutAt ? item?.checkIn?.checkedOutAt : null;
  }, [item?.checkIn?.checkedOutAt]);

  const photoItems = useMemo(() => {
    const photoGroups = item?.photoGroups;
    const photoGroupComments = item?.photoGroupComments
      ?.map((el) => el.photoGroup)
      .map((photo) => photo);
    return uniqBy(photoGroups.concat(photoGroupComments), (v) => v.id);
  }, [item?.photoGroupComments, item?.photoGroups]);

  const activityStatusRef = useRef();

  const activityStatus = useMemo(() => {
    if (item?.type === "CHECKED_IN") {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
          onClick={() => item && onClickCheckin(item)}
        >
          <FlagIcon />
          <Typography fontSize="12px" color="#42A57F" ml="6px" mr="3px" noWrap>
            Checked in
          </Typography>
        </Box>
      );
    }

    if (item?.type === "PHOTO_GROUP_ADDED") {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() => {
            if (disabledCustomersGallery) return;
            !!photoItems?.length && onOpenProductDialog(photoItems[0]);
          }}
        >
          <Box sx={{ display: "flex", ml: "-3px" }}>
            <PicIcon />
          </Box>
          <Typography fontSize="12px" color="#42A57F" ml="3px" mr="3px" noWrap>
            Photo
          </Typography>
        </Box>
      );
    }

    if (item?.type === "NOTE_ADDED") {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() => {
            if (disabledCustomersCreateEdit) return;
            !!item?.notes?.length &&
              handleOpenCustomerNotes({
                customer: {
                  id: item?.customer?.id,
                  name: item?.customer?.displayName || item?.customer?.name,
                },
              });
          }}
        >
          <NoteIcon />
          <Typography fontSize="12px" color="#42A57F" ml="5px" mr="3px" noWrap>
            Note
          </Typography>
        </Box>
      );
    }

    if (item?.type === "COMMENT_ADDED") {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() => {
            if (disabledCustomersGallery) return;
            !!photoItems?.length && onOpenProductDialog(photoItems[0]);
          }}
        >
          <PhotoCommentIcon />
          <Typography fontSize="12px" color="#42A57F" ml="7px" mr="3px" noWrap>
            {/* {item?.createdBy === "DISTRIBUTOR"
              ? "Comment created by admin"
              : "Photo Comment"} */}
            Photo Comment
          </Typography>
        </Box>
      );
    }

    if (item?.type === "ORDER_ADDED") {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() => {
            if (disabledOrdersView) return;
            !!item?.orders?.length && navigate(`orders/${item?.orders[0].id}`);
          }}
        >
          <PurchaseOrderIcon />
          <Typography fontSize="12px" color="#42A57F" ml="7px" mr="3px" noWrap>
            Order
          </Typography>
        </Box>
      );
    }

    if (item?.type === "TASK_COMPLETED" && item?.checkIn === null) {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() => {
            if (disabledCustomersCreateEdit) return;
            !!item?.tasks?.length &&
              handleCustomerTaskDrawer(true, item?.tasks[0]);
          }}
        >
          <TaskSmallIcon />
          <Typography fontSize="12px" color="#42A57F" ml="7px" mr="3px" noWrap>
            Completed task
          </Typography>
        </Box>
      );
    }

    if (item?.type === "TASK_ADDED" && item?.checkIn === null) {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() => {
            if (disabledCustomersCreateEdit) return;
            !!item?.tasks?.length &&
              handleCustomerTaskDrawer(true, item?.tasks[0]);
          }}
        >
          <TaskSmallIcon />
          <Typography fontSize="12px" color="#42A57F" ml="7px" mr="3px" noWrap>
            Created task
          </Typography>
        </Box>
      );
    }

    return "- ";
  }, [
    disabledCustomersCreateEdit,
    disabledCustomersGallery,
    disabledOrdersView,
    handleCustomerTaskDrawer,
    handleOpenCustomerNotes,
    item,
    navigate,
    onClickCheckin,
    onOpenProductDialog,
    photoItems,
  ]);

  const setRemovedBy = useMemo(() => {
    if (!photoItems?.length) {
      return item?.distributor
        ? "Admin"
        : item?.representativeDuplicate?.name || "";
    }
  }, [item, photoItems]);

  const formattedDate = formatDate({
    utc_date: item?.updatedAt,
    formatThisYear: "MMM D, h:mm a",
  });

  const setRemovedAt = useMemo(() => {
    if (!photoItems?.length) {
      return (
        moment(
          getFormattedDate(item?.updatedAt || item?.createdAt, timeZone)
        ).format(formattedDate) || ""
      );
    }
  }, [
    formattedDate,
    item?.createdAt,
    item?.updatedAt,
    photoItems?.length,
    timeZone,
  ]);

  const preparedDueDate = useDaysAgo({ date: checkInTime, timeZone });

  const isQuickbooksUser = item?.createdBy === "QUICKBOOKS";

  const preparedAvatarData = useMemo(() => {
    return {
      name:
        item?.createdBy === "DISTRIBUTOR"
          ? ""
          : item?.representativeDuplicate?.name ||
            item?.appCustomer?.name ||
            distributor.name,

      src: isQuickbooksUser
        ? qbLogo
        : profilePhoto(
            item?.representativeDuplicate || item?.appCustomer || distributor
          ),

      onMouseEnter: (e) =>
        handleSetAssignedPopover(
          e.target,
          (item?.createdBy === "DISTRIBUTOR" && { name: "You" }) ||
            item?.representativeDuplicate ||
            item?.appCustomer ||
            distributor,
          true
        ),
      onMouseLeave: (e) =>
        handleSetAssignedPopover(
          e.target,
          (item?.createdBy === "DISTRIBUTOR" && { name: "You" }) ||
            item?.representativeDuplicate ||
            item?.appCustomer ||
            distributor,
          false
        ),
    };
  }, [
    distributor,
    handleSetAssignedPopover,
    isQuickbooksUser,
    item?.appCustomer,
    item?.createdBy,
    item?.representativeDuplicate,
    profilePhoto,
  ]);

  // const showDeletedLabel = useMemo(() => {
  //   const type = item?.type;
  //   if (type === "TASK_ADDED") return !item?.tasks?.length;
  //   if (type === "TASK_COMPLETED") return !item?.tasks?.length;
  //   if (type === "ORDER_ADDED") return !item?.orders?.length;
  //   if (type === "NOTE_ADDED") return false;

  //   return !(
  //     item.representativeDuplicate?.representativeId ||
  //     item?.appCustomer?.id ||
  //     (item?.type === "COMMENT_ADDED" && item?.createdBy === "DISTRIBUTOR")
  //   );
  // }, [
  //   item?.appCustomer?.id,
  //   item?.createdBy,
  //   item?.orders?.length,
  //   item.representativeDuplicate?.representativeId,
  //   item?.tasks?.length,
  //   item?.type,
  // ]);

  const setName = useMemo(() => {
    const { representativeDuplicate, appCustomer, type, notes, createdBy } =
      item || {};

    if (createdBy === "APP_CUSTOMER") return "Order direct";

    if (representativeDuplicate?.name || appCustomer?.name) {
      // return "Direct Order ";
      return representativeDuplicate?.name || appCustomer?.name;
    }

    if (type === "NOTE_ADDED" && notes?.[0]?.representativeDuplicate === null) {
      return "Admin ";
    }

    if (createdBy === "DISTRIBUTOR") return "Admin ";
  }, [item]);

  const activityStatusRefWidth = useMemo(() => {
    return activityStatusRef?.current?.clientWidth || 100;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityStatus, activityStatusRef?.current]);

  const checkInMoment = checkInTime
    ? moment(getFormattedDate(checkInTime, timeZone)).format("h:mm a")
    : null;
  const checkOutMoment = checkOutTime
    ? moment(getFormattedDate(checkOutTime, timeZone)).format("h:mm a")
    : null;

  const diffTime = getDiffTime(checkInTime, checkOutTime, timeZone);

  return (
    <>
      <Paper sx={paper} elevation={0} square>
        {/* Avatar */}
        <Box ml={2.25} mr={1.5}>
          <StyledAvatar
            sx={{ width: 42.25, height: 42.25 }}
            name={preparedAvatarData.name}
            src={preparedAvatarData.src}
            stringStyle={{ fontSize: 12 }}
            onMouseEnter={preparedAvatarData.onMouseEnter}
            onMouseLeave={preparedAvatarData.onMouseLeave}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "calc(100% - 73px)",
            maxWidth: "calc(100% - 73px)",
            justifyContent: "space-between",
          }}
        >
          {/* Info block */}
          <Box sx={{ width: "58%", maxWidth: "58%" }}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography
                fontSize={12}
                fontWeight={500}
                color="#1C1C19"
                noWrap
                data-testid="activity-author-name"
              >
                {setName}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  color: "#C6C6C6",
                  minWidth: "fit-content",
                }}
                noWrap
              >
                {preparedDueDate}
                {checkInTime ? ` | ${checkInMoment}` : null}{" "}
                {checkOutTime ? ` - ${checkOutMoment}` : null}{" "}
                {!!diffTime && diffTime}
              </Typography>
              {isCheckIn && <PulseBlob />}
            </Box>

            <Box
              sx={{
                display: "flex",
                "& > *:first-of-type": {
                  "& > *:first-of-type": {
                    p: {
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    },
                  },
                },
              }}
            >
              <Box ref={activityStatusRef}>{activityStatus}</Box>
              <Box sx={{ width: `calc(100% - ${activityStatusRefWidth}px)` }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 300,
                    color: "#1C1C19",
                  }}
                  noWrap
                >
                  at{" "}
                  <Box
                    component="span"
                    fontWeight={500}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => {
                      if (disabledCustomersView) return;
                      navigate(`customers/${item?.customer.id}`);
                    }}
                  >
                    {item?.customer?.displayName || item?.customer?.name}
                  </Box>
                </Typography>
              </Box>
              {/* {showDeletedLabel && (
                <Box
                  display="flex"
                  alignItems="center"
                  gap="6px"
                  ml={0.5}
                  mt="2px"
                  height="100%"
                >
                  <StyledLabel text="deleted" />
                </Box>
              )} */}
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 300,
                  color: "#000000",
                }}
                noWrap
              >
                {item?.customer?.shippingAddress?.formatted_address}
              </Typography>
            </Box>
          </Box>

          {/* CheckIn block */}
          <Box
            className="checkin-block"
            sx={{
              width: "42%",
              maxWidth: "42%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              maxWidth={{
                xs: "128px",
                xl: "246px",
              }}
              sx={{
                width: "100%",
                height: "57px",
                "& .slick-track": {
                  display: "flex",
                  alignItems: "flex-start",
                  mr: "unset",
                },
                "& .slick-next": {
                  right: "-20px",
                },
              }}
            >
              <Slider {...sliderSettings}>
                {/* tasks */}
                {item?.tasks?.length
                  ? item.tasks.map((task) => {
                      return (
                        <TaskItem
                          key={task.id}
                          onClick={() => {
                            if (disabledCustomersCreateEdit) return;
                            handleCustomerTaskDrawer(true, task);
                          }}
                        />
                      );
                    })
                  : null}

                {/* orders */}
                {item?.orders?.length
                  ? item?.orders?.map((order) => {
                      return (
                        <Box key={order?.id}>
                          <StyledTooltip
                            arrow
                            isDark
                            placement="top"
                            sx={{
                              "& .MuiTooltip-tooltip": {
                                marginRight: "6px",
                              },
                            }}
                            title={
                              <>
                                <Box fontWeight="300">
                                  Order: {order?.customId}
                                </Box>
                                <Box fontWeight="300">
                                  Total amount: $
                                  {separateNumWithComma(order?.totalAmount)}
                                </Box>
                              </>
                            }
                          >
                            <Box>
                              <OrderItem
                                onClickOnOrder={() => {
                                  if (disabledOrdersView) return;
                                  navigate(`orders/${order?.id}`);
                                }}
                                order={order}
                              />
                            </Box>
                          </StyledTooltip>
                        </Box>
                      );
                    })
                  : null}
                {/* photos / photos comments */}
                {photoItems?.length
                  ? photoItems.map((photo) => {
                      return (
                        <PhotoItem
                          key={photo?.id}
                          photo={photo}
                          onOpenProductDialog={
                            disabledCustomersGallery
                              ? () => {}
                              : onOpenProductDialog
                          }
                        />
                      );
                    })
                  : null}
                {/* notes */}
                {item?.notes?.length
                  ? item.notes.map((note) => {
                      return (
                        <NoteItem
                          key={note?.id}
                          note={note}
                          item={item}
                          handleClickCustomerNotes={() => {
                            // handleOpenCustomerNotes(note)

                            if (disabledCustomersCreateEdit) return;

                            handleOpenCustomerNotes({
                              customer: {
                                id: item?.customer?.id,
                                name:
                                  item?.customer?.displayName ||
                                  item?.customer?.name,
                              },
                            });
                          }}
                        />
                      );
                    })
                  : null}
                {/* deleted a pic */}
                {!photoItems?.length &&
                  item?.type === "PHOTO_GROUP_ADDED" &&
                  !item?.notes?.length &&
                  !item?.orders?.length && (
                    <RemovedPhotoItem
                      type="Photo"
                      removedBy={setRemovedBy}
                      removedAt={setRemovedAt}
                    />
                  )}
                {/* deleted a note */}
                {!photoItems?.length &&
                  item?.type === "NOTE_ADDED" &&
                  !item?.notes?.length &&
                  !item?.orders?.length && (
                    <RemovedPhotoItem
                      type="Note"
                      removedBy={setRemovedBy}
                      removedAt={setRemovedAt}
                    />
                  )}
                {/* deleted a task */}
                {!photoItems?.length &&
                  item?.type === "TASK_ADDED" &&
                  !item?.notes?.length &&
                  !item?.orders?.length &&
                  !item?.tasks?.length && (
                    <RemovedPhotoItem
                      type="Task"
                      removedBy={setRemovedBy}
                      removedAt={setRemovedAt}
                    />
                  )}
              </Slider>
            </Box>

            {/*<Box width={{ xs: "10%", xl: "6%" }} />*/}

            {/* {item?.createdBy === "DISTRIBUTOR" ? (
              <Box width="22.6px" />
            ) : ( */}
            <Stack
              justifyContent="center"
              width="59px"
              pr={0.75}
              alignItems="center"
            >
              <CheckInStatusBlock
                {...{ item, onClickCheckin }}
                showCheckInStatus={checkInCustomerRadiusEnabled}
                styleWrapper={{ pl: 1 }}
              />
            </Stack>
            {/* )} */}

            {/*<Box pr={{ xl: 3, xs: 0.4 }} />*/}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

ItemList.propTypes = {
  handleFetchActivities: func,
  item: object,
  timeZone: string,
  distributor: object,
  handleSetAssignedPopover: func,
  onOpenProductDialog: func,
  params: object,
  onClickCheckin: func,
  notAvailable: bool,
  checkInCustomerRadiusEnabled: bool,
  handleOpenCustomerNotes: func,
  handleCustomerTaskDrawer: func,
};
