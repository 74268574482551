import { object } from "prop-types";
import { PaperWrapper } from "components";
import CustomerCardDetails from "./components/CustomerCardDetails";

const CustomerCardInfo = ({ wrapperStyle = {}, ...props }) => {
  return (
    <PaperWrapper {...{ wrapperStyle }}>
      <CustomerCardDetails {...props} />
    </PaperWrapper>
  );
};
CustomerCardInfo.propTypes = { wrapperStyle: object };

export default CustomerCardInfo;
