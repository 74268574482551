import GoogleMapReact from "google-map-react";
import { useState, useEffect, useRef } from "react";
import { object, func, array, bool, string, number } from "prop-types";
import {
  mapOptions,
  polygonOptions,
} from "../../../../../../../utils/mapOptions.js";
import {
  Box,
  CircularProgress,
  Menu,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { createSelector } from "reselect";
import {
  customersCountSelector,
  customersInactiveCountSelector,
  customersLoadingSelector,
  customersShowInactiveSelector,
  customersStatusFilterSelector,
} from "../../../../../../../redux/selectors/customers.js";
import { useDispatch, useSelector } from "react-redux";
import MapPoint from "../../../../../../../components/MapPoint/MapPoint.jsx";
import {
  getCustomersService,
  updateCustomerService,
} from "../../../../../../../services/customers";
import {
  error,
  warningMiddle,
} from "../../../../../../../utils/notifications.js";
import useSupercluster from "use-supercluster";
import { LargeCheckbox } from "../../../../../../../components/Checkboxes";
import { useCallback } from "react";
import { useMemo } from "react";
import StyledButton from "../../../../../../../components/StyledButton/index.jsx";
import useStyles from "./styles";
import CreateRouteMenu from "./components/CreateRouteMenu/CreateRouteMenu.jsx";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setPictureItemAction } from "../../../../../../../redux/actions/gallery.js";
import PhotoGroupPopup from "../../../../GalleryTab/components/PhotoGroupPopup/PhotoGroupPopup.jsx";
import { currentUserSelector } from "../../../../../../../redux/selectors/auth.js";
import { NoteDialog } from "../../../../../../../components/NoteDialog/NoteDialog.jsx";
import { PaperIcon } from "../../../../../../../components/Icons/PaperIcon.jsx";
import { InformationIcon } from "components/Icons/index.js";
import { useTheme } from "@emotion/react";
import {
  NoteDrawer,
  StyledTooltip,
  TableHeaderControlPanel,
  TaskDrawer,
} from "components";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants.js";
import { isEqual, uniqBy } from "lodash";
import { MapRoutesDrawer } from "components/MapRoutesDrawer/MapRoutesDrawer";
import { useRoutesAssigne } from "./useRoutesAssigne.js";
import { useRepsPermissions } from "helpers/hooks.js";
import { MARKER_TYPE } from "./TabMapComponent.constants.js";
import { filterByCoordinates } from "./utils.js";
import { useReceivingHours } from "Pages/CustomersPage/pages/CustomerPage/components/CustomerMainBody/components/ReceivingHoursBlock/useReceivingHours.js";
import { WEEK_DAYS } from "components/ReceivingHoursDrawer/ReceivingHoursDrawer.constants.js";
import ReceivingHoursDrawer from "components/ReceivingHoursDrawer/ReceivingHoursDrawer.jsx";
import {
  setIncomingReceivingHours,
  setReceivingHours,
} from "Pages/CustomersPage/pages/CustomerPage/CustomerPage.helpers.js";
import { handleError, useAdmin } from "helpers/helpers.js";

const selector = createSelector(
  customersLoadingSelector,
  currentUserSelector,
  customersStatusFilterSelector,
  customersShowInactiveSelector,
  customersInactiveCountSelector,
  customersCountSelector,
  (
    customersLoading,
    currentUser,
    customersStatus,
    showInactive,
    customersInactiveCount,
    customersActiveCount
  ) => ({
    customersLoading,
    currentUser,
    customersStatus,
    showInactive,
    customersInactiveCount,
    customersActiveCount,
  })
);

const defaultMapProps = {
  center: {
    lat: 40.72316765399142,
    lng: -97.99994350677083,
  },
  zoom: 4,
};

const createMapOptions = () => {
  return {
    styles: mapOptions,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 9,
    },
  };
};

const TabMapComponent = ({
  styles,
  handleCheckCustomer,
  checkedCustomers,
  canDraw,
  setCheckedCustomers,
  setShapes,
  clearShapes,
  handleCancelSelection,
  customMapOptions,
  stops,
  note,
  handleCreateRoute,
  routePolylines,
  hasRoute,
  pendingRouteRecalculation,
  showCustomers,
  handleApplyFilter,
  currentMissingInfoCount,
  showProspectsToggle,
  params,
  showHeader,
  additionalActions,
  handleSetCheckAllCustomers,
  actionList,
  findAndReplaceCheckedCustomers,
  loadingCustomers,
  isCreateRoute,
  handleFetchList,
  isEditRoute,
  autoZoomDistributor,
  showCustomersToggle,
}) => {
  const {
    customersLoading,
    currentUser,
    customersStatus,
    showInactive,
    customersInactiveCount,
    customersActiveCount,
  } = useSelector(selector);
  const theme = useTheme();

  const isAdmin = useAdmin();

  const repPermissions = useRepsPermissions();
  const primaryColor = theme.palette.primary.main;
  const yellowColor = theme.palette.yellow.main;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startedDragging, setStartedDragging] = useState(false);
  const infoFill = {
    with_missing_info: { value: "with_missing_info", name: "Missing info" },
  };
  const [mapLoading, setMapLoading] = useState(false);
  const [zoom, setZoom] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const [bounds, setBounds] = useState(null);
  const [autoZoomUsed, setAutoZoomUsed] = useState(false);

  const [drawState, setDrawState] = useState({
    showDrawTools: false,
    drawingMode: false,
    mode: "click",
    mapApiLoaded: false,
    firstClick: false,
    finishedDrawing: false,
  });

  const [points, setPoints] = useState({
    show: false,
    list: [],
    fetched: false,
    loading: false,
  });

  const [prospectsState, setProspectsState] = useState({
    show: false,
    list: [],
    loading: false,
    fetched: false,
  });

  const [tooltipState, setTooltipState] = useState({
    photoGroupOpen: false,
    notesOpen: false,
    notes: [],
    customer: {},
  });

  const [noteOpen, setNoteOpen] = useState(false);

  const classes = useStyles({
    showDrawTools: drawState.showDrawTools,
    showSelectedDrawing: fullscreen && !!checkedCustomers.length,
  });

  const { clusters, supercluster } = useSupercluster({
    points: uniqBy(points.list, ({ properties }) => properties.id),
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 13, minPoints: 5 },
  });

  const { clusters: prospectsClusters, supercluster: prospectsSupercluster } =
    useSupercluster({
      points: uniqBy(prospectsState.list, ({ properties }) => properties.id),
      bounds,
      zoom,
      options: { radius: 75, maxZoom: 13, minPoints: 5 },
    });

  const prepareDataForCluster = useCallback((arr, type) => {
    return arr.map((item) => ({
      type: "Feature",
      properties: { cluster: false, ...item },
      geometry: {
        type,
        coordinates: [
          parseFloat(item.shippingAddress?.lng),
          parseFloat(item.shippingAddress?.lat),
        ],
      },
    }));
  }, []);

  const groupCustomersByCoordinates = (customers) => {
    return customers.reduce((acc, customer) => {
      const { shippingAddress } = customer;
      if (!shippingAddress?.lat || !shippingAddress?.lng) return acc;

      const key = `${shippingAddress.lat}-${shippingAddress.lng}`;

      if (!acc[key]) {
        acc[key] = { ...customer, subAddresses: [] };
      } else {
        acc[key].subAddresses.push(customer);
      }

      return acc;
    }, {});
  };

  useEffect(() => {
    setPoints((prev) => ({
      ...prev,
      list: [],
      show: false,
      fetched: false,
    }));
    setProspectsState((prev) => ({
      ...prev,
      list: [],
      show: false,
      fetched: false,
    }));

    if (!isCreateRoute) !!setCheckedCustomers && setCheckedCustomers([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersStatus, isCreateRoute]);

  const statusRef = useRef(null);

  // const getProspects = useCallback(async () => {
  //   setProspectsState((prev) => ({ ...prev, loading: true }));
  //   try {
  //     const { rows: prospects } = await getCustomersService({
  //       status: `["${CUSTOMER_STATUS_FILTERS.prospect}"]`,
  //       with_activities: true,
  //     });
  //     setProspectsState((prev) => ({
  //       ...prev,
  //       loading: false,
  //       fetched: true,
  //       show: true,
  //       list: prepareDataForCluster(
  //         prospects.filter(
  //           ({ shippingAddress }) =>
  //             !!shippingAddress?.lat && !!shippingAddress?.lng
  //         ),
  //         MARKER_TYPE.prospect
  //       ),
  //     }));
  //   } catch (err) {
  //     setProspectsState((prev) => ({ ...prev, loading: false }));
  //     error(err?.response?.data?.message);
  //   }
  // }, [prepareDataForCluster]);

  const handleFetchRoutes = useCallback(
    (didCancel, status) => {
      if (customersLoading || !!stops || !showCustomers) return;
      setMapLoading(true);

      if (!points.show)
        setPoints((prev) => ({
          ...prev,
          list: [],
        }));

      if (!prospectsState.show)
        setProspectsState((prev) => ({
          ...prev,
          list: [],
        }));

      const fetchStatus = status ?? customersStatus;

      const isMultipleStatus =
        (customersStatus === "PROSPECT" && points.show) ||
        (prospectsState.show && points.show);

      const customerStatus =
        customersStatus === "INACTIVE" ? "INACTIVE" : "ACTIVE";

      const setStatus = isMultipleStatus
        ? `["${customerStatus}", "PROSPECT"]`
        : `["${fetchStatus}"]`;

      const preparedParams = {
        ...params,
        status: setStatus,
        with_activities: true,
      };

      if (isEqual(statusRef.current, preparedParams)) return;

      statusRef.current = preparedParams;

      getCustomersService(preparedParams)
        .then((res) => {
          if (didCancel) return;
          const filteredCustomersByCoordinates = res.rows.filter(
            ({ shippingAddress }) =>
              !!shippingAddress?.lat && !!shippingAddress?.lng
          );

          const groupedCustomers = groupCustomersByCoordinates(
            filteredCustomersByCoordinates
          );

          const customers = prepareDataForCluster(
            Object.values(groupedCustomers),
            fetchStatus
          );
          if (checkedCustomers.length) {
            findAndReplaceCheckedCustomers(filteredCustomersByCoordinates);
          }
          if (isMultipleStatus) {
            const prospectsList = customers.filter(
              (c) => c?.properties?.status === "PROSPECT"
            );
            const pointsList = customers.filter(
              (c) => c?.properties?.status === customerStatus
            );

            setProspectsState((prev) => ({
              ...prev,
              loading: false,
              //show: true,
              fetched: true,
              list: prospectsList,
            }));
            setPoints((prev) => ({
              ...prev,
              loading: false,
              //show: true,
              fetched: true,
              list: pointsList,
            }));

            return;
          }

          if (fetchStatus === CUSTOMER_STATUS_FILTERS.prospect) {
            setProspectsState((prev) => ({
              ...prev,
              loading: false,
              fetched: true,
              list: customers,
            }));
            setPoints((prev) => ({
              ...prev,
              fetched: false,
            }));
          } else {
            setPoints((prev) => ({
              ...prev,
              show: true,
              fetched: true,
              list: customers,
            }));

            if (
              fetchStatus !== CUSTOMER_STATUS_FILTERS.prospect &&
              customersStatus === CUSTOMER_STATUS_FILTERS.prospect
            ) {
              setProspectsState((prev) => ({
                ...prev,
                show: true,
              }));
            }
          }
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => {
          setMapLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customersLoading,
      stops,
      showCustomers,
      points.show,
      prospectsState.show,
      // customersStatus,
      params,
      prepareDataForCluster,
    ]
  );

  useEffect(() => {
    let didCancel = false;

    handleFetchRoutes(didCancel);
    return () => {
      didCancel = true;
    };
  }, [params, handleFetchRoutes]);

  const mapDrawShapeManagerRef = useRef(null);
  const mapsRef = useRef(null);
  const mapRef = useRef(null);
  const showControlsRef = useRef(null);
  const drawMenuRef = useRef(null);

  const noteButtonRef = useRef(null);

  const addShape = useCallback(
    (shape) => {
      setShapes((prev) => [...prev, shape]);
    },
    [setShapes]
  );

  const currentPoints = useMemo(
    () => [
      ...(prospectsState.show ? prospectsState.list : []),
      ...(points.show ? points.list : []),
    ],
    [points.list, points.show, prospectsState.list, prospectsState.show]
  );

  const onDrawCallback = useCallback(
    (shape) => {
      setDrawState((prev) => ({
        ...prev,
        finishedDrawing: true,
        firstClick: false,
      }));
      addShape(shape);
      let newChecked = [];
      currentPoints.forEach((point) => {
        const { coordinates } = point.geometry;
        const contains = mapsRef.current.geometry.poly.containsLocation(
          { lng: coordinates[0], lat: coordinates[1] },
          shape
        );
        if (contains) {
          const { properties: customer } = point;
          newChecked.push(customer);
        }
      });

      const uniqueCustomers = [
        ...new Map(
          [...checkedCustomers, ...newChecked].map((item) => [item.id, item])
        ).values(),
      ];
      setCheckedCustomers([...uniqueCustomers]);
    },
    [checkedCustomers, setCheckedCustomers, addShape, currentPoints]
  );

  const [polyline, setPolyline] = useState(null);

  const onMouseMove = useCallback(
    (e) => {
      if (
        drawState.mode !== "drag" ||
        !startedDragging ||
        !drawState.drawingMode
      )
        return;

      const p = polyline;
      p.getPath().push(e.latLng);
      setPolyline(p);
    },
    [drawState.drawingMode, drawState.mode, startedDragging, polyline]
  );

  useEffect(() => {
    if (!mapsRef.current || !drawState.mapApiLoaded) return;

    const listener = mapsRef.current.event.addListener(
      mapDrawShapeManagerRef.current,
      "polygoncomplete",
      onDrawCallback
    );

    return () => {
      if (!mapsRef.current) return;
      listener.remove();
    };
  }, [onDrawCallback, checkedCustomers, drawState.mapApiLoaded, currentPoints]);

  const drawCurrentRoute = useCallback(() => {
    setAutoZoomUsed(false);
    if (hasRoute && routePolylines)
      return routePolylines?.forEach((poly) => poly.setMap(mapRef.current));
    if (!stops?.length || !hasRoute)
      return routePolylines?.forEach((poly) => poly.setMap(null));
  }, [hasRoute, routePolylines, stops?.length]);

  useEffect(() => {
    drawCurrentRoute();
  }, [drawCurrentRoute]);

  const centerCurrentRoute = useCallback(() => {
    if (autoZoomUsed) return;
    const checkedCustomersWithCoordinates =
      filterByCoordinates(checkedCustomers);

    const routePoints = filterByCoordinates(
      isCreateRoute ? checkedCustomers : stops
    );

    const pointsCondition = isCreateRoute
      ? checkedCustomersWithCoordinates?.length >= 2
      : !!stops?.length;

    if (!mapsRef.current) return;

    if (
      // force distributor shipping location auto zoom externally (autoZoomDistributor) or auto zoom if no customers are checked (< 2)
      autoZoomDistributor ||
      (isCreateRoute &&
        !isEditRoute &&
        checkedCustomersWithCoordinates?.length < 2)
    ) {
      const { shippingAddress } = currentUser || {};
      const { lat, lng } = shippingAddress || {};
      if (!lat || !lng) return;
      mapRef.current.setCenter({
        lat,
        lng,
      });
      mapRef.current.setZoom(10);
      setAutoZoomUsed(true);
      return;
    }

    if (!pointsCondition) return;
    const routeBounds = new mapsRef.current.LatLngBounds();

    routePoints.forEach((stop) => {
      routeBounds.extend({
        lat: stop.lat || stop.shippingAddress.lat,
        lng: stop.lng || stop.shippingAddress.lng,
      });
    });
    const center = routeBounds.getCenter();
    mapRef.current.setCenter({
      lat: center.lat(),
      lng: center.lng(),
    });

    mapRef.current.fitBounds(routeBounds);
    setAutoZoomUsed(true);
  }, [
    autoZoomUsed,
    checkedCustomers,
    isCreateRoute,
    stops,
    autoZoomDistributor,
    isEditRoute,
    currentUser,
  ]);

  useEffect(() => {
    centerCurrentRoute();
  }, [centerCurrentRoute, drawState.mapApiLoaded]);

  useEffect(() => {
    if (!mapsRef.current || !mapRef.current) return;
    mapsRef.current.event.addListener(mapRef.current, "mousemove", onMouseMove);
    return () => {
      mapsRef.current.event.clearListeners(mapRef.current, "mousemove");
    };
  }, [onMouseMove]);

  const onGoogleApiLoaded = ({ map, maps }) => {
    map.setOptions(customMapOptions);
    map.setClickableIcons(false);
    mapRef.current = map;
    mapsRef.current = maps;
    mapDrawShapeManagerRef.current = new maps.drawing.DrawingManager({
      polygonOptions,
      circleOptions: polygonOptions,
      drawingControl: false,
    });

    mapDrawShapeManagerRef.current.setMap(map);

    setDrawState((prev) => ({ ...prev, mapApiLoaded: true }));
  };

  const handleFinishDragMode = useCallback(() => {
    setDrawState((prev) => ({ ...prev, firstClick: false }));
    if (!polyline) return;
    const polygon = new mapsRef.current.Polygon({
      map: mapRef.current,
      options: polygonOptions,
      paths: polyline.getPath(),
    });

    polyline.setMap(null);
    setPolyline(null);
    onDrawCallback(polygon);
    setStartedDragging(false);
  }, [onDrawCallback, polyline]);

  const setDrawingMode = () => {
    const { drawingMode, mode } = drawState;
    if (!drawingMode)
      warningMiddle(
        "Tap on the map to create a selection shape and quickly select customers."
      );
    if (mode === "click") {
      mapDrawShapeManagerRef.current.setDrawingMode(
        drawingMode ? null : "polygon"
      );
    }
    if (mode === "drag") {
      mapRef.current.setOptions({ draggableCursor: "crosshair" });
      mapDrawShapeManagerRef.current.setDrawingMode(null);

      if (drawingMode) {
        handleFinishDragMode();
      }
    }

    if (drawingMode) mapRef.current.setOptions({ draggableCursor: "grab" });

    setDrawState((prev) => ({
      ...prev,
      drawingMode: !drawingMode,
    }));
  };

  const handleShowDrawTools = () => {
    const { showDrawTools } = drawState;

    setDrawState((prev) => ({
      ...prev,
      showDrawTools: !prev.showDrawTools,
      drawingMode: showDrawTools ? false : prev.drawingMode,
    }));
    if (showDrawTools) {
      mapDrawShapeManagerRef.current.setDrawingMode(null);
      clearShapes();
    }
  };

  const getCheckedCount = (cluster, type) => {
    const currentSuperCluster =
      type === MARKER_TYPE.customer ? supercluster : prospectsSupercluster;

    const clusterCustomers = currentSuperCluster
      .getLeaves(cluster.id, Infinity)
      .map((sc) => sc.properties);
    const checkedLength = checkedCustomers.filter((checkedCustomer) =>
      clusterCustomers.some(
        (clusterCustomer) => clusterCustomer.id === checkedCustomer.id
      )
    ).length;

    return checkedLength;
  };

  const loading = useMemo(
    () => mapLoading || customersLoading,
    [customersLoading, mapLoading]
  );

  useEffect(() => {
    if (fullscreen && canDraw) {
      const gmap = document.fullscreenElement;
      gmap.appendChild(showControlsRef.current);
      gmap.appendChild(drawMenuRef.current);
    }
  }, [fullscreen, canDraw]);

  const handleChangeDrawMode = (e, newVal) => {
    setDrawState((prev) => ({ ...prev, mode: newVal }));

    if (newVal === "click") {
      setStartedDragging(false);
      if (drawState.drawingMode) {
        handleFinishDragMode();
        mapDrawShapeManagerRef.current.setDrawingMode("polygon");
      }
    }

    if (newVal === "drag") {
      mapDrawShapeManagerRef.current.setDrawingMode(null);

      if (drawState.drawingMode)
        mapRef.current.setOptions({ draggableCursor: "crosshair" });
    }
  };

  const handleMapClick = useCallback(() => {
    toast.dismiss();
    const { drawingMode, mode } = drawState;

    if (drawingMode) {
      if (mode === "drag") {
        setPolyline(
          new mapsRef.current.Polyline({
            map: mapRef.current,
            options: polygonOptions,
          })
        );
        setStartedDragging((prev) => !prev);
      }

      if (!drawState.firstClick && !drawState.finishedDrawing) {
        setDrawState((prev) => ({ ...prev, firstClick: true }));
      } else {
        setDrawState((prev) => ({ ...prev, finishedDrawing: false }));
      }
    }

    if (startedDragging) {
      handleFinishDragMode();
    }
  }, [handleFinishDragMode, drawState, startedDragging]);

  const handleOpenProductDialog = (el) => {
    dispatch(setPictureItemAction(el?.id));
    setTooltipState((prev) => ({ ...prev, photoGroupOpen: true }));
  };

  // const handleOpenNotes = (noteId, visit, customer) => {
  //   setTooltipState((prev) => ({
  //     ...prev,
  //     notesOpen: true,
  //     notes: visit?.activity?.notes,
  //     customer,
  //   }));
  // };

  const showRoutePlaceholder = useMemo(
    () => pendingRouteRecalculation || (stops && !stops.length),
    [pendingRouteRecalculation, stops]
  );

  // const getCustomers = useCallback(async () => {
  //   setPoints((prev) => ({ ...prev, loading: true }));
  //   try {
  //     const { rows: customers } = await getCustomersService({
  //       status: `["${CUSTOMER_STATUS_FILTERS.active}"]`,
  //       with_activities: true,
  //     });

  //     const filteredCustomersByCoordinates = customers.filter(
  //       ({ shippingAddress }) =>
  //         !!shippingAddress?.lat && !!shippingAddress?.lng
  //     );

  //     const groupedCustomers = groupCustomersByCoordinates(
  //       filteredCustomersByCoordinates
  //     );

  //     const preparedCustomers = prepareDataForCluster(
  //       Object.values(groupedCustomers),
  //       MARKER_TYPE.customer
  //     );

  //     setPoints((prev) => ({
  //       ...prev,
  //       loading: false,
  //       fetched: true,
  //       show: true,
  //       list: preparedCustomers,
  //     }));
  //   } catch (err) {
  //     setPoints((prev) => ({ ...prev, loading: false }));
  //     error(err?.response?.data?.message);
  //   }
  // }, [prepareDataForCluster]);

  const handleShowProspects = useCallback((show) => {
    // if (prospectsState.fetched) {
    setProspectsState((prev) => ({ ...prev, show: show ?? !prev.show }));
    // } else {
    //   getProspects();
    // }
  }, []);

  const handleShowCustomers = useCallback((show) => {
    //if (points.fetched) {
    setPoints((prev) => ({ ...prev, show: show ?? !prev.show }));
    //} else {
    //  getCustomers();
    //}
  }, []);

  const autoShowProspects = useCallback(() => {
    const hasProspects = checkedCustomers.some(
      ({ status }) => status === CUSTOMER_STATUS_FILTERS.prospect
    );
    if (hasProspects) {
      handleShowProspects(true);
    }
  }, [checkedCustomers, handleShowProspects]);

  useEffect(autoShowProspects, [autoShowProspects]);

  const {
    handleCloseDrawer,
    isDrawerOpen,
    onRouteChange,
    loading: drawerLoading,
    routesList,
    handleOpenDrawer,
    handleUnassignAllRoutes,
    onSubmit,
  } = useRoutesAssigne({
    refreshCallback: (status) => {
      setCheckedCustomers && setCheckedCustomers([]);
      handleFetchRoutes(false, status);
    },
  });

  const [receivingHoursData, setReceivingHoursData] = useState({
    weekDays: {},
    customer: null,
  });

  const { weekDays } = receivingHoursData;

  const handleUpdateCustomer = async (data, customerId) => {
    try {
      await updateCustomerService(data, customerId);
    } catch (err) {
      error(err?.response?.data?.message);
    }
  };

  const handleUpdateHours = async (_, data) => {
    const customer = receivingHoursData.customer;

    if (!customer.id) return;

    const receivingHours = setReceivingHours(data);

    await handleUpdateCustomer({ receivingHours }, customer?.id);

    setReceivingHoursData({
      weekDays: {},
      customer: null,
    });

    handleFetchRoutes(false, customer.status);

    if (handleFetchList) handleFetchList();
  };

  const onOpenReceivingHours = (customer) => {
    const { receivingHours } = customer || {};

    setReceivingHoursData({
      weekDays: receivingHours
        ? setIncomingReceivingHours(receivingHours)
        : WEEK_DAYS,
      customer,
    });

    handleOpenReceivingHours();
  };

  const onCloseReceivingHours = () => {
    handleCloseReceivingHours();
    setReceivingHoursData((prev) => ({
      ...prev,
      weekDays: {},
    }));
  };

  const {
    openReceivingHours,
    handleSetHours,
    handleOpenReceivingHours,
    handleCloseReceivingHours,
  } = useReceivingHours({
    setValue: handleUpdateHours,
    weekDays,
    onDelete: handleUpdateHours,
  });

  const [taskDialogState, setTaskDialogState] = useState({
    open: false,
    data: null,
  });

  const handleCustomerTaskDrawer = (open, data) => {
    setTaskDialogState({
      open,
      data,
    });
  };

  const [noteDialogState, setNoteDialogState] = useState({
    open: false,
    data: null,
  });

  const handleOpenCustomerNotes = useCallback((data) => {
    setNoteDialogState({
      open: true,
      data,
    });
  }, []);

  const handleCloseCustomerNotes = useCallback(() => {
    handleFetchRoutes(false, noteDialogState?.data?.status);
    if (handleFetchList) handleFetchList();

    setNoteDialogState({
      open: false,
      data: null,
    });
  }, [handleFetchList, handleFetchRoutes, noteDialogState?.data?.status]);

  const count = useMemo(
    () => (showInactive ? customersInactiveCount : customersActiveCount),
    [showInactive, customersInactiveCount, customersActiveCount]
  );

  const currentClusters = useMemo(() => {
    if (customersStatus === CUSTOMER_STATUS_FILTERS.prospect)
      return [
        ...prospectsClusters.map((c) => ({
          ...c,
          type: MARKER_TYPE.prospect,
        })),
        ...(points.show
          ? clusters.map((c) => ({ ...c, type: MARKER_TYPE.customer }))
          : []),
      ];

    if (prospectsState.show || points.show)
      return [
        ...(points.show
          ? clusters.map((c) => ({ ...c, type: MARKER_TYPE.customer }))
          : []),
        ...(prospectsState.show
          ? prospectsClusters.map((c) => ({
              ...c,
              type: MARKER_TYPE.prospect,
            }))
          : []),
      ];

    return clusters.map((c) => ({ ...c, type: MARKER_TYPE.customer }));
  }, [
    clusters,
    customersStatus,
    points.show,
    prospectsClusters,
    prospectsState.show,
  ]);

  const onMarkerClick = (customer, _, subAddressesList) => {
    if (isAdmin) return;
    if (subAddressesList?.length) {
      const hasAllCheckedIds = subAddressesList.every(({ id }) =>
        checkedCustomers?.some((c) => c.id === id)
      );

      if (hasAllCheckedIds) {
        const ids = subAddressesList.map(({ id }) => id);

        return setCheckedCustomers(
          checkedCustomers.filter((c) => !ids.includes(c.id))
        );
      }

      return setCheckedCustomers(uniqBy(subAddressesList, "id"));
    } else {
      handleCheckCustomer(customer);
    }
  };

  const onCreateCommentCallback = async (comment) => {
    const customerId = comment.customer.id;

    const indexForChange = points.list.findIndex(
      (c) => c?.properties?.id === customerId
    );

    if (indexForChange !== -1) {
      setMapLoading(true);
      try {
        const { rows } = await getCustomersService({
          customer_ids: JSON.stringify([customerId]),
          with_activities: true,
        });

        const data = [...points.list];

        data[indexForChange].properties.activities =
          rows?.[0]?.activities || [];

        setPoints((prev) => ({
          ...prev,
          list: data,
        }));

        setMapLoading(false);
      } catch (err) {
        setMapLoading(false);
        error(err?.response?.data?.message);
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 275px)",
        mb: "-70px",
        position: "relative",
        overflow: "hidden",
        ...styles,
      }}
    >
      {showHeader && (
        <TableHeaderControlPanel
          actionWrapperProps={{
            sx: { padding: "0 13px 0 4px !important" },
          }}
          checkedCount={checkedCustomers?.length}
          actionsList={additionalActions}
          loading={loadingCustomers}
          dropDownActions={actionList}
          onSelectAll={(value) => handleSetCheckAllCustomers(value)}
          hasCheckedItems={!!checkedCustomers?.length}
          availableSelectCount={count}
          selectName="customer"
          onSelectVisible={null}
          cancelSelection={() => {
            setCheckedCustomers([]);
            clearShapes();
          }}
          headerComponent={null}
        />
      )}
      {!!isDrawerOpen && (
        <MapRoutesDrawer
          open={!!isDrawerOpen}
          onClose={handleCloseDrawer}
          loading={drawerLoading}
          {...{ routesList, handleUnassignAllRoutes, onRouteChange, onSubmit }}
        />
      )}

      {!!openReceivingHours && !!Object.keys(weekDays)?.length && (
        <ReceivingHoursDrawer
          isOpen={openReceivingHours && !!Object.keys(weekDays)?.length}
          handleClose={onCloseReceivingHours}
          handleSetData={handleSetHours}
          weekDays={weekDays}
        />
      )}

      {!!noteDialogState.open && (
        <NoteDrawer
          open={noteDialogState.open}
          onClose={handleCloseCustomerNotes}
          data={noteDialogState.data}
          titleLabel="Notes"
        />
      )}

      {!!taskDialogState.open && (
        <TaskDrawer
          open={taskDialogState.open}
          onClose={() => handleCustomerTaskDrawer(false, null)}
          data={taskDialogState.data}
          refetchCallback={() => handleFetchRoutes(false)}
        />
      )}

      {!!tooltipState.photoGroupOpen && (
        <PhotoGroupPopup
          hidePagination
          user={{
            avatar: currentUser?.profilePhoto,
            name: currentUser?.name,
            id: currentUser?.id,
          }}
          isOpenProductDialog={tooltipState.photoGroupOpen}
          handleCloseProductDialog={() =>
            setTooltipState((prev) => ({ ...prev, photoGroupOpen: false }))
          }
          createCallback={onCreateCommentCallback}
        />
      )}

      {!!tooltipState.notesOpen && (
        <NoteDialog
          open={tooltipState.notesOpen}
          onClose={() =>
            setTooltipState((prev) => ({
              ...prev,
              notesOpen: false,
              notes: [],
            }))
          }
          notes={tooltipState.notes}
          data={tooltipState.customer}
        />
      )}

      <Menu
        id="basic-menu"
        anchorEl={noteButtonRef.current}
        open={!!noteOpen}
        onClose={() => setNoteOpen(false)}
        PaperProps={{
          sx: { border: "0.5px solid #CCCCCC" },
        }}
        sx={{
          backgroundColor: "transparent",
          ".MuiMenu-list": {
            padding: "9px 23px",
            background: "rgba(64, 154, 101, 0.1)",
          },
          "& .MuiTypography-root": {
            fontSize: "11px",
          },
        }}
      >
        <Typography>Notes:</Typography>
        <Typography color="#707070">{note}</Typography>
      </Menu>

      {showRoutePlaceholder && (
        <Box className={classes.routePlaceholder}>
          <StyledTooltip
            placement="top"
            arrow
            PopperProps={{
              modifiers: [{ name: "offset", options: { offset: [0, -6] } }],
            }}
            disableHoverListener={!pendingRouteRecalculation}
            title="The route view will be updated once the assigned representative will re-optimize it"
          >
            <Box className={classes.routePlaceholderMessage}>
              {pendingRouteRecalculation && (
                <InformationIcon stroke={theme.palette.error.main} size="20" />
              )}
              <Typography
                fontSize="20px"
                color={pendingRouteRecalculation ? "error" : "primary"}
              >
                {pendingRouteRecalculation
                  ? "Pending Recalculation"
                  : "Select representative to show route."}
              </Typography>
            </Box>
          </StyledTooltip>
        </Box>
      )}
      {loading && (
        <CircularProgress
          size="50px"
          sx={{
            position: "absolute",
            zIndex: "100",
            top: checkedCustomers?.length ? "69px" : "30px",
            left: "62px",
          }}
        />
      )}
      <GoogleMapReact
        bootstrapURLKeys={{
          // eslint-disable-next-line no-undef
          key: process.env.REACT_APP_MAP_KEY,
          libraries: ["drawing", "geometry", "directions", "places"],
        }}
        onClick={handleMapClick}
        defaultCenter={defaultMapProps.center}
        defaultZoom={defaultMapProps.zoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        shouldUnregisterMapOnUnmount
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={({ zoom, bounds, size }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
          if (size.width === window.innerWidth) return setFullscreen(true);
          setFullscreen(false);
        }}
      >
        {stops?.map((stop, index) => {
          const isProspect = stop.status === CUSTOMER_STATUS_FILTERS.prospect;
          const color = isProspect ? yellowColor : primaryColor;

          return (
            <MapPoint
              isCluster={false}
              count={0}
              length={0}
              key={index}
              address={
                stop.shippingAddress?.formatted_address ||
                stop.formatted_address
              }
              customer={stop}
              color={color}
              completed={stop.representativeStop || !!stop.startLoc}
              lat={stop.shippingAddress?.lat || stop.lat}
              lng={stop.shippingAddress?.lng || stop.lng}
              index={stop.started ? index : 0}
              startLoc={!!stop.startLoc}
              endLoc={!!stop.endLoc}
              handleOpenRoutesDrawer={handleOpenDrawer}
              handleOpenReceivingHours={onOpenReceivingHours}
              // handleOpenCustomerNotes={handleOpenCustomerNotes}
              handleCustomerTaskDrawer={handleCustomerTaskDrawer}
              currentUser={currentUser}
              handleOpenNotes={handleOpenCustomerNotes}
              {...{
                handleOpenProductDialog,
                currentUser,
              }}
            />
          );
        })}
        {currentClusters.map((cluster) => {
          const {
            cluster: isCluster,
            point_count: pointCount,
            id,
            type: propertiesType,
            status,
          } = cluster.properties;
          const customer = cluster.properties;
          const [lng, lat] = cluster.geometry.coordinates;
          const type = propertiesType ?? cluster.type;
          const isProspect =
            status === CUSTOMER_STATUS_FILTERS.prospect ||
            type === MARKER_TYPE.prospect;
          const color = isProspect ? yellowColor : primaryColor;
          if (isCluster) {
            const checkedCount = getCheckedCount(cluster, type);
            return (
              <MapPoint
                key={cluster.id || id}
                isCluster
                count={pointCount}
                lng={lng}
                lat={lat}
                length={
                  isProspect ? prospectsState.list.length : points.list.length
                }
                color={color}
                checkedCount={checkedCount}
                handleOpenRoutesDrawer={handleOpenDrawer}
              />
            );
          }

          return (
            <MapPoint
              isCluster={false}
              count={0}
              length={0}
              key={customer?.id}
              checkedCustomers={checkedCustomers}
              handleCheckCustomer={handleCheckCustomer}
              isChecked={checkedCustomers.some((it) => it.id === customer?.id)}
              onMarkerClick={onMarkerClick}
              address={customer?.shippingAddress?.formatted_address}
              subAddresses={customer?.subAddresses}
              customer={customer}
              color={color}
              setCheckedCustomers={setCheckedCustomers}
              handleOpenRoutesDrawer={handleOpenDrawer}
              handleOpenReceivingHours={onOpenReceivingHours}
              handleCustomerTaskDrawer={handleCustomerTaskDrawer}
              lat={customer?.shippingAddress?.lat}
              lng={customer?.shippingAddress?.lng}
              handleOpenNotes={handleOpenCustomerNotes}
              {...{
                handleOpenProductDialog,
                currentUser,
              }}
            />
          );
        })}
      </GoogleMapReact>
      {note && (
        <StyledButton
          className={classes.noteButton}
          label={<PaperIcon color="#707070" size={27} />}
          color="white"
          variant="contained"
          ref={noteButtonRef}
          onClick={() => setNoteOpen(true)}
        />
      )}
      {currentMissingInfoCount > 0 && (
        <Box className={classes.showMissing}>
          <Typography sx={{ fontSize: "16px", color: "#EB4233" }}>
            {currentMissingInfoCount} missing info
          </Typography>
          <Typography
            sx={{
              textDecoration: "underline",
              fontSize: "16px",
              color: "#47A06D",
              cursor: "pointer",
            }}
            onClick={() => {
              handleApplyFilter(infoFill.with_missing_info);
            }}
          >
            View
          </Typography>
        </Box>
      )}

      {showProspectsToggle && (
        <Box className={classes.showProspectsWrapper}>
          <LargeCheckbox
            label="Show prospects"
            labelSx={{
              fontSize: "12px",
            }}
            checked={prospectsState.show}
            onChange={() => handleShowProspects()}
            size={17}
            disabled={mapLoading || prospectsState.loading}
          />
        </Box>
      )}

      {showCustomersToggle && (
        <Box className={classes.showProspectsWrapper}>
          <LargeCheckbox
            label="Show customers"
            labelSx={{
              fontSize: "12px",
            }}
            checked={points.show}
            onChange={() => handleShowCustomers()}
            disabled={mapLoading || points.loading}
            size={17}
          />
        </Box>
      )}

      {canDraw && (
        <Box ref={showControlsRef} className={classes.showControlsWrapper}>
          <LargeCheckbox
            label="Map Select Tool"
            labelSx={{
              fontSize: "12px",
            }}
            checked={drawState.showDrawTools}
            onChange={handleShowDrawTools}
            size={17}
          />
        </Box>
      )}
      {canDraw && (
        <>
          <Box className={classes.drawMenuWrapper} ref={drawMenuRef}>
            <Box
              className={`${classes.drawCreateBlockWrapper} ${classes.tools}`}
            >
              <StyledButton
                label={`${drawState.drawingMode ? "End" : "Start"} Draw`}
                sx={{
                  width: "91px",
                  height: "44px",
                  borderRadius: "13px 0 0 13px",
                  border: "0.5px solid #d5d9d9",
                  background: drawState.drawingMode ? "#EB4233" : "#47A06D",
                  "&:hover": {
                    background: drawState.drawingMode ? "#EB4233" : "#47A06D",
                  },
                }}
                color="white"
                fontSize="13px"
                onClick={setDrawingMode}
              />
            </Box>
            <Box className={`${classes.drawBlockWrapper} ${classes.tools}`}>
              <Tabs
                className={classes.drawTabs}
                value={drawState.mode}
                TabIndicatorProps={{ style: { display: "none" } }}
                onChange={handleChangeDrawMode}
              >
                <Tab
                  className={classes.drawTab}
                  label="Click Mode"
                  value="click"
                />
                <Tab
                  className={classes.drawTab}
                  label="Drag Mode"
                  value="drag"
                />
              </Tabs>
            </Box>
            <Box
              className={`${classes.drawBlockWrapper} ${classes.selectedMenu}`}
              ml="12px"
            >
              <CreateRouteMenu
                selectedNum={checkedCustomers.length}
                showSelectLabel
                createRouteProps={{
                  disabled:
                    repPermissions && !repPermissions?.routes?.create_edit,
                  color: "white",
                  sx: { color: "#fff", height: "32px", py: 0 },
                  onClick: handleCreateRoute,
                }}
                editProps={{
                  color: "white",
                  disabled: checkedCustomers.length > 1,
                  onClick: () =>
                    navigate(`/customers/${checkedCustomers[0].id}`),
                  sx: {
                    color: "#fff",
                    "&.Mui-disabled": {
                      color: "#fff",
                      opacity: "0.5",
                    },
                  },
                }}
                cancelProps={{
                  color: "error",
                  className: classes.searchButton,
                  onClick: handleCancelSelection,
                }}
                dividerProps={{
                  sx: {
                    background: "#fff",
                    height: "14px",
                    ml: "8px",
                  },
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

TabMapComponent.propTypes = {
  styles: object,
  handleCheckCustomer: func,
  checkedCustomers: array,
  canDraw: bool,
  setCheckedCustomers: func,
  setShapes: func,
  clearShapes: func,
  handleCancelSelection: func,
  customMapOptions: object,
  stops: array,
  note: string,
  handleCreateRoute: func,
  routePolylines: array,
  hasRoute: bool,
  pendingRouteRecalculation: bool,
  showCustomers: bool,
  handleApplyFilter: func,
  currentMissingInfoCount: number,
  showProspectsToggle: bool,
  showCustomersToggle: bool,
  params: object,
  showHeader: bool,
  loadingCustomers: bool,
  additionalActions: array,
  actionList: array,
  handleSetCheckAllCustomers: func,
  findAndReplaceCheckedCustomers: func,
  isCreateRoute: bool,
  handleFetchList: func,
  isEditRoute: bool,
  autoZoomDistributor: bool,
};

TabMapComponent.defaultProps = {
  styles: {},
  handleCheckCustomer: () => {},
  checkedCustomers: [],
  canDraw: false,
  customMapOptions: {},
  stops: null,
  note: "",
  handleCreateRoute: () => {},
  setAssignedAnchorEl: () => {},
  handleSetCheckAllCustomers: () => {},
  hasRoute: false,
  pendingRouteRecalculation: false,
  showCustomers: true,
  showProspectsToggle: false,
  params: {},
  showHeader: false,
  additionalActions: [],
  actionList: [],
  loadingCustomers: false,
  findAndReplaceCheckedCustomers: () => {},
  isCreateRoute: false,
  isEditRoute: false,
  autoZoomDistributor: false,
  showCustomersToggle: false,
};

export default TabMapComponent;
