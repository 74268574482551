import {
  getProductsService,
  createSingleProductService,
  updateSingleProductService,
  createMultipleProductService,
  updateMultipleProductService,
  bulkUpdateProductsCategoryService,
  bulkDeleteProductsService,
  deleteProductService,
  archiveProductsService,
  bulkUpdateProductsAssignmentService,
} from "../../services/products.js";

import { SCROLL_LIMIT } from "../../utils/constants.js";
import { success, error } from "../../utils/notifications";
import { setEditTypeAction } from "./confirmDialogs.js";
// import { getProductCategoriesAction } from "./product-categories.js";
import { getTagsAction } from "./tags.js";
import {
  getTotalSummariesCatalogAction,
  getTotalSummariesOrdersAction,
} from "./total-summaries.js";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const EXIST_PRODUCTS = "EXIST_PRODUCTS";
export const PRODUCTS_WITH_VARIATIONS_COUNT = "PRODUCTS_WITH_VARIATIONS_COUNT";
export const SET_PARENT_PRODUCT = "SET_PARENT_PRODUCT";
export const GET_PRODUCTS_PARAMS = "GET_PRODUCTS_PARAMS";
export const START_LOADING_PRODUCTS = "START_LOADING_PRODUCTS";
export const END_LOADING_PRODUCTS = "END_LOADING_PRODUCTS";
export const UPDATE_SINGLE_PRODUCT = "UPDATE_SINGLE_PRODUCT";
export const UPDATE_MULTIPLY_PRODUCT = "UPDATE_MULTIPLY_PRODUCT";
export const GET_PRODUCTS_COUNT = "GET_PRODUCTS_COUNT";
export const SHOW_INACTIVE_PRODUCTS = "SHOW_INACTIVE_PRODUCTS";
export const BULK_UPDATE_PRODUCTS_CATEGORY = "BULK_UPDATE_PRODUCTS_CATEGORY";
export const SELECTED_PRODUCTS = "SELECTED_PRODUCTS";
export const SELECTED_CHILDRENS_OF_PRODUCTS = "SELECTED_CHILDRENS_OF_PRODUCTS";
export const CATALOG_GET_FILTER_FIELD = "CATALOG_GET_FILTER_FIELD";
export const CATALOG_GET_SWITCH = "CATALOG_GET_SWITCH";
export const RESET_CATALOG_ACTION_FILTER = "RESET_CATALOG_ACTION_FILTER";
export const RESET_CATALOG_ACTION_SWITCH = "RESET_CATALOG_ACTION_SWITCH";
export const SET_SEARCH_PRODUCTS = "SET_SEARCH_PRODUCTS";

const getProducts = (payload) => ({
  type: GET_PRODUCTS,
  payload,
});

const productsExist = (payload) => ({
  type: EXIST_PRODUCTS,
  payload,
});

const productsCountWithVariations = (payload) => ({
  type: PRODUCTS_WITH_VARIATIONS_COUNT,
  payload,
});

export const setParentProduct = (payload) => ({
  type: SET_PARENT_PRODUCT,
  payload,
});

const getProductsParams = (payload) => ({
  type: GET_PRODUCTS_PARAMS,
  payload,
});

const updateSingleProduct = (payload) => ({
  type: UPDATE_SINGLE_PRODUCT,
  payload,
});

const updateMultiplyProducts = (payload) => ({
  type: UPDATE_MULTIPLY_PRODUCT,
  payload,
});

const getProductsCount = (payload) => ({
  type: GET_PRODUCTS_COUNT,
  payload,
});

export const setShowInactiveProducts = (payload) => ({
  type: SHOW_INACTIVE_PRODUCTS,
  payload,
});

const bulkUpdateProductsCategory = (payload) => ({
  type: BULK_UPDATE_PRODUCTS_CATEGORY,
  payload,
});

export const selectedProductsAction = (payload) => ({
  type: SELECTED_PRODUCTS,
  payload,
});

export const selectedChildrensOfProductsAction = (payload) => ({
  type: SELECTED_CHILDRENS_OF_PRODUCTS,
  payload,
});

export const catalogGetFilterField = (payload) => ({
  type: CATALOG_GET_FILTER_FIELD,
  payload,
});

export const catalogGetSwitchField = (payload) => ({
  type: CATALOG_GET_SWITCH,
  payload,
});

export const resetCatalogActionsFilter = (payload) => ({
  type: RESET_CATALOG_ACTION_FILTER,
  payload,
});

export const resetCatalogActionsSwitch = (payload) => ({
  type: RESET_CATALOG_ACTION_SWITCH,
  payload,
});

export const setSearchProductsAction = (payload) => ({
  type: SET_SEARCH_PRODUCTS,
  payload,
});

const startLoadingProducts = () => ({ type: START_LOADING_PRODUCTS });
const endLoadingProducts = () => ({ type: END_LOADING_PRODUCTS });

export const getProductsAction = ({
  query = {},
  onSuccess,
  fetchAll = false,
} = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const page = query?.page || 1;
    const limit = query?.limit ?? SCROLL_LIMIT;

    dispatch(startLoadingProducts());
    getProductsService({
      ...state.products.params,
      ...query,
      status: state.products.showInactive
        ? '["inactive"]'
        : '["active", "inactive_for_customers"]',
      ...(!fetchAll?.skipLimit && limit),
      page,
    })
      .then((res) => {
        onSuccess && onSuccess(res.rows);
        dispatch(productsCountWithVariations(res.countWithVariations));
        dispatch(productsExist(res.existData));
        dispatch(
          getProducts({
            products: res?.rows,
            isScrolling: page > 1,
          })
        );
        dispatch(getProductsCount(res?.count));
        dispatch(endLoadingProducts());
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
        dispatch(endLoadingProducts());
      });
  };
};

export const createSingleProductAction = (data, navigate, setError) => {
  return (dispatch, getState) => {
    const state = getState();
    const permissions = state?.auth?.currentUser?.permissions;

    const permissionOrderView = !permissions || permissions?.orders?.view;

    dispatch(startLoadingProducts());
    createSingleProductService(data)
      .then(() => {
        // dispatch(setFormChangedAction(false));
        dispatch(endLoadingProducts());
        if (navigate) navigate();
        dispatch(getTagsAction({ limit: SCROLL_LIMIT }));
        dispatch(getProductsAction());
        dispatch(getTotalSummariesCatalogAction());
        if (permissionOrderView) dispatch(getTotalSummariesOrdersAction());
        success("Product created");
      })
      .catch((err) => {
        dispatch(endLoadingProducts());

        if (err.response.data.uniqueError) {
          setError("barcode", {
            type: "required",
            message:
              "This barcode has been already used on the existing product",
          });
        } else {
          error(err?.response?.data?.message || "Something went wrong.");
        }
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const resetGetActionsFilter = () => {
  return (dispatch) => {
    dispatch(resetCatalogActionsFilter());
  };
};
export const resetGetActionsSwitchCatalog = () => {
  return (dispatch) => {
    dispatch(resetCatalogActionsSwitch());
  };
};

export const resetGlobalCatalogActions = () => {
  return (dispatch) => {
    dispatch(resetGetActionsSwitchCatalog());
    dispatch(resetCatalogActionsFilter());
  };
};
export const updateSingleProductAction = ({
  id,
  data,
  navigate,
  setError,
  onSuccess,
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const permissions = state?.auth?.currentUser?.permissions;

    const permissionOrderView = !permissions || permissions?.orders?.view;

    dispatch(startLoadingProducts());
    updateSingleProductService(id, data)
      .then((res) => {
        // dispatch(setFormChangedAction(false));
        dispatch(setEditTypeAction("product"));
        dispatch(endLoadingProducts());
        dispatch(updateSingleProduct(res));
        dispatch(getProductsAction());
        // dispatch(getProductCategoriesAction());
        dispatch(getTagsAction({ limit: SCROLL_LIMIT }));
        dispatch(getTotalSummariesCatalogAction());
        if (permissionOrderView) dispatch(getTotalSummariesOrdersAction());
        if (navigate) navigate("/catalog");
        if (onSuccess) {
          onSuccess(res);
        } else {
          success("Product updated");
        }
      })
      .catch((err) => {
        dispatch(endLoadingProducts());

        if (err?.response?.data?.uniqueError) {
          setError("barcode", {
            type: "required",
            message:
              "This barcode has been already used on the existing product",
          });
        } else {
          error(err?.response?.data?.message || "Something went wrong.");
        }
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const deleteSingleProductAction = ({ id, onSuccess }) => {
  return (dispatch, getState) => {
    const state = getState();
    const permissions = state?.auth?.currentUser?.permissions;

    const permissionOrderView = !permissions || permissions?.orders?.view;

    dispatch(startLoadingProducts());
    deleteProductService(id)
      .then(() => {
        success("Product deleted");
        dispatch(selectedProductsAction([]));
        dispatch(selectedChildrensOfProductsAction([]));
        dispatch(getProductsAction());
        dispatch(getTotalSummariesCatalogAction());
        if (permissionOrderView) dispatch(getTotalSummariesOrdersAction());
        dispatch(endLoadingProducts());
        !!onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(endLoadingProducts());
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const createMultipleProductAction = (
  data,
  navigate,
  setError,
  variationsFields
) => {
  return (dispatch, getState) => {
    const state = getState();
    const permissions = state?.auth?.currentUser?.permissions;

    const permissionOrderView = !permissions || permissions?.orders?.view;

    dispatch(startLoadingProducts());
    createMultipleProductService(data)
      .then(() => {
        // dispatch(setFormChangedAction(false));
        dispatch(endLoadingProducts());
        if (navigate) navigate();
        dispatch(getProductsAction());
        dispatch(getTagsAction({ limit: SCROLL_LIMIT }));
        dispatch(getTotalSummariesCatalogAction());
        if (permissionOrderView) dispatch(getTotalSummariesOrdersAction());
        success("Product created");
      })
      .catch((err) => {
        dispatch(endLoadingProducts());

        const existBarcodes = err?.response?.data?.existBarcodes;

        if (existBarcodes?.length) {
          existBarcodes.forEach((bar) => {
            const index = variationsFields.findIndex(
              (item) => item.barcode === bar
            );
            setError(`variationsFields[${index}].barcode`, {
              type: "required",
              message:
                "This barcode has been already used on the existing product",
            });
          });
        } else {
          error(err?.response?.data?.message || "Something went wrong.");
        }
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const catalogGetFiltersAction = (data) => {
  return (dispatch) => {
    dispatch(catalogGetFilterField(data));
  };
};
export const catalogGetSwitchFieldActions = (data) => {
  return (dispatch) => {
    dispatch(catalogGetSwitchField(data));
  };
};

export const updateMultipleProductActions = ({
  id,
  data,
  navigate,
  setError,
  variationsFields,
  onSuccess,
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const permissions = state?.auth?.currentUser?.permissions;

    const permissionOrderView = !permissions || permissions?.orders?.view;

    dispatch(startLoadingProducts());
    updateMultipleProductService(id, data)
      .then((res) => {
        // dispatch(setFormChangedAction(false));
        dispatch(setEditTypeAction("product"));
        dispatch(endLoadingProducts());
        dispatch(updateMultiplyProducts(res));
        dispatch(getProductsAction());
        dispatch(getTagsAction({ limit: SCROLL_LIMIT }));
        dispatch(getTotalSummariesCatalogAction());
        if (permissionOrderView) dispatch(getTotalSummariesOrdersAction());
        if (navigate) navigate("/catalog");
        if (onSuccess) {
          onSuccess(res);
        } else {
          success("Product updated");
        }
      })
      .catch((err) => {
        dispatch(endLoadingProducts());

        const existBarcodes = err?.response?.data?.existBarcodes;

        if (existBarcodes?.length) {
          existBarcodes.forEach((bar) => {
            const index = variationsFields.findIndex(
              (item) => item.barcode === bar
            );
            setError(`variationsFields[${index}].barcode`, {
              type: "required",
              message:
                "This barcode has been already used on the existing product",
            });
          });
        } else {
          error(err?.response?.data?.message || "Something went wrong.");
        }
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const archiveProductsAction = (productsIds, active) => {
  return (dispatch, getState) => {
    const state = getState();
    const permissions = state?.auth?.currentUser?.permissions;
    const loading = state?.products?.loading;
    if (loading) return;

    const permissionOrderView = !permissions || permissions?.orders?.view;

    dispatch(startLoadingProducts());
    archiveProductsService({ productsIds, active })
      .then(() => {
        dispatch(getProductsAction());
        dispatch(selectedProductsAction([]));
        dispatch(selectedChildrensOfProductsAction([]));
        dispatch(getTotalSummariesCatalogAction());
        if (permissionOrderView) dispatch(getTotalSummariesOrdersAction());
        success(`Products ${active ? "unarchived" : "archived"}`);
        dispatch(endLoadingProducts());
      })
      .catch((err) => {
        dispatch(endLoadingProducts());
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const deleteMultipleProductAction = ({ id, onSuccess }) => {
  return (dispatch, getState) => {
    const state = getState();
    const permissions = state?.auth?.currentUser?.permissions;
    const loading = state?.products?.loading;
    if (loading) return;

    const permissionOrderView = !permissions || permissions?.orders?.view;

    dispatch(startLoadingProducts());
    deleteProductService(id)
      .then(() => {
        success("Product deleted");
        dispatch(getProductsAction());
        dispatch(getTotalSummariesCatalogAction());
        if (permissionOrderView) dispatch(getTotalSummariesOrdersAction());
        dispatch(selectedProductsAction([]));
        dispatch(selectedChildrensOfProductsAction([]));
        !!onSuccess && onSuccess();
      })
      .catch((err) => {
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      })
      .finally(() => {
        dispatch(endLoadingProducts());
      });
  };
};

export const getProductsParamsAction = (data) => {
  return (dispatch) => {
    dispatch(getProductsParams(data));
    dispatch(getProductsAction({ query: { limit: SCROLL_LIMIT } }));
  };
};

export const resetNewProductFilter = () => {
  return (dispatch) => {
    dispatch(
      getProductsParamsAction({
        manufacturer_id: "",
        category_id: null,
        tag_ids: [],
        sort_by_name: "asc",
        sort_by_category: null,
        sort_by_manufacturer: null,
        wholesale_price_greater: "",
        wholesale_price_lesser: "",
        wholesale_price_equals: "",
        search: "",
        countries_of_origin: [],
      })
    );
    dispatch(setSearchProductsAction(""));
  };
};
export const resetProductsGetParamsAction = (manufacturerId) => {
  return (dispatch) => {
    dispatch(setShowInactiveProducts(false));
    dispatch(
      getProductsParamsAction({
        search: "",
        category_id: null,
        manufacturer_id: manufacturerId,
        tag_ids: [],
        wholesale_price_greater: null,
        wholesale_price_lesser: null,
        wholesale_price_equals: null,
        stock: [],
      })
    );
  };
};

export const bulkUpdateProductsCategoryActions = (categoryId, productIds) => {
  return (dispatch, getState) => {
    const state = getState();
    const permissions = state?.auth?.currentUser?.permissions;
    const loading = state?.products?.loading;
    if (loading) return;

    const permissionOrderView = !permissions || permissions?.orders?.view;

    dispatch(startLoadingProducts());
    bulkUpdateProductsCategoryService(categoryId, productIds)
      .then((res) => {
        dispatch(endLoadingProducts());
        dispatch(bulkUpdateProductsCategory(res));
        dispatch(getProductsAction());
        // dispatch(getProductCategoriesAction());
        dispatch(selectedProductsAction([]));
        dispatch(selectedChildrensOfProductsAction([]));
        dispatch(getTotalSummariesCatalogAction());
        if (permissionOrderView) dispatch(getTotalSummariesOrdersAction());
        success("Products category updated");
      })
      .catch((err) => {
        dispatch(endLoadingProducts());
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const bulkUpdateProductsAssignmentAction = (
  manufacturerId,
  productIds
) => {
  return (dispatch, getState) => {
    const {
      products: { loading },
    } = getState();

    if (loading) return;

    dispatch(startLoadingProducts());
    bulkUpdateProductsAssignmentService(manufacturerId, productIds)
      .then(() => {
        dispatch(endLoadingProducts());
        dispatch(getProductsAction());
        dispatch(selectedProductsAction([]));
        dispatch(selectedChildrensOfProductsAction([]));
        success("Product(s) updated");
      })
      .catch((err) => {
        dispatch(endLoadingProducts());
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};

export const bulkDeleteProductsAction = ({ productsIds, onSuccess }) => {
  return (dispatch, getState) => {
    const state = getState();
    const permissions = state?.auth?.currentUser?.permissions;

    const permissionOrderView = !permissions || permissions?.orders?.view;

    dispatch(startLoadingProducts());
    bulkDeleteProductsService(productsIds)
      .then(() => {
        success("Products deleted");
        dispatch(getProductsAction());
        dispatch(getTotalSummariesCatalogAction());
        if (permissionOrderView) dispatch(getTotalSummariesOrdersAction());
        dispatch(endLoadingProducts());
        !!onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch(endLoadingProducts());
        error(err?.response?.data?.message || "Something went wrong.");
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
      });
  };
};
