import { array, object, func, bool } from "prop-types";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { StyledAvatar } from "components";
import { photoUrl } from "helpers/helpers";
import { useSelector } from "react-redux";
import { ThirdPartyCircleIcon } from "components/Icons";

export const RepBlock = ({ list, checkedRep, handleCheckRep, loading }) => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const prepareRole = (role) => {
    switch (role) {
      case "MERCHANDISER":
        return "Merchandiser";

      case "SALES":
        return "Sales";

      case "THIRD_PARTY":
        return "3rd Party";

      default:
        return role;
    }
  };

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress color="inherit" size={20} />
    </Box>
  ) : (
    <Box py="18px" px="20px">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          borderRadius: "8px",
          height: "39px",
          px: "12px",
        }}
      >
        <FormControlLabel
          control={
            <Radio
              sx={{
                p: "6px",
                ml: "-2px",
                "& svg": {
                  width: "20px",
                  height: "20px",
                },
              }}
              checked={currentUser?.id === checkedRep?.id}
              onChange={() => {
                handleCheckRep({ id: currentUser?.id, role: "distributor" });
              }}
            />
          }
          label={
            <Box display="flex" alignItems="center" width="460px">
              <StyledAvatar
                sx={{
                  height: "21px",
                  width: "21px",
                  border: "1px solid #CBCBCB",
                }}
                src={photoUrl(currentUser.profilePhoto?.fileName)}
                stringStyle={{ fontSize: "25px" }}
              />
              <Typography
                fontSize={17}
                fontWeight={300}
                color="#707070"
                ml="7px"
                noWrap
              >
                {currentUser?.name}
              </Typography>
              <Typography
                fontSize={17}
                fontWeight={300}
                color="#B2B2B2"
                ml="4px"
                noWrap
                sx={{
                  width: "fit-content",
                  maxWidth: "100%",
                  minWidth: "fit-content",
                }}
              >
                {"|"} Admin
              </Typography>
            </Box>
          }
        />
      </Box>

      {list?.map((item) => (
        <Box
          key={item?.id}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            borderRadius: "8px",
            height: "39px",
            px: "12px",
          }}
        >
          <FormControlLabel
            control={
              <Radio
                sx={{
                  p: "6px",
                  ml: "-2px",
                  "& svg": {
                    width: "20px",
                    height: "20px",
                  },
                }}
                checked={item?.id === checkedRep?.id}
                onChange={() => handleCheckRep(item)}
              />
            }
            label={
              <Box display="flex" alignItems="center" width="460px">
                {item?.role === "THIRD_PARTY" ? (
                  <ThirdPartyCircleIcon width={21} height={21} />
                ) : (
                  <StyledAvatar
                    sx={{
                      height: "21px",
                      width: "21px",
                      border: "1px solid #CBCBCB",
                    }}
                    src={photoUrl(item.profilePhoto?.fileName)}
                    stringStyle={{ fontSize: "25px" }}
                  />
                )}
                <Typography
                  fontSize={17}
                  fontWeight={300}
                  color="#707070"
                  ml="7px"
                  noWrap
                >
                  {item?.name}
                </Typography>
                <Typography
                  fontSize={17}
                  fontWeight={300}
                  color="#B2B2B2"
                  ml="4px"
                  noWrap
                  sx={{
                    width: "fit-content",
                    maxWidth: "100%",
                    minWidth: "fit-content",
                  }}
                >
                  {"|"} {prepareRole(item?.role)}
                </Typography>
              </Box>
            }
          />
        </Box>
      ))}
    </Box>
  );
};

RepBlock.propTypes = {
  list: array,
  checkedRep: object,
  handleCheckRep: func,
  loading: bool,
};
RepBlock.defaultProps = {
  list: [],
  checkedRep: {},
  handleCheckRep: () => {},
  loading: false,
};
