import { useEffect, useMemo, useRef, useState } from "react";
import { string, func, object, oneOfType } from "prop-types";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";

import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { StyledTextField } from "../../../../components/TextFields/TextFields";
import useStyles, { cl } from "./styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ChildItem } from "./ChildItem";
import ClearIcon from "@mui/icons-material/Clear";

import { useDebounce } from "../../../../helpers/hooks";
import { getItemWithId } from "./helpers";

import {
  categoriesListSelector,
  isLoadingCategoriesSelector,
} from "../../../../redux/selectors/categories";
import { getCategoriesListAction } from "../../../../redux/actions/categories";

const selector = createSelector(
  isLoadingCategoriesSelector,
  categoriesListSelector,
  (isLoadingCategories, categoriesList) => ({
    isLoadingCategories,
    categoriesList,
  })
);

export const CategoriesComponent = ({
  value,
  selectLabel,
  handleSetTemporaryFields,
  fetchQueryParams,
}) => {
  const { control, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      category: "",
    },
  });

  const formField = useWatch({ control });
  const classes = useStyles();
  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const { isLoadingCategories, categoriesList } = useSelector(selector);

  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [showCategories, setShowCategories] = useState([]);
  const searchInputDebounced = useDebounce(searchInput, 500);

  useEffect(() => {
    if (!open) return;
    dispatch(
      getCategoriesListAction({
        search: searchInputDebounced,
        ...fetchQueryParams,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchInputDebounced, fetchQueryParams?.used, open]);

  useEffect(() => {
    handleSetTemporaryFields(formField?.category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField?.category]);

  useEffect(() => {
    setShowCategories(categoriesList);
  }, [categoriesList]);

  useEffect(() => {
    if (value) setValue("category", value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parentCategory = useMemo(() => {
    const hasParent = getItemWithId(
      categoriesList,
      showCategories.find((category) => category?.parentCategoryId)
        ?.parentCategoryId
    );

    return (
      !!hasParent && (
        <Box
          sx={{
            "&:hover": { backgroundColor: "#F7F7F7" },
            borderRadius: "4px",
            width: "100%",
            height: "26px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            ml: "-16px",
          }}
          onClick={() => {
            setValue("category", hasParent);
          }}
        >
          <IconButton
            sx={{
              width: "26px",
              height: "26px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (hasParent?.parentCategoryId) {
                setShowCategories(
                  getItemWithId(categoriesList, hasParent?.parentCategoryId)
                    ?.childCategories
                );
              }
              if (hasParent?.parentCategoryId === null) {
                setShowCategories(categoriesList);
              }
            }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#1C1C19",
            }}
          >
            {hasParent?.name}
          </Typography>
        </Box>
      )
    );
  }, [categoriesList, setValue, showCategories]);

  const categoryField = useMemo(() => {
    return (
      <Paper data-testid="filter-category-list" sx={cl.dropdownList} elevation={0}>
        {parentCategory}
        {showCategories?.map((category) => (
          <Box
            data-testid='filter-category-item'
            key={category?.id}
            sx={{
              "&:hover": { backgroundColor: "#F7F7F7" },
              borderRadius: "4px",
              width: "100%",
              height: "26px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(false);
              setValue("category", category);
            }}
          >
            <Typography
              sx={{
                pl: "10px",
                fontSize: 12,
                fontWeight: 300,
                color: "#1C1C19",
              }}
            >
              {category?.name}
            </Typography>
            {!!category?.childCategories?.length && (
              <IconButton
                sx={{
                  width: "26px",
                  height: "26px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCategories(
                    getItemWithId(categoriesList, category?.id)?.childCategories
                  );
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            )}
          </Box>
        ))}
      </Paper>
    );
  }, [categoriesList, parentCategory, setValue, showCategories]);

  const getCurrentCategoriesList = useMemo(() => {
    return searchInput ? categoriesList : [];
  }, [categoriesList, searchInput]);

  return (
    <Grid xs={12} item position="relative">
      <Controller
        render={({ field }) => (
          <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => {
              setSearchInput("");
              setOpen(false);
            }}
            fullWidth
            disableClearable={!field.value ? true : false}
            onInputChange={(event, option, reason) => {
              if (reason === "clear") setSearchInput("");
            }}
            loading={isLoadingCategories}
            PaperComponent={(props) => {
              return <Box {...props} className={classes.recipientsTable} />;
            }}
            size="small"
            isOptionEqualToValue={(option, value) => {
              return option.id === value;
            }}
            sx={cl.search}
            className={classes.textfield}
            getOptionLabel={(option) => {
              return option.name || "";
            }}
            renderInput={(params) => (
              <StyledTextField
                placeholder={selectLabel}
                className={classes.textfield}
                value={searchInput}
                inputRef={inputRef}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                sx={cl.searchInput}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params?.InputProps?.endAdornment}
                      {!!searchInput && !field.value && (
                        <IconButton
                          sx={{ p: 0, mr: "29px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setSearchInput("");
                            setValue("category", "");
                            inputRef.current.blur();
                          }}
                        >
                          <ClearIcon sx={{ width: "20px", height: "20px" }} />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShowCategories([...categoriesList]);
                }}
              />
            )}
            ListboxProps={{ sx: cl.searchListBoxProps }}
            classes={{
              noOptions: classes.noOptions,
            }}
            noOptionsText={
              categoriesList.length ? (
                categoryField
              ) : (
                <Typography
                  sx={{
                    pl: "10px",
                    fontSize: 13,
                    fontWeight: 300,
                    color: "#1C1C19",
                    flexGrow: 1,
                  }}
                >
                  No categories found
                </Typography>
              )
            }
            popupIcon={<ExpandMoreIcon style={{ color: "#B5B5AC" }} />}
            renderOption={(props, recipient) => {
              return (
                <ChildItem
                  {...props}
                  category={recipient}
                  setValue={setValue}
                />
              );
            }}
            options={getCurrentCategoriesList}
            {...field}
            onChange={(e, newValue) => {
              setValue(field.name, newValue);
            }}
          />
        )}
        name="category"
        control={control}
      />
    </Grid>
  );
};

CategoriesComponent.propTypes = {
  value: oneOfType([object, string]),
  selectLabel: string,
  handleSetTemporaryFields: func,
  fetchQueryParams: object,
};
CategoriesComponent.defaultProps = {
  selectLabel: "Search product categories",
  fetchQueryParams: {},
};
